<template>
    <div>
    <section v-html="privacyContent"></section>
</div>
</template>

<script>

import axios from "axios";

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
    data() {
        return {

            privacyContent:"",
            getPrivacyUrl: "",
            privacyType: "",
        }
    },

    methods: {

        getConfig() {
            // let getConfigData = this.$store.state.data

            // let portalLocal = localStorage.getItem("portalData")
            // let getConfigData = JSON.parse(portalLocal)
            const getConfigData = getConfigDataFromLocalStorage();
            if (getConfigData) {
                let locale = this.getLangname;
                
                // console.log(getConfigData, 'head...about..1..')
                // console.log(this.getPrivacyUrl, 'head...about..2..')

                let siteContentApi = getConfigData.payload.portal_configuration.site_content_data[locale];
                if(siteContentApi){
                    this.getPrivacyUrl = siteContentApi.privacypolicy.url
                    this.privacyType = siteContentApi.privacypolicy.type
                }
                this.getConfigFunc();
            }
        },

        getConfigFunc() {
            axios.get(this.getPrivacyUrl, {
                headers: {
                    "Content-Type": this.privacyType,
                },
            })
                .then((response) => {

                    this.privacyContent = response.data
                    // console.log(response.data, "getPrivacyUrlrgetPrivacyUrlrgetPrivacyUrlrab.....")

                })

                .catch((error) => {
                    console.log(error, 'erroroor.....')
                })
        },
        updateContent() {
            this.getLangname = this.$i18n.locale;
            console.log(this.$i18n.locale, 'getcontententUrl...1')
            this.getConfig();
        }
    },

    created() {
            this.updateContent();
            // this.getConfig();
            this.getConfigFunc();
    },
    watch:{
        "$i18n.locale": {
			handler: function () {
				this.updateContent();
				this.getConfig();
				this.getConfigFunc();
			},
			deep: true,
		},
    },
}
</script>

<style scoped>
@import "../../assets/css/style-04.css";
@import "../../assets/css/style-responsive.css";
@import "../../assets/css/bootstrap.min.css";
@import "../../assets/css/custom.css";
@import "../../assets/css/slick/slick.css";
@import "../../assets/css/slick/slick-theme.css";
</style>
<template>
  <!--Footer start-->
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
          <h2>{{ $t('footerContents.usefulinks') }}</h2>

          <div class="row flink">
            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <a href="/aboutus" style="text-decoration: none"
                ><i class="fa fa-institution"></i>{{ $t('aboutUsContent.heading') }}</a
              >

              <a href="/contact" style="text-decoration: none"
                ><i class="fa fa-phone"></i>{{ $t('footerContents.contact') }}</a
              >
              <!-- <a href="#"><i class="fa fa-hotel"></i>Hotels</a>

              <a href="#"><i class="fa fa-anchor"></i>Holidays</a>

              <a href="#"><i class="fa fa-suitcase"></i>Bus</a>

              <a href="#"><i class="fa fa-home"></i>Cab</a> -->
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <a href="/privacyPolicy" style="text-decoration: none">
                <i class="fa fa-lock"></i> {{ $t('flightInfoContent.contents.privacyPolicy') }}
              </a>
              <a href="/termsofuse" style="text-decoration: none">
                <i class="fa fa-file-contract"></i> {{ $t('footerContents.termsUse') }}
              </a>

              <!-- <a href="blog.html"><i class="fa fa-wordpress"></i>Blog</a>

              <a href="#"><i class="fa fa-share"></i>Timeline</a> -->
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <!-- <a href="#"
                ><i class="fa fa-laptop"></i>Coming Soon</a
              >

              <a href="#"
                ><i class="fa fa-desktop"></i>Maintenance</a
              >

              <a href="#"><i class="fa fa-info"></i>404 Error</a>

              <a href="#"><i class="fa fa-file"></i>Common page</a> -->
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <h2>
            {{ $t('footerContents.about') }} <span class="text-lowercase">{{ portalLegalName }}</span>
          </h2>

          <p>
            {{ $t('footerContents.text1') }}
          </p>

          <p>
            {{ $t('footerContents.text2') }}
          </p>

          <a href="#" class="read d-none">Read More</a>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <h2>{{ $t('footerContents.contactinfo') }}</h2>

          <h4><i class="fa fa-phone"></i> {{ phonePortal }}</h4>

          <!-- <h5><i class="fa fa-clock-o"></i> (07:30 - 23:59 Hrs, Mon - Fri)</h5> -->

          <h6><i class="fa fa-envelope-o"></i> {{ emailPortal }}</h6>
          <h5 style="font-size:14px;">TOLL FREE NUMBER : 1 833 468 6759</h5>

          <div class="social d-none">
            <a href="#!"><i class="fab fa-twitter"></i></a>
            <a href="#!"><i class="fab fa-facebook"></i></a>
            <a href="#!"><i class="fab fa-google-plus"></i></a>
            <a href="#!"><i class="fab fa-instagram"></i></a>
            <a href="#!"><i class="fab fa-youtube"></i></a>
          </div>

          <div class="backto">
            <a href="/">{{ $t('footerContents.home') }}</a> |
            <a href="javascript:void(0);" @click="scrollToTop">{{ $t('footerContents.backtotop') }} <i class="fa fa-long-arrow-up"></i></a>
          </div>
        </div>
      </div>
    </div>

    <div class="payment d-none">
      <span>We Accept</span>
    </div>

    <div class="container d-none">
      <img :src="require('@/assets/img/payment.png')" alt="" />
    </div>

    <div class="copy">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            &copy; {{ new Date().getFullYear() }} {{ portalLegalName }}.{{ $t('footerContents.allRights') }}
          </div>

          <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-right">
            Design by <a class="designedby" href="#!">Mohammad</a>
          </div> -->
        </div>
      </div>
    </div>
  </footer>
  <!--Footer end-->
</template>

<script>
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
export default {
  data() {
    return {
      portalUrl: "",
      portalLogo: "",
      portalLegalName: "",
      phonePortal: "",
      emailPortal: "",
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    getConfig() {
      const getConfigData = getConfigDataFromLocalStorage();

      if (getConfigData) {
        this.portalUrl = getConfigData.payload.portal_configuration.portal_url;
        this.portalLogo = getConfigData.payload.portal_configuration.logo_path;
        this.portalLegalName =
          getConfigData.payload.portal_configuration.portal_legal_name;
        this.phonePortal = getConfigData.payload.portal_configuration.phone;
        this.emailPortal = getConfigData.payload.portal_configuration.email;
      }
    },
  },
  created() {
    this.getConfig();
  },
};
</script>

<style scoped>
@import "../../assets/css/style-04.css";
@import "../../assets/css/style-responsive.css";
@import "../../assets/css/bootstrap.min.css";
@import "../../assets/css/custom.css";
@import "../../assets/css/slick/slick.css";
@import "../../assets/css/slick/slick-theme.css";
</style>

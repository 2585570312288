

<template>
  <!--Blog start-->
  <section class="blog-bg d-none">
    <div class="container ">
      <div class="row justify-content-center">
        <div class="col-10">
          <h3>Read our news & blogs</h3>
          <h6>
            Our duty towards you is to share our experience we're reaching in
            our work path with you.
          </h6>
        </div>
      </div>

      <div class="row">
        <!--blog item 01-->
        <div class="col-12 col-md-6 col-lg-6">
          <div class="blog-item">
            <div class="row">
              <div class= "col-12 col-sm-7 col-md-12 col-lg-7">
                <div class="blog-text">
                  <a href="#"  style="text-decoration: none; color: black;">
                    <h2>How to keep your SEO rankings</h2>
                  </a>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipiscing elit arcu
                    urna.
                  </p>
                </div>
              </div>
              <div class="col-12 col-sm-5 col-md-12 col-lg-5">
                <a href="#"  style="text-decoration: none; color: black;">
                  <img :src="require('@/assets/img/about-02.jpg')" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--blog item 02-->
        <div class="col-12 col-md-6 col-lg-6">
          <div class="blog-item">
            <div class="row">
              <div class="col-12 col-sm-7 col-md-12 col-lg-7">
                <div class="blog-text">
                  <a href="#"  style="text-decoration: none; color: black;">
                    <h2>The best rebrands of the year</h2>
                  </a>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipiscing elit arcu
                    urna.
                  </p>
                </div>
              </div>
              <div class="col-12 col-sm-5 col-md-12 col-lg-5">
                <a href="#"  style="text-decoration: none; color: black;">
                  <img :src="require('@/assets/img/about-03.jpg')" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--blog item 03-->
        <div class="col-12 col-md-6 col-lg-6">
          <div class="blog-item">
            <div class="row">
              <div class="col-12 col-sm-7 col-md-12 col-lg-7">
                <div class="blog-text">
                  <a href="#"  style="text-decoration: none; color: black;">
                    <h2>Key benefits of my portfolio update</h2>
                  </a>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipiscing elit arcu
                    urna.
                  </p>
                </div>
              </div>
              <div class="col-12 col-sm-5 col-md-12 col-lg-5">
                <a href="#"  style="text-decoration: none; color: black;">
                  <img :src="require('@/assets/img/about-04.jpg')" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--blog item 04-->
        <div class="col-12 col-md-6 col-lg-6">
          <div class="blog-item">
            <div class="row">
              <div class="col-12 col-sm-7 col-md-12 col-lg-7">
                <div class="blog-text">
                  <a href="#"  style="text-decoration: none; color: black;">
                    <h2>Reasons to go responsive</h2>
                  </a>
                  <p>
                    Lorem ipsum dolor sit amet consectetur, adipiscing elit arcu
                    urna.
                  </p>
                </div>
              </div>
              <div class="col-12 col-sm-5 col-md-12 col-lg-5">
                <a href="#"  style="text-decoration: none; color: black;">
                  <img :src="require('@/assets/img/about-05.jpg')" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import topdestinationdata from '@/topdestination.json';

export default {
  data(){
    return{
      // topdestination: topdestinationdata
    }
  }
}
</script>

<style scoped>
@import "../../assets/css/style-04.css";
@import "../../assets/css/style-responsive.css";
@import "../../assets/css/bootstrap.min.css";
@import "../../assets/css/custom.css";
@import "../../assets/css/slick/slick.css";
@import "../../assets/css/slick/slick-theme.css";
</style>
<template>
  <section class="tour">
    <div class="container">
      <h2>
        Top Destination
        <span class="d-none">Hurry Up, These are expiring soon.</span>
        <a href="#" class="view-more d-none">View More</a>
      </h2>

      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-3">
          <a href="#" class="tour-block">
            <img :src="require('@/assets/img/tour/t1.jpg')" alt="" />

            <h2>Prague</h2>
          </a>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3">
          <a href="#" class="tour-block">
            <img :src="require('@/assets/img/tour/t2.jpg')" alt="" />

            <h2>Barcelona</h2>
          </a>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3">
          <a href="#" class="tour-block">
            <img :src="require('@/assets/img/tour/t3.jpg')" alt="" />

            <h2>New York</h2>
          </a>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3">
          <a href="#" class="tour-block">
            <img :src="require('@/assets/img/tour/t4.jpg')" alt="" />

            <h2>Paris</h2>
          </a>
        </div>
      </div>
    </div>
  </section>
  <!--Tour Package end-->
</template>

<script>
import recommendationsdata from '@/recommendations.json';

export default {
    data() {
        return {
            recommendations: recommendationsdata
        };
    }
};
</script>

<style scoped>
@import "../../assets/css/style-04.css";
@import "../../assets/css/style-responsive.css";
@import "../../assets/css/bootstrap.min.css";
@import "../../assets/css/custom.css";
@import "../../assets/css/slick/slick.css";
@import "../../assets/css/slick/slick-theme.css";
</style>

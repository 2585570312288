import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import vuetify from "./plugins/vuetify";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import './assets/css/bootstrap.min.css';
import './assets/css/style-responsive.css';
import i18n from '@/plugins/i18n';

createApp(App)
    .use(vuetify)
    .use(router)
    .use(i18n)
    .component('VueDatePicker', VueDatePicker)
    .mount('#app')

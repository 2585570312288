export default {
  headerContents: {
    welcome: 'Welcome to',
    loginorcreate: 'Login or Create Account',
    support: '24/7 Support',
    language: 'Language',
    home:'Home',
    viewMyTrip: 'View My Trip',
    account: 'Account',
    login: 'Login',
    register: 'Register',
    myBooking: 'My Bookings',
    myProfile: 'My Profile',
    logout: 'Log Out'
  },
  homePageContent: {
    welcomeMsg: {
      text1: 'The best tour experience',
      text2: 'find and book best',
      text3: 'Ready for takeoff ? Let your dreams soar with our seamless flight booking experience!',
    },
    contentSection: {
      heading1: 'Amazing travel deals & More',
      subHeading1: ' Explore the best of travel with our exclusive deals for',
      heading2: 'Featured Flights Deals',
      subHeading2: ' Everything You Want & More',
      heading3: 'Flights to top destinations',
      subHeading3: 'Everything You Want & More',
      heading4: 'Amazing Deals Best Prices',
      subHeading4: 'Search & Book in 3 Simple Steps',
      heading5: 'Only Unique Experiences',
      subHeading5: 'Recommendations',
      subheading: 'How it works?',
      from: "From",
      bookNow: "Book Now",
      getPrices: "Get Prices",
      flights: "flights",
      ticket:"ticket",
      seat: "seats",
      tour: "tour"
    },
    label:{
      origin: 'Origin',
      destination: 'Destination',
      selectData: 'Select Date',
      recentSearches: 'Recent Searches',
      flight: 'Flight',
      recent: 'Recent',
      remove: 'Remove',
    },
  },
  advancedOptions:{
    heading: "Advanced Options",
    airline: "Airline",
    airport: "Airport",
    include: "Include",
    exclude: "Exclude",
    stops: "Stops",
    layoverTime: "Layover Time",
    layoverDuration: "Layover Duration",
    avoidCountry: "Avoid Country",
    airlineLabel: "Airline code/Name",
    anyStop: "Any Stop",
    nonStop: "Non Stop",
    upto1Stop: "Upto 1 Stop",
    upto2Stop: "Upto 2 Stops",
    upto3Stop: "Upto 3 Stops",
    nopreference: "No Preference",
    fourStops: "4+ Stops",
    hoursData1: "1-3 hours",
    hoursData2: "3-6 hours",
    hoursData3: "6-12 hours",
    hoursData4: "12+ hours",
    viaAirport: "Via-Airport",
    airportLabel: "Airport code/Name",
    directFlight: "Direct Flight",
    BaggageInclusive: "Baggage Inclusive Only",
    nearbyAirport: "Nearby Airport",
    resetBtn: "Reset",
    doneBtn: "Done"
  },
  formsContents: {
    labels: {
      departure: 'Origin',
      arrival: 'Arrival',
      selectDate: 'Select Date',
      recentSearch: 'Recent searches',
      moreoptions: 'Show More Options',
      departures: 'Departure'
    },
    options: {
      oneWay: 'One-way',
      return: 'Round Trip',
      multiCity: 'Multi-city',
      adult: 'Adult',
      child: 'Child',
      infants: 'Infants',
      travellers: 'Traveller(s)',
      economy: 'Economy',
      premiumEconomy: 'Premium Economy',
      business: 'Business',
      firstClass: 'First Class',
      done: 'Done',
      addCity: 'Add City',
      clearAll: 'Clear All',
      search: 'Search',
      passengers: "Passenger(s)",
      passenger: 'Passenger',
      class: 'Class',
      depart: 'Depart',
      addflight: 'Add another flight',
    }
  }, 
  loaderPageContent:{
    loading:'LOADING',
    loadMsg1:'Find our lowest price to destinations worldwide guranteed',
    loadMsg2:'Search select and save. the fastest way to book your trip',
    loadMsg3:'Get award winning support and special deals',
  },
  searchPageContent:{
    filterContent:{
      filter:'Filters',
      price: 'Price',
      filterSearch : 'FILTER YOUR SEARCH',
      stops : 'Stops',
      stop: 'Stop',
      nonStop: 'Non Stop',
      direct:'Direct',
      fareType : 'Fare Type',
      refund: 'Refundable',
      nonRefund: 'Non Refundable',
      priceRange : 'Price Range',
      avgperPax : 'Avg.per pax',
      airlines : 'Airlines',
      selectAll : 'Select All',
      clearAll : 'Clear All',
      more : 'More',
      showless : 'Show less',
      onwardjourney: "Onward Journey",
      returnjourney: "Return Journey",
      flightDuration:"Flight Duration",
      outBound: "Outbound",
      inBound: "Inbound",
      after: "After",
      before: "Before",
      resetFilter: 'Reset Filters',
      depFrom : 'Departure From',
      arrivalAt : 'Arrival at',
      travellers: 'Traveller(s)',
      adult: 'Adult',
      child: 'Child',
      infant: 'infants',
      economy: 'Economy',
      firstClass: 'First Class',
      business: 'Business',
      premEconomy: 'Premium Economy',
      done: 'Done',
      addCity: 'Add City',
      recommended:'Recommended',
      best: 'Best',
      cheapest: 'Cheapest',
      shortest: 'Shortest',
    },
    itinContent:{
      changeplane: 'Change plane at',
      stop: "STOP",
      bookNow: "Book Now",
      time: "Connecting Time :",
      fareOptions: 'Fare options',
      hideOptions:'Hide options',
      depature : 'Depature',
      return : 'Return',
      arrival : 'Arrival',
      nonRefund : 'Non Refundable',
      refund : 'Refundable',
      flightDetails : 'Flight Details',
      fareDetails: 'Fare Details',
      perPerson: 'Per Person',
      select: 'Select',
      totalPrice: 'Total Price',
      baseprice: 'Base Price',
      taxFees: 'Taxes & Fees',
      pax: 'Pax',
      base: 'Base',
      tax: 'Tax',
      passenger: 'Passengers',
      adult: 'Adult',
      child: 'Child',
      infant: 'Lap Infant',
      adt: 'Adt',
      ch: 'Ch',
      inf: 'Inf',
      text1: "Please enter valid details"
    },
    flightDetails:{
      cabin:'Cabin',
      overView:'OVERVIEW',
      baggage:'BAGGAGE',
      fareDetails:'FARE DETAILS',
      depature:'Depature',
      arrival:'Arrival',
      flight: 'Flight',
      layoverTime:'LayoverTime',
      bookFlight:'Book This Flight',
    },
  },
  brantFareContent: {
    contents:{
      cabin: 'Cabin',
      perperson: 'per person',
    },
    labels: {
      flight: 'Flight',
      selected: 'SELECTED',
      select: 'SELECT'
    },
  },
  fareDetContent:{
    fareDetails : 'Fare Details',
    passenger : 'Passenger',
    adult : 'Adult',
    child : 'Child',
    infrant : 'Infrant',
    checkIn : 'CHECK-IN',
    grandTotal : 'Grand Total',
    fareSummary : 'Fare Summary',
    basePrice : 'Base Price',
    taxesFees : 'Taxes & Fees',
    pax: 'Pax',
    base: 'Base',
    tax: 'Tax',
    perPerson: 'Per Person'
  },
  checkoutcontent:{
    contents:{
    checkout: 'Checkout',
    payNow : 'Pay Now',
    text1: 'By selecting to continue I acknowledge that I have read and accept the Rules & Restrictions, Terms of use, Fare rules and Privacy Policy. In addition, I also confirm that I have verified that all information entered during the reservation process is accurate. I also acknowledge that this will serve as my electronic signature.',
    agree: 'You must agree to continue!',
    },
  },
  flightDetContent:{
    contents:{
      flightSelected: 'Flight Selected',
      reviewBooking: "Review Your Booking",
      review: 'Review',
      traveller: 'Traveller',
      addons: 'Addons',
      makePayment: 'MakePayment',
      flightDetails : 'Flight Details',
      backSearch : 'Back To Search',
      depart : 'DEPART',
      return : 'RETURN',
      fareRules: 'Fare Rules',
      changePlanes : 'Change planes at',
      connectTime : 'Connecting Time',
      connectFlight : 'Connecting flight may depart from a different terminal',
      continue : 'Continue',
    },
  },
  baggageContent:{
    heading: "Baggage and Cancellation Rules",
    cancelRules: "Cancellation Rules",
    baggageRules: "Baggage Rules",
    checkedBag: 'Checked Baggage',
    cabinBag: 'Cabin Baggage',
    carryOn: "Carry-On",
    checked: "CHECKED",
    pieces: 'Pieces(s)',
    piece: 'Piece',
    baggage: 'Baggage',
    checkIn: 'CHECK-IN',
    cabin: 'CABIN',
    errMsg: "Please check the Airline websites"
  },
  flightInfoContent:{
    contents:{
      reserveYourSeat : 'Reserve your seat',
      text1: 'Sit next to family & friends',
      text2: 'More legroom with Premium seats',
      text3: 'Choose a seat close to the exit',
      travellerDetails : 'Traveller Details',
      flightSelected: "Flight Selected",
      flightDetails: "Flight Details",
      bookingDetails: "Booking Details",
      travelDetails:"Travel Details",
      roundTrip: "RoundTrip",
      oneway: "Oneway",
      departure: "Depature",
      arrival: "Arrival",
      return: "Return",
      stop:"Stop",
      flight: "Flight",
      layoverTime: "LayoverTime",
      to: "To",
      adult: 'Adult',
      add:"Add",
      errText: "Please add the required number of",
      child: 'Child',
      infant: 'Infant',
      contactInfo : 'Contact information',
      subHaed : 'Note: All communication related to booking will be sent to this email address and mobile.',
      acknowledgement : 'Acknowledgement',
      agreeText: "You must agree to continue!",
      continue: "Continue",
      privacyPolicy : 'Privacy Policy',
      termsCondition : 'Terms & Conditions',
      ackInfo:"I understand and agree with the",
      labels:{
        firstName : 'First Name',
        middleName : 'Middle Name',
        lastName : 'Last Name',
        gender : 'Gender',
        dateofBirth : 'Date of Birth',
        year : 'Year',
        month : 'Month',
        date : 'Date',
        countryCode : 'Country Code',
        phoneNumber : 'Phone Number',
        emailId : 'Email ID',
      }
    }
  },
  seatMappingContent: {
    contents: {
      txt1: 'Request Your Seats (Optional)',
      passengerdetail: 'Passenger Details',
      price: 'Price',
      availableseat: 'Available Seat',
      occupiedseat : 'Occupied Seat',
      selectedseat : 'Selected Seat',
      requestprice : 'Total Seat Request Price',
      continue : 'Continue',
      skipseat: 'Skip for seat selection',
      fareDetails: 'Fare Details',
      passenger: 'Passenger',
      adult:'Adult',
      child: 'Child',
      infant: 'Infant',
      grandtotal: 'Grand Total',
      faresummary: 'Fare Summary',
    },
  },
  billingDetContent:{
    headingContent:{
      payment: 'Payment',
      billingHeading : 'Billing Details',
      paymentHeading : 'Choose Payment Mode',
      bookingDetHeading : 'Billing Details',
      contactDetails: "Contact Details",
      paymentSub : 'Your Payments are secured by SSL certificate',
    },
    labelsContent:{
      fullName : 'Full Name',
      country : 'Country',
      region : 'Region',
      state : 'State',
      Province : 'Province',
      regionState: "Region/State/Province",
      city : 'City',
      addressLine : 'Address Line',
      postalCode : 'Postal Code',
      countryCode : 'Country Code',
      phoneNumber : 'Phone Number',
      email : 'Email',
      altContact : 'Alternate Contact',
      phone : 'Phone',
      optional: "Optional",
      travellers: "Travellers"
    },
    paymentMode:{
      heading : 'Pay with Credit Card',
      subHead : 'Credit Card',
      payNow : 'Pay Now',
      payNowInfo : 'On Clicking "Pay Now" your card will be used for confirming the booking',
      lebelsContent:{
        cardNumber : 'Card Number',
        NameonCard : 'Name on Card',
        expiryDate : 'Expiry Date',
        expiry: "Expiry (MM/YY)",
        cvvCode : 'CVV Code',
        cvv: "CVV",
        cvvInfo : '3 digits printed on the back of the card',
        txt1: 'Save your card details for faster checkout. CVV is not saved.',
      }
    }
  },
  bookingviewContent:{
    statusText : 'Your Booking is Confirmed!',
    statusSubText : 'Thank you for booking with us. You will soon receive a confirmation via email.',
    headings:{
      bookingID : 'booking ID',
      bookedOn : 'Booked on',
      bookingStatus: "Booking Status",
      depature : 'Depature',
      return : 'Return',
      flight: "Flight",
      layoverTime: "layover Time",
      flightdetails : 'Flight details',
      tripID : 'Trip ID',
      bookingDate : 'Booking Date',
      bookingDetails : 'Booking Details',
      manageTrip : 'Manage Trip',
      travellerDetails : 'Traveller Details',
      contactDetails : 'Contact Details',
      class: "Class",
      modifyBooking: "Modify Your Booking",
      bookingRef: "Booking Reference",
      outBound: "Outbound Between",
      inBound: "Inbound Between",
      notes: "Notes",
      travellerdetails: 'Traveller Details',
      text1:"Please mention your request below.Our support team will be in touch with you at the soonest posible"
    },
    buttonsText:{
      cancelBook : 'Cancel Booking',
      modifyBook : 'Modify Booking',
      print : 'Print',
      startLive : 'Start live chat',
      request: "Request"
    },
    manageTripContent:{
      heading : 'For more information, contact us',
      subHeadings: {
        text1 : 'How much it costs',
        text2 : 'How to change a ticket',
        recommended : 'Recommended',
        reachoutEmail : 'Reach out to us by email at',
        startChat: "Start Live Chat",
      },
      text1: "If changes are allowed by the airline, the following charges apply",
      text2: "airline's penalty when included in the ticket's fare rules",
      text3: "our service fee of 56 CAD per person (not applicable for Platinum Support Package)",
      text4: "any difference in fare and taxes from the original trip",
      chat : 'Chat',
      agreeText: "You must agree to continue!",
      checkBox : 'By using this chat I allow my data to be stored for security and quality review as mentioned in the Privacy Policy.',
      emailInfo : 'We will get back to you as soon as possible.Feel free to contact us for any Queries or Support.',
      chatNotes : 'Please note that a support ticket will only be created for inquiries submitted through channels listed on this page. We will not respond to messages received outside of these options, including but not limited to those sent via regular mail and sent directly to employees.'
    }
  },
  loginContents:{
    signIn : 'Sign in',
    subHaed : 'Sign in and Get lots of Cashback Rewards and Discount',
    labels:{
      emailAddress : 'Email Address',
      password : 'Password',
      rememberMe : 'Remember Me',
      enterEmail: "Enter your Email",
      rememberPass: "Remember your Password?",
      success: "Success",
      your: "Your",
      errMsg: "Please Enter Correct Details"
    },
    buttonsLink:{
      login: 'Login',
      or: 'OR',
      createAccount: 'Create New Account',
      forgotPassword: 'Forgot Password',
    },
    successNotify : {
      text1 : 'Login Successfully',
    },
    errorNotify : {
      text1 : 'User Not Found',
      text2 : 'Please Enter Correct Details',
    },
  },
  registerContents:{
    create : 'Create',
    account : 'Account',
    subHaed : 'For security, please sign in to access your information',
    labels:{
      name : 'Name',
      firstName: "First Name",
      middleName: "Middle Name",
      lastName: "Last Name",
      phoneNumber: "Phone Number",
      emailId : 'Email ID',
      password : 'Password',
      confirmPassword : 'Confirm Password',
      enterName: "Enter your Name",
      termsCondition: "Terms and Condition",
      privacyPolicy: "Privacy Policy",
      byClick : 'By clicking on register, I Understand & agree to world travel',
    },
    buttonsLink:{
      register: 'Register',
      login: 'Login',
      alreadyAccount : 'You have already an account?',
      alreadyamember: 'Or Already a member?',
      createAccount: "Create New Account",
    },
    successNotify : {
      text1 : 'Login Successfully',
    },
    errorNotify : {
      text1 : 'User Not Found',
      text2 : 'Please Enter Correct Details',
    },
  },
  loaderContent:{
    confirmText : 'Please wait while we confirm your booking',
    retrieveText : 'Please wait while we retrieve your booking',
  },
  newPassword:{
    heading: "Create Your Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    enterPass: "Enter New Password",
    enterCon: "Enter Confirm Password",
    resetBtn: "Reset"
  },
  myBookingContent: {
    heading: "VIEW YOUR BOOKING",
    labels: {
      bookId: "Booking ID",
      lastName: "Last Name",
    },
    buttons: {
      submit: "Submit",
      or: "OR",
      returnHome: "Return to Home",
      print: "Print"
    },
  },
  viewBooking: {
    header: {
      heading1: "UPCOMMING TRIP",
      heading2: "COMPLETED TRIP",
      heading3: "CANCELLED TRIP",
      heading4: "FAILED TRIP"
    },
    lebels: {
      bookingId: "Booking ID",
      travelDate: "Travel Date",
      bookingDate: "Booking Date",
      personalProfile:"PERSONAL PROFILE",
      profile:"Profile",
      status: "Status"
    },
    contents: {
      emptyText1: "Looks empty, you've no upcoming bookings.",
      emptyText2: "When you book a trip, you will see your itinerary here.",
    },
    buttons: {
      myBookings: "My Bookings",
      viewBooking: "VIEW BOOKING",
      logOut: "LOG OUT",
      bookNow: "Book Now"
    }
  },
  myProfile: {
    headings: {
      profile: "Profile",
      loginDetails: "Login Details",
      personalProfile: "PERSONAL PROFILE",
      myBookings: "My Bookings",
      status: "Status",
      verify: "VERIFY",
      unverified: "Unverified",
      subHead1: "Basic info, for a faster booking experience",
      subHead2: "Manage your email address mobile number and password",
    },
    contents: {
      ok: "OK",
      successText: "Profile Has Updated",
    },
    labels: {
      firstName: "FIRST NAME",
      middleName: "MIDDLE NAME",
      lastName: "LAST NAME",
      birthDay: "BIRTHDAY",
      gender: "GENDER",
      male: "MALE",
      female: "FEMALE",
      others: "OTHERS",
      userId: "USER ID",
      emailId: "EMAIL ID",
      selectDate: "Select Date"
    },
    validError: {
      firstName: "Please enter First Name",
      lastName: "Please enter Last Name",
    },
    buttons: {
      update: "UPDATE",
      edit: "EDIT",
    }
  },
  aboutUsContent: {
    heading: "About Us",
    company: "Company",
    team: "Team",
    whyus: "Why Us",
    text1: "Why",
    text2: "Working at ",
    text3: "is bound to be one of the most amazing experiences of your life.",
    text4: "The bonds you build, the friends you make and the stupendous"
  },
  contactContent:{
    heading: "Contact Us",
    name: "Name",
    natureOfEnq: {
      heading: "Nature of Enquirey",
      generalEnq: "General Enquiry",
      bookings: "Bookings",
      cancellationsGrp:"Cancellations Groups",
      packages: "Packages and HotelsComplaints",
      reportIssue: "Report Issue"
    },
    phoneNumber:"Phone Number",
    email: "Email",
    bookingRef: "Booking Reference/ PNR NO",
    comments: "Comments/Questions",
    enterCaptacha: "Enter Captcha",
    submit:"Submit"
  },
  footerContents: {
    usefulinks: 'Useful Links',
    contactinfo: 'Contact info.',
    footerText: "Keep yourself updates with latest travels news and ongoing promotions. We promise no spamming and we won't share your details with any 3rd party.",
    enterEmail: 'Enter your email address',
    erroField1: 'This field is required',
    erroField2: 'Please enter a valid email address',
    aboutUs: 'ABOUT US',
    about: 'About',
    contact: 'Contact Us',
    legal: 'Legal',
    privacyPolicy: 'Privacy policy',
    termsUse: "Terms of Use",
    followUs: 'FOLLOW US',
    subscribe: "Subscribe",
    copyright: "Copyright",
    home: "Home",
    backtotop: "Back to Top",
    allRights: "All rights reserved.",
    text1:" Welcome to Traveloplex, your most trusted Canadian partner in discovering the world's most extraordinary destinations. Founded on  principles of honesty, integrity, and dedication, we combine our extensive network of airlines and partners with the latest technology to offer real-time lowest prices on itineraries.",
    text2: "Our commitment is to get you where you need to be, as quickly and efficiently as possible—all from the heart of Canada."
  },
  pageErrorContents:{
    errorText1:"No flights offered by airlines included on our website matches you search criteria",
    errorText2:"Please check flights from other airports or change your search request",
    changeSearchBtn:"Change your search"
  },
  pageNotContents:{
    errorText1:"Error 404",
    errorText2:"Sorry, the destination you are looking for is not found.",
    backHomeBtn:"Back to Home"
  },
  errorContent:{
    dialog1:{
      text1: "OOPS!",
      text2: "Your request cannot be processed at this moment.",
      text3: "Please contact customer support.",
      text4: "Something went wrong.",
      text5: "Please contact customer support at",
    },
    dialog2: {
      text1: "Your modification request has been recorded.",
      text2: "Our customer support executive will get in touch with you soon.",
      text3: "Invalid PNR",
      text4: "Please try again",
    },
    dialog3:{
      text1:"Your request has been received.",
      text2:"Our customer support team will get back to you at the soonest possible."
    },
    button:{
      tryAgain: "Try Again",
      returnHome: "Return To Home",
      home: "Home",
      ok: "OK"
    }
  }
};
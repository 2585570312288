<template>
  <!--Testimonials start-->
  <section class="testimonial-bg d-none">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-10">
          <h2>Testimonials</h2>
          <h6>Our dear customers said about us</h6>
        </div>
      </div>

      <div class="offer slider popular-posts-slider">
        <!--Item 01-->
        <div>
          <div class="testi-con">
            <p>
              <i class="fa fa-quote-left"></i>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              incididunt aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation.
              <i class="fa fa-quote-right"></i>
            </p>
          </div>
          <div class="triangle-down"></div>
          <div class="testi-con-name">
            <img :src="require('@/assets/img/img.png')" alt="" /> Mohammad Azad
            <p>Web Developer</p>
          </div>
        </div>

        <!--Item 02-->
        <div>
          <div class="testi-con">
            <p>
              <i class="fa fa-quote-left"></i>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              incididunt aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation.
              <i class="fa fa-quote-right"></i>
            </p>
          </div>
          <div class="triangle-down"></div>
          <div class="testi-con-name">
            <img :src="require('@/assets/img/img.png')" alt="" /> Abu Bilal
            <p>UI Designer</p>
          </div>
        </div>

        <!--Item 03-->
        <div>
          <div class="testi-con">
            <p>
              <i class="fa fa-quote-left"></i>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              incididunt aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation.
              <i class="fa fa-quote-right"></i>
            </p>
          </div>
          <div class="triangle-down"></div>
          <div class="testi-con-name">
            <img :src="require('@/assets/img/img.png')" alt="" /> Mohammad
            <p>UX Designer</p>
          </div>
        </div>

        <!--Item 04-->
        <div>
          <div class="testi-con">
            <p>
              <i class="fa fa-quote-left"></i>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              incididunt aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation.
              <i class="fa fa-quote-right"></i>
            </p>
          </div>
          <div class="triangle-down"></div>
          <div class="testi-con-name">
            <img :src="require('@/assets/img/img.png')" alt="" /> Yusuf Shams
            <p>Web Designer</p>
          </div>
        </div>

        <!--Item 05-->
        <div>
          <div class="testi-con">
            <p>
              <i class="fa fa-quote-left"></i>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              incididunt aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation.
              <i class="fa fa-quote-right"></i>
            </p>
          </div>
          <div class="triangle-down"></div>
          <div class="testi-con-name">
            <img :src="require('@/assets/img/img.png')" alt="" /> Shamsuddin
            <p>Web Designer</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Testimonial end-->
</template>

<script></script>

<style scoped>
@import "../../assets/css/style-04.css";
@import "../../assets/css/style-responsive.css";
@import "../../assets/css/bootstrap.min.css";
@import "../../assets/css/custom.css";
@import "../../assets/css/slick/slick.css";
@import "../../assets/css/slick/slick-theme.css";
</style>

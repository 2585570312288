<template>
  <!-- Offer -->
  <div class="container d-none">
    <h2 class="title">
      Best Offers
      <span>Grab the deal on flights, Hotels, and holidays</span>
    </h2>

    <div class="offer slider popular-posts-slider">
      <div>
        <div class="best-block-bg">
          <img :src="img01" alt="Offer 1" />

          <footer>
            <h4>Upto Rs.1650 Off</h4>
            <span>Flat Rs.800 Off+10% Supercash (up to Rs.1000)</span>

            <div class="row">
              <div class="col-6">
                <div class="coup">AFIND50</div>
              </div>
              <div class="col-6">
                <small>Valid till: 31 Mar, 2019</small>
              </div>
            </div>
          </footer>
        </div>
      </div>
       <!-- Item 02 -->
       <div>
        <div class="best-block-bg">
          <img :src="img02" alt="Offer 1" />

          <footer>
            <h4>Upto Rs.1650 Off</h4>
            <span>Flat Rs.800 Off+10% Supercash (up to Rs.1000)</span>

            <div class="row">
              <div class="col-6">
                <div class="coup">AFIND50</div>
              </div>
              <div class="col-6">
                <small>Valid till: 31 Mar, 2019</small>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <!-- Item 03 -->
      <div>
        <div class="best-block-bg">
          <img :src="img03" alt="Offer 1" />

          <footer>
            <h4>Upto Rs.1650 Off</h4>
            <span>Flat Rs.800 Off+10% Supercash (up to Rs.1000)</span>

            <div class="row">
              <div class="col-6">
                <div class="coup">AFIND50</div>
              </div>
              <div class="col-6">
                <small>Valid till: 31 Mar, 2019</small>
              </div>
            </div>
          </footer>
        </div>
      </div>

    </div>
  </div>
  <!-- Offer end -->
</template>

<script>
import $ from "jquery";
import "slick-carousel"; 
// import flightoffers from '@/flightoffers.json';

import img01 from "@/assets/img/img-01.png";
import img02 from "@/assets/img/img-02.png";
import img03 from "@/assets/img/img-03.png";

export default {
  data() {
    return {
      // flightoffers,
      img01,
      img02,
      img03,
    };
  },
  mounted() {
    $(".slider").slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      autoplay: false,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  },
};
</script>

<style scoped>
@import "../../assets/css/style-04.css";
@import "../../assets/css/style-responsive.css";
@import "../../assets/css/bootstrap.min.css";
@import "../../assets/css/custom.css";



/* Responsive Styles */
@media (max-width: 1024px) {
  .container {
    padding: 0 15px;
  }
}

@media (max-width: 768px) {
  .best-block-bg {
    padding: 10px;
  }

  .best-block-bg img {
    width: 100%;
    height: auto;
  }

  h4 {
    font-size: 18px;
  }

  span,
  p,
  h5 {
    font-size: 14px;
  }

  .coup {
    font-size: 12px;
    padding: 5px;
  }
}
</style>

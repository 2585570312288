<template>
    <div>
      
      <span style="cursor: pointer" id="uplift-monthly-offer"
            data-up-price-value="257600"
            data-up-price-type="total">
        or from
        <span data-up-from-currency-unit-major=""></span>/mo
        <span data-up-tooltip="">
         
          <img src="/path-to-icon.svg" alt="Info">
        </span>
      </span>
  
      <div id="up-pay-monthly-container"></div>
    </div>
    <div id="up-pay-monthly-selector">
  <span>Pay Monthly</span>
  <span id="up-pay-monthly-selector-from-pricing"
    style="display: none; cursor: pointer;"
    data-up-price-type="total"
    data-up-price-value="72700">
    from
    <span data-up-from-currency-unit-major=""></span>/mo
    <span data-up-tooltip="">
    </span>
  </span>
  <span id="up-offer-not-available" data-up-error="" style="display: none; ">
    NOT AVAILABLE
    <span data-up-tooltip="">
      <!--Replace with your preferred icon/svg-->
    </span>
  </span>
</div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        orderAmount: 257600, // Amount in cents
      };
    },
    methods: {
      loadUpliftScript() {
        if (!window.Uplift) {
          const script = document.createElement("script");
          script.src = "https://tag.uplift.com/external/up.js?upcode=UP-37292414-99";
          script.async = true;
          script.onload = () => {
            if (typeof window.upReady === "function") {
              window.upReady();
            }
          };
          document.head.appendChild(script);
        }
      },
  
      setupUpReady() {
        window.upReady = () => {
          this.initPayMonthly();
          this.loadPayMonthlyOffer();
        };
      },
  
      initPayMonthly() {
        if (window.Uplift && window.Uplift.Payments) {
          window.Uplift.Payments.init({
            locale: "en-CA",
            currency: "CAD",
            checkout: true,
            channel: "desktop",
            container: "#up-pay-monthly-container",
            onChange: this.myOnChangeCallback,
          });
        } else {
          console.error("Uplift script not loaded yet.");
        }
      },
  
      loadPayMonthlyOffer() {
        const orderInfo = {
          order_amount: this.orderAmount,
          currency: "CAD",
          checkout: true,
          locale: "en-CA",
        };
  
        if (window.Uplift && window.Uplift.Payments) {
          window.Uplift.Payments.load(orderInfo);
          this.showUpliftPricing();
        } else {
          console.error("Uplift Payments API not ready.");
        }
      },
  
      showUpliftPricing() {
        const pricingElement = document.getElementById("uplift-monthly-offer");
        if (pricingElement) {
          pricingElement.style.display = "inline"; // Show element after script loads
          pricingElement.setAttribute("data-up-price-value", this.orderAmount);
        }
      },
  
      myOnChangeCallback(response) {
        const statusHandlers = {
          OFFER_AVAILABLE: () => console.log("Uplift Offer Available"),
          TOKEN_AVAILABLE: () => console.log("Uplift Token Available"),
          TOKEN_RETRIEVED: () => console.log("Uplift Token Retrieved"),
          OFFER_UNAVAILABLE: () => console.log("Uplift Offer Unavailable"),
          SERVICE_UNAVAILABLE: () => console.log("Uplift Service Unavailable"),
        };
        statusHandlers[response.status]?.();
      },
      buildOrderInfo() {
      return {
        travelers: [
          {
            id: 0,
            first_name: "Arthur",
            last_name: "Davis",
            date_of_birth: "04/07/1963"
          }
        ],
        air_reservations: [
          {
            airline_name: "AI",
            origin: "MIA",
            destination: "JFK",
            trip_type: "oneway",
            itinerary: [
              {
                departure_apc: "MIA",
                departure_time: "20271223",
                arrival_apc: "JFK",
                arrival_time: "20271224",
                fare_class: "Economy",
                carrier_code: "NF"
              }
            ],
            insurance: [
              {
                id: "0",
                types: ["cancellation"],
                price: 10000
              }
            ]
          }
        ],
        add_ons: [
          {
            id: "0",
            name: "Priority Boarding",
            price: 5000
          }
        ],
        order_amount: 99900 // Always in cents
      };
    }
    },
  
    mounted() {
        (function (u, p, l, i, f, t, o, b, j) { 
      u['UpLiftPlatformObject'] = f; 
      u[f] = u[f] || function () { (u[f].q = u[f].q || []).push(arguments) }; 
      u[f].l = 1 * new Date(); 
      b = p.createElement(l), j = p.getElementsByTagName(l)[0]; 
      b.async = 1; 
      b.src = i + '?upcode=' + t; 
      j.parentNode.insertBefore(b, j); 
      o = u.location.host.match(/[\w-]+\.\w{2,3}(:\d+)?$/); 
      if (o) o = o[0]; 
      u[f]('create', t, o);
    }) (window, document, 'script', 'https://tag.uplift.com/external/up.js', 'up', 'UP-37292414-99');
  
      this.setupUpReady();
      this.buildOrderInfo()
      this.loadUpliftScript();
    },
  };
  </script>
  
import { createRouter, createWebHistory } from 'vue-router';

import ParentHome from '@/components/homepage/ParentHome.vue';
import LoginpageCom from '@/components/LoginPage/LoginpageCom.vue';
import RegisterpageCom from '@/components/RegisterPage/RegisterpageCom.vue';
import ParentSearchpage from '@/components/searchResult/ParentSearchpage.vue';
import ParentCheckout from '@/components/checkoutPage/ParentCheckout.vue';
import ContactuspageCom from '@/components/FooterPage/ContactuspageCom.vue';
import AboutuspageCom from '@/components/FooterPage/AboutuspageCom.vue';
import pageLoader from "@/components/pageLoader.vue";
import ParentPayment from '@/components/paymentPage/ParentPayment.vue';
import SeatmapParent from '@/components/Seatmap/SeatmapParent.vue';
import mybooking from '@/components/bookingsPage/myBooking.vue'
import viewBooking from '@/components/bookingsPage/viewBooking.vue'
import brantfare from '@/components/brantFare/brantFare.vue'
import searchloader from '@/components/searchLoader.vue';
import PrivacypolicypageCom from '@/components/FooterPage/PrivacypolicypageCom.vue';
import TermsofusepageCom from '@/components/FooterPage/TermsofusepageCom.vue';
import myProfile from '@/components/bookingsPage/myProfile.vue'
import pagenot from "@/components/ErrorPages/pageNot.vue";
import BookingConfirmation from "@/components/BookingConfimation/ParentBookingview.vue";
import robotsCom from "../components/robotsCom.vue";
import sitemapCom from "../components/sitemapCom.vue";
import paymentUplist from  '@/components/paymentPage/paymentUplift.vue'

const routes = [
  { path: '/', component: ParentHome},
  { path: '/flight/search', component: ParentSearchpage},
  { path: '/paymentUplist', component: paymentUplist},
  { path: '/login', component: LoginpageCom},
  { path: '/searchloader', component: searchloader},
  { path: '/register', component: RegisterpageCom },
  { path: '/flightinfo', component: ParentCheckout},
  { path: '/seatmap', component: SeatmapParent},
  { path: '/contact', component: ContactuspageCom},
  { path: '/aboutus', component: AboutuspageCom},
  { path: "/pageLoader", component: pageLoader},
  { path: '/payment', component: ParentPayment},
  { path: '/mybooking', component: mybooking},
  { path: '/viewBooking', component: viewBooking},
  { path: '/brantfare', component: brantfare},
  { path: '/privacyPolicy', component: PrivacypolicypageCom},
  { path: '/termsofuse', component: TermsofusepageCom},
  { path: '/myProfile', component: myProfile},
  { path: '/bookingView', component: BookingConfirmation},
  { path: "/:pathMatch(.*)*", name: "NotFound", component: pagenot },
  { path: "/robots.txt", component: robotsCom},
  { path: "/sitemap.xml", component: sitemapCom},
]

const router = createRouter({
  history: createWebHistory(process.env.Base_URL),
  routes,
})

export default router;
<template>
  <!--Support-->
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <a href="#!" class="best-bg" style="text-decoration: none;">
          <img :src="require('@/assets/img/dollar.png')" alt="" />

          <span>BEST PRICE GUARANTEED</span>

          <small
            >Find a lower price? We'll refund you 100% of the difference.</small
          >
        </a>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <a href="#!" class="support-bg" style="text-decoration: none;">
          <img :src="require('@/assets/img/support.png')" alt="" />

          <span>24×7 SUPPORT</span>

          <small
            >We’re always here for you – reach us 24 hours a day, 7 days a
            week.</small
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// import highlightsdata from '@/highlights.json';
</script>

<style scoped>
@import "../../assets/css/style-04.css";
@import "../../assets/css/style-responsive.css";
@import "../../assets/css/bootstrap.min.css";
@import "../../assets/css/custom.css";
@import "../../assets/css/slick/slick.css";
@import "../../assets/css/slick/slick-theme.css";
</style>

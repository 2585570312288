<template>
  <!--Subscribe start-->
  <section class="subscribe-bg">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-4 col-lg-2">
          <div class="qr">
            <h3>Scan QR Code</h3>

            <img :src="require('@/assets/img/travel-engine-QR.png')" alt="" />
          </div>
        </div>

        <div class="col-xs-12 col-sm-9 col-md-8 col-lg-5 d-none">
          <div class="download">
            <h3>Download Our App</h3>

            <a href="#"><img :src="require('@/assets/img/aap_01.png')" alt="" /></a>

            <img :src="require('@/assets/img/aap_02.png')" alt="" />
          </div>

          <div class="subscribe">
            <small>Download via SMS</small>

            <div class="sms-bg">
              <select>
                <option selected>+966</option>

                <option>+91</option>

                <option>+971</option>
              </select>

              <input
                type="text"
                placeholder="Enter your 10 digit mobile number"
              />

              <button type="button">Submit</button>
            </div>

            <small
              >Or you can also give a missed call on 8888888888 to download the
              app</small
            >
          </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
          <div class="sub-email">
            <h3>Sign up for Exclusive Coupons / Deals</h3>

            <span
              >Exclusive access to coupons, special offers and promotions.</span
            >

            <div class="sub-input">
              <input type="text" placeholder="Enter your e-mail address" />

              <button type="button">Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Subscribe end-->
</template>

<style scoped>
@import "../../assets/css/style-04.css";
@import "../../assets/css/style-responsive.css";
@import "../../assets/css/bootstrap.min.css";
@import "../../assets/css/custom.css";
@import "../../assets/css/slick/slick.css";
@import "../../assets/css/slick/slick-theme.css";
</style>

<template>
  <div class="mob-nav">
    <div class="row d-none">
      <div class="col-3">
        <a href="flight.html"
          ><i class="fa fa-plane"></i> <span>Flight</span></a
        >
      </div>

      <div class="col-3">
        <a href="hotel.html"><i class="fa fa-bed"></i> <span>Hotel</span></a>
      </div>

      <div class="col-3">
        <a href="holidays.html"
          ><i class="fa fa-briefcase"></i> <span>Holidays</span></a
        >
      </div>

      <div class="col-3">
        <a href="login.html"><i class="fa fa-user"></i> <span>Login</span></a>
      </div>
    </div>
  </div>
  <header>
    <!--Top bar start-->
    <div class="top-bar">
      <div class="container">
        <div class="row">
          <div class="col-10 col-sm-10 col-md-11 col-lg-5">
            <span
              >{{ $t("headerContents.welcome") }}
              <span class="text-lowercase">{{ portalLegalName }}</span></span
            >
          </div>

          <div class="col-2 col-sm-2 col-md-1 col-lg-7">
            <nav class="navbar navbar-expand-lg float-lg-right z-3">
              <button
                class="navbar-toggler"
                type="button"
                @click="toggleTopNavbar()"
                :class="{ collapsed: !isTopNavbarOpen }"
              >
                <span><i class="fa fa-th-list"></i></span>
              </button>

              <div
                class="collapse navbar-collapse"
                id="navbarText"
                :class="{ show: isTopNavbarOpen }"
              >
                <ul class="navbar-nav me-auto">
                  <li class="support">
                    <!-- <v-img :src="displayFlagLogo" width="20px" height="20px" cover /> -->
                    <a class="nav-link d-flex align-center" href="/contact">
                      <v-img
                        :src="require('@/assets/icons/phone.png')"
                        width="20px"
                        height="20px"
                        style="max-width: 20px"
                        cover
                      />
                      <span class="ms-1">{{
                        $t("headerContents.support")
                      }}</span></a
                    >
                  </li>
                  <li class="d-none">
                    <v-menu
                      v-model="isMenuCountry"
                      location="bottom"
                      class="nav-link"
                    >
                      <template v-slot:activator="{ props }">
                        <v-card
                          class="shadow-none bg-transparent d-flex"
                          v-bind="props"
                          style="cursor: pointer"
                        >
                          <div class="d-flex align-center">
                            <div>
                              <v-img
                                :src="displayFlagLogo"
                                width="18px"
                                height="18px"
                                cover
                              />
                            </div>

                            <span
                              v-if="currentCountry"
                              class="ps-1 currency-text f-size-15 fw-400 text-dark"
                            >
                              {{ currentCountry.currency }}
                            </span>
                          </div>

                          <v-list-item-icon v-if="countryOptions.length > 1">
                            <span>
                              <v-icon
                                size="20"
                                :class="{ 'rotate-icon': isMenuCountry }"
                                >mdi-menu-down</v-icon
                              >
                            </span>
                          </v-list-item-icon>
                        </v-card>
                      </template>
                      <v-list
                        style="cursor: pointer"
                        v-if="countryOptions.length > 1"
                      >
                        <div
                          v-for="(country, index) of filteredCountryOptions"
                          :key="index"
                        >
                          <v-list-item
                            class="hoverList"
                            v-if="country.country_code !== countyIsocode"
                          >
                            <div>
                              <a
                                :href="`${country.portal_url}/?redirect=true`"
                                style="text-decoration: none"
                              >
                                <div class="d-flex align-center">
                                  <div>
                                    <v-img
                                      :src="country.flag_logo"
                                      width="20px"
                                      height="20px"
                                      style="border-radius: 50%"
                                    />
                                  </div>
                                  <span class="ps-1 f-size-15 text-dark">{{
                                    country.currency
                                  }}</span>
                                </div>
                              </a>
                            </div>
                          </v-list-item>
                        </div>
                      </v-list>
                    </v-menu>
                  </li>

                  <li>
                    <v-menu v-model="isMenulanguage" location="bottom center">
                      <template v-slot:activator="{ props }">
                        <v-card
                          class="shadow-none bg-transparent d-flex align-center"
                          v-bind="props"
                          style="cursor: pointer"
                        >
                          <div class="d-flex align-center">
                            
                            <v-img
                                :src="displayFlagLogo"
                                width="18px"
                                height="18px"
                                cover
                              />
                              <span
                              v-if="currentCountry"
                              class="ps-1 currency-text f-size-15 fw-400 text-dark"
                            >
                              {{ currentCountry.currency }}</span>
                            <!-- {{ console.log(currencyData.display,"currencyDatadisplaycurrencyDatadisplay",this.i18n.locale )}} -->
                            <span
                              class="f-size-15 fw-400 text-dark ps-1 text-uppercase"
                              >{{ displayLanguage }}</span
                            >
                          </div>

                          <v-list-item-icon
                            v-if="languageOptions.length > 1"
                          >
                              <v-icon
                                size="20"
                                style="color: white;"
                                :class="{ 'rotate-icon': isMenulanguage }"
                                >mdi-menu-down</v-icon
                              >
                          </v-list-item-icon>
                        </v-card>
                      </template>

                      <v-card
                        class="d-flex flex-wrap p-2 rounded-4"
                        style="
                          cursor: pointer;
                          max-width:100%; 
                          width: 400px !important;
                          padding: 0px !important;
                          max-height: 350px;
                          overflow-y: auto;
                          position: relative;
                        "
                        v-if="languageOptions.length > 1"
                      >
                      <div class="w-100">
                        <h6 class="text-dark px-3 py-1 m-1 mb-2">Language</h6>
                      </div>

                        <div
                          class="hoverList px-3 py-1 m-1 mb-2 rounded-2 "
                          :class="{ disabledLang: index == 0 }"
                          v-for="(lang, index) in sortedLanguageOptions"
                          :key="index"
                        >
                          <div
                            class="d-flex align-center"
                            @click="changeLocale(lang.code)"
                            style="font-size: 14px"
                          >
                            <span
                              class="ps-1 f-size-15"
                              :style="{ color: index == 0 ? '#003580' : '' }"
                            >
                              <span class="text-uppercase fw-500">{{
                                lang.code
                              }}</span>
                              <span class="fw-400"> - {{ lang.name }}</span>
                            </span>
                          </div>
                        </div>
                        <div class="w-100">
                          <h6 class="text-dark px-3 py-1 m-1 mb-2">Country/Region</h6>
                        </div>
                        <div class="d-flex align-center px-3 py-1 m-1 mb-2">
                            <div>
                              <v-img
                                :src="displayFlagLogo"
                                width="18px"
                                height="18px"
                                cover
                              />
                              
                            </div>

                            <span
                              v-if="currentCountry"
                              class="ps-1 currency-text f-size-15 fw-400 text-dark"
                            >
                              {{ currentCountry.currency }}
                            </span>
                          </div>

                          <!-- <v-list-item-icon v-if="countryOptions.length > 1">
                            <span>
                              <v-icon
                                size="20"
                                :class="{ 'rotate-icon': isMenuCountry }"
                                >mdi-menu-down</v-icon
                              >
                            </span>
                          </v-list-item-icon> -->
                        <v-list class="p-0"
                        style="cursor: pointer;display: flex;  max-height: 200px; overflow-y: auto;"
                        v-if="countryOptions.length > 1"
                      >
                        <div
                          v-for="(country, index) of filteredCountryOptions"
                          :key="index"
                           class="w-100"
                        >
                          <v-list-item
                            class="hoverList d-flex align-center"
                            v-if="country.country_code !== countyIsocode"
                          >
                            <div>
                              <a
                                :href="`${country.portal_url}/?redirect=true`"
                                style="text-decoration: none; display: flex; align-items: center; width: 100%;"
                              >
                                    <v-img
                                      :src="country.flag_logo"
                                      width="20px"
                                      height="20px"
                                      style="border-radius: 50%"
                                    />
                                  <span class="ps-1 f-size-15 text-dark">{{
                                    country.currency
                                  }}</span>
                              </a>
                            </div>
                          </v-list-item>
                        </div>
                      </v-list>
                      </v-card>

                      <!-- <v-list style="cursor: pointer" v-if="languageOptions.length > 1">
              <v-list-item class="hoverList" v-for="(lang, index) in dropdownLanguages" :key="index">
                <div class="d-flex align-center" @click="changeLocale(lang.code)">
                  <span class="ps-1" style="font-size: 15px; color: black">{{ lang.name }} -
                    <span class="text-uppercase">{{ lang.code }}</span></span>
                </div>
              </v-list-item>
            </v-list> -->
                    </v-menu>
                  </li>

                  <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Language
                    </a>

                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a class="dropdown-item" href="index.html">English</a>
                      <a class="dropdown-item" href="ar/index.html">Arabic</a>
                    </div>
                  </li> -->
                  <!-- <li>
                    <a class="nav-link" href="login.html">Login or Create Account</a>
                  </li> -->
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <!--Top bar end-->

    <!--Main navigation start-->
    <div class="navigation">
      <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-white">
          <a class="navbar-brand" href="/">
            <img :src="this.portalLogo" alt="" style="max-width: 243px" />
          </a>

          <button
            class="navbar-toggler"
            type="button"
            @click="toggleNavbar()"
            :class="{ collapsed: !isNavbarOpen }"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div
            class="collapse navbar-collapse"
            :class="{ show: isNavbarOpen }"
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav ms-auto">
              <li
                class="nav-item"
                :class="{ active: this.$route.path === '/' }"
              >
                <a class="nav-link" href="/">
                  <i class="fa fa-home"></i>

                  {{ $t("headerContents.home") }}
                </a>
              </li>
              <li
                class="nav-item"
                :class="{ active: this.$route.path === '/login' }"
                v-if="!userName"
              >
                <a class="nav-link" href="/login">
                  <i class="fa-solid fa-user"></i>
                  {{ $t("headerContents.account") }}
                </a>
              </li>
              <li class="nav-item dropdown" v-if="userName">
                <a
                  class="nav-link dropdown-toggle"
                  id="userDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="fa-solid fa-user"></i>
                  {{ userName }}
                </a>
                <ul class="dropdown-menu" aria-labelledby="userDropdown">
                  <li>
                    <a class="dropdown-item" href="/myProfile">
                      <i class="fa-solid fa-user"></i> Profile
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/myBooking">
                      <i class="fa-solid fa-calendar-check"></i> My Booking
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      @click="deleteItem()"
                      style="cursor: pointer"
                    >
                      <i class="fa-solid fa-sign-out-alt"></i> Log Out
                    </a>
                  </li>
                </ul>
              </li>

              <li
                class="nav-item"
                :class="{ active: this.$route.path === '/viewBooking' }"
              >
                <a class="nav-link" href="/viewBooking">
                  <i
                    class="bi bi-journal-text"
                    style="position: relative; top: -4px"
                  ></i>
                  {{ $t("headerContents.viewMyTrip") }}
                </a>
              </li>
              <!-- <li class="nav-item">

								<a class="nav-link" href="flight.html">

									<i class="fa fa-plane"></i>

									Flights

								</a>

							</li> -->

              <!--  <li class="nav-item">
                            
                                <a class="nav-link" href="hotel.html">
                                
                                    <i class="fa fa-bed"></i>
                                
                                    Hotels
                                
                                </a>
                            
                            </li>
                        
                            <li class="nav-item">
                            
                                <a class="nav-link" href="bus.html">
                                
                                    <i class="fa fa-bus"></i>
                                
                                    Bus
                                
                                </a>
                            
                            </li>
                        
                            <li class="nav-item">
                            
                                <a class="nav-link" href="cab.html">
                                
                                    <i class="fa fa-car"></i>
                                
                                    Car
                                
                                </a>
                            
                            </li>
                        
                            <li class="nav-item">
                            
                                <a class="nav-link" href="holidays.html">
                                
                                    <i class="fa fa-suitcase"></i>
                                
                                    Holidays
                                
                                </a>
                            
                            </li> -->
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <!--Main navigation end-->
  </header>
</template>
<script>
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
export default {
  data() {
    return {
      isTopNavbarOpen: false,
      isNavbarOpen: false,
      menuData: "",
      headerMenus: "",
      isMenuCountry: false,
      isMenulanguage: false,
      displayFlagLogo: "",

      portalUrl: "",
      portalLogo: "",
      portalLegalName: "",
      phonePortal: "",
      emailPortal: "",
      currencyData: "",

      portalLanguage: "",
      countyIsocode: "",
      countryOptions: [],
      languageOptions: "",
      displayLanguage: "",
    };
  },
  methods: {
    toggleTopNavbar() {
      this.isTopNavbarOpen = !this.isTopNavbarOpen;
    },
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
    deleteItem() {
      localStorage.removeItem("value");
      localStorage.removeItem("loginuser");
      setInterval(() => {
        location.reload();
      }, 500);
      this.userDetails = true;
      this.userProfile = false;
      this.$router.push("/");
    },
    getConfig() {
      const getConfigData = getConfigDataFromLocalStorage();
      console.log(getConfigData, "portalLegalNameportalLegalName");

      if (getConfigData) {
        let Menus = "";
        Menus = getConfigData.payload.portal_configuration.menus.enabled.header;
        this.menuData =
          getConfigData.payload.portal_configuration.menus.enabled.header;
        this.headerMenus = Menus.split(", ");

        this.portalUrl = getConfigData.payload.portal_configuration.portal_url;
        this.portalLogo = getConfigData.payload.portal_configuration.logo_path;
        this.portalLegalName =
          getConfigData.payload.portal_configuration.portal_legal_name;
        this.phonePortal = getConfigData.payload.portal_configuration.phone;
        this.emailPortal = getConfigData.payload.portal_configuration.email;
        this.currencyData = getConfigData.payload.portal_configuration.currency;

        this.portalLanguage =
          getConfigData.payload.portal_configuration.language;
        this.countyIsocode =
          getConfigData.payload.portal_configuration.country.iso_code;
        this.countryOptions =
          getConfigData.payload.portal_configuration.country_options;
        this.languageOptions =
          getConfigData.payload.portal_configuration.languages;

        this.displayLanguage = this.languageOptions[0].code;

        //   this.countryOptions.forEach((v,i) => {
        //   if(i == 0){
        //     this.displayFlagLogo = v.flag_logo
        //     console.log(this.displayFlagLogo,'datasdatasdatas')
        //   }
        // });

        this.countryOptions.forEach((v) => {
          // let currencyDisplay = this.currencyData.display.substring(0, 2);
          if (v.currency == this.currencyData.display) {
            this.displayFlagLogo = v.flag_logo;
            this.displayCurrencycode = v.currency_code || v.currency || null;
            console.log(this.displayFlagLogo, "currencyDatacurrencyData");
          }
        });
      }
    },
    changeLocale(locale) {
      const reloadLang = setInterval(() => {
        // location.reload();
      }, 150);

      // this.languages.forEach(v=>{
      // console.log(v.language.split('-')[1],'v.language.split('-')[1]v.language.split('-')[1]')
      // if(v.language.split('-')[1] == locale){
      // const countryCode = this.countryOptions[0].country_code;

      if (reloadLang) {
        console.log(reloadLang, "reloadLangreloadLang");
        const newLocale = `${this.countyIsocode}-${locale}`;
        console.log("Changing locale to", newLocale);
        // this.$i18n.locale = newLocale;

        this.changedLang = newLocale;
        this.$emit("localChanged", this.changedLang);
        localStorage.setItem("defaultlang", newLocale);
        // this.$i18n.fallbackLocale = v.language;
        console.log("Current locale:", this.$i18n);
        // }
        // })
        this.displayLanguage = locale;
        this.updateDropdownLanguages();

        this.localeLanguage();
      }
    },
    updateDropdownLanguages() {
      console.log(
        this.languageOptions,
        "languageOptionslanguage",
        this.displayLanguage
      );
      this.dropdownLanguages = this.languageOptions.filter((lang) => {
        if (lang.code !== this.displayLanguage) {
          return lang.code;
        }
      });
      console.log(this.displayLanguage, "dropdownLanguagesdropdownLanguages");
      console.log(this.$i18n.locale, "getegeteegtegete....1");
    },
    displayedLang() {
      let getlocalIso = this.$i18n.locale;

      let getsetlang = getlocalIso.split("-")[1];
      this.displayLanguage = getsetlang;
      // let datalang = this.localeLanguage();
      // let getlocalIso = datalang;
      // this.$i18n.locale = datalang;
      // let getlocalIso = this.$i18n.locale;
      console.log(this.displayLanguage, "i18ni18ni18ni18ni18n");
      this.updateDropdownLanguages();
      console.log(this.$i18n.locale, "getegeteegtegete....2");
    },
    localeLanguage() {
      const storedLocale = localStorage.getItem("defaultlang");
      const storedSetLang = localStorage.getItem("setlang");

      let getlocalIso = "";
      let getlocalLang = "";
      let getsetIso = "";
      let getsetlang = "";
      // let savedLanguage = "";

      if (storedLocale) {
        getlocalIso = storedLocale.split("-")[0];
        getlocalLang = storedLocale.split("-")[1];
      }
      if (storedSetLang) {
        getsetIso = storedSetLang.split("-")[0];
        getsetlang = storedSetLang.split("-")[1];
      }

      if (storedSetLang && storedLocale) {
        console.log("Check...1");
        if (storedLocale == storedSetLang) {
          console.log("Check...2");

          return storedSetLang;
          // this.i18n.locale = storedSetLang;
        } else if (storedLocale !== storedSetLang) {
          console.log(
            getlocalIso,
            getsetIso,
            "Check...3",
            getlocalLang,
            getsetlang
          );
          if (getlocalIso !== getsetIso && getlocalLang !== getsetlang) {
            localStorage.removeItem("defaultlang");
            console.log("Check...4");
            return storedSetLang;
            // this.i18n.locale = storedSetLang;
          } else if (getlocalIso == getsetIso && getlocalLang !== getsetlang) {
            console.log("Check...5");

            return storedLocale;
            // this.i18n.locale = storedLocale;
          }
        }
      } else if (storedSetLang) {
        console.log("Check...6");

        return storedSetLang;
        // this.i18n.locale = storedSetLang;
      } else if (storedLocale) {
        console.log("Check...7");

        return storedLocale;
        // this.i18n.locale = storedLocale;
      } else {
        console.log("Check...8");

        return `${this.countyIsocode}-${this.getLanguage[0].code}`;
        // this.i18n.locale = `${this.countyIsocode}-${this.getLanguage[0].code}`;
      }
    },
  },
  watch: {
    "$i18n.locale": function () {
      this.displayedLang();
    },

    "$route.path": {
      immediate: true,
      handler(newValue) {
        this.isCollapsed = true;
        if (newValue === "/confirmBook" || newValue === "/bookingView") {
          // this.langDisabled = true;
          this.countryDisabled = true;
        } else {
          // this.langDisabled = false;
          this.countryDisabled = false;
        }
        // console.log(newValue, '4545');
      },
    },

    // getResData: {
    //           immediate: true, // Run the handler immediately on mount
    //           handler(newVal) {
    //               console.log(newVal, 'getResDatagetResData...');
    //               if(newVal){
    //                 this.getUser();
    //               }
    //           }
    //       }
  },
  computed: {
    mybookTextColor() {
      if (this.$route.path === "/" && this.isCollapseShown) {
        return "black";
      } else if (this.$route.path === "/") {
        return "black";
      } else {
        return "black";
      }
    },
    currentCountry() {
      return (
        this.countryOptions.find(
          (country) => country.country_code === this.countyIsocode
        ) || null
      );
    },
    filteredCountryOptions() {
      return this.countryOptions.filter(
        (country) => country.country_code !== this.countyIsocode
      );
    },
    sortedLanguageOptions() {
      const currentLanguage = this.languageOptions.find(
        (lang) => lang.code === this.displayLanguage
      );
      let remainingLanguages = [...this.languageOptions].filter(
        (lang) => lang.code !== this.displayLanguage
      );
      remainingLanguages.sort((a, b) => a.name.localeCompare(b.name));
      if (currentLanguage) {
        remainingLanguages.unshift(currentLanguage);
      }
      return remainingLanguages;
    },
  },
  created() {
    let $useresult = JSON.parse(localStorage.getItem("loginuser"));
    // console.log($useresult, '$useresult $useresult ')
    if ($useresult) {
      this.userEmail = $useresult.user.email;
      this.userName = $useresult.user.first_name;
      console.log($useresult, "$useresult$useresult");
      console.log(this.userEmail, this.userName, "adsasfasdas");
    }
    this.getConfig();
    this.displayedLang();
    // this.localeLanguage();
    console.log(this.$i18n.locale, "getegeteegtegete");
  },
};
</script>
<style scoped>
/* @import "../../assets/css/style-04.css";
@import "../../assets/css/style-responsive.css";
@import "../../assets/css/bootstrap.min.css"; */

.rotate-icon {
  transform: rotate(180deg);
  transition: transform 0.3s;
}

.top-bar .v-img {
  border-radius: 50%;
  background: white;
}

.top-bar .v-card .v-img {
  border-radius: 50%;
  background: white;
}

:deep(.v-list-item span) {
  font-size: 14px;
  font-weight: 400;
}

:deep(.v-list-item--density-default.v-list-item--one-line) {
  min-height: 34px;
}

:deep(.v-list-item--density-default.v-list-item--one-line:hover) {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-menu::before,
.dropdown-toggle::before {
  top: 64% !important;
}
.dropdown-toggle::after {
  display: none;
}
</style>

<template>
    <!--Banner start-->
    <section class="banner-blog overlay d-none" :style="{ backgroundImage: `url(${require('@/assets/img/blog-banner.png')})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat'}">
       <div class="container">
           <h1>About Travel Engine</h1>
           <h6>We help people find great products at a reasonable price</h6>
           <nav aria-label="breadcrumb">
               <ol class="breadcrumb">
                   <li class="breadcrumb-item active"><a href="index.html" style="text-decoration: none"><i class="fa fa-home"></i> Home</a></li>
                   <li class="breadcrumb-item">About Us</li>
               </ol>
           </nav>  
       </div>
   </section>
   <!--Banner end-->

   <!--Content start-->
   <!--Intro-->
   <section class="about-bg mar30 d-none">
       <div class="container">
           <div class="row">

               <div class="col-12 col-md-6 col-lg-6 about-aling">
                   <div class="about-intro">
                       <h6>We are the Travel Engine!</h6>
                       <h2>
                           We are a full service <span>Creative agency</span> that makes good ideas come to life.
                       </h2>
                       <p>
                           A team of passinate designers and developers who are unmatched. We are an insight and behaviour driven creative digital agency. A Full package Digital Creative Agency Specializing in: Web Design, Mobile APP Design, and Branding.
                       </p>
                       <ul>
                           <li>Creative Ideas </li>
                           <li>Super Responsive for all devices</li>
                           <li>Excellent features</li>
                       </ul>
                   </div>
               </div>

               <div class="col-12 col-md-6 col-lg-6">
                   <div class="intro-img">
                       <img :src="require('@/assets/img/about-01.jpg')" alt="">
                   </div>
               </div>

           </div>
       </div>
   </section>

   <!--Tag Line-->
   <section class="about-tag d-none">
       <div class="container">
           <div class="row justify-content-center">
               <div class="col-10">
                   <h3>
                       Our passion for customer excellence is just one reason why we are the market leader. We've always worked very hard to give our customers the best experience in dealing with our company.
                   </h3>
               </div>
           </div>
       </div>
   </section>

   <!--Why Choose Us-->
   <section class="why-us d-none">
       <div class="container">
           <div class="row justify-content-center">
               <div class="col-10">
                   <h3>
                       See why people choose us
                   </h3>
                   <h6>
                       We help people find great products at a reasonable price
                   </h6>
               </div>
           </div>
           
           <div class="row">
               <div class="col-12 col-md-4 col-lg-4">
                   <div class="why-item">
                       <i class="fa fa-user" aria-hidden="true"></i>
                       <h2>
                           Professional designers
                       </h2>
                       <p>
                           Lorem ipsum dolor sit amet consectetur, adipiscing elit ullamcorper laoreet, litora nibh magnis dictum.
                       </p>
                   </div>
               </div>
               <div class="col-sm-12 col-md-4 col-lg-4">
                   <div class="why-item">
                       <i class="fa fa-check-circle" aria-hidden="true"></i>
                       <h2>
                           Quality control
                       </h2>
                       <p>
                           Lorem ipsum dolor sit amet consectetur, adipiscing elit ullamcorper laoreet, litora nibh magnis dictum.
                       </p>
                   </div>
               </div>
               <div class="col-sm-12 col-md-4 col-lg-4">
                   <div class="why-item">
                       <i class="far fa-clock" aria-hidden="true"></i>
                       <h2>
                           Timely delivery
                       </h2>
                       <p>
                           Lorem ipsum dolor sit amet consectetur, adipiscing elit ullamcorper laoreet, litora nibh magnis dictum.
                       </p>
                   </div>
               </div>
           </div>
       </div>
   </section>

   <!--Team-->
   <section class="team-bg d-none">
       <div class="container">
           <div class="row justify-content-center">
               <div class="col-10">
                   <h3>
                       People behind the scenes
                   </h3>
                   <h6>
                       Lorem ipsum dolor sit amet consectetur, adipiscing elit ullamcorper laoreet, litora nibh magnis dictum.
                   </h6>
               </div>
           </div>
           
           <div class="row">
               <!--Member 01-->
               <div class="col-12 col-md-4 col-lg-4">
                   <div class="team-item">
                       <img :src="require('@/assets/img/team/captain-america.png')" alt="" >
                       <h2>
                           Captain America
                       </h2>
                       <h5>Travel Engine, Co-Founder</h5>
                       <div class="team-social">
                           <a href="#!"><i class="fab fa-facebook"></i></a>
                           <a href="#!"><i class="fab fa-twitter"></i></a>
                           <a href="#!"><i class="fab fa-linkedin"></i></a>
                           <a href="#!"><i class="fab fa-instagram"></i></a>
                       </div>
                   </div>
               </div>
               <!--Member 02-->
               <div class="col-12 col-md-4 col-lg-4">
                   <div class="team-item">
                       <img :src="require('@/assets/img/team/funky.png')" alt="" >
                       <h2>
                           Fury
                       </h2>
                       <h5>Travel Engine, Co-Founder</h5>
                       <div class="team-social">
                           <a href="#!"><i class="fab fa-facebook"></i></a>
                           <a href="#!"><i class="fab fa-twitter"></i></a>
                           <a href="#!"><i class="fab fa-linkedin"></i></a>
                           <a href="#!"><i class="fab fa-instagram"></i></a>
                       </div>
                   </div>
               </div>
               <!--Member 03-->
               <div class="col-12 col-md-4 col-lg-4">
                   <div class="team-item">
                       <img :src="require('@/assets/img/team/iron-man.png')" alt="" >
                       <h2>
                           Iron man
                       </h2>
                       <h5>Travel Engine, CEO</h5>
                       <div class="team-social">
                           <a href="#!"><i class="fab fa-facebook"></i></a>
                           <a href="#!"><i class="fab fa-twitter"></i></a>
                           <a href="#!"><i class="fab fa-linkedin"></i></a>
                           <a href="#!"><i class="fab fa-instagram"></i></a>
                       </div>
                   </div>
               </div>

           </div>
       </div>
   </section>
   
   <!--Clients-->
   <section class="client-bg d-none">
       <div class="container">
           <div class="row justify-content-center">
               <div class="col-10">
                   <h3>
                       Some of our happy clients
                   </h3>
                   <h6>
                       Lorem ipsum dolor sit amet consectetur, adipiscing elit ullamcorper laoreet, litora nibh magnis dictum.
                   </h6>
               </div>
           </div>
           
           <div class="row">
               <!--Client logo 01-->
               <div class="col-6 col-sm-4 col-md-2 col-lg-2">
                   <img :src="require('@/assets/img/p_01.png')" alt="" >
               </div>
               <!--Client logo 02-->
               <div class="col-6 col-sm-4 col-md-2 col-lg-2">
                   <img :src="require('@/assets/img/p_02.png')" alt="" >
               </div>
               <!--Client logo 03-->
               <div class="col-6 col-sm-4 col-md-2 col-lg-2">
                   <img :src="require('@/assets/img/p_03.png')" alt="" >
               </div>
               <!--Client logo 04-->
               <div class="col-6 col-sm-4 col-md-2 col-lg-2">
                   <img :src="require('@/assets/img/p_04.png')" alt="" >
               </div>
               <!--Client logo 05-->
               <div class="col-6 col-sm-4 col-md-2 col-lg-2">
                   <img :src="require('@/assets/img/p_05.png')" alt="" >
               </div>
               <!--Client logo 06-->
               <div class="col-6 col-sm-4 col-md-2 col-lg-2">
                   <img :src="require('@/assets/img/p_06.png')" alt="" >
               </div>

           </div>
       </div>
   </section>
   
   <!--News-->
   <section class="news-bg d-none">
       <div class="container">
           <div class="row justify-content-center">
               <div class="col-10">
                   <h3>
                       Read our news & blogs
                   </h3>
                   <h6>
                       Our duty towards you is to share our experience we're reaching in our work path with you.
                   </h6>
               </div>
           </div>
           
           <div class="row">
               <!--News item 01-->
               <div class="col-12 col-md-6 col-lg-6">
                   <div class="news-item">
                       <div class="row">
                           <div class="col-12 col-sm-7 col-md-12 col-lg-7">
                               <div class="news-text" >
                                   <a href="blog-details.html" style="text-decoration: none; color: black;">
                                       <h2>
                                           How to keep your SEO rankings
                                       </h2>
                                   </a>
                                   <p>
                                       Lorem ipsum dolor sit amet consectetur, adipiscing elit arcu urna.
                                   </p>
                               </div>
                           </div>
                           <div class="col-12 col-sm-5 col-md-12 col-lg-5">
                               <a href="blog-details.html">
                                   <img :src="require('@/assets/img/about-02.jpg')" alt="" >
                               </a>
                           </div>
                       </div>
                   </div>
               </div>
               <!--News item 01-->
               <div class="col-12 col-md-6 col-lg-6">
                   <div class="news-item">
                       <div class="row">
                           <div class="col-12 col-sm-7 col-md-12 col-lg-7">
                               <div class="news-text">
                                   <a href="blog-details.html" style="text-decoration: none; color: black;">
                                   <h2>
                                      The best rebrands of the year
                                       </h2>
                                   </a>
                                   <p>
                                       Lorem ipsum dolor sit amet consectetur, adipiscing elit arcu urna.
                                   </p>
                               </div>
                           </div>
                           <div class="col-12 col-sm-5 col-md-12 col-lg-5">
                               <a href="blog-details.html">
                                   <img :src="require('@/assets/img/about-03.jpg')" alt="" >
                               </a>
                           </div>
                       </div>
                   </div>
               </div>
               <!--News item 01-->
               <div class="col-12 col-md-6 col-lg-6">
                   <div class="news-item">
                       <div class="row">
                           <div class="col-12 col-sm-7 col-md-12 col-lg-7">
                               <div class="news-text">
                                   <a href="blog-details.html" style="text-decoration: none; color: black;">
                                       <h2>
                                           Key benefits of my portfolio update
                                       </h2>
                                   </a>
                                   <p>
                                       Lorem ipsum dolor sit amet consectetur, adipiscing elit arcu urna.
                                   </p>
                               </div>
                           </div>
                           <div class="col-12 col-sm-5 col-md-12 col-lg-5">
                                   <a href="blog-details.html">
                                   <img :src="require('@/assets/img/about-04.jpg')" alt="" >
                               </a>
                           </div>
                       </div>
                   </div>
               </div>
               <!--News item 01-->
               <div class="col-12 col-md-6 col-lg-6">
                   <div class="news-item">
                       <div class="row">
                           <div class="col-12 col-sm-7 col-md-12 col-lg-7">
                               <div class="news-text" >
                                   <a href="blog-details.html" style="text-decoration: none; color: black;">
                                       <h2>
                                           Reasons to go responsive
                                       </h2>
                                   </a>
                                   <p>
                                       Lorem ipsum dolor sit amet consectetur, adipiscing elit arcu urna.
                                   </p>
                               </div>
                           </div>
                           <div class="col-12 col-sm-5 col-md-12 col-lg-5">
                               <a href="blog-details.html">
                                   <img :src="require('@/assets/img/about-05.jpg')" alt="" >
                               </a>
                           </div>
                       </div>
                   </div>
               </div>

           </div>
       </div>
   </section>
   <!--Content end-->

   <!--Testimonials start-->
   <section class="testimonial-bg d-none">
       <div class="container">
           <div class="row justify-content-center">
               <div class="col-10">
                   <h2>
                       Testimonials
                   </h2>
                   <h6>
                       Our dear customers said about us
                   </h6>
               </div>
           </div>

           
           <div class="offer slider popular-posts-slider">

               <!--Item 01-->
               <div>
                   <div class="testi-con">
                       <p>
                           <i class="fa fa-quote-left"></i> 
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit, incididunt aliqua. Ut enim ad minim veniam, quis nostrud exercitation. 
                           <i class="fa fa-quote-right"></i> 
                       </p>
                   </div>
                   <div class="triangle-down"></div>
                   <div class="testi-con-name">
                   <img :src="require('@/assets/img/img.png')" alt=""> Mohammad Azad
                       <p>
                           Web Designer
                       </p>
                   </div>
               </div>

               <!--Item 02-->
               <div>
                   <div class="testi-con">
                       <p>
                           <i class="fa fa-quote-left"></i> 
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit, incididunt aliqua. Ut enim ad minim veniam, quis nostrud exercitation. 
                           <i class="fa fa-quote-right"></i> 
                       </p>
                   </div>
                   <div class="triangle-down"></div>
                   <div class="testi-con-name">
                   <img :src="require('@/assets/img/img.png')" alt=""> Mohammad
                       <p>
                           UI Designer
                       </p>
                   </div>
               </div>

               <!--Item 03-->
               <div>
                   <div class="testi-con">
                       <p>
                           <i class="fa fa-quote-left"></i> 
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit, incididunt aliqua. Ut enim ad minim veniam, quis nostrud exercitation. 
                           <i class="fa fa-quote-right"></i> 
                       </p>
                   </div>
                   <div class="triangle-down"></div>
                   <div class="testi-con-name">
                   <img :src="require('@/assets/img/img.png')" alt=""> Bilal Azad
                       <p>
                           UX Designer
                       </p>
                   </div>
               </div>

               <!--Item 04-->
               <div>
                   <div class="testi-con">
                       <p>
                           <i class="fa fa-quote-left"></i> 
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit, incididunt aliqua. Ut enim ad minim veniam, quis nostrud exercitation. 
                           <i class="fa fa-quote-right"></i> 
                       </p>
                   </div>
                   <div class="triangle-down"></div>
                   <div class="testi-con-name">
                   <img :src="require('@/assets/img/img.png')" alt=""> Abu Bilal
                       <p>
                           Web Developer
                       </p>
                   </div>
               </div>

               <!--Item 05-->
               <div>
                   <div class="testi-con">
                       <p>
                           <i class="fa fa-quote-left"></i> 
                           Lorem ipsum dolor sit amet, consectetur adipiscing elit, incididunt aliqua. Ut enim ad minim veniam, quis nostrud exercitation. 
                           <i class="fa fa-quote-right"></i> 
                       </p>
                   </div>
                   <div class="triangle-down"></div>
                   <div class="testi-con-name">
                   <img :src="require('@/assets/img/img.png')" alt=""> Yusuf Shams
                       <p>
                           UI/UX Designer
                       </p>
                   </div>
               </div>
               

           </div>
       </div>
   </section>

   
  
   <div class="pagewrap modify-search">
       <div class="head-wrapper">
           <div class="flight-search" style="padding: 1.5rem 0px !important;">
               <div class="container">
                   <div class="row">
                       <div class="col-12 col-md-12">
                           <span class="fw-bold theme-text-white">{{  $t("aboutUsContent.heading") }}</span>
                       </div>
                   </div>
               </div>
           </div>
       </div>
       <!-- body section -->
       <div class="content-section">
           <!-- flight result page - oneway start -->
           <section class="theme-bg-white pt-5">
               <div class="container">
                   <div class="row">
                       <div class="col-12 col-md-2 mb-4 mb-md-0" data-aos="fade-up">
                          <div class="sidebar d-flex flex-column theme-border-radius theme-bg-white theme-box-shadow" :class="{ 'sticky': isSticky }">
                               <a href="#aboutContent" class="border-bottom p-4 theme-text-accent-one" @click.prevent="scrollToSection('aboutContent')">{{ $t("aboutUsContent.company") }}</a>
                               <a href="#our-team" class="border-bottom p-4 theme-text-accent-one">{{ $t("aboutUsContent.team") }}</a>
                               <a href="#why-us" class="border-bottom p-4 theme-text-accent-one" @click.prevent="scrollToSection('why-us')">{{ $t("aboutUsContent.whyus") }}</a>
                           </div>
                       </div>
                       <div class="col-12 col-md-10" id="aboutContent" data-aos="fade-up" data-aos-delay="200">
                           <section v-html="aboutContent"></section>
                       </div>
                       <!-- <div class="col-12 col-md-12 mt-4" data-aos="fade-down" data-aos-delay="300">
                           <img :src="require('@/assets/images/about-us-bg.jpg')" alt="about us"
                               class="img-fluid w-100">
                       </div> -->
                       <!-- <div class="col-12 col-md-6 mt-5 align-self-center" data-aos="fade-up" data-aos-delay="300">
                           <p class="company-qotes fst-italic">Our work does make sense
                               only if it is a faithful witness
                               of his time.
                               <span class="d-flex mt-5 font-medium">John Doe - Director</span>
                           </p>

                       </div> -->
                       <!-- team meeting collarge -->
                       <!-- <div class="col-12 col-md-6 mt-5" data-aos="fade-down" data-aos-delay="400">
                           <img :src="require('@/assets/images/aboutus-team.jpg')" alt="about us"
                               class="img-fluid w-100 about-pic">
                       </div> -->
                       <!-- team picture collarge -->
                       <!-- <div class="col-12 col-md-6 mt-5" data-aos="fade-up" data-aos-delay="400">
                           <img :src="require('@/assets/images/about-team.png')" alt="about us"
                               class="img-fluid w-100">
                       </div> -->
                       <!-- our team content -->
                       <!-- <div class="col-12 col-md-6 mt-5 align-self-center" id="our-team" data-aos="fade-down"
                           data-aos-delay="300">
                           <h2 class="fs-2 mb-3">Our Team
                           </h2>
                           <p class="mb-3 theme-text-accent-two font-medium lh-lg">Nunc lacinia vestibulum sem id
                               fringilla. Nulla facilisi. Aenean volutpat leo ac nunc laoreet, vel lobortis dolor
                               maximus. Cras sodales sollicitudin felis, eu bibendum ligula blandit non. Nulla non
                               consectetur arcu. Aenean lacinia posuere lacinia. Interdum et malesuada fames ac ante
                               ipsum primis in faucibus.
                               <br><br>
                               Mauris condimentum, arcu ac condimentum congue, purus nisi fringilla orci, bibendum
                               aliquet lorem arcu sed leo. Donec nulla erat, congue vel facilisis a, dapibus ut odio.
                               Curabitur lacinia risus est, in auctor purus viverra eu. Nulla et hendrerit ipsum. In
                               euismod arcu orci, ut vestibulum eros venenatis vitae.
                           </p>
                           <div class="row">
                               <div class="col-12 col-md-3">
                                   <h3 class="fs-1 fw-bold mb-2">600</h3>
                                   <p class="font-medium theme-text-accent-two mb-0">million flight
                                       sustainable work</p>
                               </div>
                               <div class="col-12 col-md-3">
                                   <h3 class="fs-1 fw-bold mb-2">700</h3>
                                   <p class="font-medium theme-text-accent-two mb-0">million flight
                                       sustainable work</p>
                               </div>
                               <div class="col-12 col-md-3">
                                   <h3 class="fs-1 fw-bold mb-2">1.2</h3>
                                   <p class="font-medium theme-text-accent-two mb-0">million flight
                                       sustainable work</p>
                               </div>
                               <div class="col-12 col-md-3">
                                   <h3 class="fs-1 fw-bold mb-2">110</h3>
                                   <p class="font-medium theme-text-accent-two mb-0">million flight
                                       sustainable work</p>
                               </div>
                           </div>
                       </div> -->
                   </div>
               </div>
               <!-- why us section -->
               <div class="why-us mt-5 py-5 theme-bg-accent-three" id="why-us">
                   <div class="container">
                       <div class="row">
                           <div class="col-12 col-md-12 text-center mb-5" data-aos="fade-up" data-aos-delay="500">
                               <h4 class="mb-3 fs-2">{{ $t("aboutUsContent.text1") }} {{ portal_id }}</h4>
                               <p class="mb-0 max-1 theme-text-accent-two">{{ $t("aboutUsContent.text2") }} {{ portal_id }} {{ $t("aboutUsContent.text3") }} {{ $t("aboutUsContent.text4") }}</p>
                           </div>
                           <div v-for="data in gethowitworksData" :key="data" class="col-12 col-md-3 text-center mb-3 mb-md-0" data-aos="fade-up"
                               data-aos-delay="600">
                               <div class="d-inline-flex mb-3 p-4 rounded-circle theme-bg-white theme-box-shadow">
                                   <img :src="data.image" alt="about us"
                                       class="img-fluid">
                               </div>
                               <h2 class="mb-3 fw-bold fs-5">{{ data.headline }}
                               </h2>
                               <p class="mb-0 max-1 theme-text-accent-two font-medium">{{ data.content }}</p>
                           </div>
                       </div>
                   </div>
               </div>
           </section>


       </div>
   </div>

</template>

<script>
import axios from "axios";
import App from "@/App.vue";
import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
   component:{
       App
   },
   data() {
       return {

           aboutContent: "",
           getAboutUrl: "",
           aboutType: "",
           isSticky: false,
           portal_id:"",
           howitworksApi:"",
           gethowitworksData:[],
       }
   },

   methods: {
       scrollToSection(sectionId) {
           const section = document.getElementById(sectionId);
           if (section) {
               section.scrollIntoView({ behavior: 'smooth' });
           }
           },
           handleScroll() {
           this.isSticky = window.scrollY > 0; 
           },

       gethowitworks(){
           console.log(this.howitworksApi, 'getTopdestinationdatagetTopdestinationdata')
           axios.get(this.howitworksApi.url, {
            headers: {
                "Content-Type": this.howitworksApi.type,
            },
           })
           .then((response) => {
               if(!response.data.error){
                  this.gethowitworksData = response.data;
                  console.log(this.gethowitworksData, 'gethowitworksDatagethowitworksData..0')
               }
               else{
               //  this.gethowitworksData = topdestinationsdata;
                console.log(this.gethowitworksData, 'gethowitworksDatagethowitworksData..1')
               }
           })
           .catch((error) => {
            //  this.getTopdestinationdata = topdestinationsdata;
               console.log(error, 'erroroor.....')
           })
       },
       

       getConfig() {
           let locale = this.getLangname;

           const getConfigData = getConfigDataFromLocalStorage();
           if (getConfigData) {
               // this.getAboutUrl = getConfigData.payload.portal_configuration.content_data.aboutus.url
               // this.aboutType = getConfigData.payload.portal_configuration.content_data.aboutus.type
               this.portal_id = getConfigData.payload.portal_configuration.portal_id
               // console.log(getConfigData, 'head...about..1..')
               
               
               let siteContentApi = getConfigData.payload.portal_configuration.site_content_data[locale];
               if(siteContentApi){
                   this.getAboutUrl = siteContentApi.aboutus.url
                   this.aboutType = siteContentApi.aboutus.type
                   this.howitworksApi = siteContentApi.whyaboutcontent;
                   this.getabout();
                 if(this.howitworksApi) this.gethowitworks();
               }
           }
       },

       getabout() {
           axios.get(this.getAboutUrl, {
               headers: {
                   "Content-Type": this.aboutType,
               },
           })
               .then((response) => {
                   this.aboutContent = response.data
               })

               .catch((error) => {
                   console.log(error, 'erroroor.....')
               })
       },
       updateContent() {
      const currentLocale = this.$i18n.locale;

      const routeLang = this.$route.query.lang || currentLocale;

      if (this.getLangname !== routeLang) {
        this.getLangname = routeLang;
        console.log("Updated language:", this.getLangname);
        this.getConfig();
      }
    },
   },
   watch: {
    "$i18n.locale": {
      handler() {
        this.updateContent();
      },
    },
  },

   created() {
       
       this.getConfig();
   },
    mounted() {
        this.updateContent();
   window.addEventListener('scroll', this.handleScroll);
 },
}

</script>

<style scoped>
@import "../../assets/css/style-04.css";
@import "../../assets/css/style-responsive.css";
@import "../../assets/css/bootstrap.min.css";
@import "../../assets/css/custom.css";
@import "../../assets/css/slick/slick.css";
@import "../../assets/css/slick/slick-theme.css";

.sticky {
 position: sticky;
 top: 15%;
 background-color:#ffffff !important;
}
</style>

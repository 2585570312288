<template>
  <div v-if="!searchloader">
	<SearchForm :formDatas="formDatas" />
  <div class="container">
		<div class="row" v-if="!noflightError">
      <!-- <FilterSection :itinDatas="itindatas" @updateFilteredData="handleFilteredData"/> -->
      <ItinenerySection :brandchildData="barentFareData" :getDataResult="itindatas"/>
		</div>
    <div v-if="noflightError">
      <noflightsError :dateError="dateError"></noflightsError>
    </div> 
	</div>
</div>

  
<div v-if="searchloader">
  <searchLoader :searchCase="searchCase"/>
</div>


</template>
<script>
import axios from 'axios';
import SearchForm from '../homepage/SearchForm.vue';
import airLogo from "@/airlinesLogo.json";
import moment from 'moment';
// import FilterSection from './FilterSection.vue';
import ItinenerySection from './ItinenerySection.vue';
import noflightsError from "../ErrorPages/NoflightsError.vue";
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
const totalPricefilter = require("lodash");
import searchLoader from '@/components/searchLoader.vue'
export default {
	components: {
		SearchForm,
		// FilterSection,
		ItinenerySection,
    searchLoader,
    noflightsError
	},
	data(){
		return{
      barentFareData:[],
			Adult: [],
      Child: [],
      Infrant: [],
      maximum: null,
      minimum: null,
      countries: [{ name: "Australia", code: "AU" }],
      selectedCountry: "",
      loading: false,
      fromAirports: [],
      airitems: [],
			uniqueId:[],
      noflightError:false,
      dateError: false,

      adultValue: 1,
      childValue: 0,
      infantValue: 0,
      addition: 0,
      classType: "Economy",
      activetrip: "Roundtrip",

      oneway: {
        from: null,
        to: null,
        travelDate: null,
        dedate: null,
        redate: null,
        class: "Economy",
        adult: 1,
        child: 0,
        infant: 0,
        tripType: "",
      },

      tripData: {
        from: null,
        to: null,
        dedate: null,
        redate: null,
        class: "Economy",
        child: null,
        adult: null,
        infant: null,
        tripType: "",
      },
      formDatas: {},

      multiData: {
        itinery: [
          {
            from: null,
            to: null,
            depdate: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocomfrom: false,
            autocomTo: false,
            selectedFrom: false,
            selectedTo: false,
          },
          {
            from: null,
            to: null,
            depdate: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocomfrom: false,
            autocomTo: false,
            selectedFrom: false,
            selectedTo: false,
          },
        ],
        class: "Economy",
        child: "",
        adult: "",
        infant: "",
        tripType: "",
      },

			barantFare: false,
      childitems: [
        { id: 1, name: "Item 1" },
        { id: 2, name: "Item 2" },
        { id: 3, name: "Item 3" },
      ],
      showSpinner: false,
      alert: false,
      isFilter: false,
      openOffCanvas: false,
      tab: null,
      flightData: [],
      isNotActive: false,
      searchCase: null,
      // pageErrorData:false,
      drawer: null,
      dialog1: true,
      headerStyles: ["headerCom", "headerCom1", "headerCom2", "headerCom3"],
      footerStyles: ["footerCom", "footerCom1", "footerCom2", "footerCom3"],
      strResult: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      selectedHeader: "headerCom1",
      selectedFooter: "footerCom",

      inputCity: "",
      inputCityto: "",
      typedrop: false,
      typedrop1: false,
      city: [],
      city1: [],

      dataLoader: false,
      dialog: false,
      progressLoader: false,
      fareOpen: false,
      multiCalender: true,
      totaladult: 0,
      classSelect: false,
      triggerRadio: false,
      vj: "",
      modifySection: false,
      FlightDetail: false,
      FlightDetail1: false,
      dateFormat: " dd MMM ",

      items: [],
      airlogodata: [],
      oneway1: false,
      roundtrip: false,
      multitrip: false,
      multicityTotalData: [],
      searchresult: false,
      searchloader: true,
      isIcon1: true,
      fareIcon: true,
      isIcon: true,
      date1: "",
      date2: "",
      value: "1",
      class: "Business",
      travels: false,
      red: 1,
      red1: 1,
      // paxhide: true,

      roundModify: true,
      onewayModify: false,
      multiModify: false,

      find1: [],
      find2: [],

      offerData1: [],
      offerData2: [],
      offerData3: [],
      offerData4: [],
      offerData5: [],

      returnData: [],
      Oneway: false,
      rount: true,
      multicity: false,
      airData: [],
      flight: [],
      flight1: [],
      flightTime: [],
      flightseg: [],
      segkey: [],
      SegmentKey: [],
      arriv: [],
      dep: [],
      splitValues: [],
      splitValues1: [],
      seats: [],
      seg: [],
      Journey: [],
      Journey1: [],
      flightsegmet: [],
      flightsegmetkey: [],
      datas: [],
      journeyData: [],
      changevalue: [],
      steps: null,
      value1: [0, 100],
      value2: [0, 24],
      datasource: [],
      air: [],
      codeData: [],
      airnam: [],
      airnumber: [],
      airflight: [],
      flightprice: [],
      price: [],
      flightdate: [],
      time: [],
      arrivaltime: [],
      Renuvable: [],
      vasan: [],
      vasan1: [],
      totalprice: [],
      segmentref: [],
      stop: [],
      time1: [],
      swapData: false,
      multicityData: [],
      multicityData1: [],
      aircode: [],
      depcode: [],
      airname: [],
      depname: [],
      airtime: [],
      deptime: [],
      airid: [],
      airnum: [],
      airstop: [],
      offerpay: [],
      airstime: [],
      airfname: [],
      airdate: [],
      depdate: [],
      airref: [],
      airprice: [],
      sortingPrice: [],
      depnamedate: [],
      rountresult: [],
      filterData: [],
      onewayresulted: [],
      onewayfilterData: [],
      multiFilterData: [],
      multishowData: [],
      routtime: [],
      airportname: "",
      traveltime: "",


      data_id: "",
      roundOfferId: [],
      airlineList: [],
      airlineArr: [],
      oneway_id: "",
      onewayOfferId: [],

      clicked1: false,
      clicked2: false,
      clicked3: false,
      datePickerIsOpen: false,

      fromErrorMessage: false,
      toErrorMessage: false,
      fromDateError: false,
      toDateError: false,

      disButton: false,
      loadSearch: false,

      hoveredName: false,
      hoverDetail: "Hello....",

      storefilterData: [],

      // Filter Data 
      showNonStop: true,
      showOneStop: true,
      showOneplusStop: true,

      NonStopFil: false,
      OneStopFil: false,
      OnePlusFil: false,
      hasNonStop: false,
      hasOneStop: false,
      hasOnePlusStop: false,
      hasRefundable: false,
      hasNonRefundable: false,
      RefundableData: false,
      nonRefundableData: false,
      selectedFromAirports: false,
      selectedToAirports: false,
      onedatafil: false,
      rounddatafil: false,
      multifil: false,
      timeFilterData1: false,
      timeFilterData2: false,
      timeFilterData3: false,
      timeFilterData4: false,
      timeFilterArrData1: false,
      timeFilterArrData2: false,
      timeFilterArrData3: false,
      timeFilterArrData4: false,
      timearrFilterData1: false,
      timearrFilterData2: false,
      timearrFilterData3: false,
      timearrFilterData4: false,
      timearrFilterArrData1: false,
      timearrFilterArrData2: false,
      timearrFilterArrData3: false,
      timearrFilterArrData4: false,
      morningRange: [6, 12],
      afternoonRange: [12, 18],
      eveningRange: [18, 24],
      beforeMorningRange: [0, 6],
      hasMorningFlights: false,
      hasAfternoonFlights: false,
      hasEveningFlights: false,
      hasMorningFlights1: false,
      hasAfternoonFlights1: false,
      hasEveningFlights1: false,
      hasMorningReturn: false,
      hasAfternoonReturn: false,
      hasEveningReturn: false,
      hasMorningReturn1: false,
      hasAfternoonReturn1: false,
      hasEveningReturn1: false,
      nonStop: [],
      oneStop: [],
      onePlusStop: [],
      disableData: true,
      outBoundTime: [],
      inBoundTime: [],
      outBoundTimeMulti: [],
      defaultOutBoundMulti: null,
      defaultOutBoundTime: null,
      defaultInBoundTime: null,
      outBoundMulti: null,
      filNonstop: [],
      filOneStop: [],
      filOnePlusStop: [],
      concatStops: [],
      totalpassenger: [],
      timeMulti1:false,
      timeMulti2:false,
      timeMulti3:false,
      timeMulti4:false,
      // Filter Data End

      recperperson: "",
      recCurrecy: "",
      shotperperson: "",
      layperperson: "",
      shorCurrecy: "",
      active: false,
      active1: false,
      active2: false,
      active3: false,
      radiosData: 0,
      radioData1: 0,
      radioData2: 0,
      radioData3: 0,
      radioData4: 0,
      flightdetailsData: [],
      flightdetailsData1: [],
      flightdetailsData2: [],
      onewaymultiData: [],
      rountmultiData: [],
      airCanada: false,
      qatarAirline: false,

      Flight2: [],
      Flight3: [],
      Flight4: [],
      Flight5: [],
      resulted: [],
      multiplesameData: [],
      multiDepatureData: [],
      multireturnData: [],
      multiflight1: [],
      multiflight2: [],
      multiflight3: [],
      multiflight4: [],

      multiCityForm: false,
      returnForm: false,
      onewayForm: false,

      totalMinutesT1: "",
      totalMinutesT2: "",

			itinaryRequest: {
        SearchClass: "",
        OriginCity1: "YVR",
        DestinationCity1: "SEL",
        OriginCity2: "SEL",
        DestinationCity2: "YVR",
        TripType: "RoundTrip",
        PointOfSaleCountry: "CA",
        DisplayedPrice: "5915.24",
        DisplayedPriceCurrency: "CAD",
        UserLanguage: "en",
        UserCurrency: "CAD",
        Adult: "1",
        Child: "0",
        InfantLap: "0",
        Slice1: "1",
        Slice2: "2,3",
        mTime: "2023-09-06T12:49:58.885351Z",
        ugid: "43a49112d6a5-4b03-970af4d126a1deb1",
        ReferralId: "btgfs",
        HitId: "ca-65093a3b3f7c02-50740846-5893",
      },

      itinaryRequestoneway: {
        SearchClass: "",
        OriginCity1: "",
        DestinationCity1: "",
        OriginCity2: "",
        DestinationCity2: "",
        TripType: "Oneway",
        PointOfSaleCountry: "CA",
        DisplayedPrice: "5915.24",
        DisplayedPriceCurrency: "CAD",
        UserLanguage: "en",
        UserCurrency: "CAD",
        Adult: "1",
        Child: "0",
        InfantLap: "0",
        Slice1: "1",
        mTime: "2023-09-06T12:49:58.885351Z",
        ugid: "43a49112d6a5-4b03-970af4d126a1deb1",
        ReferralId: "btgfs",
        HitId: "ca-65093a3b3f7c02-50740846-5893",
      },

      formMultiData: [],
      FilterTimeDepname: "",
      FilterTimearrname: "",

      multidrop: [],
      multidrop1: [],
      showMulti_data: {},

      addmore: true,
      clearall: false,
      deleteSeg: true,
      portal_domain:"",
      airlineIcaoUrl: "",
      airlineIcaoType: "",

      readMore: false,
      maxItemsToShow: 5,
      filterAirline: [],

      // Advanced Options
      isAdvanceOpt: false,

      adAirlineMenu: false,
      adStopMenu: false,
      adlayoverMenu: false,
      adviaAirportMenu: false,
      adavoidCountryMenu: false,

      directFlight: false,
      baggageInclusive: false,
      nearbyAirport: false,
      airlineSelect1: "includeAirline",
      stopsSelect: "anyStop",
      adlayoverTime: "noPreference",
      adviaAirport: "includeAirport",
      selectedAirline: [],
      selectedAirport: [],
      avoidCountry: [],

      airlineVal: "",
      airportVal: "",
      airlineList1: [],
      airportList: [],

      getallAdvanceOptions: [],
      combinedPricedetail: [],
      // selectAirports: "",
      priceChangeValue: [],


			// Make Search Request
			onewaydata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-10-19",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ONEWAY",
              CabinType: "Y",
              Currency: "USD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },

      Rountdata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-08-25",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                  {
                    Departure: {
                      AirportCode: "BOM",
                      Date: "2023-10-27",
                    },
                    Arrival: {
                      AirportCode: "MAA",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ROUND",
              CabinType: "Y",
              Currency: "USD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },

      MulticityReq: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [],
              },
            },
            Preference: {
              TripType: "MULTICITY",
              CabinType: "Y",
              Currency: "USD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },
			// Make Search Request End

			// getconfig Variables
			makeSearchUrl: "",
			airLogoApi: "",
			preferCurrency: "",
      countryCurrency:"",
			metaCurrency: "",
			countryCodeData: "",
			autocompleteApi: "",
			portal_id: "",
			airlineApi: "",
			airportdataApi: "",
			pricingApi: "",
			pointofSale: "",
			responseData:[],
			localData:[],
			airline_logoData:[]
			// getconfig Variables End
		}
	},
	methods:{
		async fetchCity(event) {
      console.log("workings...1");
      const getConfigData = getConfigDataFromLocalStorage();
      let autocompleteApi = null;
      if (getConfigData) {
        autocompleteApi =
          getConfigData.payload.portal_configuration.API_endpoints.autocomplete;
      }

      if (event.length >= 3) {
        console.log(`${autocompleteApi}?airportString=${event}`, "workings");

        try {
          const response = await axios.get(
            `${autocompleteApi}?airportString=${event}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          );
          console.log(event, "responseresponseresponse", response);
          return response.data;
        } catch (error) {
          console.log(error, "erroroor.....");
        }
      } else {
        return [];
      }
    },

    async roundData() {
      if (this.$route.query.itinerary) {
        let getItinerary = this.$route.query.itinerary;
        let getpaxtype = this.$route.query.paxType;

        let splitItin = getItinerary.split("_");

        let getdepDetails = splitItin[0].split("-");
        let [from, to, depdate] = getdepDetails;

        let getfromlist = [];
        let getTolist = [];

        if (from) {
          getfromlist = await this.fetchCity(from);
          if (getfromlist.length > 0) {
            getfromlist.forEach((data, i) => {
              if (i == 0) {
                this.oneway.from = `${data.city} (${from})`;
                this.tripData.from = this.oneway.from;
                console.log(data.city, "asdasdadadsas", i);
              }
            });
          }
        }

        if (to) {
          getTolist = await this.fetchCity(to);
          if (getTolist.length > 0) {
            getTolist.forEach((data, i) => {
              if (i == 0) {
                this.oneway.to = `${data.city} (${to})`;
                this.tripData.to = this.oneway.to;
                console.log(data.city, "asdasdadadsas", i);
              }
            });
          }
        }

        this.oneway.dedate = new Date(depdate);
        this.tripData.dedate = new Date(depdate);

        console.log(splitItin, "splitItinsplitItin");

        if (splitItin.length == 2) {
          let getredate = splitItin[1].split("-")[2];
          this.oneway.redate = new Date(getredate);
          this.tripData.redate = new Date(getredate);
          // this.oneway.redate = moment(getredate).format("DD MMM-YYYY");
        }

        let getPaxDetails = getpaxtype.split("_");
        let [Adt, Chd, Inf] = getPaxDetails;

        let [getAdult, getChild, getInf] = [
          Adt.split("-")[1],
          Chd.split("-")[1],
          Inf.split("-")[1],
        ];

        console.log(getPaxDetails, "getAdultgetAdultaSAs...1");
        console.log(getAdult, getChild, getInf, "getAdultgetAdultaSAs...2");

        this.oneway.adult = JSON.parse(getAdult);
        this.oneway.child = JSON.parse(getChild);
        this.oneway.infant = JSON.parse(getInf);
        this.oneway.tripType = this.$route.query.tripType;

        console.log(this.oneway,getAdult,getChild, "getAdultgetAdultaSAs...3");

        let getrouteCabin = this.$route.query.cabin;
        if (getrouteCabin == "Economy") {
          this.oneway.class = "Economy";
        }
        if (getrouteCabin == "Business") {
          this.oneway.class = "Business";
        }
        if (getrouteCabin == "FirstClass") {
          this.oneway.class = "First Class";
        }
        if (getrouteCabin == "PremiumEconomy") {
          this.oneway.class = "Premium Economy";
        }
        if (getrouteCabin == "PremiumBusiness") {
          this.oneway.class = "Premium Business";
        }
        if (getrouteCabin == "PremiumFirstClass") {
          this.oneway.class = "Premium First";
        }
        // this.oneway.class = this.$route.query.cabin;

        console.log(this.oneway, "splitItinsplit...6");

        if (getAdult) {
          for (let i = 0; i < getAdult; i++) {
            this.Adult.push({
              PassengerID: "T",
              PTC: "ADT",
            });
          }
        }

        if (getChild) {
          for (let i = 0; i < getChild; i++) {
            this.Child.push({
              PassengerID: "T",
              PTC: "CHD",
            });
          }
        }
        if (getInf) {
          for (let i = 0; i < getInf; i++) {
            this.Infrant.push({
              PassengerID: "T",
              PTC: "INF",
            });
          }
        }

        let totalpass = [];
        totalpass = [...this.Adult, ...this.Child, ...this.Infrant];

        totalpass.forEach((v, index) => {
          let obj = {
            PassengerID: `${v.PassengerID}${index + 1}`,
            PTC: v.PTC,
          };
          this.totalpassenger.push(obj);
        });

        // console.log(this.totalpassenger, "aadjj");
        this.Rountdata.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
          this.totalpassenger;
        this.onewaydata.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
          this.totalpassenger;

        this.adultValue = parseFloat(getAdult);
        this.tripData.adult = parseFloat(getAdult);

        this.childValue = parseFloat(getChild);
        this.tripData.child = parseFloat(getChild);

        this.infantValue = parseFloat(getInf);
        this.tripData.infant = parseFloat(getInf);

        this.totaladult = this.adultValue + this.childValue + this.infantValue;
        // this.classType = this.$route.query.cabin;

        // let langLocale = `${this.pointofSale}-${this.$route.query.language}`
        // this.$i18n.locale = langLocale;

        // this.changedLang = `${this.pointofSale}-${this.$route.query.language}`
        // this.$emit('localeChanged', this.changedLang);

        this.tripData.select = this.$route.query.class;

        const tabId = sessionStorage.getItem("tabId") || Date.now().toString();
        sessionStorage.setItem("tabId", tabId);

        const getLocalData =
          JSON.parse(localStorage.getItem(`searchedData-round-${tabId}`)) ||
          JSON.parse(localStorage.getItem(`searchedData-oneway-${tabId}`));

        if (getLocalData)
          getLocalData.advanceOptions
            ? (this.oneway.advanceOptions = getLocalData.advanceOptions)
            : delete this.oneway.advanceOptions;

        console.log(this.oneway, "getLocalDataasdasdgetLocalData");
        if (
          this.oneway.from &&
          this.oneway.to &&
          this.oneway.dedate &&
          this.oneway.redate
        ) {
          console.log(this.oneway, "sdasdaresult112332");
          const result1 = JSON.stringify(this.oneway);
          console.log(result1, "sdasdaresult11233");

          Object.keys(localStorage).forEach((key) => {
            if (key.startsWith("searchedData-round-") && key !== `searchedData-round-${tabId}`) {
              localStorage.removeItem(key);
            }
          });
          localStorage.setItem(`searchedData-round-${tabId}`, result1);

          this.searchCase = JSON.stringify(this.oneway);
          this.tripData = this.oneway;
          this.formDatas = JSON.stringify(this.oneway);

          console.log(this.tripData,'asdfasfdasnewValue...1');
          console.log(this.searchCase, "ssdasdseasaaSrchCase...1");

          // if(this.searchCase){
          this.searchloader = true;
          // }
          console.log(this.fromErrorMessage, "validatecheckworking.....8");

          this.sendedRoundData();
        }

        if (
          this.oneway.from &&
          this.oneway.to &&
          this.oneway.dedate &&
          !this.oneway.redate
        ) {
          // let result1 = [];
          //   result1 = JSON.stringify(this.oneway);
          //   localStorage.setItem("searchedData", result1);
          const result1 = JSON.stringify(this.oneway);
          this.tripData = this.oneway;
          this.formDatas = JSON.stringify(this.oneway);
          console.log(result1, "sdasdaresult12333");

          Object.keys(localStorage).forEach((key) => {
            if (key.startsWith("searchedData-oneway-") && key !== `searchedData-oneway-${tabId}`) {
              localStorage.removeItem(key);
            }
          });
          localStorage.setItem(`searchedData-oneway-${tabId}`, result1);

          this.searchCase = JSON.stringify(this.oneway);
          console.log(this.searchCase, "ssdasdseasaaSrchCase...2");
          if (this.searchCase) {
            this.searchloader = true;
          }
          this.sendedone1();
        }
      }
    },

    roundedtrip() {  
        if (this.responseData.length > 0) {
          this.responseData.forEach((v) => {
           // console.log(v.DataLists, "good");
            // let seats = [];
            let baggageList = [];
            this.Journey = v.DataLists.FlightList.Flight;
            let shoppingId = v.ShoppingResponseId;
            // console.log(shoppingId, "shoppingIdshoppingId");
            let Baseprice = [];
            let Tax = [];
            let pricedetail = [];
            let $data = v.OffersGroup.AirlineOffers.Offer;
            baggageList = v.DataLists?.BaggageAllowanceList?.BaggageAllowance;
            let offerId = [];
            // $data.forEach((v=>{
            //   offerId.push(v.OffersGroup.AirlineOffers.Offer.offerId)
            // }))
            // offerId.push(v.OffersGroup.AirlineOffers.Offer)

            let arrsplit = [];
            let brantvalue = [];
            let depsplit = [];
            let resultdata = [];
            let resultdata1 = [];
            let refund = [];
            let currency = [];
            let ownerlogo = [];
            let ownername = [];
            let deeplinkurl = [];
            let perperson = [];
            let TotalPrice = [];

            $data.forEach((v) => {
              // console.log(v, i, "offeriddatataa");
              offerId.push(v.OfferID);
              if (v.BrandedFareIDs &&
                v.BrandedFareIDs.length > 0) {
                brantvalue.push(true);
                // console.log(i, v.BrandedFareIDs, "brantvalue");
              } else {
                brantvalue.push(false);
              }
            });
            // console.log(brantvalue,'brantvalue')

            // $data.forEach((s) => {
            //   if (s.ReqCurrency == "USD") {
            //     currency.push("US$");
            //   } else if (s.ReqCurrency == "CAD") {
            //     currency.push("CA$");
            //   } else if (s.ReqCurrency == "AUD") {
            //     currency.push("AU$");
            //   }

            $data.forEach((s) => {
              if (s.ReqCurrency == this.preferCurrency) {
                currency.push(this.countryCurrency);
              }
              else {
                currency.push(s.ReqCurrency);
              }

              // console.log(this.airline_logoData,"vlvlvlvlvvl")

              
              deeplinkurl.push(s.DeeplinkUrl || s.Deeplink_URL);
              for (let i = 0; i < this.airline_logoData.length - 1; i++) {
                if (s.Owner == this.airline_logoData[i].id) {
                       console.log(s.Owner,this.airline_logoData[i].id,'ppppprrrrrp44444')
                       ownername.push(s.OwnerName);
                  ownerlogo.push(this.airline_logoData[i].logo);

                  // console.log(ownerlogo,'owmpownwonwown')
                }
              }
              // console.log(s.ReqCurrency, "currency");
              Baseprice.push(
                parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
              );
              Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
              perperson.push(
                parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
              );
              TotalPrice.push(
                parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
              );
              pricedetail.push(s.OfferItem);

              let adultPriceDetails = [];
              let childPriceDetails = [];
              let infPriceDetails = [];

              s.OfferItem[0].forEach((e) => {
                // console.log(e, "local12233");
                if (e.PassengerType === "ADT") {
                  adultPriceDetails = this.getPriceDetails(
                    e,
                    "Adult",
                    "adultcount"
                  );
                }

                if (e.PassengerType === "CNN") {
                  childPriceDetails = this.getPriceDetails(
                    e,
                    "Child",
                    "childcount"
                  );
                }

                if (e.PassengerType === "INF") {
                  infPriceDetails = this.getPriceDetails(
                    e,
                    "Inf(lap)",
                    "infcount"
                  );
                }
              });

              this.combinedPricedetail.push([
                adultPriceDetails,
                childPriceDetails,
                infPriceDetails,
              ]);

              s.OfferItem.forEach((v) => {
                // console.log(v, "kannannaaannnanannananannanan");
                resultdata1 = v[0].FareComponent[0].SegmentRefs;
                resultdata = v[0].FareComponent[1].SegmentRefs;

                // console.log(
                //   v[0].FareDetail[0].Price.NonRefundable,
                //   "kannannaaannnanannananannanan"
                // );

                if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
                  refund.push("Refundable");
                } else {
                  refund.push("Non Refundable");
                }
              });
              arrsplit.push(resultdata.split(" "));
              depsplit.push(resultdata1.split(" "));
            });
            // console.log(arrsplit, "$data");

            // console.log(refund, 'ppppppppppppppppppppppppppppppppp')
            this.Journey1 = v.DataLists.FlightSegmentList.FlightSegment;
            // console.log( this.Journey1,'flight1')
            this.Journey.forEach((a) => {
              this.routtime.push(a.Journey);
              // console.log(a,'thesplitvalueaaaaaaaaaaaaaa')
              this.flightsegmet.push(a.SegmentReferences.toString().split(" "));
              // console.log(this.flightsegmet ,'thesplitvalue')
            });
            this.Journey1.forEach((t) => {

              this.flightsegmetkey.push(t);
            });



            // seats = v.DataLists.PriceClassList.PriceClass;
            // console.log(seats, "seatsseatsseats");
            // seats.forEach((c) => {
            //   c.ClassOfService.forEach((v) => {
            //     for (let i = 0; i < this.flightsegmetkey.length - 1; i++) {
            //       if (v.SegementRef == this.flightsegmetkey[i].SegmentKey) {
            //         this.flightsegmetkey[i].Seat = v.Code.SeatsLeft;
            //       }
            //     }
            //   });
            // });

            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              for (let j = 0; j < this.flightsegmetkey.length - 1; j++) {
                if (
                  this.flightsegmetkey[j].MarketingCarrier.AirlineID ==
                  this.airlogodata[i].id
                ) {
                  this.flightsegmetkey[j].MarketingCarrier.logo =
                    this.airlogodata[i].logo;
                }
              }
            }

            for (let i = 0; i <= depsplit.length - 1; i++) {
              // console.log(depsplit,'depsplit')
              let seg = [];
              depsplit[i].forEach((f, index) => {
                let items = [];
                items = this.flightsegmetkey.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == depsplit[i].length - 1) {
                  this.rountresult.push({ Depature: seg });
                }
              });
            }

            for (let i = 0; i <= arrsplit.length - 1; i++) {
              // console.log(arrsplit,'arrsplit')
              let seg = [];
              arrsplit[i].forEach((f, index) => {
                this.items = this.flightsegmetkey.filter(
                  (v) => v.SegmentKey == f
                );
                if (this.items.length > 0) {
                  seg.push(this.items[0]);
                }
                if (index == arrsplit[i].length - 1) {
                  this.returnData.push(seg);
                }
              });
            }
            let Arrjourney = [];
            let depjourney = [];
            let depBaggage = [];
            let arrBaggage = [];
            let brantFare = [];
            let brantName = [];
            let BrandId=[]
            for (let j = 0; j <= $data.length - 1; j++) {
              let result = $data[j].OfferItem[0][0].FareComponent[0];
              brantFare.push($data[j]?.OfferItem[0][0].BrandFeature);
              brantName.push($data[j]?.OfferItem[0]?.[0]?.BrandName);
              BrandId.push($data[j]?.OfferItem[0]?.[0]?.BrandId);
              depBaggage.push(
                $data[j]?.OfferItem[0][0]?.BaggageAllowance[0]?.BaggageAllowanceRef
              );
              arrBaggage.push(
                $data[j]?.OfferItem[0][0]?.BaggageAllowance[1]?.BaggageAllowanceRef
              );
              let result1 = $data[j].OfferItem[0][0].FareComponent[1];
              let source = [];
              let source1 = [];
              for (let i = 0; i <= this.Journey.length - 1; i++) {
                if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
                  source = this.Journey[i].Journey
                } else if (
                  result1.SegmentRefs == this.Journey[i].SegmentReferences
                ) {
                  source1 = this.Journey[i].Journey
                }
              }
              Arrjourney.push(source);
              depjourney.push(source1);
            }

            let BaggageData = [];
            let BaggageData1 = [];
            depBaggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData.push(c);
                }
              });
            });
            arrBaggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData1.push(c);
                }
              });
            });

            for (let i = 0; i < perperson.length; i++) {
              let nume1 = perperson[i];

              this.find1.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[0]
              );
              this.find2.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[1]
              );
            }
            // console.log(this.find1, "this.find1this.find1");
            depjourney = depjourney.map(a => ({
  ...a, 
  Time: a.Time.replace(" H", "h").replace(" M", "m")
}))
Arrjourney = Arrjourney.map(a => ({
  ...a, 
  Time: a.Time.replace(" H", "h").replace(" M", "m")
}))
            for (let i = 0; i <= this.rountresult.length - 1; i++) {
              this.rountresult[i].Baseprice = Baseprice[i];
              this.rountresult[i].brantFare = brantFare[i];
              this.rountresult[i].brantName = brantName[i];
              this.rountresult[i].BrandId = BrandId[i];
              this.rountresult[i].TotalPrice = TotalPrice[i];
              this.rountresult[i].brantvalue = brantvalue[i];
              this.rountresult[i].barantFare = false;
              this.rountresult[i].Taxprice = Tax[i];
              this.rountresult[i].pricedetail = pricedetail[i];
              this.rountresult[i].pricedetails = this.combinedPricedetail[i];
              this.rountresult[i].Return = this.returnData[i];
              this.rountresult[i].depBaggage = BaggageData[i];
              this.rountresult[i].arrBaggage = BaggageData1[i];
              this.rountresult[i].Flightreturn = false;
              this.rountresult[i].multipleDatas = false;
              this.rountresult[i].Flightdepature = false;
              this.rountresult[i].hoveredName1 = false;
              this.rountresult[i].hoveredName2 = false;
              this.rountresult[i].hoveredName3 = false;
              this.rountresult[i].hoveredName4 = false;
              this.rountresult[i].Refund = refund[i];
              this.rountresult[i].currency = currency[i];
              this.rountresult[i].triptype = "RoundTrip";
              this.rountresult[i].pointofSale = this.pointofSale;
              this.rountresult[i].hitId = `${currency[i]}-${this.uniqueId}`;
              this.rountresult[i].ownerlogo = ownerlogo[i];
              this.rountresult[i].ownername = ownername[i];
              this.rountresult[i].deeplinkurl = deeplinkurl[i];
              this.rountresult[i].Returnjourney = depjourney[i];
              this.rountresult[i].Depaturejourney = Arrjourney[i];
              this.rountresult[i].perperson = perperson[i];
              this.rountresult[i].cabinClass = this.oneway.class;
              this.rountresult[i].find1 = this.find1[i];
              this.rountresult[i].find2 = this.find2[i];
              this.rountresult[i].ShoppingResponseId = shoppingId;
              this.rountresult[i].selectId = offerId[i];
              this.rountresult[i].Adult = this.adultValue;
              this.rountresult[i].Child = this.childValue;
              this.rountresult[i].Inf = this.infantValue;
              this.rountresult[i].radioData = true;
              this.rountresult[i].radiretData = true;
              this.rountresult[i].radios = false;
              this.rountresult[i].radiosret = false;
              this.rountresult[i].fromTrip = this.tripData.from;
              this.rountresult[i].toTrip = this.tripData.to;
            }
            this.rountmultiData = this.rountresult;
            this.filterData = this.rountresult;
            this.rounddatafil = true;
            console.log(this.filterData, "resulppppppppppppppppppppppppt");
            this.noflightError = !this.rountresult || this.rountresult == "" ? true : false;
            this.gettimedep();
            this.gettimeret();
            // this.filterOutBound();
            // this.priceRange();
            // this.Shortest();
            // this.Recommend();
            // this.Cheapest();
            // this.bestlayTime();
            // this.airlineFilter();
            // this.applyFilters();
						
            // this.calculateTotalTime();
            // this.singlepriceRountData();
            // this.stopFilter();

            if (this.rountresult) {
              this.searchresult = true;
              this.searchloader = false;

              document.getElementById("navbar").style.display = "block";
              document.getElementById("food").style.display = "block";
            }
            // console.log(this.rountresult, "resulppppppppppppppppppppppppt");

            // this.filterShowing();

            // console.log(this.rountresult, 'result222222222222222222222222')
            this.filterData = this.rountresult;
            this.filterData.sort((a, b) => a.perperson - b.perperson);
            this.rountresult = this.filterData;
          });
        }
      
    },

    async sendedRoundData() {
      // localStorage.removeItem("onewaytrip");
      // localStorage.removeItem("rounttrip");
      this.onewayresulted = [];

      this.oneway.city = this.red1;
      this.oneway.adult = this.aduls;
      this.oneway.infant = this.aduls2;
      this.oneway.child = this.aduls1;
      // this.oneway.class = this.select;
      let rountdetail = {
        from: null,
        to: null,
        depdate: this.oneway.dedate,
        class: this.oneway.class,
        child: this.oneway.child,
        adult: this.oneway.adult,
        infant: this.oneway.infant,
        city: this.oneway.city,
      };
      let result1 = [];
      result1 = JSON.stringify(this.oneway);
      localStorage.setItem("rountData", result1);

      if (
        this.oneway.from &&
        this.oneway.to &&
        this.oneway.dedate &&
        this.oneway.redate
      ) {
        console.log(this.oneway, "onewsdsayonewsdsdayonesdsway");
        this.loadSearch = true;
        this.disButton = true;
        let res = "";
        if (this.oneway.from.name) {
          rountdetail.from = this.oneway.from.name;
          res = this.oneway.from.name.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.from = this.oneway.from;
          res = this.oneway.from.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let res1 = "";
        if (this.oneway.to.name) {
          rountdetail.to = this.oneway.to.name;
          res1 = this.oneway.to.name.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.to = this.oneway.to;
          res1 = this.oneway.to.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let result =
          this.Rountdata.request.AirShoppingRQ.CoreQuery.OriginDestinations
            .OriginDestination;
        for (let i = 0; i <= result.length - 1; i++) {
          result[0].Departure.AirportCode = this.roundfrom;

          result[0].Departure.Date = moment(
            this.oneway.dedate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[0].Arrival.AirportCode = this.roundto;
          result[1].Departure.AirportCode = this.roundto;
          result[1].Departure.Date = moment(
            this.oneway.redate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[1].Arrival.AirportCode = this.roundfrom;
        }

        let cabin_type = this.oneway.class;
        if (cabin_type == "Economy") {
          cabin_type = "Y";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Business") {
          cabin_type = "C";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else {
          cabin_type = "F";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        }

        this.Rountdata.request.AirShoppingRQ.Preference.Currency =
          this.preferCurrency;
        this.Rountdata.request.AirShoppingRQ.MetaData.Currency =
          this.metaCurrency;
        this.Rountdata.country = this.countryCodeData;
        this.Rountdata.portal_domain = this.portal_domain;
        if (this.oneway.advanceOptions) {
          let getadvanceOpt = this.oneway.advanceOptions;
          this.Rountdata.request.AirShoppingRQ.Preference.DirectFlight =
            getadvanceOpt.directFlight;
          this.Rountdata.request.AirShoppingRQ.Preference.BaggageInclusive =
            getadvanceOpt.baggageInclusive;
          this.Rountdata.request.AirShoppingRQ.Preference.NearbyAirport =
            getadvanceOpt.nearbyAirport;
          this.Rountdata.request.AirShoppingRQ.Preference.Stops =
            getadvanceOpt.stopsSelect ? [getadvanceOpt.stopsSelect] : [];
          this.Rountdata.request.AirShoppingRQ.Preference.LayoverHours =
            getadvanceOpt.adlayoverTime;
          this.Rountdata.request.AirShoppingRQ.Preference.AvoidCountry =
            getadvanceOpt.avoidCountry;
          this.Rountdata.request.AirShoppingRQ.Preference.IncludedAirlines = (
            getadvanceOpt.includeAirline ?? []
          ).map((airline) => {
            const match = airline.match(/\(([^)]+)\)/);
            return match ? match[1] : airline;
          });
          this.Rountdata.request.AirShoppingRQ.Preference.ExcludedAirlines = (
            getadvanceOpt.excludeAirline ?? []
          ).map((airline) => {
            const match = airline.match(/\(([^)]+)\)/);
            return match ? match[1] : airline;
          });
          this.Rountdata.request.AirShoppingRQ.Preference.IncludedViaStops = (
            getadvanceOpt.includeAirport ?? []
          ).map((airport) => {
            const match = airport.match(/\(([^)]+)\)/);
            return match ? match[1] : airport;
          });
          this.Rountdata.request.AirShoppingRQ.Preference.ExcludedViaStops = (
            getadvanceOpt.excludeAirport ?? []
          ).map((airport) => {
            const match = airport.match(/\(([^)]+)\)/);
            return match ? match[1] : airport;
          });
        }

        console.log(
          this.makeSearchUrl,
          this.Rountdata,
          "this.Rountdatathis.Rountdatathis.Rountdatathis.Rountdatathis.Rountdata"
        );
        try {
          const response = await axios.post(
            this.makeSearchUrl,
            this.Rountdata,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          this.barentFareData = response.data;
          console.log(this.barentFareData,'this.barentFareData')
          this.responseData.push(response.data);

          console.log(this.responseData, "thisresponseDatathisresponseData");

          let getRounddata = [];
          getRounddata = JSON.stringify(this.responseData);

          // localStorage.setItem("rounttrip", getRounddata);

          // this.responseData.push(JSON.parse(getRounddata));

          if (getRounddata) {
            this.roundedtrip();
          }

          if (response.data.Errors == "Errors") {
            this.searchresult = false;
            // this.searchloader = true;
            setTimeout(() => {
              this.noflightError = true;
              this.searchresult = false;
              this.searchloader = false;
            }, 7000);
          }
        } catch (error) {
          this.searchloader = true;
          setTimeout(() => {
            this.searchloader = false;
            this.searchresult = true;
            if (error.response && error.response.data.success == false) {
              this.dateError = error.response.data.message;
            }
            this.noflightError = true;
          }, 7000);

          console.log(error, "eroororororoowooe...round");
        }
      } else {
        this.alert = true;
        // alert("please enter your detail");
        this.validateOnewayForm();
      }

      // console.log(this.responseData, "0077777777777");
    },

    async sendedone1() {
      // localStorage.removeItem("rounttrip");
      // localStorage.removeItem("onewaytrip");

      let resul =
        this.onewaydata.request.AirShoppingRQ.DataLists.PassengerList.Passenger;

      // console.log(resul, "5555555555555555555555");
      if (this.concatenatedArray) {
        this.concatenatedArray.forEach((v) => {
          // console.log(v, "vvvvvvvvvvvvvvvvvvvvvvvvvv");
          resul.push(v);
        });
      } else {
        // console.log(this.concatenatedArray);
      }
      if (this.oneway.from && this.oneway.to && this.oneway.dedate) {
        this.loadSearch = true;
        this.disButton = true;

        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infant = this.aduls2;
        this.oneway.child = this.aduls1;
        // this.oneway.class = this.select;
        // console.log(this.oneway, "this.onewaythis.onewaythis.oneway");
        let result1 = [];
        result1 = JSON.stringify(this.oneway);
        // console.log(result1)
        localStorage.setItem("rountData", result1);

        let res = "";

        if (this.oneway.from.name) {
          res = this.oneway.from.name.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          res = this.oneway.from.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let res1 = "";
        if (this.oneway.to.name) {
          res1 = this.oneway.to.name.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          res1 = this.oneway.to.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        this.urldedate = moment(this.oneway.dedate, "DD MMM-YYYY").format(
          "YYYY-MM-DD"
        );

        let result =
          this.onewaydata.request.AirShoppingRQ.CoreQuery.OriginDestinations
            .OriginDestination;
        for (let i = 0; i <= result.length - 1; i++) {
          result[0].Departure.AirportCode = this.roundfrom;
          result[0].Departure.Date = moment(
            this.oneway.dedate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[0].Arrival.AirportCode = this.roundto;
          // result[1].Departure.AirportCode = this.roundto
          // result[1].Departure.Date = moment(this.oneway.redate).format('YYYY-MM-DD')
          // result[1].Arrival.AirportCode = this.roundfrom
        }

        let cabin_type = this.oneway.class;
        if (cabin_type == "Economy") {
          cabin_type = "Y";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Business") {
          cabin_type = "C";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else {
          cabin_type = "F";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        }

        this.onewaydata.request.AirShoppingRQ.Preference.Currency =
          this.preferCurrency;
        this.onewaydata.request.AirShoppingRQ.MetaData.Currency =
          this.metaCurrency;
        this.onewaydata.country = this.countryCodeData;
        this.onewaydata.portal_domain = this.portal_domain;
        if (this.oneway.advanceOptions) {
          let getadvanceOpt = this.oneway.advanceOptions;
          this.onewaydata.request.AirShoppingRQ.Preference.DirectFlight =
            getadvanceOpt.directFlight;
          this.onewaydata.request.AirShoppingRQ.Preference.BaggageInclusive =
            getadvanceOpt.baggageInclusive;
          this.onewaydata.request.AirShoppingRQ.Preference.NearbyAirport =
            getadvanceOpt.nearbyAirport;
          this.onewaydata.request.AirShoppingRQ.Preference.Stops =
            getadvanceOpt.stopsSelect ? [getadvanceOpt.stopsSelect] : [];
          this.onewaydata.request.AirShoppingRQ.Preference.LayoverHours =
            getadvanceOpt.adlayoverTime;
          this.onewaydata.request.AirShoppingRQ.Preference.AvoidCountry =
            getadvanceOpt.avoidCountry;
          this.onewaydata.request.AirShoppingRQ.Preference.IncludedAirlines = (
            getadvanceOpt.includeAirline ?? []
          ).map((airline) => {
            const match = airline.match(/\(([^)]+)\)/);
            return match ? match[1] : airline;
          });
          this.onewaydata.request.AirShoppingRQ.Preference.ExcludedAirlines = (
            getadvanceOpt.excludeAirline ?? []
          ).map((airline) => {
            const match = airline.match(/\(([^)]+)\)/);
            return match ? match[1] : airline;
          });
          this.onewaydata.request.AirShoppingRQ.Preference.IncludedViaStops = (
            getadvanceOpt.includeAirport ?? []
          ).map((airport) => {
            const match = airport.match(/\(([^)]+)\)/);
            return match ? match[1] : airport;
          });
          this.onewaydata.request.AirShoppingRQ.Preference.ExcludedViaStops = (
            getadvanceOpt.excludeAirport ?? []
          ).map((airport) => {
            const match = airport.match(/\(([^)]+)\)/);
            return match ? match[1] : airport;
          });
        }

        console.log(this.onewaydata, "this.onewaydatathis.onewaydata");

        try {
          const response = await axios.post(
            this.makeSearchUrl,
            this.onewaydata,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          console.log(response, "responseresponseresponse");
          this.responseData.push(response.data);

          let getOnewaydata = [];
          getOnewaydata = JSON.stringify(this.responseData);

          // localStorage.setItem("onewaytrip", getOnewaydata);
          this.barentFareData = response.data;
          if (getOnewaydata) {
            this.onewaytrip();
          }

          if (response.data.Errors == "Errors") {
            this.searchresult = false;
            this.searchloader = true;
            setTimeout(() => {
              // this.noflightError = true;
              this.searchresult = false;
              this.searchloader = false;
            }, 7000);
          }
        } catch (error) {
          this.searchloader = true;
          setTimeout(() => {
            this.searchloader = false;
            this.searchresult = true;
            if (error.response && error.response.data.success == false) {
              this.dateError = error.response.data.message;
            }
            this.noflightError = true;
          }, 7000);

          console.log(error, "eroororororoowooe...oneway");
        }

        //   axios
        //     .post(this.makeSearchUrl, this.onewaydata, {
        //       headers: {
        //         "Content-Type": "application/json",
        //       },
        //     })
        //     .then((response) => {
        //       // console.log(response, "responseresponseresponse");
        //       this.responseData.push(response.data);
        //       // console.log(
        //       //   this.responseData,
        //       //   "this.responseDatathis.responseData"
        //       // );
        //       let rountData = [];
        //       rountData = JSON.stringify(this.responseData);

        //       localStorage.setItem("onewaytrip", rountData);

        //       if (rountData) {
        //         this.onewaytrip();
        //       }

        //       //             if (response.data.AirShoppingRS.Errors) {
        //       //               this.searchresult = false;
        //       //               this.searchloader = true;
        //       //               setTimeout(() => {
        //       //                 this.noflightError = true;
        //       //                 this.searchresult = false;
        //       //               this.searchloader = false;
        //       //               }, 7000);
        //       //             }

        //       // else{
        //       //   this.responseData.push(response.data.AirShoppingRS);
        //       // console.og(this.responseData,'this.responseDatathis.responseData')
        //       // let rountData = [];
        //       // rountData = JSON.stringify(this.responseData);

        //       // localStorage.setItem("onewaytrip", rountData);
        //       // this.$router.push({ path: "/flight/search", query: rountdetail });

        //       // if (rountData) {
        //       //   this.onewaytrip();
        //       // }
        //       // }
        //     })
        //     .catch((error) => {
        //       console.log(error);
        //       if(error.response && error.response.data.success == false){
        //       this.dateError = error.response.data.message;
        //     }
        //     this.noflightError = true;
        //       // this.$router.push("/noflightError");
        //     });
      } else {
        this.alert = true;
        // alert("please enter your detail");

        this.validateOnewayForm();
      }
    },

		onewaytrip() {
      // let onewayresult = [];
      // onewayresult = JSON.parse(localStorage.getItem("onewaytrip"));
      // console.log(onewayresult, "onewayresult");
      let baggageList = [];
      if (this.responseData.length>0) {
        this.responseData.forEach((v)=> {
          
            this.Journey = v.DataLists.FlightList.Flight;
            baggageList =
              v.DataLists?.BaggageAllowanceList?.BaggageAllowance;
            let shoppingId = v.ShoppingResponseId;
            let refund = [];
            let Baseprice = [];
            let Tax = [];
            let pricedetail = [];
            let $data = v.OffersGroup.AirlineOffers.Offer;
            // this.oneway.class = v

            let $data_onewayId =
              v.OffersGroup.AirlineOffers.Offer;

            //  console.log($data,'onewaaayyyyyyy....1...')

            let offerId = [];
            let depsplit = [];
            let resultdata1 = [];
            let currency = [];
            let ownerlogo = [];
            let ownername = [];
            let deeplinkurl = [];
            let perperson = [];
            let TotalPrice = [];
            let brantvalue = [];
            $data_onewayId.forEach((v) => {
              offerId.push(v.OfferID);
              if (v.BrandedFareIDs && v.BrandedFareIDs.length > 0) {
                brantvalue.push(true);
                // console.log(i, v.BrandedFareIDs, "brantvalue");
              } else {
                brantvalue.push(false);
              }
            });

            $data.forEach((s) => {
              if (s.ReqCurrency == this.preferCurrency) {
                currency.push(this.countryCurrency);
              }
              else {
                currency.push(s.ReqCurrency);
              }
              ownername.push(s.OwnerName);
              deeplinkurl.push(s.DeeplinkUrl ||s.Deeplink_URL);
              for (let i = 0; i < this.airline_logoData.length - 1; i++) {
                if (s.Owner == this.airline_logoData[i].id) {
                  ownerlogo.push(this.airline_logoData[i].logo);
                }
              }

              Baseprice.push(
                parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
              );
              Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
              perperson.push(
                parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
              );
              TotalPrice.push(
                parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
              );

              pricedetail.push(s.OfferItem);

              let adultPriceDetails = [];
              let childPriceDetails = [];
              let infPriceDetails = [];

              s.OfferItem[0].forEach((e) => {
                // console.log(e, "local12233");
                if (e.PassengerType === "ADT") {
                  adultPriceDetails = this.getPriceDetails(
                    e,
                    "Adult",
                    "adultcount"
                  );
                }

                if (e.PassengerType === "CNN") {
                  childPriceDetails = this.getPriceDetails(
                    e,
                    "Child",
                    "childcount"
                  );
                }

                if (e.PassengerType === "INF") {
                  infPriceDetails = this.getPriceDetails(
                    e,
                    "Inf(lap)",
                    "infcount"
                  );
                }
              });

              this.combinedPricedetail.push([
                adultPriceDetails,
                childPriceDetails,
                infPriceDetails,
              ]);

              s.OfferItem.forEach((v) => {
                if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
                  refund.push("Refundable");
                } else {
                  refund.push("Non Refundable");
                }

                if (v[0].FareComponent[0].SegmentRefs) {
                  resultdata1 = v[0].FareComponent[0].SegmentRefs;
                }
              });

              depsplit.push(resultdata1.split(" "));
            });

            // console.log(depsplit, '$data')
            this.Journey1 =
              v.DataLists.FlightSegmentList.FlightSegment;
            // console.log( this.Journey1,'flight1')Seatsseats
            this.Journey.forEach((a) => {
              this.routtime.push(a.Journey);
              this.flightsegmet.push(a.SegmentReferences.split(" "));
            });
            this.Journey1.forEach((t) => {
              this.flightsegmetkey.push(t);
            });
            // let seats = [];
            // seats = onewayresult[i].DataLists.PriceClassList.PriceClass;
            // seats.forEach((c) => {
            //   c.ClassOfService.forEach((v) => {
            //     for (let i = 0; i < this.flightsegmetkey.length - 1; i++) {
            //       if (v.SegementRef == this.flightsegmetkey[i].SegmentKey) {
            //         this.flightsegmetkey[i].Seat = v.Code.SeatsLeft;
            //       }
            //     }
            //   });
            // });

            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              for (let j = 0; j < this.flightsegmetkey.length - 1; j++) {
                if (
                  this.flightsegmetkey[j].MarketingCarrier.AirlineID ==
                  this.airlogodata[i].id
                ) {
                  this.flightsegmetkey[j].MarketingCarrier.logo =
                    this.airlogodata[i].logo;
                }
              }
            }

            for (let i = 0; i <= depsplit.length - 1; i++) {
              let seg = [];
              depsplit[i].forEach((f, index) => {
                let items = [];
                items = this.flightsegmetkey.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == depsplit[i].length - 1) {
                  this.onewayresulted.push({ Depature: seg });
                }
              });
            }

            let depjourney = [];
            let Baggage = [];
            let brantFare = [];
            let brantName = [];
            let BrandId=[]
            for (let j = 0; j <= $data.length - 1; j++) {
              let result = $data[j].OfferItem[0][0].FareComponent[0];
              brantFare.push($data[j]?.OfferItem[0]?.[0]?.BrandFeature);
              brantName.push($data[j]?.OfferItem[0]?.[0]?.BrandName);
              BrandId.push($data[j]?.OfferItem[0]?.[0]?.BrandId);
              Baggage.push(
                $data[j]?.OfferItem[0][0]?.BaggageAllowance[0]?.BaggageAllowanceRef
              );
              let source = [];

              for (let i = 0; i <= this.Journey.length - 1; i++) {
                if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
                  source = this.Journey[i].Journey
                  console.log(source,'source')
                  
                }
              }

              depjourney.push(source);
            }
            let BaggageData = [];

            Baggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData.push(c);
                }
              });
            });

            for (let i = 0; i < perperson.length; i++) {
              let nume1 = perperson[i];
              // console.log(nume1, "llldlldldldldld")
              this.find1.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[0]
              );
              this.find2.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[1]
              );
            }

            depjourney = depjourney.map(a => ({
  ...a, 
  Time: a.Time.replace(" H", "h").replace(" M", "m")
}));



            for (let i = 0; i < this.onewayresulted.length; i++) {
              this.onewayresulted[i].Baseprice = Baseprice[i];
              this.onewayresulted[i].brantFare = brantFare[i];
              this.onewayresulted[i].brantName = brantName[i];
              this.onewayresulted[i].BrandId = BrandId[i];
              this.onewayresulted[i].TotalPrice = TotalPrice[i];
              this.onewayresulted[i].Taxprice = Tax[i];
              this.onewayresulted[i].pricedetail = pricedetail[i];
              this.onewayresulted[i].pricedetails = this.combinedPricedetail[i];
              this.onewayresulted[i].Flightreturn = false;
              this.onewayresulted[i].Flightdepature = false;
              this.onewayresulted[i].multipleDatas = false;
              this.onewayresulted[i].hoveredName1 = false;
              this.onewayresulted[i].hoveredName2 = false;
              this.onewayresulted[i].hoveredName3 = false;
              this.onewayresulted[i].hoveredName4 = false;
              this.onewayresulted[i].onewaymulticheck = true;
              this.onewayresulted[i].Refund = refund[i];
              this.onewayresulted[i].depBaggage = BaggageData[i];
              this.onewayresulted[i].currency = currency[i];
              this.onewayresulted[i].brantvalue = brantvalue[i];
              this.onewayresulted[i].barantFare = false;
              this.onewayresulted[i].pointofSale = this.pointofSale;
              this.onewayresulted[i].triptype = "OneWay";
              this.onewayresulted[i].hitId = `${currency[i]}-${this.uniqueId}`;
              this.onewayresulted[i].ownerlogo = ownerlogo[i];
              this.onewayresulted[i].ownername = ownername[i];
              this.onewayresulted[i].deeplinkurl = deeplinkurl[i];
              this.onewayresulted[i].Depaturejourney = depjourney[i];
              this.onewayresulted[i].perperson = perperson[i];
              this.onewayresulted[i].cabinClass = this.oneway.class;
              this.onewayresulted[i].find1 = this.find1[i];
              this.onewayresulted[i].find2 = this.find2[i];
              this.onewayresulted[i].ShoppingResponseId = shoppingId;
              this.onewayresulted[i].selectId = offerId[i];
              this.onewayresulted[i].Adult = this.adultValue;
              this.onewayresulted[i].Child = this.childValue;
              this.onewayresulted[i].Inf = this.infantValue;
              this.onewayresulted[i].radios = true;
              this.onewayresulted[i].radiosData = false;
              this.onewayresulted[i].fromTrip = this.tripData.from;
              this.onewayresulted[i].toTrip = this.tripData.to;
            }

            console.log(this.onewayresulted, "oneway.....w...w..w..w");
            this.onewaymultiData = this.onewayresulted;
            this.onewayfilterData = this.onewayresulted;
            this.noflightError =
              !this.onewayresulted || this.onewayresulted == "" ? true : false;
            this.onedatafil = true;

            this.gettimedep1();
            // this.getlayTime();
            // this.airlineFilter();
            // this.priceRange();
            // this.Shortest();
            // this.stopFilterOneway();
            // this.Recommend();
            // this.Cheapest();
            // this.bestlayTime();
            // this.filterOutBound();
            // this.applyFilters();
						
            // this.gettimeret();
            // this.calculateTotalTime();
            // this.singlepriceData();

            if (this.onewayfilterData) {
              this.searchresult = true;
              this.searchloader = false;

              document.getElementById("navbar").style.display = "block";
              document.getElementById("food").style.display = "block";
            }
            this.onewayresulted.sort((a, b) => a.perperson - b.perperson);
          
        })
      }
    },

    sendedMulticity() {
      for (let i = 0; i < this.multiData.itinery.length; i++) {
        if (this.multiData.itinery[i].from) {
          this.multiData.itinery[i].multiFromError = false;

          // console.log(this.multiData.itinery[i].from, "mememememe.....");
        } else {
          this.multiData.itinery[i].multiFromError = true;
        }

        if (this.multiData.itinery[i].to) {
          this.multiData.itinery[i].multiToError = false;
        } else {
          this.multiData.itinery[i].multiToError = true;
        }

        if (this.multiData.itinery[i].depdate) {
          this.multiData.itinery[i].multiDateError = false;
        } else {
          this.multiData.itinery[i].multiDateError = true;
        }
      }
    },

    async multiDataConvert() {
      if (this.$route.query.itinerary) {
        let getItinerary = this.$route.query.itinerary;
        let getpaxtype = this.$route.query.paxType;

        let splitItin = getItinerary.split("_");
        let segments = splitItin.map((segment) => segment.split("-"));

        this.multiData.itinery = [];
        for (let i = 0; i < segments.length; i++) {
          let [from, to, depdate] = segments[i];

          let $result = {
            Departure: {
              AirportCode: from,
              Date: moment(new Date(depdate)).format("YYYY-MM-DD"),
            },
            Arrival: {
              AirportCode: to,
            },
          };
          this.MulticityReq.request.AirShoppingRQ.CoreQuery.OriginDestinations.OriginDestination.push(
            $result
          );

          if (from && to) {
            const [fromCityList, toCityList] = await Promise.all([
              this.fetchCity(from),
              this.fetchCity(to),
            ]);

            console.log(fromCityList, "sdfdfdasdada", toCityList);

            if (fromCityList.length > 0 && toCityList.length > 0) {
              this.multiData.itinery.push({
                from: `${fromCityList[0].city} (${from})`,
                to: `${toCityList[0].city} (${to})`,
                depdate: moment(new Date(depdate)).format("YYYY-MM-DD"),
              });

              this.codeData.push({
                from: `${fromCityList[0].city} (${from})`,
                to: `${toCityList[0].city} (${to})`,
              });
            }
          }

          if (from) {
            this.multiData.itinery[i].selectedFrom = true;
          }

          if (to) {
            this.multiData.itinery[i].selectedTo = true;
          }

          console.log(this.multiData.itinery[i], `Segment ${i + 1}`);
        }

        let getPaxDetails = getpaxtype.split("_");
        let [Adt, Chd, Inf] = getPaxDetails;
        let [getAdult, getChild, getInf] = [
          Adt.split("-")[1],
          Chd.split("-")[1],
          Inf.split("-")[1],
        ];

        this.multiData.adult = getAdult;
        this.multiData.child = getChild;
        this.multiData.infant = getInf;
        // this.classType = this.$route.query.cabin;

        let getrouteCabin = this.$route.query.cabin;
        if (getrouteCabin == "Economy") {
          this.classType = "Economy";
        }
        if (getrouteCabin == "Business") {
          this.classType = "Business";
        }
        if (getrouteCabin == "FirstClass") {
          this.classType = "First Class";
        }
        if (getrouteCabin == "PremiumEconomy") {
          this.classType = "Premium Economy";
        }
        if (getrouteCabin == "PremiumBusiness") {
          this.classType = "Premium Business";
        }
        if (getrouteCabin == "PremiumFirstClass") {
          this.classType = "Premium First";
        }

        console.log(this.classType, "asdasdasdasdaswqwqwqwdaszxsdfsfsd");

        this.multiData.tripType = this.$route.query.tripType;

        if (getAdult) {
          for (let i = 0; i < getAdult; i++) {
            this.Adult.push({
              PassengerID: "T",
              PTC: "ADT",
            });
          }
        }

        if (getChild) {
          for (let i = 0; i < getChild; i++) {
            this.Child.push({
              PassengerID: "T",
              PTC: "CHD",
            });
          }
        }

        if (getInf) {
          for (let i = 0; i < getInf; i++) {
            this.Infrant.push({
              PassengerID: "T",
              PTC: "INF",
            });
          }
        }

        let totalpass = [...this.Adult, ...this.Child, ...this.Infrant];
        totalpass.forEach((v, index) => {
          let obj = {
            PassengerID: `${v.PassengerID}${index + 1}`,
            PTC: v.PTC,
          };
          this.totalpassenger.push(obj);
        });

        this.MulticityReq.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
          this.totalpassenger;

        this.adultValue = parseFloat(getAdult);
        // this.tripData.adultValue = parseFloat(getAdult);

        this.childValue = parseFloat(getChild);
        // this.tripData.childValue = parseFloat(getChild);

        this.infantValue = parseFloat(getInf);
        // this.tripData.infantValue = parseFloat(getInf);

        this.totaladult = this.adultValue + this.childValue + this.infantValue;
        this.multiData.class = this.classType;
        // this.tripData.select = this.$route.query.cabin;

        // let langLocale = `${this.pointofSale}-${this.$route.query.language}`;
        // this.$i18n.locale = langLocale;

        // this.changedLang = `${this.pointofSale}-${this.$route.query.language}`
        // this.$emit('localeChanged', this.changedLang);

        const tabId = sessionStorage.getItem("tabId") || Date.now().toString();
        sessionStorage.setItem("tabId", tabId);

        const getLocalData = JSON.parse(
          localStorage.getItem(`searchedData-multicity-${tabId}`)
        );
        console.log(getLocalData, "getLocalDataasdasdgetLocalData");
        if (getLocalData)
          getLocalData.advanceOptions
            ? (this.multiData.advanceOptions = getLocalData.advanceOptions)
            : delete this.multiData.advanceOptions;

        if (this.multiData.itinery.length > 0) {
          const result1 = JSON.stringify(this.multiData);
          Object.keys(localStorage).forEach((key) => {
            if (key.startsWith("searchedData-multicity-") && key !== `searchedData-multicity-${tabId}`) {
              localStorage.removeItem(key);
            }
          });
          localStorage.setItem(`searchedData-multicity-${tabId}`, result1);

          this.searchCase = JSON.stringify(this.multiData);
          console.log(this.searchCase, "ssdasdseasaaSrchCase...3");
          // this.tripData = this.multiData;
          this.formDatas = JSON.stringify(this.multiData);

          if (this.searchCase) {
            this.searchloader = true;
          }
          console.log(this.multiData, "MulticityRequestData...1");
          console.log(this.MulticityReq, "MulticityRequestData....2");
          await this.multicityresult();
        }
      }
    },

    async multicityresult() {
      this.multiData.class = this.classType;
      let multiCabin_type = this.multiData.class;

      console.log(
        multiCabin_type,
        "multiCabin_typemultiCabin_typemultiCabin_type"
      );

      if (multiCabin_type == "Economy") {
        multiCabin_type = "Y";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      } else if (multiCabin_type == "Business") {
        multiCabin_type = "C";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      } else {
        multiCabin_type = "F";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      }

      this.MulticityReq.request.AirShoppingRQ.Preference.Currency =
        this.preferCurrency;
      this.MulticityReq.request.AirShoppingRQ.MetaData.Currency =
        this.metaCurrency;
      this.MulticityReq.country = this.countryCodeData;
      this.MulticityReq.portal_domain = this.portal_domain;

      if (this.multiData.advanceOptions) {
        let getadvanceOpt = this.multiData.advanceOptions;
        this.MulticityReq.request.AirShoppingRQ.Preference.DirectFlight =
          getadvanceOpt.directFlight;
        this.MulticityReq.request.AirShoppingRQ.Preference.BaggageInclusive =
          getadvanceOpt.baggageInclusive;
        this.MulticityReq.request.AirShoppingRQ.Preference.NearbyAirport =
          getadvanceOpt.nearbyAirport;
        this.MulticityReq.request.AirShoppingRQ.Preference.Stops =
          getadvanceOpt.stopsSelect ? [getadvanceOpt.stopsSelect] : [];
        this.MulticityReq.request.AirShoppingRQ.Preference.LayoverHours =
          getadvanceOpt.adlayoverTime;
        this.MulticityReq.request.AirShoppingRQ.Preference.AvoidCountry =
          getadvanceOpt.avoidCountry;
        this.MulticityReq.request.AirShoppingRQ.Preference.IncludedAirlines = (
          getadvanceOpt.includeAirline ?? []
        ).map((airline) => {
          const match = airline.match(/\(([^)]+)\)/);
          return match ? match[1] : airline;
        });
        this.MulticityReq.request.AirShoppingRQ.Preference.ExcludedAirlines = (
          getadvanceOpt.excludeAirline ?? []
        ).map((airline) => {
          const match = airline.match(/\(([^)]+)\)/);
          return match ? match[1] : airline;
        });
        this.MulticityReq.request.AirShoppingRQ.Preference.IncludedViaStops = (
          getadvanceOpt.includeAirport ?? []
        ).map((airport) => {
          const match = airport.match(/\(([^)]+)\)/);
          return match ? match[1] : airport;
        });
        this.MulticityReq.request.AirShoppingRQ.Preference.ExcludedViaStops = (
          getadvanceOpt.excludeAirport ?? []
        ).map((airport) => {
          const match = airport.match(/\(([^)]+)\)/);
          return match ? match[1] : airport;
        });
      }
      console.log(
        this.MulticityReq,
        "MulticityReqMulticityReqMulticityReqMulticityReq"
      );

      try {
        const response = await axios.post(
          this.makeSearchUrl,
          this.MulticityReq,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response, "multi_citymulti_citymulti_city");
        if (response.data) {
          this.barentFareData = response.data;
          this.multicityTotalData.push(response.data);
          this.multicityconvert();
        }
      } catch (error) {
        this.searchloader = true;
        setTimeout(() => {
          this.searchloader = false;
          this.searchresult = true;
          if (error.response && error.response.data.success == false) {
            this.dateError = error.response.data.message;
          }
          this.noflightError = true;
        }, 7000);
        console.error("Error during Axios request:", error);
      }
    },

    multicityconvert() {
      let baggageList = [];

      for (let i = 0; i < this.multicityTotalData.length; i++) {
        console.log(this.multicityTotalData[i], "power");

        let shoppingId = this.multicityTotalData[i].ShoppingResponseId;
        let Journey = [];
        let flightsegment = [];
        Journey = this.multicityTotalData[i].DataLists.FlightList.Flight;
        baggageList =
          this.multicityTotalData[i].DataLists?.BaggageAllowanceList?.BaggageAllowance;

        console.log(Journey, "Journey");
        flightsegment =
          this.multicityTotalData[i].DataLists.FlightSegmentList.FlightSegment;
        // console.log(flightsegment, "flightsegment");
        let $data = [];
        $data = this.multicityTotalData[i].OffersGroup.AirlineOffers.Offer;

        let source1 = [];
        let source2 = [];
        let source3 = [];
        let source4 = [];
        let source5 = [];
        let Baggage1 = [];
        let Baggage2 = [];
        let Baggage3 = [];
        let Baggage4 = [];
        let Baggage5 = [];
        let brantFare = [];
        let brantName = [];
        let BrandId=[]
        $data.forEach((v) => {
          v.OfferItem[0][0].FareComponent.forEach((s, index) => {
            // console.log(v, "multicityTotalData");
            brantFare.push(v?.OfferItem[0][0].BrandFeature);
            brantName.push(v?.OfferItem[0]?.[0]?.BrandName);
            BrandId.push(v?.OfferItem[0]?.[0]?.BrandId);
            if (index == 0) {
              Baggage1.push(
                v.OfferItem[0][0]?.BaggageAllowance[0]?.BaggageAllowanceRef
              );
              this.offerData1.push(s.SegmentRefs.split(" "));

              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source1.push(Journey[i].Journey);
                }
              }
            }
            if (index == 1) {
              Baggage2.push(
                v.OfferItem[0][0]?.BaggageAllowance[0]?.BaggageAllowanceRef
              );
              this.offerData2.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source2.push(Journey[i].Journey);
                }
              }
            }
            if (index == 2) {
              Baggage3.push(
                v.OfferItem[0][0]?.BaggageAllowance[0]?.BaggageAllowanceRef
              );
              this.offerData3.push(s.SegmentRefs.split(" "));
              console.log(this.offerData3, "this.offerData3this.offerData3");
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source3.push(Journey[i].Journey);
                }
              }
            }
            if (index == 3) {
              Baggage4.push(
                v.OfferItem[0][0]?.BaggageAllowance[0]?.BaggageAllowanceRef
              );
              this.offerData4.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source4.push(Journey[i].Journey);
                }
              }
            }
            if (index == 4) {
              Baggage5.push(
                v.OfferItem[0][0]?.BaggageAllowance[0]?.BaggageAllowanceRef
              );
              this.offerData5.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source5.push(Journey[i].Journey);
                }
              }
            }
          });
        });
        console.log(flightsegment, "this.flightsegment");
        let BaggageData1 = [];
        if (this.offerData1.length > 0) {
          Baggage1.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData1.push(c);
              }
            });
          });
          for (let i = 0; i < this.offerData1.length; i++) {
            let seg = [];
            this.offerData1[i].forEach((f, index) => {
              let items = [];
              items = flightsegment.filter((v) => v.SegmentKey == f);
              if (items.length > 0) {
                seg.push(items[0]);
                console.log(seg, "offerData2offerData2offerData2...0");
              }
              if (index == this.offerData1[i].length - 1) {
                this.multicityData.push({ Flight1: seg });
              }
            });
          }
        }
        console.log(this.multicityData, "offerData2offerData2offerData2...1");

        let BaggageData2 = [];
        if (this.offerData2.length > 0) {
          Baggage2.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData2.push(c);
              }
            });
          });

          for (let i = 0; i < this.offerData2.length; i++) {
            let seg = [];
            this.offerData2[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData2[i].length - 1) {
                this.Flight2.push(seg);
              }
            });
          }
        }
        console.log(this.Flight2, "offerData2offerData2offerData2...2");

        let BaggageData3 = [];
        if (this.offerData3.length > 0) {
          Baggage3.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData3.push(c);
              }
            });
          });

          // console.log(this.offerData3,'this.offerData3this.offerData36557')
          for (let i = 0; i < this.offerData3.length; i++) {
            let seg = [];
            this.offerData3[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              console.log(
                index,
                this.offerData3[i].length - 1,
                "this.offerData2[i].length - 1"
              );
              if (index == this.offerData3[i].length - 1) {
                this.Flight3.push(seg);
                // console.log(this.Flight3,'this.Flight3this.Flight3this.Flight3this.Flight3')
              }
            });
          }
        }
        console.log(this.Flight3, "offerData2offerData2offerData2...3");

        let BaggageData4 = [];
        if (this.offerData4.length > 0) {
          Baggage4.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData4.push(c);
              }
            });
          });

          for (let i = 0; i < this.offerData4.length; i++) {
            let seg = [];
            this.offerData4[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData4[i].length - 1) {
                this.Flight4.push(seg);
              }
            });
          }
        }
        console.log(this.Flight4, "offerData2offerData2offerData2...4");

        let BaggageData5 = [];
        if (this.offerData5.length > 0) {
          Baggage5.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData5.push(c);
              }
            });
          });

          for (let i = 0; i < this.offerData5.length; i++) {
            let seg = [];
            this.offerData5[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData5[i].length - 1) {
                this.Flight5.push(seg);
              }
            });
          }
        }
        for (let i = 0; i < this.airlogodata.length - 1; i++) {
          for (let j = 0; j < flightsegment.length - 1; j++) {
            if (
              flightsegment[j].MarketingCarrier.AirlineID ==
              this.airlogodata[i].id
            ) {
              flightsegment[j].MarketingCarrier.logo = this.airlogodata[i].logo;
            }
          }
        }

        let currency = [];
        let Baseprice = [];
        let Tax = [];
        let perperson = [];
        let ownername = [];
        let deeplinkurl = [];
        let ownerlogo = [];
        let offerId = [];
        let TotalPrice = [];
        let refund = [];
        let brantvalue = [];
        $data.forEach((v) => {
          // console.log(v, "offeriddatataa");
          offerId.push(v.OfferID);
          if (v.BrandedFareIDs && v.BrandedFareIDs.length > 0) {
            brantvalue.push(true);
            // console.log(i, v.BrandedFareIDs, "brantvalue");
          } else {
            brantvalue.push(false);
          }
        });

        $data.forEach((s) => {
          if (s.ReqCurrency == this.preferCurrency) {
            currency.push(this.countryCurrency);
          }
          else {
            currency.push(s.ReqCurrency);
          }

          ownername.push(s.OwnerName);
          deeplinkurl.push(s.DeeplinkUrl || s.Deeplink_URL);
          for (let i = 0; i < this.airline_logoData.length - 1; i++) {
            if (s.Owner == this.airline_logoData[i].id) {
              ownerlogo.push(this.airline_logoData[i].logo);
            }
          }

          Baseprice.push(
            parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
          );
          Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
          perperson.push(
            parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
          );
          TotalPrice.push(
            parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
          );

          let adultPriceDetails = [];
          let childPriceDetails = [];
          let infPriceDetails = [];

          s.OfferItem[0].forEach((e) => {
            if (e.PassengerType === "ADT") {
              adultPriceDetails = this.getPriceDetails(
                e,
                "Adult",
                "adultcount"
              );
            }

            if (e.PassengerType === "CNN") {
              childPriceDetails = this.getPriceDetails(
                e,
                "Child",
                "childcount"
              );
            }

            if (e.PassengerType === "INF") {
              infPriceDetails = this.getPriceDetails(e, "Inf(lap)", "infcount");
            }
          });

          this.combinedPricedetail.push([
            adultPriceDetails,
            childPriceDetails,
            infPriceDetails,
          ]);

          s.OfferItem.forEach((v) => {
            console.log(
              v[0].FareDetail[0].Price.NonRefundable,
              "Price.RefundablePrice.Refundable"
            );

            if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
              refund.push("Refundable");
            } else {
              refund.push("Non Refundable");
            }
          });
        });

        for (let i = 0; i < perperson.length; i++) {
          let nume1 = perperson[i];
          // console.log(nume1, "llldlldldldldld")
          this.find1.push(
            (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[0]
          );
          this.find2.push(
            (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[1]
          );
        }

        for (let i = 0; i < this.multicityData.length; i++) {
          this.multicityData[i].selectId = offerId[i];
          this.multicityData[i].currency = currency[i];
          this.multicityData[i].pointofSale = this.pointofSale;
          this.multicityData[i].brantFare = brantFare[i];
          this.multicityData[i].brantName = brantName[i];
          this.multicityData[i].BrandId = BrandId[i];
          this.multicityData[i].barantFare = false;
          this.multicityData[i].brantvalue = brantvalue[i];
          this.multicityData[i].triptype = "MultiCity";
          this.multicityData[i].hitId = `${currency[i]}-${this.uniqueId}`;
          this.multicityData[i].Baseprice = Baseprice[i];
          this.multicityData[i].TotalPrice = TotalPrice[i];
          this.multicityData[i].pricedetails = this.combinedPricedetail[i];
          this.multicityData[i].BaggageData1 = BaggageData1[i];
          this.multicityData[i].BaggageData2 = BaggageData2[i];
          this.multicityData[i].Taxprice = Tax[i];
          this.multicityData[i].perperson = perperson[i];
          this.multicityData[i].Flight2 = this.Flight2[i];
          this.multicityData[i].find1 = this.find1[i];
          this.multicityData[i].find2 = this.find2[i];
          this.multicityData[i].Flightdepature = false;
          this.multicityData[i].hoveredName1 = false;
          this.multicityData[i].ShoppingResponseId = shoppingId;
          this.multicityData[i].Adult = this.adultValue;
          this.multicityData[i].Child = this.childValue;
          this.multicityData[i].Inf = this.infantValue;
          this.multicityData[i].muticityData = this.codeData;
          this.multicityData[i].ownerlogo = ownerlogo[i];
          this.multicityData[i].ownername = ownername[i];
          this.multicityData[i].deeplinkurl = deeplinkurl[i];
          this.multicityData[i].Flight1JourneyTime = source1[i];
          this.multicityData[i].Flight2JourneyTime = source2[i];
          this.multicityData[i].Refund = refund[i];
          this.multicityData[i].cabinClass = this.multiData.class;
          this.multicityData[i].multipleDatas1 = false;
          this.multicityData[i].multipleDatas2 = false;
          this.multicityData[i].radios1 = true;
          this.multicityData[i].radios2 = true;
          this.multicityData[i].radioData1 = false;
          this.multicityData[i].radioData2 = false;
          if (this.Flight3.length > 0) {
            this.multicityData[i].Flight3 = this.Flight3[i];
            this.multicityData[i].Flight3JourneyTime = source3[i];
            this.multicityData[i].multipleDatas3 = false;
            this.multicityData[i].radios3 = true;
            this.multicityData[i].radioData3 = false;
            this.multicityData[i].BaggageData3 = BaggageData3[i];
          }

          if (this.Flight4.length > 0) {
            this.multicityData[i].BaggageData4 = BaggageData4[i];
            this.multicityData[i].Flight4 = this.Flight4[i];
            this.multicityData[i].Flight4JourneyTime = source4[i];
            this.multicityData[i].multipleDatas4 = false;
            this.multicityData[i].radios4 = true;
            this.multicityData[i].radioData3 = false;
          }
          if (this.Flight5.length > 0) {
            this.multicityData[i].Flight5 = this.Flight5[i];
            this.multicityData[i].Flight5JourneyTime = source5[i];
            this.multicityData[i].BaggageData5 = BaggageData5[i];
          }
        }
        this.getmultitime1();

        console.log(
          this.multicityData,
          "multicityDatamulticityDatamulticityData...."
        );

        this.noflightError =
          !this.multicityData || this.multicityData == "" ? true : false;

        this.multishowData = this.multicityData;
        this.multiFilterData = this.multicityData;
        // this.priceRange();
        // this.airlineFilter();
        // this.multicitystopFilter();
        // this.filterOutBound();
        // this.applyFilters();

        // this.multipriceData();
        // this.Shortest();
        // this.Recommend();
        // this.Cheapest();
        // this.bestlayTime();

        this.multicityData = totalPricefilter.sortBy(this.multiFilterData, [
          "TotalAmount",
        ]);

        if (this.multicityData) {
          this.searchresult = true;
          this.searchloader = false;

          document.getElementById("navbar").style.display = "block";
          document.getElementById("food").style.display = "block";
        }
        // console.log(this.multicityData, "this.multicityDatathis.multicityData");
      }
    },

		generateBookingRefId() {
      const randomChars1 = this.getRandomChars(8);
      const randomNum = this.getRandomInt(10, 99);
      const randomChars2 = this.getRandomChars(8);

      this.uniqueId = randomChars1 + randomNum + randomChars2;
      // console.log(
      //   this.uniqueId,
      //   "bookingRefIdbookingRefIdbookingRefId"
      // );
    },
		getRandomChars(length) {
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * this.strResult.length);
        result += this.strResult[randomIndex];
      }
      // console.log(result, "result");
      return result;
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
		async airlineLogo_func() {
      let airlogoUrl = "";
      let airlogoType = "";

      airlogoUrl = this.airLogoApi.url;
      airlogoType = this.airLogoApi.type;

      await axios.get(airlogoUrl,{
          headers: {
            "Content-Type": airlogoType,
          },
        })
        .then((response) => {
          if (response.data) {
            this.airline_logoData = response.data;
            // console.log(this.airline_logoData, "ressloglooglooglooglool")
          }
        })
        .catch((error) => {
          console.log(error, "ressloerrrororooool");
        });
    },
		getPriceDetails(e, type, quantityKey) {
      // console.log(e, "univer");
      if (e) {
        let basePrice =
          e.FareDetail[e.FareDetail.length - 1].Price.BaseAmount
            .BookingCurrencyPrice;
        let taxPrice =
          e.FareDetail[e.FareDetail.length - 1].Price.TaxAmount
            .BookingCurrencyPrice;
        let count = e.PassengerQuantity;

        return {
          baseprice: parseFloat(basePrice).toFixed(2),
          taxprice: parseFloat(taxPrice).toFixed(2),
          [quantityKey]: count,
          pax_type: type,
        };
      }
    },

    handleFilteredData(result){
      console.log(result,'handleFisdslteredDatawValnewVal');

      if ( result && result.length > 0) {
				if (result[0].triptype == 'RoundTrip') {
					this.rountresult = result;
					console.log(result, "vvvvvresult....1");
				} else if (this.onewayresulted.length > 0) {
					this.onewayresulted = result;
					console.log(result, "vvvvvresult....2");
				} else if (this.multicityData.length > 0) {
					this.multicityData = result;
					console.log(result, "vvvvvresult....3");
				}
      }
    },

    gettimedep() {
      let layoverTime = [];
      for (let i = 0; i < this.rountresult.length; i++) {
        let layoverTime1 = [];
        for (let j = 0; j < this.rountresult[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.rountresult[i].Depature.length;

          const time1 = new Date(
            `${this.rountresult[i].Depature[currentIndex].Arrival.Date}T${this.rountresult[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.rountresult[i].Depature[nextIndex].Departure.Date}T${this.rountresult[i].Depature[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layoverTime1.push(
            `${diffInHours > 0 ? diffInHours + "h " : ""}${
              remainingMinutes > 0 ? remainingMinutes + "m" : ""
            }`
          );
        }
        layoverTime.push(layoverTime1);
        // console.log(layoverTime,'layoverTimelayoverTimee')
      }

      // console.log(layoverTime, "ppppppppp");
      this.rountresult.forEach((a, i) => {
        layoverTime.forEach((c, j) => {
          if (i == j) {
            a.layoverTimes1 = c;
          }
        });
      });
    },

    gettimeret() {
      let layoverTime = [];
      for (let i = 0; i < this.rountresult.length; i++) {
        let layoverTime1 = [];
        for (let j = 0; j < this.rountresult[i].Return.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.rountresult[i].Return.length;

          const time1 = new Date(
            `${this.rountresult[i].Return[currentIndex].Arrival.Date}T${this.rountresult[i].Return[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.rountresult[i].Return[nextIndex].Departure.Date}T${this.rountresult[i].Return[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layoverTime1.push(
            `${diffInHours > 0 ? diffInHours + "h " : ""}${
              remainingMinutes > 0 ? remainingMinutes + "m" : ""
            }`
          );
        }
        layoverTime.push(layoverTime1);
      }

      // console.log(layoverTime, "ppppppppp");
      this.rountresult.forEach((a, i) => {
        layoverTime.forEach((c, j) => {
          if (i == j) {
            a.layoverTimes2 = c;
          }
        });
      });
    },

    gettimedep1() {
      let layoverTime = [];
      for (let i = 0; i < this.onewayresulted.length; i++) {
        let layoverTime1 = [];
        for (let j = 0; j < this.onewayresulted[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.onewayresulted[i].Depature.length;

          const time1 = new Date(
            `${this.onewayresulted[i].Depature[currentIndex].Arrival.Date}T${this.onewayresulted[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.onewayresulted[i].Depature[nextIndex].Departure.Date}T${this.onewayresulted[i].Depature[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layoverTime1.push(
            `${diffInHours > 0 ? diffInHours + "h " : ""}${
              remainingMinutes > 0 ? remainingMinutes + "m" : ""
            }`
          );
        }
        layoverTime.push(layoverTime1);
      }

      // console.log(layoverTime, "ppppppppp");
      this.onewayresulted.forEach((a, i) => {
        layoverTime.forEach((c, j) => {
          if (i == j) {
            a.layoverTimes = c;
          }
        });
      });
    },

    getmultitime1() {
      let layoverTime1 = [];
      let layoverTime2 = [];
      let layoverTime3 = [];
      let layoverTime4 = [];

      for (let i = 0; i < this.multicityData.length; i++) {
        let layover1 = [];
        for (let j = 0; j < this.multicityData[i].Flight1.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.multicityData[i].Flight1.length;

          const time1 = new Date(
            `${this.multicityData[i].Flight1[currentIndex].Arrival.Date}T${this.multicityData[i].Flight1[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.multicityData[i].Flight1[nextIndex].Departure.Date}T${this.multicityData[i].Flight1[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layover1.push(
            `${diffInHours > 0 ? diffInHours + "h " : ""}${
              remainingMinutes > 0 ? remainingMinutes + "m" : ""
            }`
          );
        }
        layoverTime1.push(layover1);

        this.multicityData.forEach((a, i) => {
          layoverTime1.forEach((c, j) => {
            if (i == j) {
              a.layoverTimes1 = c;
            }
          });
        });

        if (this.multicityData[i].Flight2) {
          let layover2 = [];
          for (let j = 0; j < this.multicityData[i].Flight2.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight2.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight2[currentIndex].Arrival.Date}T${this.multicityData[i].Flight2[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight2[nextIndex].Departure.Date}T${this.multicityData[i].Flight2[nextIndex].Departure.Time}`
            );

            let diffInMilliseconds = time2 - time1;
            let diffInMinutes = diffInMilliseconds / (1000 * 60);
            let diffInHours = Math.floor(diffInMinutes / 60);
            let remainingMinutes = diffInMinutes % 60;

            layover2.push(
              `${diffInHours > 0 ? diffInHours + "h " : ""}${
                remainingMinutes > 0 ? remainingMinutes + "m" : ""
              }`
            );
          }
          layoverTime2.push(layover2);

          this.multicityData.forEach((a, i) => {
            layoverTime2.forEach((c, j) => {
              if (i == j) {
                a.layoverTimes2 = c;
              }
            });
          });
        }
        if (this.multicityData[i].Flight3) {
          let layover3 = [];
          for (let j = 0; j < this.multicityData[i].Flight3.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight3.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight3[currentIndex].Arrival.Date}T${this.multicityData[i].Flight3[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight3[nextIndex].Departure.Date}T${this.multicityData[i].Flight3[nextIndex].Departure.Time}`
            );

            let diffInMilliseconds = time2 - time1;
            let diffInMinutes = diffInMilliseconds / (1000 * 60);
            let diffInHours = Math.floor(diffInMinutes / 60);
            let remainingMinutes = diffInMinutes % 60;

            layover3.push(
              `${diffInHours > 0 ? diffInHours + "h " : ""}${
                remainingMinutes > 0 ? remainingMinutes + "m" : ""
              }`
            );
          }
          layoverTime3.push(layover3);

          this.multicityData.forEach((a, i) => {
            layoverTime3.forEach((c, j) => {
              if (i == j) {
                a.layoverTimes3 = c;
              }
            });
          });
        }

        if (this.multicityData[i].Flight4) {
          let layover4 = [];
          for (let j = 0; j < this.multicityData[i].Flight4.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight4.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight4[currentIndex].Arrival.Date}T${this.multicityData[i].Flight4[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight4[nextIndex].Departure.Date}T${this.multicityData[i].Flight4[nextIndex].Departure.Time}`
            );

            let diffInMilliseconds = time2 - time1;
            let diffInMinutes = diffInMilliseconds / (1000 * 60);
            let diffInHours = Math.floor(diffInMinutes / 60);
            let remainingMinutes = diffInMinutes % 60;

            layover4.push(
              `${diffInHours > 0 ? diffInHours + "h " : ""}${
                remainingMinutes > 0 ? remainingMinutes + "m" : ""
              }`
            );
          }
          layoverTime4.push(layover4);

          this.multicityData.forEach((a, i) => {
            layoverTime4.forEach((c, j) => {
              if (i == j) {
                a.layoverTimes4 = c;
              }
            });
          });
        }
      }
    },
		

		getConfig() {
      const getConfigData1 = getConfigDataFromLocalStorage();

      if (getConfigData1) {
        this.makeSearchUrl =
          getConfigData1.payload.portal_configuration.API_endpoints.search;
           this.portal_domain = getConfigData1.payload.portal_configuration.portal_url.replace("https://","");
        this.airLogoApi =
          getConfigData1.payload.portal_configuration.content_data.airlines_logo;
          this.countryCurrency = getConfigData1.payload.portal_configuration.currency.display;
        this.preferCurrency =
          getConfigData1.payload.portal_configuration.currency.iso_code;
        this.metaCurrency =
          getConfigData1.payload.portal_configuration.currency.iso_code;
        this.countryCodeData =
          getConfigData1.payload.portal_configuration.country.iso_code;
        this.autocompleteApi =
          getConfigData1.payload.portal_configuration.API_endpoints.autocomplete;
        this.portal_id = getConfigData1.payload.portal_configuration.portal_id;
        console.log(this.countryCodeData, "vvvvvvvvvvvvvvvvvvvvvvv");
        this.airlineApi =
          getConfigData1.payload.portal_configuration.API_endpoints.airline_autocomplete;
        this.airportdataApi =
          getConfigData1.payload.portal_configuration.API_endpoints.airport_data;
        this.pricingApi =
          getConfigData1.payload.portal_configuration.API_endpoints.pricing ||
          getConfigData1.payload.portal_configuration.API_endpoints
            .pricingconfirmation;

        this.pointofSale =
          getConfigData1.payload.portal_configuration.pointofsale;
        this.airlineLogo_func();
      }
    },
	},
  watch:{
    searchloader(newVal){
      if (newVal) {
        document.getElementById("navbar").style.display = "none";
        document.getElementById("food").style.display = "none";
      }else {
        document.getElementById("navbar").style.display = "block";
        document.getElementById("food").style.display = "block";
      }
    }
    // handleFilteredData(result){
    //   console.log(result,'handleFisdslteredDatawValnewVal');
    //   this.itindatas = result;
    // }
  },
	computed: {
    itindatas() {
      if (this.rountresult && this.rountresult.length > 0) {
        console.log(this.rountresult,'vsdfbvfsvsdfsf...1');
        return this.rountresult;
      } else if (this.onewayresulted && this.onewayresulted.length > 0) {
        console.log(this.onewayresulted,'vsdfbvfsvsdfsf...2');
        return this.onewayresulted;
      } else if (this.multicityData && this.multicityData.length > 0) {
        console.log(this.multicityData,'vsdfbvfsvsdfsf...3');
        return this.multicityData;
      }
      return [];
    }
  },
	created() {
    // localStorage.removeItem("rounttrip");
    // localStorage.removeItem("onewaytrip");

    console.log(
      this.oneway.dedate,
      this.oneway.redate,
      this.oneway.travelDate,
      "pppppppppReturn"
    );

    if (this.activetrip == "Roundtrip") {
      // this.isActiveTab = "Roundtrip";
      this.returnForm = true;
      console.log(this.returnForm, "activetripsadasdadada...1");
    } else if (this.activetrip == "Oneway") {
      // this.isActiveTab = "Oneway";
      this.onewayForm = true;
      console.log(this.onewayForm, "activetripsadasdadada...2");
    } else {
      // this.isActiveTab = "Multicity";
      this.multiCityForm = true;
      console.log(this.multiCityForm, "activetripsadasdadada...3");
      // this.airlineLogo_func();

      console.log(this.$route.query, "this.$route.query.depdaste");
      this.roundModify = false;
      this.onewayModify = false;
      this.multiModify = true;
      console.log(this.activetrip, "activetripactivetrip...2");

      localStorage.removeItem("rountData");
      this.multiData = JSON.parse(this.$route.query.muti_city);

      this.classType = this.multiData.class;
      this.aduls = this.multiData.adult;
      this.aduls1 = this.multiData.infant;
      this.aduls2 = this.multiData.child;
      console.log(this.aduls, "adultDatasssssss");

      console.log(this.multiData, "this.showMulti_datauerthis.showMulti_datay");

      // this.multiDataConvert();

      console.log(this.multiData, "this.$route.querythis.$route.query....1");
      if (this.multiData.itinery.length > 2) {
        this.clearall = true;
      }

      if (this.multiData.itinery.length == 4) {
        console.log(
          this.multiData,
          this.multiData.itinery.length,
          "lengthlen.....gthlength"
        );
        this.addmore = false;
      }
    }

    // this.generateBookingRefId();
    this.airlogodata = airLogo;
    this.clicked1 = true;
    this.clicked2 = true;
    // window.addEventListener("resize", this.handleResize);
    // window.addEventListener("resize", this.handleCalenderResize);
  },
	async mounted() {
    // this.getConfig();
    this.activetrip = this.$route.query.tripType;
		console.log(this.activetrip,'activetripactivetrip');

    if (this.activetrip == "RT" || this.activetrip == "OW") {
      this.roundData();
      this.activetrip = this.activetrip == "RT" ? "Roundtrip" : "Oneway";
    } else {
      this.multiDataConvert();
      this.activetrip = "Multicity";
    }
    console.log(this.activetrip, "activetripactivetrip...1");

    if (this.searchloader) {
      document.getElementById("navbar").style.display = "none";
      document.getElementById("food").style.display = "none";
    }else {
      document.getElementById("navbar").style.display = "block";
      document.getElementById("food").style.display = "block";
    }

		this.getConfig();
  },
}
</script>
<template>
    <v-container class="d-flex justify-center px-0">
        <div class="py-5 Error-section w-100">

            <div class="flight-bg">
                <div v-if="dateError">
                    <p class="error-content-1">{{ dateError}}</p>
                </div>
                <div v-else>
                    <p class="error-content-1">{{'No flights offered by airlines included on our website matches you search criteria.' || $t("pageErrorContents.errorText1")}}</p>
                    <p class="error-content-2 m-0">{{'Please check flights from other airports or change your search request.' || $t("pageErrorContents.errorText2")}}
                    </p>
                </div>

                <div class="d-flex justify-center">
                    <v-btn @click="added()" class="book text-capitalize mt-3">{{'Change your search' || $t("pageErrorContents.changeSearchBtn")}}</v-btn>
                </div>
                <!-- <div class="mb-5 d-none">
                    <v-img class="m-auto" :src="portalLogo" width="250px"></v-img>
                </div>
                <div class="row justify-center mb-4 d-none" v-if="!searchData.itinery">
                    <div class="col-3">
                        <div class="re-fon">
                            {{ searchData.from }}
                        </div>
                        <div class="re-fon" v-if="searchData.city == '1'">
                            {{ getdated(searchData.fromDate) }}
                        </div>
                    </div>
                    <div class="col-2 d-flex justify-center align-items-center">
                        <div v-if="searchData.city == '1'">
                            <v-icon size="30">mdi-swap-horizontal</v-icon>
                        </div>
                        <div v-if="searchData.city == '2'">
                            <div class="col-12 d-flex flex-column">
                                <div class="d-flex justify-center">
                                    <v-icon>mdi-arrow-right</v-icon>
                                </div>
                                <div class="re-fon">
                                   {{ getdated(searchData.fromDate) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="re-fon">
                            {{ searchData.to }}
                        </div>
                        <div class="re-fon" v-if="searchData.city == '1'">
                            {{ getdated(searchData.toDate) }}
                        </div>
                    </div>
                </div>
                <div v-if="searchData.itinery" class="mb-3 d-none">
                <div class="row d-flex justify-center mb-2" v-for="(item,index) of searchData.itinery" :key="index">
                    <div class="col-3">
                        <div class="re-fon">
                            {{ item.from }}
                        </div>
                        
                        </div>
                        <div class="col-2 d-flex flex-column">
                            <div class="d-flex justify-center">
                                <v-icon>mdi-arrow-right</v-icon>
                            </div>
                            <div class="re-fon">
                                {{ getdated(item.depdata) }}
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="re-fon">
                                {{ item.to }}
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </v-container>

</template>
<script>

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';
import moment from 'moment';

export default {
    props: {
        dateError: String 
    },
    data() {
        return {
            portalLogo: "",
            searchData:null,
        }
    },
    methods: {
        getdated($event) {
            return moment($event).format(" DD-MMM-YYYY");
        },
        added() {
            // localStorage.setItem("recentPrefill",JSON.stringify(true))

            this.$router.push('/')
            document.getElementById("navbar").style.display = "block";
            document.getElementById("food").style.display = "block";
        },
        getsearchData(){
           let recentData = JSON.parse(localStorage.getItem('recentData'));
           console.log(recentData,'recentDataasdasd')
           if(recentData){
                this.searchData = recentData[0];
           }
        },

        getConfig() {
            // Parse the decrypted data as JSON
            const getConfigData = getConfigDataFromLocalStorage();


            console.log(getConfigData, 'head...1')

            if (getConfigData) {

                this.portalLogo = getConfigData.payload.portal_configuration.logo_path
            }

            this.getsearchData()
        }
    },

    created(){
        this.getConfig();
    }
}



</script>

<style scoped>
.re-fon {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  font-family: "Roboto";
}
.Error-section {
    background-color: #ffffffd9;
    border-radius: 20px;
}

.error-content-1 {
    font-size: 24px;
    font-family: 'Roboto';
    text-align: center;
    padding: 0px 20px;
}

.error-content-2 {
    font-size: 20px;
    font-family: 'Roboto';
    text-align: center;
    padding: 0px 20px;

}
</style>
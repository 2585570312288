<template>
     <!--Holidays call to action start-->
    <section class="holidays-call-to-action" :style="{  backgroundImage: `url(${require('@/assets/img/tour/holidays.jpg')})`,  backgroundColor: '#003580',backgroundSize: 'cover', backgroundPosition: 'center'}">
        <div class="container">
            <h2>Ready for your next holidays?</h2>
            <a href="#" style="text-decoration: none;">Get Started</a>
        </div>
    </section>
    <!--Holidays call to action end-->
</template>

<style scoped>
@import '../../assets/css/style-04.css';
@import '../../assets/css/style-responsive.css';
@import '../../assets/css/bootstrap.min.css';
@import '../../assets/css/custom.css';
@import '../../assets/css/slick/slick.css';
@import '../../assets/css/slick/slick-theme.css';
</style>
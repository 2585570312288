<template>
  <div class="loader-container">
    <img :src="require('@/assets/loader/pageloader.gif')" alt="Loading..." style="max-width: 200px;" />
  </div>
  <!-- <div class="preloader">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="load-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <p v-if="enableText" class="fw-600 mt-2" style="color: #002D5B;">
              Please wait while we {{ confirmData ? 'confirm' : 'retrieve' }} your booking<span ref="waitSpan">.</span>
            </p>

        </div>
    </div>
</div> -->
</template>

<script>
export default {

  props: {
    enableText: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dots: '.',
      confirmData: true,
    };
  },
  mounted() {
    if (this.$route.query.pass_id || this.$route.query.pass_uid) {
      this.confirmData = true
      // console.log(this.$route.query.pass_uid,'vasanth333')
    }
    else {
      this.confirmData = false
      // console.log(this.$route.query.pass_uid,'vasanth444')
    }

    if (this.$refs.waitSpan) {
      this.dotsInterval = setInterval(this.updateDots, 500);
      // this.checkRoute();

    }

  },
  beforeUnmount() {
    clearInterval(this.dotsInterval);
  },


  // watch: {
  //   '$route.path': 'checkRoute'
  // },
  methods: {
    updateDots() {

      if (this.$refs.waitSpan) {

        if (this.dots.length > 3) {
          this.dots = '.';
        } else {
          this.dots += '.';
        }
        this.$refs.waitSpan.innerHTML = this.dots;
      }

    },
  },
  created() {
    console.log(this.enableText, 'enableTextenableTextenableTextllll');
  }
};
</script>

<style scoped>
.loader-container {
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    z-index: 9999;
}

.preloader {
  position: fixed;
  z-index: 999999;
  background-color: #efeeee;
  width: 100%;
  height: 100%;
  text-align: center;
  left: 0;
  right: 0;
}

.preloader .d-table {
  width: 100%;
  height: 100%;
}

.preloader .d-table .d-table-cell {
  vertical-align: middle;
}

.preloader .load-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.preloader .load-spinner div {
  transform-origin: 40px 40px;
  animation: load-spinner 1.2s linear infinite;
}

.preloader .load-spinner div::after {
  content: " ";
  display: block;
  position: absolute;
  top: 5px;
  left: 35px;
  width: 5px;
  height: 20px;
  border-radius: 20%;
  background: #002d5b;
}

.preloader .load-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.preloader .load-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.preloader .load-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.preloader .load-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.preloader .load-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.preloader .load-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.preloader .load-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.preloader .load-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.preloader .load-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.preloader .load-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.preloader .load-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.preloader .load-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes load-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
<template>
  <!--Partner Package start-->
  <section class="partner">
    <div class="container">
      <h2>Partner Airlines</h2>
      <div class="row">
        <div class="col-6 col-sm-4 col-md-4 col-lg-2">
          <img :src="require('@/assets/img/p_01.png')" class="plogo w-100" alt="" />
        </div>
        <div class="col-6 col-sm-4 col-md-4 col-lg-2">
          <img :src="require('@/assets/img/p_02.png')" class="plogo w-100" alt="" />
        </div>
        <div class="col-6 col-sm-4 col-md-4 col-lg-2">
          <img :src="require('@/assets/img/p_03.png')" class="plogo w-100" alt="" />
        </div>
        <div class="col-6 col-sm-4 col-md-4 col-lg-2">
          <img :src="require('@/assets/img/p_04.png')" class="plogo w-100" alt="" style="padding: 0px;"/>
        </div>
        <div class="col-6 col-sm-4 col-md-4 col-lg-2" style="padding: 0px;">
          <img :src="require('@/assets/img/p_05.png')" class="plogo w-100" style="padding-top: 17px;padding-bottom: 17px;" alt="" />
        </div>
        <div class="col-6 col-sm-4 col-md-4 col-lg-2">
          <img :src="require('@/assets/img/p_06.png')" class="plogo w-100" alt="" />
        </div>
      </div>
    </div>
  </section>
  <!--Partner Package end-->
</template>

<style scoped>
@import "../../assets/css/style-04.css";
@import "../../assets/css/style-responsive.css";
@import "../../assets/css/bootstrap.min.css";
@import "../../assets/css/custom.css";
@import "../../assets/css/slick/slick.css";
@import "../../assets/css/slick/slick-theme.css";
</style>

<template>
  <section
    class="banner-blog overlay d-none"
    :style="{
      backgroundImage: `url(${require('@/assets/img/blog-banner.png')})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    }"
  >
    <div class="container">
      <h1>Contact us</h1>

      <h6>Get in touch with us by filling out our contact form.</h6>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active">
            <a href="index.html" style="text-decoration: none"
              ><i class="fa fa-home"></i> Home</a
            >
          </li>
          <li class="breadcrumb-item">Contact Us</li>
        </ol>
      </nav>
    </div>
  </section>
  <!--Banner end-->
  <!--Content start-->
  <div class="container mar30 d-none">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-8 col-lg-8">
        <div class="contact-form">
          <form>
            <div class="row">
              <div class="col-md-12">
                <h2>Get in touch with us</h2>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Name"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="E-mail"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Mobile number"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Subject"
                    required
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <textarea
                    cols="40"
                    rows="6"
                    class="form-control"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
              </div>
              <div class="col-md-12 text-center">
                <button type="submit" class="btn-block btn btn-dark">
                  Write to us
                </button>
              </div>
              <div class="col-md-12">
                <div class="data-notes">
                  <i class="fa fa-check-circle"></i> Your personal data is safe
                  with us,
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-4 col-lg-4">
        <div class="contact-info">
          <h3><i class="fa fa-phone"></i> Help & support</h3>
          <p>
            We’re here to help with any questions or issues:
            <br />
            <a
              href="mailto:contact@yourdomain.com"
              style="text-decoration: none"
              >support@traveloplex.com</a
            >
          </p>
        </div>

        <div class="contact-info">
          <h3><i class="fa fa-map-marker"></i> Our location</h3>
          <p>
            Shaheen Bagh, Okhla, New Delhi, India.
            <br />
            <a
              target="_blank"
              href="https://g.page/ams-web-design-company-in-delhi?share"
              style="text-decoration: none"
              >Show on map</a
            >
          </p>
        </div>

        <div class="contact-info">
          <h3><i class="fa fa-dollar"></i> Sales department</h3>
          <p>
            We’d love to discuss how we can save you money:
            <br />
            <a href="mailto:sales@yourdomain.com" style="text-decoration: none"
              >sales@yourdomain.com</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
  <div style="background-color:#ffffffd9;">
 



 <div class="pagewrap modify-search">
  <div class="head-wrapper">
      
      <div class="flight-search" style="padding: 1.5rem 0 !important;">
          <div class="container">
              <div class="row">
                  <div class="col-12 col-md-12">
                      <span class="fw-bold theme-text-white">{{ $t("contactContent.heading") }}</span>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="content-section">
  <section class="theme-bg-white pt-5">
    <div class="container">
      <div class="row">
        <!-- <div class="col-12 col-md-5 mb-4 mb-md-0">
                            <div class="d-flex flex-column theme-border-radius theme-bg-white theme-box-shadow">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.5404266061414!2d-0.12403836556756871!3d51.50330055369121!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604b900d26973%3A0x4291f3172409ea92!2slastminute.com%20London%20Eye!5e0!3m2!1sen!2sin!4v1662441698175!5m2!1sen!2sin"
                                    width="1000" height="800" style="border:0;" allowfullscreen="" loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div> -->
        <div class="col-12 col-md-8 align-self-center">
          <!-- <h2 class="display-4 mb-3 fw-bold text-color">Feel Free Contact
                                Us Now
                            </h2>
                            <p class="font-medium theme-text-accent-two mb-0"> Submit your queries here and we will get
                                back to you as soon as possible.</p> -->
          <div class="mt-5">
            <v-form ref="form" v-model="valid1" @submit.prevent="submitform">
              <div class="row">
                <div class="col-md-12">
                  <div class="row" style="margin-top: 50px">
                    <div class="col-md-6">
                      <v-text-field
                        :label="this.$t('contactContent.name')"
                        v-model="contactDetails.name"
                        :rules="name"
                        variant="outlined"
                      ></v-text-field>
                    </div>
                    <div class="col-md-6">
                      <v-select
                        :label="
                          
                          this.$t('contactContent.natureOfEnq.heading')
                        "
                        :rules="Enquiry"
                        v-model="contactDetails.enquiry_type"
                        :items="[
                          'General Enquiry',
                          'Bookings',
                          'Cancellations Groups',
                          ' Packages and HotelsComplaints',
                          'Report Issue',
                        ]"
                        variant="outlined"
                      ></v-select>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 10px">
                    <div class="col-md-6">
                      <v-text-field
                        :label="
                          this.$t('contactContent.phoneNumber')
                        "
                        @keypress="filter(event)"
                        v-model="contactDetails.phone_no"
                        variant="outlined"
                        :rules="phonenumber"
                      ></v-text-field>
                    </div>
                    <div class="col-md-6">
                      <v-text-field
                        :label=" this.$t('contactContent.email')"
                        v-model="contactDetails.email"
                        variant="outlined"
                        :rules="email"
                      ></v-text-field>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 10px">
                    <div class="col-md-12">
                      <v-text-field
                        v-model="contactDetails.booking_reference"
                        :label="
                          this.$t('contactContent.bookingRef')
                        "
                        variant="outlined"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 10px">
                    <div class="col-md-12">
                      <v-textarea
                        :rules="comment"
                        v-model="contactDetails.comments"
                        :label=" this.$t('contactContent.comments')
                        "
                        variant="outlined"
                      ></v-textarea>
                    </div>
                  </div>

                  <div class="row" style="margin-top: 10px">
                    <div class="col-md-6">
                      <div
                        class="d-flex"
                        style="
                          background-color: #213d77;
                          height: 50px;
                          justify-content: space-around;
                        "
                      >
                        <div>
                          <canvas ref="myCanvas"></canvas>
                        </div>
                        <div
                          @click="generate()"
                          style="position: relative; top: 15px"
                        >
                          <v-icon color="white" style="cursor: pointer">
                            mdi-reload
                          </v-icon>
                        </div>
                      </div>

                      <div>
                        <v-text-field
                          :placeholder="
                            this.$t('contactContent.enterCaptacha')
                          "
                          v-model="cacheData"
                          @keyup="keyData(cacheData)"
                          variant="outlined"
                        ></v-text-field>
                      </div>
                    </div>
                    <div
                      class="col-md-6"
                      style="display: flex; justify-content: end"
                    >
                      <v-btn
                        type="submit"
                        :disabled="loading"
                        color="#002d5b"
                        width="300px"
                        height="50px"
                        rounded="3"
                        >{{  $t("contactContent.submit") }}</v-btn
                      >
                    </div>
                  </div>
                </div>

                <!-- <div class="col-md-4 p-3 ">
                    <div v-html="contactContent"></div>
                </div> -->
              </div>
            </v-form>
          </div>
        </div>
        <div class="col-md-4">
          <div v-html="contactContent"></div>
        </div>
        <!-- our team content -->
      </div>
    </div>
    <!-- customer service section -->
    <div class="why-us mt-5 py-5 d-none">
      <div class="container">
        <div class="row">
          <div
            class="col-12 col-md-12 text-center mb-5"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <h4 class="display-4 mb-3 fw-bold text-color">
              We Are Here For You
            </h4>
            <p class="font-medium theme-text-accent-two mb-0 max-1">
              We’d love to hear about it. Take five minutes to fill out our
              project form so that we can get to know you and understand your
              project.
            </p>
          </div>
          <div
            class="col-12 col-lg-12 mb-3 mb-md-0"
            style="display: flex; justify-content: center"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <div
                  class="d-inline-flex mb-3 p-4 rounded-circle theme-bg-white theme-box-shadow"
                >
                  <!-- <img src="assets/images/icons/enquiry-icon.svg" alt="enquiry-icon"
                                                class="img-fluid"> -->
                  <v-img
                    src="https://themeslay.com/preview/items/HTML-Websites/flight-world/assets/images/icons/enquiry-icon.svg"
                    class="img-fluid"
                  />
                </div>
              </div>
              <div class="flex-grow-1 ms-3">
                <h2 class="mb-3 fw-bold fs-6">Write to us</h2>
                <a
                  href="mailto:support@flightworld.com"
                  class="mb-0 max-1 theme-text-accent-one font-medium"
                  >info@traveljunctions.co</a
                >
              </div>
            </div>
          </div>
          <!-- <div class="col-12 col-lg-4 mb-3 mb-md-0" data-aos="fade-down" data-aos-delay="300">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <div
                                            class="d-inline-flex mb-3 p-4 rounded-circle theme-bg-white theme-box-shadow">
                                           <v-img src="https://themeslay.com/preview/items/HTML-Websites/flight-world/assets/images/icons/customer-care.svg"  class="img-fluid"/>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h2 class="mb-3 fw-bold fs-6">Customer Care
                                        </h2>
                                        <span class="mb-0 max-1 theme-text-accent-one font-medium">+91 011 - 1111
                                            1100</span>
                                    </div>
                                </div>
                            </div> -->
          <!-- <div class="col-12 col-lg-4 mb-3 mb-md-0" data-aos="fade-up" data-aos-delay="300">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <div
                                            class="d-inline-flex mb-3 p-4 rounded-circle theme-bg-white theme-box-shadow">
                                           <v-img src="https://themeslay.com/preview/items/HTML-Websites/flight-world/assets/images/icons/office-hours.svg"  class="img-fluid"/>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h2 class="mb-3 fw-bold fs-6">Office Hours
                                        </h2>
                                        <span class="mb-0 max-1 theme-text-accent-one font-medium">7 days a week
                                            <br>
                                            09:00am to 5:00pm</span>
                                    </div>
                                </div>
                            </div> -->
        </div>
      </div>
    </div>
  </section>
</div>
</div>
</div>
</template>

<script>
import axios from "axios";
// import dataloader from "@/components/dataLoder.vue"
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  components: {
    // dataloader
  },
  data() {
    return {
      contactDetails: {
        request_type: "contact_form",
        token: "jdjfdjfkkk",
        name: "",
        enquiry_type: "",
        phone_no: "",
        email: "",
        booking_reference: "",
        comments: "",
      },
      valid1: false,
      cacheData: "",
      randomAlphabets: "",
      contactContent: "",
      getContactUrl: "",
      loading: true,
      aboutType: "",
      loader: false,
      dialog1: false,
      dialog2: false,
      emailPortal: "",
      name: [
        (v) => !!v || "Please enter your  Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],
      Enquiry: [(v) => !!v || "this field is required"],
      phonenumber: [
        (v) => !!v || "Phone Number is required",
        (v) => /^\+?\d{5,15}$/.test(v) || "Invalid Phone Number",
      ],
      email: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address",
      ],
      pnrno: [(v) => !!v || "This field is required"],
      comment: [(v) => !!v || "this field is required"],
      contactApi: "",
    };
  },

  methods: {
    generateRandomAlphabets() {
      const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let result = "";

      for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * alphabets.length);
        result += alphabets[randomIndex];
      }

      this.randomAlphabets = result;
      this.updateCanvas();
    },
    updateCanvas() {
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.font = "23px Arial";
      ctx.fillStyle = "white";
      ctx.fillText(this.randomAlphabets, 10, 40);
    },
    generate() {
      this.generateRandomAlphabets();
    },

    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    submitform() {
      console.log("pp", this.valid1);

      if (this.valid1) {
        this.loader = true;
        console.log(this.contactDetails, "contactDetailscontactDetails");
        axios
          .post(this.contactApi, this.contactDetails, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log(response, "response");
            this.loader = false;
            if (response.data.contact_form_response == "success") {
              this.dialog2 = true;
            }
          })
          .catch((error) => {
            console.log(error);
            setTimeout(() => {
              this.dialog1 = true;
            }, 500);
          });
      }
    },
    getConfig() {
      // let getConfigData = this.$store.state.data

      // let portalLocal = localStorage.getItem("portalData")
      // let getConfigData = JSON.parse(portalLocal)

      const getConfigData = getConfigDataFromLocalStorage();
      if (getConfigData) {
        let locale = this.getLangname;

        this.contactApi =
          getConfigData.payload.portal_configuration.API_endpoints.contactus;
        // console.log(getConfigData, 'head...about..1..')
        // console.log(this.getContactUrl, 'head...about..2..')

        let siteContentApi =
          getConfigData.payload.portal_configuration.site_content_data[locale];
        if (siteContentApi) {
          this.getContactUrl = siteContentApi.contactus.url;
          this.aboutType = siteContentApi.contactus.type;
        }
        this.emailPortal = getConfigData.payload.portal_configuration.email;
        this.getConfigFunc();
      }
    },

    getConfigFunc() {
      axios
        .get(this.getContactUrl, {
          headers: {
            "Content-Type": this.aboutType,
          },
        })
        .then((response) => {
          this.contactContent = response.data;
          console.log(
            this.contactContent,
            "getContactUrlrgetContactUrlrgetContactUrlrab....."
          );
        })

        .catch((error) => {
          console.log(error, "erroroor.....");
        });
    },
    customer() {
      (this.dialog2 = false),
        (this.loader = false),
        setTimeout(() => {
          location.reload();
        }, 100);
    },

    keyData($data) {
      if (this.randomAlphabets == $data.toUpperCase()) {
        this.loading = false;
      } else {
        this.loading = true;
      }
    },
    updateContent() {
            this.getLangname = this.$i18n.locale;
            console.log(this.getLangname,'getcontententUrl...1');
            this.getConfig();
        },
        translateDropdown(){
      this.listItems = [
        {
          text: this.$t("contactContent.natureOfEn.generalEnq"),
          value: "General Enquiry",
        },
        {
          text: this.$t("contactContent.natureOfEn.booking"),
          value: "Bookings",
        },
         {
          text: this.$t("contactContent.natureOfEn.cancelGroups"),
          value: "Cancellations Groups",
        },
         {
          text: this.$t("contactContent.natureOfEn.packagesHotel"),
          value: "Packages and HotelsComplaints",
        },
         {
          text: this.$t("contactContent.natureOfEn.reportIss"),
          value: "Report Issue",
        },
      ];
    },
  },
  watch: {
    "$i18n.locale": {
      handler: function () {
        this.updateContent();
        this.translateDropdown();
        this.getConfig();
				this.getConfigFunc();
      },
      deep: true,
    },
        "contactDetails.booking_reference"(data) {
        this.contactDetails.booking_reference=data.toUpperCase()
      },
   
    
    },
    created() {
      console.log(this.$i18n.locale,'dfasfasfasfafrgdfthtretr')
     this.updateContent();
            this.getConfig();
        this.getConfigFunc();
   
    },
    mounted() {
    this.generateRandomAlphabets();
  },
};
</script>

<style scoped>
@import "../../assets/css/style-04.css";
@import "../../assets/css/style-responsive.css";
@import "../../assets/css/bootstrap.min.css";
@import "../../assets/css/custom.css";
@import "../../assets/css/slick/slick.css";
@import "../../assets/css/slick/slick-theme.css";
</style>

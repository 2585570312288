<template>
  <v-app id="app">
    <v-main v-if="!pageLoader">
      <div
        v-if="geomessage && handleshowmsg && requestdomain"
        class="text-white f-size-14 d-flex justify-content-between align-center px-5 py-1"
        style="background-color: #005672"
      >
        <div>
          <span>{{ msgData }}</span>
          <span
            @click="routerDomain()"
            class="border-bottom border-1 fw-500 ms-1"
            style="cursor: pointer"
          >
            {{ requestdomain }}
          </span>
        </div>
        <div>
          <v-icon
            @click="geomessage = false"
            color="red"
            class="bg-white rounded-circle"
          >
            mdi-close-circle-outline
          </v-icon>
        </div>
      </div>
      <div>
        <div v-if="robotweb">
          <div v-if="whatsappApi">
            <a
              :href="whatsappApi"
              class="whatsapp-float"
              target="_blank"
              rel="noopener noreferrer"
            >
              <v-icon class="whatsappImg" size="40" style="top: 5px"
                >mdi-whatsapp</v-icon
              >
            </a>
          </div>
        </div>
        <div id="navbar" v-if="navbar">
          <HeaderCom @localChanged="updateLocale" />
        </div>

        <router-view></router-view>

        <div id="food" v-if="food">
          <FooterpageCom />
        </div>
      </div>
      <v-dialog v-model="portalError" persistent>
        <div class="d-flex justify-center">
          <v-card class="p-4">
            <h3 class="text-center">Oops!</h3>
            <h5 class="text-center">
              We are experiencing technical issues at the moment.
            </h5>
            <h6 class="text-center">
              Please try refreshing the page or come back later.
            </h6>
            <div class="d-flex justify-space-evenly mt-3">
              <v-btn @click="reloadPage()" color="#162849">Retry</v-btn>
            </div>
          </v-card>
        </div>
      </v-dialog>
    </v-main>
    <div v-if="pageLoader">
      <pageLoader></pageLoader>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import CryptoJS from "crypto-js";
import moment from "moment";
// import pageLoader from "@/components/dataLoder.vue";
import HeaderCom from "./components/headerPage/HeaderCom.vue";
import pageLoader from "@/components/pageLoader.vue";
import FooterpageCom from "./components/FooterPage/FooterpageCom.vue";
import { useHead } from "@vueuse/head";
import {
  setConfigDataToLocalStorage,
  getConfigDataFromLocalStorage,
} from "@/localStorageData/localStorageUtil.js";
// import { updateLocaleMessages } from '@/plugins/i18n.js';

export default {
  name: "App",
  components: {
    HeaderCom,
    FooterpageCom,
    pageLoader,
  },
  data: () => ({
    pageLoader: true,
    portalError: false,
    getConfigResponse: null,

    preloadImages: [],
    navbar: true,
    food: true,
    pageShow: false,
    countryname: "",
    visitcountryname: "",
    configResponse: "",

    i18nlocaleLang: "",
    whatsappApi: "",
    tawktoApi: "",

    portalsiteTitle: "",
    geomessage: false,
    msgData: "",
    requestdomain: "",
    handleshowmsg: false,
    findpage: false,
    userDomain: "",
    envPortalCalled: false,

    countryOptions: [],
    siteContentData: "",
    languageData: "",
    getLanguages: "",
    countryIsocode: "",
    getredirect: null,
    geoCountrydomain: null,
    geohandleRedirect: null,
    robotweb: true,
    metahead:true,

    configReq: {
      service_type: "GetPortalConfigRQ",
      api_ver: "1",
      payload: {
        domain_name: "",
        env: "CERT",
        language: "EN",
        token: "jsdjOJDKJ7675",
      },
    },

    configUser_Id: "",

    getEndpoint: "",
    loaderReq: {
      payload: {
        domain: "https://uatca-v3.traveloplex.com",
        etoken: "",
      },
    },
    redirectReq: {
      payload: {
        requestdomain: "",
        portalname: "",
        portaloriginname: "",
        requesttype: "",
      },
    },
  }),

  methods: {
    reloadPage() {
      setTimeout(() => {
        location.reload();
      }, 300);
      window.location.href = "/";
      this.portalError = false;
    },
    routerDomain() {
      let routeredirectid = null;
      if (this.$route.query && this.$route.query.authreqid) {
        try {
          routeredirectid = this.$route.query.authreqid || null;
        } catch (e) {
          console.log("Invalid route redirectid value:", this.$route.query);
          routeredirectid = null;
        }
      }

      if (!routeredirectid) {
        window.location.href = this.userDomain;
      } else {
        // sessionStorage.setItem("redirectId",routeredirectid)
        window.location.href = `${this.userDomain}`;
      }
    },
    updateLocale(newLocale) {
      // Update the app's locale based on the event received from headerCom1.vue
      this.i18nlocaleLang = newLocale;
      console.log("App locale updated to:", this.i18nlocaleLang);
    },
    async getloaderPortal() {
      let hostname = window.location.hostname;
      if (hostname == "localhost") {
        console.log(hostname, "localhost....");
      } else {
        this.loaderReq.payload.domain = hostname;
        // this.loaderReq.payload.domain = hostname;
      }

      let Key = "8dsxBc9vDl4I9alHbJU7Dg4EBbr7AS0w";
      let domain_name = this.loaderReq.payload.domain;
      let current_date = moment(new Date()).format("YYYYMMDD");
      let fixed_value = 3;

      // console.log(current_date,'dasafsdfdasdasd');

      let compineskey =
        Key + domain_name + current_date + fixed_value.toString();
      const encryptedkey = CryptoJS.MD5(compineskey).toString();

      this.loaderReq.payload.etoken = encryptedkey;

      console.log(this.loaderReq, "loderred...2");

      await axios
        .post("https://otaget.nexus2.wistirna.com", this.loaderReq, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data.response_type === "success") {
            // this.pageLoader = false;
            this.portalError = false;
            this.getEndpoint = response.data.endpoint;
            console.log(this.getEndpoint, "this.getEndpoint");

            if (this.getEndpoint && !this.envPortalCalled) {
              this.envPortal(true);
              this.envPortalCalled = true;
            }
          }
        })
        .catch((error) => {
          console.log("Error fetching application:", error);
          this.pageLoader = true;
          console.log(this.pageLoader, "getedataslodadedr....1");

          setTimeout(() => {
            this.portalError = true;
            if (this.portalError) {
              this.pageLoader = false;
              console.log(this.pageLoader, "getedataslodadedr....2");
            }
          }, 3000);
          // else{
          //   alert("Oops! Site is being updated. Sorry for the inconvenience. We will be right back with exciting new features. Please visit again after some time.");
          //   window.location.href = '/';
          // }
        });
    },

    async envPortal(handledredirect) {
      this.configReq.payload.domain_name = this.loaderReq.payload.domain;

      console.log(this.configReq.payload.domain_name, "hshshshs....");
      console.log(this.configReq, "configReqconfigReqconfigReq");

      axios
        .get(`${this.getEndpoint}getportalconfig`, {
          params: this.configReq,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async (response) => {
          if (response) {
            let configResponse = response.data;
            setConfigDataToLocalStorage(configResponse);

            this.getConfigResponse = getConfigDataFromLocalStorage();

            if (this.getConfigResponse) {
              console.log(response, "configResponseconfigResponse");
              console.log(configResponse, "sdasdconfigRespasdasdonse");

              // this.pageLoader = false;
              console.log(
                this.findpage,
                handledredirect,
                "asdasdsvdfgfghfg...1"
              );

              if (this.findpage && handledredirect) {
                let redirectId = sessionStorage.getItem("redirectId");
                let timeData = sessionStorage.getItem("mySessionKey_timestamp");
                if (!redirectId && !timeData) {
                  this.geolocationDomain();
                } else {
                  this.pageLoader = false;
                }
              } else {
                this.pageLoader = false;
              }
              this.indexHeader();
            }
          }
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
          // alert("Something went difficulties...");
          this.pageLoader = true;
          console.log(this.pageLoader, "getedataslodadedr....4");

          setTimeout(() => {
            this.portalError = true;
            if (this.portalError) {
              this.pageLoader = false;
              console.log(this.pageLoader, "getedataslodadedr....5");
            }
          }, 3000);
        });
    },

    async geolocationDomain() {
      console.log("geolocationDomain");

      const getConfigResponse = getConfigDataFromLocalStorage();
      if (!getConfigResponse) return;

      let georedirectUrl =
        getConfigResponse.payload.portal_configuration.API_endpoints
          .georedirect;
      let portalurlconfig =
        getConfigResponse.payload.portal_configuration.portal_url;
      let portalgroupid =
        getConfigResponse.payload.portal_configuration.portal_group_id;
      let getuseriso =
        getConfigResponse.payload.portal_configuration.country.iso_code || null;
      let getcountryoptions =
        getConfigResponse.payload.portal_configuration.country_options || null;

      let portaldomain = portalurlconfig;
      let portaldomainname = portalurlconfig.replace("https://", "") || null;

      let getredirectid = null;
      let getrouteiso = null;
      let usercountryIso = null;
      let findcountry = null;
      let findportalurl = null;
      let findportalname = null;

      if (
        this.$route.query &&
        this.$route.query.authreq &&
        this.$route.query.authreqid
      ) {
        try {
          getrouteiso = this.$route.query.authreq || null;
          getredirectid = this.$route.query.authreqid || null;

          // Decrypt the country ISO code
          if (getrouteiso) {
            usercountryIso =
              CryptoJS.enc.Base64.parse(getrouteiso).toString(
                CryptoJS.enc.Utf8
              ) || null;
          }

          if (usercountryIso && getcountryoptions) {
            findcountry =
              getcountryoptions.find((v) => v.country_code == usercountryIso) ||
              null;
            findportalurl = findcountry ? findcountry.portal_url : null;
            findportalname = findcountry
              ? findcountry.portal_url.replace("https://", "")
              : null;
          }
        } catch (e) {
          console.log("Invalid redirectid value:", this.$route.query);
          getrouteiso = null;
          getredirectid = null;
        }
      } else if (
        this.$route.query &&
        this.$route.query.authreqid &&
        !this.$route.query.authreq
      ) {
        try {
          getredirectid = this.$route.query.authreqid || null;
        } catch (e) {
          console.log("Invalid redirectid value:", this.$route.query);
          getredirectid = null;
        }
      } else {
        getredirectid = null;
        getrouteiso = null;
      }

      let getredirecttype = null;
      if (this.$route.query && this.$route.query.redirect) {
        try {
          getredirecttype = JSON.parse(this.$route.query.redirect);
        } catch (e) {
          console.log(
            "Invalid redirect type value:",
            this.$route.query.redirect
          );
          getredirecttype = null;
        }
      }

      let localorigin = window.location.origin || null;
      if (localorigin && localorigin.includes("localhost")) {
        this.redirectReq.payload.ip = "";
      }

      this.redirectReq.payload.requestdomain =
        findportalurl && getredirectid ? findportalurl : portaldomain;
      this.redirectReq.payload.portalname =
        findportalname && getredirectid ? findportalname : portaldomainname;
      this.redirectReq.payload.portaloriginname = portalgroupid;
      this.redirectReq.payload.requesttype = getredirecttype
        ? "manual"
        : "auto";
      if (getredirectid) this.redirectReq.payload.redirectid = getredirectid;

      if (georedirectUrl) {
        axios
          .post(georedirectUrl, this.redirectReq, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            let getresponse = response.data;
            console.log(getresponse, "getresponse");
            let geohandleredirect = getresponse.redirect || null;
            let georedirectdomain = getresponse.redirect_domain || null;
            let georedirectId = getresponse.redirectid || null;
            this.handleshowmsg = getresponse.show_msg || null;

            if (
              geohandleredirect &&
              georedirectdomain &&
              georedirectId &&
              getuseriso
            ) {
              let encryptiso = CryptoJS.enc.Base64.stringify(
                CryptoJS.enc.Utf8.parse(getuseriso)
              );
              if (encryptiso) {
                // localStorage.setItem("lastVisitTime", new Date().toISOString()); // Store visit time before redirection
                sessionStorage.setItem("redirectId", georedirectId);
                sessionStorage.setItem("hasRedirected", "true");
                this.handleSessionTimeout();
                window.location.href = `${georedirectdomain}?authreq=${encryptiso}&authreqid=${georedirectId}`;
              }
            } else if (
              !geohandleredirect &&
              this.handleshowmsg &&
              getrouteiso &&
              getredirectid
            ) {
              this.msgData = getresponse.message.text;
              this.geomessage = getresponse.message.text;
              this.userDomain = getresponse.message.url;
              const getuserdomain = this.userDomain.replace("https://", "");
              const trimedDomain =
                getuserdomain.charAt(0).toUpperCase() + getuserdomain.slice(1);
              this.requestdomain = trimedDomain;
              console.log(this.requestdomain, "requestdomain");
              this.pageLoader = false;
            } else {
              this.pageLoader = false;
              // localStorage.removeItem("lastVisitTime", new Date().toISOString()); // Store visit time if no redirection
              this.handleSessionTimeout();
            }
          })
          .catch((error) => {
            this.findpage = false;
            this.pageLoader = false;
            this.envPortal(null);
            console.log(error, "Error during redirection");
          });
      } else {
        this.pageLoader = false;
        this.envPortal(null);
      }
    },
    handleSessionTimeout() {
      const keyName = "mySessionKey"; // Your session key
      const storedTimestamp = sessionStorage.getItem(`${keyName}_timestamp`);
      const currentDomain = window.location.hostname;
      const storedDomain = sessionStorage.getItem("storedDomain");

      if (storedTimestamp) {
        const formattedTime = new Date(
          parseInt(storedTimestamp)
        ).toLocaleString();
        console.log("Stored Time:", formattedTime);
      }

      // If domain changed, reset session data
      if (storedDomain !== currentDomain) {
        sessionStorage.removeItem(`${keyName}_timestamp`);
        sessionStorage.setItem("storedDomain", currentDomain);
        console.log("Domain changed. Resetting session.");
      }

      // Set timestamp if not already stored
      if (
        !storedTimestamp &&
        sessionStorage.getItem("hasRedirected") === "true"
      ) {
        sessionStorage.setItem(`${keyName}_timestamp`, Date.now().toString());
        console.log("Session timestamp set.");
      }
      // if (storedTimestamp) {
      //   const date = new Date(parseInt(storedTimestamp));
      //   const formattedTime = new Intl.DateTimeFormat("en-US", {
      //     year: "numeric",
      //     month: "2-digit",
      //     day: "2-digit",
      //     hour: "2-digit",
      //     minute: "2-digit",
      //     second: "2-digit",
      //     hour12: true,
      //   }).format(date);

      //   console.log("Stored Time:", formattedTime);
      // }

      // Check and clear session after 1 hour
      setInterval(() => {
        const savedTime = sessionStorage.getItem(`${keyName}_timestamp`);
        if (savedTime && Date.now() - parseInt(savedTime) > 3600000) {
          sessionStorage.removeItem(`${keyName}_timestamp`);
          sessionStorage.removeItem("redirectId");
          sessionStorage.removeItem("hasRedirected");
          console.log("Session expired. Clearing session data.");
        }
      }, 10000);
    },

    setLanguage() {
      let getLang = this.getLanguages[0].code;
      let savedLanguage = `${this.countryIsocode}-${getLang}`;
      localStorage.setItem("setlang", savedLanguage);
      // let setlocalLang = localStorage.setItem("setlang", savedLanguage);
      // if(setlocalLang){
      //   this.localeLanguage();
      // }
    },

    localeLanguage() {
      const storedLocale = localStorage.getItem("defaultlang");
      const storedSetLang = localStorage.getItem("setlang");

      let getlocalIso = "";
      let getlocalLang = "";
      let getsetIso = "";
      let getsetlang = "";
      // let savedLanguage = "";

      if (storedLocale) {
        getlocalIso = storedLocale.split("-")[0];
        getlocalLang = storedLocale.split("-")[1];
      }
      if (storedSetLang) {
        getsetIso = storedSetLang.split("-")[0];
        getsetlang = storedSetLang.split("-")[1];
      }

      if (storedSetLang && storedLocale) {
        console.log("Check...1");
        if (storedLocale == storedSetLang) {
          console.log("Check...2");

          return storedSetLang;
          // this.i18n.locale = storedSetLang;
        } else if (storedLocale !== storedSetLang) {
          console.log(
            getlocalIso,
            getsetIso,
            "Check...3",
            getlocalLang,
            getsetlang
          );
          if (
            (getlocalIso !== getsetIso && getlocalLang !== getsetlang) ||
            (getlocalIso !== getsetIso && getlocalLang == getsetlang)
          ) {
            localStorage.removeItem("defaultlang");
            console.log("Check...4");
            return storedSetLang;
            // this.i18n.locale = storedSetLang;
          } else if (getlocalIso == getsetIso && getlocalLang !== getsetlang) {
            console.log(storedLocale, "Check...5");

            return storedLocale;
            // this.i18n.locale = storedLocale;
          }
        }
      } else if (storedSetLang) {
        console.log("Check...6");

        return storedSetLang;
        // this.i18n.locale = storedSetLang;
      } else if (storedLocale) {
        console.log("Check...7");

        return storedLocale;
        // this.i18n.locale = storedLocale;
      } else {
        console.log("Check...8");

        return "CA-en";
        // return `${this.countryIsocode}-${this.getLanguages[0].code}`;

        // this.i18n.locale = `${this.countryIsocode}-${this.getLanguages[0].code}`;
      }
    },

    getlangaugeUrl() {
      let langData = this.languageData;
      // let getlocale = this.localeLanguage();
      // this.$i18n.locale = this.localeLanguage();
      var localLang = this.localeLanguage(); //CA.es

      console.log(localLang, "getegeteegtegete...1");

      // this.$i18n.locale = localLang;
      // console.log(this.$i18n.locale, "getegeteegtegete...2");
      let langdataUrl = "";
      // console.log(localLang,'localLanglocalLang')

      Object.keys(langData).forEach((v) => {
        console.log(
          v,
          this.countryIsocode,
          "vcountryIsocodevcountryIsocode...3"
        );
        if (v == this.countryIsocode) {
          const lang = langData[v];

          Object.keys(lang).forEach((local) => {
            console.log(local, "dasasasasdas");
            if (local == localLang) {
              langdataUrl = lang[local];
              console.log(local, localLang, langdataUrl, "dasasasasdas...2");

              axios
                .get(langdataUrl, {
                  headers: {
                    "Content-Type": "text/html; charset=utf-8",
                  },
                })
                .then((response) => {
                  if (response) {
                    console.log(response, "getegeteegtegete...3");
                    // this.dataloader = false;
                    const cleanedData = this.parseLanguageData(response.data);
                    this.setLanguageMessages(localLang, cleanedData);
                  }
                })
                .catch((error) => {
                  console.log(error, "errorrLangaugeAPI.....");
                  // if(error){
                  //   setInterval(() => {
                  //     this.dataloader = false;
                  //   }, 3000);
                  // }

                  this.$i18n
                    ? this.$i18n.locale
                      ? (this.$i18n.locale = "CA-en")
                      : null
                    : null;
                });
            }
          });
        }
      });
    },

    parseLanguageData(data) {
      try {
        const cleanedData = data
          .replace(/export\s+default\s+/, "")
          .replace(/;\s*$/, ""); // Remove trailing semicolon if present
        return eval(`(${cleanedData})`);
      } catch (error) {
        console.error("Error processing language content:", error);
        return {};
      }
    },

    setLanguageMessages(locale, messages) {
      this.$i18n.setLocaleMessage(locale, messages);
      this.$i18n.locale = locale;

      console.log(this.$i18n.locale, "setLanguageMessagessetLanguageMessages");
    },

    indexHeader() {
      const getConfigData = getConfigDataFromLocalStorage();

      if (getConfigData) {
        let portalTitle = getConfigData.payload.portal_configuration.site_title;
        let portalFavicon = getConfigData.payload.portal_configuration.favicon;
        document.getElementById("siteTitle").innerText = portalTitle;
        document.getElementById("faviconPortal").href = portalFavicon;

        this.portalsiteTitle =
          getConfigData.payload.portal_configuration.site_title;
        this.countryname =
          getConfigData.payload.portal_configuration.country.name;
        this.countryIsocode =
          getConfigData.payload.portal_configuration.country.iso_code;
        this.countryOptions =
          getConfigData.payload.portal_configuration.country_options;
        // Extract all image URLs for preloading
        // const getpreimg = this.countryOptions.map((country) => country.flag_logo);

        // Preload all images
        // await this.preloadedimg(getpreimg);

        this.siteContentData =
          getConfigData.payload.portal_configuration.site_content_data;
        this.getLanguages =
          getConfigData.payload.portal_configuration.languages;
        this.languageData =
          getConfigData.payload.portal_configuration.language_data;

        let portal_url = getConfigData.payload.portal_configuration.portal_url;
        this.metaDate =
          getConfigData.payload.portal_configuration?.seo_data?.seo_data;
        // console.log(
        //   this.metaDate,
        //   getConfigData.payload.portal_configuration.seo_data.seo_data,
        //   "this.metaDate"
        // );
        if (this.metaDate) {
          this.metaDate.forEach((c) => {
            console.log(c, "000");

            if (c.name === "title" || c.name === "sitemap") {
              delete c.name;
              delete c.content;
            } else if (
              typeof c.name === "string" &&
              c.name.includes("robots")
            ) {
              c.name = `${portal_url}/robots.txt`;
            }

            if (typeof c.name === "string") {
              c.name = c.name.replace("meta_", "");
            }

            delete c.label;
            delete c.type;
          });
          console.log(this.metaDate, "this.metaDate1");
        }
        if (this.metahead) {
          useHead({
            meta: this.metaDate,
          });
          console.log(this.metaDate, "this.metaDate2");
        }
        if (this.robotweb) {
          let enabledPlugins = "";
          enabledPlugins =
            getConfigData.payload.portal_configuration.menus.enabled.plugins;

          if (enabledPlugins.includes("whatsapp")) {
            this.whatsappApi =
              getConfigData.payload.portal_configuration.API_endpoints.whatsapp;
          } else {
            this.whatsappApi = "";
          }
          if (enabledPlugins.includes("tawkto")) {
            this.tawktoApi =
              getConfigData.payload.portal_configuration.API_endpoints.tawkto;
          } else {
            this.tawktoApi = "";
          }

          console.log(
            this.languageData,
            "siteContentDatasiteContentDatasiteContentData"
          );

          document.getElementById("siteTitle").innerText = portalTitle;
          document.getElementById("faviconPortal").href = portalFavicon;

          // TawkTo code
          const s1 = document.createElement("script");
          const s0 = document.getElementsByTagName("script")[0];
          s1.async = true;
          s1.src = this.tawktoApi;
          s1.charset = "UTF-8";
          s1.setAttribute("crossorigin", "*");
          s0.parentNode.insertBefore(s1, s0);
        }
        if (this.countryIsocode && this.countryOptions) {
          //  this.geolocationRedirection();
        }
        if (this.languageData) {
          this.getlangaugeUrl();
        } else {
          this.$i18n.locale = "CA-en";
        }

        if (getConfigData) {
          this.pageShow = true;
        }

        if (this.getLanguages && this.countryIsocode) {
          this.setLanguage();
          console.log("langsetting...1");
          // this.$i18n.locale = `${this.countryIsocode}-${this.getLanguages[0].code}`
          // console.log(this.$i18n.locale, "langresponseresponse...1");
        } else {
          this.$i18n.locale = "CA-en";
          console.log("langsetting...2");
          // this.$i18n.locale = `${this.countryIsocode}-${this.getLanguages[0].code}`
        }
      }
    },
  },
  watch: {
    i18nlocaleLang: {
      immediate: true,
      handler: function (newValue) {
        // this.localeLanguage();
        console.log(newValue, "sdasdasdasnewValue");
        // this.dataloader = true;
        console.log(this.dataloader, "getedataslodadedr....9");
        this.getlangaugeUrl();
        console.log(this.$i18n.locale, "getegeteegtegete....5");
      },
      // deep: true,
    },

    "$route.path": {
      immediate: true,
      handler(newValue) {
        this.getloaderPortal();
        this.geomessage = newValue === "/" ? true : false;
        this.findpage = newValue === "/" ? true : false;
        console.log(this.findpage, "asdasdsvdfgfghfg...2");

        if (["/loader", "/robots.txt", "/sitemap.xml"].includes(newValue)) {
          this.metahead = true;
          this.navbar = false;
          this.food = false;
          this.robotweb = false;
          document
            .querySelectorAll('style[type="text/css"]')
            .forEach((el) => el.remove());
        } else {
          this.navbar = true;
          this.food = true;
        }
        // Page loader
        if (!this.findpage && newValue) {
          this.$router.beforeEach((to, from, next) => {
            this.pageLoader = true;
            next();
          });

          this.$router.afterEach(() => {
            setTimeout(() => {
              this.pageLoader = false;
            }, 4000);
          });
        }
        // Page loader End
      },
    },
  },
  mounted() {
    // console.log("portalEncryteDecryptddata...6");
    this.getloaderPortal();
    if (sessionStorage.getItem("hasRedirected") === "true") {
      this.handleSessionTimeout();
    }
  },
};
</script>

<style>
/* .v-app{
  color: #5f6367;
} */
#navbar {
  display: block;
}
#app {
  font-family: "roboto", sans-serif;
  font-size: 14px;
  color: #5f6367 !important;
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  -moz-scroll-behavior: smooth;
  -o-scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  line-height: 1.2;
  color: #343a40 !important;
}

a {
  color: #003580;
}

a:hover {
  color: #013e94;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: #343a40;
}

hr {
  border-top: 1px solid #e5e5e5;
}

img {
  width: 100%;
}

.whatsapp-float {
  position: fixed;
  width: 55px;
  height: 52px;
  bottom: 115px;
  right: 30px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
  box-shadow: 1px 1px 2px #888;
}
</style>

<template>
    <SearchForm/>
    <TopdestinationCom/>
    <TraveldealsCom/>
    <FlightdealsCom/>
    <RecommendationCom/>
    <AmazingdealsCom/>
    <TourpackagesCom/>
    <PackageofferCom/>
    <SubscriberstartCom/>
</template>
  
<script>
import SearchForm from './SearchForm.vue';
import TopdestinationCom from './TopdestinationCom.vue';
import TraveldealsCom from './TraveldealsCom.vue';
import FlightdealsCom from './FlightdealsCom.vue';
import RecommendationCom from './RecommendationCom.vue';
import AmazingdealsCom from './AmazingdealsCom.vue';
import TourpackagesCom from './TourpackagesCom.vue';
import PackageofferCom from './PackageofferCom.vue';
import SubscriberstartCom from './SubscriberstartCom.vue';

export default {
  name: 'ParentHome',
  components:{
    SearchForm,
    TopdestinationCom,
    TraveldealsCom,
    FlightdealsCom,
    RecommendationCom,
    AmazingdealsCom,
    TourpackagesCom,
    PackageofferCom,
    SubscriberstartCom
  },
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @import '../../assets/css/style-04.css';
  @import '../../assets/css/style-responsive.css';
  @import '../../assets/css/bootstrap.min.css';
</style>
  
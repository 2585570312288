<template>
  <!--Banner start-->
  <div class="banner-bg" v-if="handleform">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-12 col-md-12 col-lg-10">
          <div class="search-bg">
            <!--Tab Nav start-->
            <div class="tab-nav">
              <div class="list-group mtab" id="myList" role="tablist">
                <span class="list-group-item list-group-item-action" :class="{ active: activeOption == 'flight' }"
                  @click="toggleOption('flight')" data-toggle="list" data-target="#flights" role="tab"><i
                    class="fa fa-plane"></i> {{ $t("homePageContent.label.flight") }}</span>
                <!-- <span class="list-group-item list-group-item-action" :class="{ active: activeOption == 'recent' }"
                  @click="toggleOption('recent')" v-if="this.RecentSearchData.length > 0" data-toggle="list" 
                  data-target="#recent" role="tab"><i class="fa-solid fa-clock-rotate-left"></i>
                  {{ $t("homePageContent.label.recent") }}</span> -->
              </div>
            </div>
            <!--Tab Nav end-->

            <!--Tab Content start-->
            <div class="tab-content" v-if="!travelHistory">
              <!--Flight-->

              <div class="tab-pane active" id="flights" role="tabpanel">
                <!--Tab Nav start-->

                <div class="tab-bg">
                  <!--Tab Nav start-->
                  <div class="list-group stab" id="myList2" role="tablist">
                    <div class="travelClass">
                      <a class="list-group-item list-group-item-action" :class="{ active: activetrip == 'Oneway' }"
                        @click="activeType('Oneway')" data-toggle="list" data-target="#oneway" role="tab">{{
                          $t('formsContents.options.oneWay') }}</a>
                      <a class="list-group-item list-group-item-action" :class="{ active: activetrip == 'Roundtrip' }"
                        @click="activeType('Roundtrip')" data-toggle="list" data-target="#roundtrip" role="tab">{{
                          $t('formsContents.options.return') }}
                      </a>
                      <a class="list-group-item list-group-item-action" :class="{ active: activetrip == 'Multicity' }"
                        @click="activeType('Multicity')" data-toggle="list" data-target="#multicity" role="tab">{{
                          $t('formsContents.options.multiCity') }}
                      </a>
                    </div>
                    <div class="searchClass">
                      <a class="recentSearch" :class="{ active: activeOption == 'recent' }"
                        @click="toggleOption('recent')" v-if="RecentSearchData.length > 0" data-toggle="list"
                        data-target="#recent" role="tab" style="text-decoration: underline;">
                        <i class="fa-solid fa-clock-rotate-left"></i>
                        {{ $t("homePageContent.label.recentSearches") }}
                      </a>
                    </div>
                  </div>
                  <!--Tab Nav end-->

                  <div class="tab-content">
                    <!--Round Trip-->

                    <div class="tab-pane" :class="{ active: activetrip == 'Roundtrip' || 'Oneway' }"
                      v-if="roundTrip || Oneway">
                      <form>
                        <div class="row">
                          <div class="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                            <div class="input">
                              <span>{{
                                $t("homePageContent.contentSection.from")
                              }}</span>
                              <v-text-field :placeholder="departureLabel()" v-model="oneway.from" variant="outlined"
                                @input="typeCity(oneway.from, 1)" class="inter-input" @click="handlefrom()"
                                v-bind="!fromloader ? { clearable: true } : {}">
                                <template v-slot:append-inner v-if="fromloader">
                                  <v-fade-transition leave-absolute>
                                    <v-progress-circular color="#002d5b" size="18" indeterminate></v-progress-circular>
                                  </v-fade-transition>
                                </template>
                              </v-text-field>
                              <!-- <input type="text" placeholder="City or Airport" required> -->
                              <img :src="require('@/assets/img/fu.png')" alt="" />
                            </div>
                            <div v-if="typedrop" v-click-outside="clickoutDropdown">
                              <div v-if="this.city.length > 0 ? true : false" class="autocompete-menual-dropdown">
                                <div v-for="(data, index) in city" :key="index">
                                  <div @click="datachange(data)" :style="index == city.length - 1
                                    ? ''
                                    : 'border-bottom:1px solid lightgray'
                                    " class="d-flex p-2 va-back">
                                    <div style="display: flex; align-items: center">
                                      <v-icon color="gray" size="20">
                                        {{
                                          data.location === "airport"
                                            ? "mdi-airplane"
                                            : "mdi-radiobox-marked"
                                        }}
                                      </v-icon>
                                    </div>

                                    <div class="m-1 va-content">
                                      {{ data.iata }}, {{ data.city }},{{
                                        data.name
                                      }}
                                    </div>
                                  </div>

                                  <div v-if="data.group_airports.length > 0" class="ms-4">
                                    <div v-for="group in data.group_airports" :key="group" class="va-back px-1">
                                      <div v-if="
                                        data.group_airport_city != group.iata
                                      ">
                                        <div class="d-flex va-back" @click="datachange(group)">
                                          <div style="
                                              display: flex;
                                              align-items: center;
                                            ">
                                            <v-icon color="gray" size="15">mdi-airplane</v-icon>
                                          </div>
                                          <div class="m-1 va-content">
                                            {{ group.iata }},
                                            {{ group.city }},{{ group.name }}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="formres && resultFrom.length > 0" v-click-outside="onClickOutside">
                              <div class="recent_style">
                                <v-card style="
                                    max-height: 300px;
                                    border-radius: 10px;
                                    box-shadow: none;
                                  ">
                                  <h6 style="
                                      text-align: left;
                                      margin: 12px;
                                      font-weight: 600;
                                      font-size: 14px;
                                    ">
                                    {{
                                      $t("homePageContent.label.recentSearches")
                                    }}
                                  </h6>
                                  <div class="resu-form d-flex mt-1" v-for="data in resultFrom" :key="data"
                                    @click="getrecent(data)">
                                    <div class="me-2">
                                      <v-icon size="18">
                                        mdi-clock-outline
                                      </v-icon>
                                    </div>

                                    <div>
                                      <p class="m-0" style="margin-top: 1px !important">
                                        {{ data.from }}
                                      </p>
                                    </div>
                                  </div>
                                </v-card>
                              </div>
                            </div>
                          </div>

                          <div class="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                            <div class="input">
                              <span>{{
                                $t("flightInfoContent.contents.to")
                              }}</span>
                              <v-text-field :placeholder="arrivalLabel()" v-model="oneway.to" variant="outlined"
                                @input="typeCity(oneway.to, 2)" class="inter-input" @click="handleto()"
                                ref="secondAutoComplete" :clearable="!toloader">
                                <template v-slot:append-inner>
                                  <v-fade-transition leave-absolute>
                                    <v-progress-circular v-if="toloader" color="#002d5b" size="18"
                                      indeterminate></v-progress-circular>
                                  </v-fade-transition>
                                </template>
                              </v-text-field>
                              <!-- <input type="text" placeholder="City or Airport" required> -->
                              <img :src="require('@/assets/img/fd.png')" alt="" />
                            </div>
                            <div v-if="typedrop1" v-click-outside="clickoutDropdown">
                              <div v-if="this.city1.length > 0 ? true : false" class="autocompete-menual-dropdown">
                                <div v-for="(data, index) in city1" :key="index">
                                  <div @click="datachangeTo(data)" :style="index == city.length - 1
                                    ? ''
                                    : 'border-bottom:1px solid lightgray'
                                    " class="d-flex p-2 va-back">
                                    <div style="display: flex; align-items: center">
                                      <v-icon color="gray" size="20">
                                        {{
                                          data.location === "airport"
                                            ? "mdi-airplane"
                                            : "mdi-radiobox-marked"
                                        }}
                                      </v-icon>
                                    </div>

                                    <div class="m-1 va-content">
                                      {{ data.iata }}, {{ data.city }},{{
                                        data.name
                                      }}
                                    </div>
                                  </div>

                                  <div v-if="data.group_airports.length > 0" class="ms-4">
                                    <div v-for="group in data.group_airports" :key="group" class="va-back px-1">
                                      <div v-if="
                                        data.group_airport_city != group.iata
                                      ">
                                        <div class="d-flex va-back" @click="datachangeTo(group)">
                                          <div style="
                                              display: flex;
                                              align-items: center;
                                            ">
                                            <v-icon style="transform: rotate(90deg)" color="gray"
                                              size="15">mdi-airplane</v-icon>
                                          </div>

                                          <div class="m-1 va-content">
                                            {{ group.iata }}, {{ group.city }},
                                            {{ group.name }}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="recent_style" v-if="formres1 && resultTo.length > 0"
                              v-click-outside="onClickOutside">
                              <v-card style="
                                  max-height: 300px;
                                  border-radius: 10px;
                                  box-shadow: none;
                                ">
                                <h6 style="
                                    text-align: left;
                                    margin: 12px;
                                    font-weight: 600;
                                    font-size: 14px;
                                  ">
                                  {{
                                    $t("homePageContent.label.recentSearches")
                                  }}
                                </h6>
                                <div class="resu-form d-flex mt-1" v-for="data in resultTo" :key="data"
                                  @click="getrecent1(data)">
                                  <div class="me-2">
                                    <v-icon size="18">
                                      mdi-clock-outline
                                    </v-icon>
                                  </div>

                                  <div>
                                    <p class="m-0" style="margin-top: 1px !important">
                                      {{ data.to }}
                                    </p>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </div>

                          <!-- <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 DatecolumnSizing">
                            <div class="input">
                              <span>{{ $t('formsContents.labels.departures') }}ee</span>
                              <VueDatePicker ref="dpRef1" :multi-calendars="multiCalender"   :year-picker="false" v-model="oneway.dedate" :min-date="new Date()"
                                :format="dateFormat" no-today :six-weeks="sixWeekMode" :placeholder="selectLabel()"
                                :start-date="oneway.dedate" :hide-navigation="['time']" auto-apply
                                :max-date="new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate())"
                                :on-click-outside="ClickOutDepat" @update:modelValue="fromDateChanged"
                                class="datePickInput" :month-change-on-scroll="false" style="--dp-cell-size:28px;">
                                <template #clear-icon="{}">
                                  <v-icon
                                    class="input-slot-image me-2"
                                    @click="clearDate(oneway.dedate, 'dep')"
                                    >mdi-close-circle</v-icon
                                  >
                                </template>
                              </VueDatePicker>
                              <VueDatePicker ref="dpRef1" v-model="oneway.dedate" :min-date="new Date()" auto-apply 
                                  :six-weeks="sixWeekMode" :format="dateFormat"
                                  :hide-navigation="['time']" @focus="(focusEvent3 = true),(focusEvent1 = false),(focusEvent2 = false)"
                                  :placeholder="selectLabel()"  @blur="dateUnfocus" @closed="closeDateMenu" @update:modelValue="fromDateChanged"
                                  class="datePickInput px-0" :on-click-outside="ClickOutDepat" :month-change-on-scroll="false"
                                  style="--dp-cell-size:28px;">
                                </VueDatePicker>

                              <img
                                :src="require('@/assets/img/cl.png')"
                                alt=""
                              />
                            </div>
                          </div>

                          <div
                            class="col-xs-12 col-sm-6 col-md-3 col-lg-3 DatecolumnSizing"
                          >
                            <div class="input">
                              <span>{{
                                $t("formsContents.options.return")
                              }}</span>
                              <VueDatePicker
                                ref="dpRef2"
                                :multi-calendars="multiCalender"
                                v-model="oneway.redate"
                                :min-date="oneway.dedate"
                                :format="dateFormat"
                                no-today
                                :six-weeks="sixWeekMode"
                                :placeholder="selectLabel()"
                                :start-date="oneway.dedate"
                                :hide-navigation="['time']"
                                auto-apply
                                :max-date="
                                  new Date(
                                    new Date().getFullYear() + 1,
                                    new Date().getMonth(),
                                    new Date().getDate()
                                  )
                                "
                                :on-click-outside="ClickOutReturn"
                                @update:modelValue="toDateChanged"
                                :disabled="Oneway"
                                class="datePickInput"
                                style="--dp-cell-size: 28px"
                              >
                                <template #clear-icon="{}">
                                  <v-icon
                                    class="input-slot-image me-2"
                                    @click="clearDate(oneway.redate, 'arr')"
                                    >mdi-close-circle</v-icon
                                  >
                                </template>
                              </VueDatePicker>
                              <img :src="require('@/assets/img/cl.png')" alt="">
                            </div>
                          </div> -->

                          <div class="col-xs-12 col-sm-6 col-md-3 col-lg-4 DatecolumnSizing">
                            <div class="input">
                              <span v-if="activetrip==='Roundtrip'">{{ $t("formsContents.options.depart") }} - {{ $t("formsContents.options.return") }}</span>
                              <span v-else>{{ $t("formsContents.labels.departures") }}</span>
                              <VueDatePicker ref="dpRef1" v-model="oneway.travelDate"
                                :range="activetrip === 'Roundtrip'" :multi-calendars="activetrip === 'Roundtrip'? 3 : 2"         
                                :min-date="new Date()" :format="dateFormat" no-today :six-weeks="sixWeekMode"
                                :placeholder="selectLabel()" :hide-navigation="['time']" auto-apply
                                :max-date="new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate())"
                                :on-click-outside="ClickOutDepat" @update:modelValue="updateDates" class="datePickInput"
                                style="--dp-cell-size:28px;">
                                <template #clear-icon>
                                  <v-icon class="input-slot-image me-2"
                                    @click="clearDateRange">mdi-close-circle</v-icon>
                                </template>
                              </VueDatePicker>

                              <img :src="require('@/assets/img/cl.png')" alt="">
                            </div>
                          </div>
                        </div>
                        <button class="search-btn" @click.prevent="sended()">
                          {{ $t("formsContents.options.search") }}
                        </button>
                      </form>
                    </div>

                    <!--Multi City-->

                    <div class="tab-pane" :class="{ active: activetrip == 'Multicity' }" v-if="multicity">
                      <form>
                        <div class="row">
                          <div class="col-xs-12 col-sm-11 col-md-11 col-lg-11">
                            <div class="row" v-for="(data, index) in multiData.itinery" :key="index">
                              <div class="col-xs-12 col-sm-4 col-md-4 col-lg-12 flightbehavebtn">
                                <div class="input d-flex justify-content-between mb-1">
                                  <span>{{ $t("homePageContent.label.flight") }}
                                    {{ index + 1 }}</span>
                                  <button class="px-1 rounded" @click="removemulti(index)" v-if="canRemove(index)"
                                    style="background-color: #eeeeee">
                                    {{ $t("homePageContent.label.remove") }}
                                  </button>
                                </div>
                              </div>
                              <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <div class="input">
                                  <span>{{
                                    $t("homePageContent.contentSection.from")
                                  }}</span>

                                  <!-- <input type="text" placeholder="City or Airport" required> -->
                                  <v-text-field v-model="data.from" type="text" :placeholder="departureLabel()"
                                    @input="typeMultiCity(data.from, index, 1)" @click="
                                      (multiData.itinery[index].from = ''),
                                      (data.selectedFrom = false)
                                      " class="inter-input" ref="multiFromAutocomplete" variant="outlined"
                                    :clearable="!data.fromloader">
                                    <template v-slot:append-inner>
                                      <v-fade-transition leave-absolute>
                                        <v-progress-circular v-if="data.fromloader" color="#002d5b" size="18"
                                          indeterminate></v-progress-circular>
                                      </v-fade-transition>
                                    </template>
                                  </v-text-field>
                                  <img :src="require('@/assets/img/fu.png')" alt="" />
                                </div>

                                <div v-if="
                                  multiData.itinery[index].autocomfrom
                                    ? true
                                    : false
                                " v-click-outside="clickoutmultiDropdown">
                                  <div class="autocompete-menual-dropdown" v-if="multidrop.length > 0 ? true : false">
                                    <div v-for="(data, index1) in multidrop" :key="index1">
                                      <div @click="datachangeMuti(data, index)" :style="index1 == multidrop1.length - 1
                                        ? ''
                                        : 'border-bottom:1px solid lightgray'
                                        " class="d-flex p-2 va-back">
                                        <div style="
                                            display: flex;
                                            align-items: center;
                                          ">
                                          <v-icon color="gray" size="20">
                                            {{
                                              data.location === "airport"
                                                ? "mdi-airplane"
                                                : "mdi-radiobox-marked"
                                            }}
                                          </v-icon>
                                        </div>

                                        <div class="ms-1 va-content">
                                          {{ data.iata }}, {{ data.city }},{{
                                            data.name
                                          }}
                                        </div>
                                      </div>

                                      <div v-if="data.group_airports.length > 0" class="ms-4">
                                        <div v-for="group in data.group_airports" :key="group">
                                          <div v-if="
                                            data.group_airport_city !=
                                            group.iata
                                          ">
                                            <div class="d-flex va-back px-1" @click="
                                              datachangeMuti(group, index)
                                              ">
                                              <div style="
                                                  display: flex;
                                                  align-items: center;
                                                ">
                                                <v-icon color="gray" size="20">mdi-airplane</v-icon>
                                              </div>

                                              <div class="m-1 va-content">
                                                {{ group.iata }},
                                                {{ group.city }},
                                                {{ group.name }}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <div class="input">
                                  <span>{{
                                    $t("flightInfoContent.contents.to")
                                  }}</span>

                                  <!-- <input type="text" placeholder="City or Airport" required> -->
                                  <v-text-field v-model="data.to" :placeholder="arrivalLabel()"
                                    @input="typeMultiCity(data.to, index, 2)" @click="
                                      (multiData.itinery[index].to = ''),
                                      (data.selectedTo = false)
                                      " class="inter-input" ref="multiToAutocomplete" variant="outlined"
                                    :clearable="!data.toloader">
                                    <template v-slot:append-inner>
                                      <v-fade-transition leave-absolute>
                                        <v-progress-circular v-if="data.toloader" color="#002d5b" size="18"
                                          indeterminate></v-progress-circular>
                                      </v-fade-transition>
                                    </template>
                                  </v-text-field>
                                  <img :src="require('@/assets/img/fd.png')" alt="" />
                                </div>
                                <div v-if="data.autocomTo" v-click-outside="clickoutmultiDropdown">
                                  <div class="autocompete-menual-dropdown" v-if="multidrop1.length > 0 ? true : false">
                                    <div v-for="(data, index1) in multidrop1" :key="index1">
                                      <div @click="datachangeMutiTo(data, index)" :style="index1 == multidrop1.length - 1
                                        ? ''
                                        : 'border-bottom:1px solid lightgray'
                                        " class="d-flex p-2 va-back">
                                        <div style="
                                            display: flex;
                                            align-items: center;
                                          ">
                                          <v-icon color="gray" size="20">
                                            {{
                                              data.location === "airport"
                                                ? "mdi-airplane"
                                                : "mdi-radiobox-marked"
                                            }}
                                          </v-icon>
                                        </div>

                                        <div class="ms-1 va-content">
                                          {{ data.iata }}, {{ data.city }},{{
                                            data.name
                                          }}
                                        </div>
                                      </div>

                                      <div v-if="data.group_airports.length > 0" class="ms-4">
                                        <div v-for="group in data.group_airports" :key="group">
                                          <div v-if="
                                            data.group_airport_city !=
                                            group.iata
                                          ">
                                            <div class="d-flex va-back px-1" @click="
                                              datachangeMutiTo(group, index)
                                              ">
                                              <div style="
                                                  display: flex;
                                                  align-items: center;
                                                ">
                                                <v-icon color="gray" size="20">mdi-airplane</v-icon>
                                              </div>

                                              <div class="m-1 va-content">
                                                {{ group.iata }},
                                                {{ group.city }},
                                                {{ group.name }}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 multidatecolumnSizing">
                                <div class="input">
                                  <span>{{
                                    $t("formsContents.labels.departures")
                                  }}</span>

                                  <!-- <input type="text" placeholder="Departure" data-language="en" class="datepicker-here"> -->
                                  <VueDatePicker ref="dpRef4" v-model="data.depdate" :min-date="getdate1(multiData.itinery, index)
                                    " :start-date="getdate1(multiData.itinery, index)
                                      "
                                      :multi-calendars="multiCalender"
                                       :max-date="new Date(
                                        new Date().getFullYear() + 1,
                                        new Date().getMonth(),
                                        new Date().getDate()
                                      )
                                        " auto-apply :six-weeks="sixWeekMode" :format="dateFormat"
                                    :hide-navigation="['time']" @focus="
                                      (focusEvent3 = true),
                                      (focusEvent1 = false),
                                      (focusEvent2 = false)
                                      " :placeholder="selectLabel()" @blur="dateUnfocus" @closed="closeDateMenu"
                                    @update:modelValue="
                                      (newValue) =>
                                        multiDateChanged(newValue, index)
                                    " class="datePickInput px-0" :on-click-outside="ClickOutDepat"
                                    :month-change-on-scroll="false" style="--dp-cell-size: 28px">
                                    <template #clear-icon="{ }">
                                      <v-icon class="input-slot-image me-2" v-if="focusEvent3" @click="
                                        clearDate(), (data.depdate = null)
                                        ">mdi-close-circle</v-icon>
                                    </template>
                                  </VueDatePicker>
                                  <img :src="require('@/assets/img/cl.png')" alt="" />
                                  <div v-if="canAdd(index)" class="flightbehaveIcon" style="
                                      position: absolute;
                                      right: -32px;
                                      top: 50%;
                                      bottom: 26px;
                                      cursor: pointer;
                                    ">
                                    <v-icon color="success" size="25" @click="addTrip(index)">
                                      mdi-plus-box
                                    </v-icon>
                                  </div>
                                  <div v-if="canRemove(index)" class="flightbehaveIcon" style="
                                      position: absolute;
                                      right: -32px;
                                      top: 50%;
                                      bottom: 26px;
                                      cursor: pointer;
                                    ">
                                    <v-icon @click="removemulti(index)" color="red" size="25">
                                      mdi-close-box
                                    </v-icon>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 flightbehavebtn">
                                <a href="#!" v-if="canAdd(index)" @click="addTrip(index)" class="addmore"><i
                                    class="fa fa-plus"></i>
                                  {{
                                    $t("formsContents.options.addflight")
                                  }}t</a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- <a href="#!" class="addmore"><i class="fa fa-plus"></i> Add another
                          flight</a> -->

                        <button class="search-btn" type="submit" @click.prevent="sendedMulticity()">
                          {{ $t("formsContents.options.search") }}
                        </button>
                      </form>
                    </div>

                    <div class="d-flex flex-wrap">
                      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-12 flight-search pe-2">
                        <div class="input">
                          <span>{{
                            $t("formsContents.options.passengers")
                          }}</span>
                          <div class="dropdown" id="myDDReturn">
                            <button class="dropdown-toggle form-control d-flex align-center" type="button"
                              id="travellerInfoReturn" data-bs-toggle="dropdown" aria-expanded="false">
                              <!-- <i class="bi bi-person-lines-fill h2 icon-pos mb-0 me-2"></i> -->
                              <span class="text-truncate">{{ adultValue + childValue + infantValue }}
                                {{ $t("formsContents.options.travellers") }}
                              </span>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="travellerInfoReturn">
                              <ul class="drop-rest p-0">
                                <li>
                                  <div class="d-flex">
                                    {{ $t("formsContents.options.adult") }}
                                  </div>
                                  <div class="ms-auto input-group plus-minus-input">
                                    <div class="addbtn" @click.stop="deg1()">
                                      -
                                    </div>
                                    <div class="adddata">{{ adultValue }}</div>
                                    <div class="addbtn" @click.stop="add1()">
                                      +
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="d-flex">
                                    {{ $t("formsContents.options.child") }}
                                  </div>
                                  <div class="ms-auto input-group plus-minus-input">
                                    <div class="addbtn" @click.stop="deg2()">
                                      -
                                    </div>
                                    <div class="adddata">{{ childValue }}</div>
                                    <div class="addbtn" @click.stop="add2()">
                                      +
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="d-flex">
                                    {{ $t("formsContents.options.infants") }}
                                  </div>
                                  <div class="ms-auto input-group plus-minus-input">
                                    <div class="addbtn" @click.stop="deg3()">
                                      -
                                    </div>
                                    <div class="adddata">{{ infantValue }}</div>
                                    <div class="addbtn" @click.stop="add3()">
                                      +
                                    </div>
                                  </div>
                                </li>
                              </ul>

                              <ul class="drop-rest m-0">
                                <li class="justify-end">
                                  <button type="button" class="btn btn">
                                    {{ $t("formsContents.options.done") }}
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <img :src="require('@/assets/img/passenger.png')" alt="" style="width: 20px" />

                          <div class="input1 d-none">
                            <span>Adults 12 + Yrs</span>

                            <input type="text" value="0" required />

                            <div class="inc">
                              <a href="#!"><i class="fa fa-minus"></i></a>

                              <a href="#!"><i class="fa fa-plus"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 col-12 flight-search z-0">
                        <div class="input">
                          <span>{{
                            $t("bookingviewContent.headings.class")
                          }}</span>
                          <div class="dropdown" id="myDDReturn">
                            <button class="dropdown-toggle form-control d-flex align-center" type="button"
                              id="travellerInfoReturn" data-bs-toggle="dropdown" aria-expanded="false">
                              <!-- <i class="bi bi-person-lines-fill h2 icon-pos mb-0 me-2"></i> -->
                              <span class="text-truncate">
                                {{ classType }}
                              </span>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="travellerInfoReturn">
                              <v-radio-group v-model="classType">
                                <v-radio :label="$t('formsContents.options.economy')" value="Economy"></v-radio>
                                <v-radio :label="$t('formsContents.options.premiumEconomy')
                                  " value="Premium Economy"></v-radio>
                                <v-radio :label="$t('formsContents.options.business')" value="Business"></v-radio>
                                <v-radio :label="$t('formsContents.options.firstClass')
                                  " value="First Class"></v-radio>
                              </v-radio-group>

                              <ul class="drop-rest m-0">
                                <li class="justify-end">
                                  <button type="button" class="btn btn">
                                    {{ $t("formsContents.options.done") }}
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <img :src="require('@/assets/img/cabin.png')" alt="" style="width: 20px" />

                          <div class="input1 d-none">
                            <span>Adults 12 + Yrs</span>

                            <input type="text" value="0" required />

                            <div class="inc">
                              <a href="#!"><i class="fa fa-minus"></i></a>

                              <a href="#!"><i class="fa fa-plus"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--Show More Options start-->
                    <div class="mt-2 mb-sm-2 mb-0">
                      <div class="option mt-2 mb-2" @click="toggleShowmore()" :class="{ collapsed: !isShowmore }"
                        data-toggle="collapse" data-target="#option">
                        <b><i class="fa fa-chevron-down"></i>{{ $t("formsContents.labels.moreoptions") }}</b>
                        <span v-if="selectedOptions.length">
                          ( <span>{{ selectedOptions.join(", ") }}</span> )
                        </span>
                      </div>
                      <div class="option-det collapse" id="option" :class="{ show: isShowmore }">
                        <div class="advanceOpt pt-0" style="
                            background-color: white;
                            padding: 10px 15px;
                            border-bottom-left-radius: 0.5rem;
                            border-bottom-right-radius: 0.5rem;
                          ">
                          <div class="d-flex flex-wrap">
                            {{
                              console.log(
                                this.directFlight,
                                this.baggageInclusive,
                                this.nearbyAirport
                              )
                            }}
                            <div class="d-flex flex-wrap">
                              <v-checkbox v-model="directFlight" :label="this.$t('advancedOptions.directFlight')"
                                class="me-4"></v-checkbox>
                              <v-checkbox v-model="baggageInclusive" :label="this.$t('advancedOptions.BaggageInclusive')
                                " class="me-4"></v-checkbox>
                              <v-checkbox v-model="nearbyAirport" :label="this.$t('advancedOptions.nearbyAirport')
                                " class="me-4"></v-checkbox>
                              <!-- <v-switch v-model="directFlight" :label="'Direct Flight' || this.$t('advancedOptions.directFlight')" color="rgb(38 34 94)" class="me-4"></v-switch>
                            <v-switch v-model="baggageInclusive" :label="'Baggage Inclusive' || this.$t('advancedOptions.BaggageInclusive')" color="rgb(38 34 94)" class="me-4"></v-switch>
                            <v-switch v-model="nearbyAirport" :label="'Nearby Airports' || this.$t('advancedOptions.nearbyAirport')" color="rgb(38 34 94)" class="me-4"></v-switch> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--Show More Options end-->
                  </div>
                </div>
              </div>
            </div>

            <!--Tab Content start-->

            <div class="tab-content rounded-3 px-2 py-2 bg-white border border-1" v-if="travelHistory">
              <div class="tab-pane active" id="flights" role="tabpanel">
                <p class="px-2 py-1 mb-1 bg-light fw-bold">
                  {{ $t("formsContents.labels.recentSearch") }}
                </p>

                <div v-if="this.RecentSearchData.length > 0" class="searchrecent masonry-grid">
                  <div v-for="data of this.RecentSearchData" :key="data" class="masonry-item"
                    :class="{ 'multi-city': data.itinery }">
                    <v-card @click="cardassigndata(data)" class="pa-3 card-style">
                      <!-- Multi-City Itinerary -->
                      <div v-if="data.itinery">
                        <div class="row" v-for="(item, index) of data.itinery" :key="index">
                          <div class="col-5 text-start">
                            <p class="f-size-14 fw-500 m-0">{{ item.from }}</p>
                            <p class="f-size-14 fw-500 m-0">
                              {{ getdated(item.depdate) }}
                            </p>
                          </div>
                          <div class="col-2 text-center">
                            <v-icon>mdi-arrow-right</v-icon>
                          </div>
                          <div class="col-5 text-start">
                            <p class="f-size-14 fw-500 m-0">{{ item.to }}</p>
                          </div>
                        </div>
                      </div>

                      <!-- One-Way & Roundtrip -->
                      <div v-else>
                        <div class="row">
                          <div class="col-5 text-start">
                            <p class="f-size-14 fw-500 m-0">{{ data.from }}</p>
                            <p class="f-size-14 fw-500 m-0">
                              {{ getdated(data.fromDate) }}
                            </p>
                          </div>
                          <div class="col-2 text-center">
                            <v-icon v-if="data.tripType == 'Roundtrip'">mdi-swap-horizontal</v-icon>
                            <v-icon v-if="data.tripType == 'Oneway'">mdi-arrow-right</v-icon>
                          </div>
                          <div class="col-5 text-start">
                            <p class="f-size-14 fw-500 m-0">{{ data.to }}</p>
                            <p class="f-size-14 fw-500 m-0" v-if="data.toDate">
                              {{ getdated(data.toDate) }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <!-- Passenger & Class Info -->
                      <div class="row mt-2">
                        <div class="col-7 text-start">
                          <p class="f-size-14 fw-500 m-0">
                            {{ $t("formsContents.options.passengers") }}:
                            <span v-if="data.adult > 0">{{ data.adult }}
                              {{
                                $t("searchPageContent.itinContent.adt")
                              }}</span>
                            <span class="ms-1" v-if="data.child > 0">{{ data.child }}
                              {{ $t("searchPageContent.itinContent.ch") }}</span>
                            <span class="ms-1" v-if="data.infant > 0">{{ data.infant }}
                              {{
                                $t("searchPageContent.itinContent.inf")
                              }}</span>
                          </p>
                        </div>
                        <div class="col-5 text-start" v-if="data.class">
                          <span class="f-size-14 fw-500">{{ $t("bookingviewContent.headings.class") }}:
                            {{ data.class }}</span>
                        </div>
                      </div>
                    </v-card>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="tab-content rounded-3 px-2 py-2 bg-white" v-if="travelHistory">
              <div class="tab-pane active" id="flights" role="tabpanel">
                <p class="f-size-18 fw-500 m-0 pb-1">{{ 'Recent Search' || $t("formsContents.labels.recentSearch") }}</p>
                <div v-if="this.RecentSearchData.length > 0 ? true : false" class="searchrecent">
                  <div class="row">
  
                    <div class="col-md-6 mt-2" v-for="data of this.RecentSearchData" :key="data">
                      <div v-if="!data.itinery">
                        <v-card @click="cardassigndata(data)" class="pa-2" style="border-radius:10px;">
                          <div class="row">
                            <div class="col-5 text-start">
                              <p class="f-size-14 fw-500 m-0">
                                {{ data.from }}
                              </p>
                              <p class="f-size-14 fw-500 m-0">
                                {{ getdated(data.fromDate) }}
                              </p>
                            </div>
                            <div class="col-2">
                              <div v-if="data.tripType == 'Roundtrip'">
                                <v-icon>mdi-swap-horizontal</v-icon>
                              </div>
                              <div v-if="data.tripType == 'Oneway'">
                                <v-icon>mdi-arrow-right</v-icon>
                              </div>
                              <div v-if="data.tripType == 'multithree'">
                                      <v-icon>mdi-swap-horizontal</v-icon>
                                      <v-icon>mdi-swap-horizontal</v-icon>
                                      <v-icon>mdi-swap-horizontal</v-icon>
                                    </div>
                            </div>
                            <div class="col-5 text-start">
                              <p class="f-size-14 fw-500 m-0">
                                {{ data.to }}
                              </p>
                              <p class="f-size-14 fw-500 m-0" v-if="data.toDate">
                                {{ getdated(data.toDate) }}
                              </p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-7 text-start">
                              <p class="f-size-14 fw-500 m-0">
                                {{ 'Passenger(s)' || $t("formsContents.options.passengers") }}:
                                <span v-if="data.adult > 0">{{ data.adult }} {{ 'Adult' || $t("searchPageContent.itinContent.adt")
                                  }}</span>
                                <span class="ms-1" v-if="data.child > 0">{{ data.child }} {{
                                  'Child' || $t("searchPageContent.itinContent.ch")
                                  }}</span>
                                <span class="ms-1" v-if="data.inf > 0">{{ data.inf }} {{
                                  'Infant' || $t("searchPageContent.itinContent.inf")
                                  }}</span>
                              </p>
                            </div>
                            <div class="col-5 text-start" v-if="data.class">
                              <span class="f-size-14 fw-500">{{ 'Class' || $t("bookingviewContent.headings.class") }}: {{ data.class
                                }}</span>
                            </div>
                          </div>
                        </v-card>
                      </div>
  
                      <div v-if="data.itinery">
                        <v-card @click="cardassigndata(data)" class="pa-2">
                          <div class="row" v-for="(item, index) of data.itinery" :key="index">
                            <div class="col-5 text-start">
                              <p class="f-size-14 fw-500 m-0">
                                {{ item.from }}
                              </p>
                              <p class="f-size-14 fw-500 m-0">
                                {{ getdated(item.depdate) }}
                              </p>
                            </div>
                            <div class="col-2">
                              <div>
                                <v-icon>mdi-arrow-right</v-icon>
                              </div>
  
                            </div>
                            <div class="col-5 text-start">
                              <p class="f-size-14 fw-500 m-0">
                                {{ item.to }}
                              </p>
                              <p class="f-size-14 fw-500 m-0" v-if="data.toDate">
                                      {{ getdated(data.toDate) }}
                                    </p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-7 text-start">
                              <p class="f-size-14 fw-500 m-0">
                                {{ 'Passenger(s)' || $t("formsContents.options.passengers") }}:
                                <span v-if="data.adult > 0">{{ data.adult }} {{ 'Adult' || $t("searchPageContent.itinContent.adt")
                                  }}</span>
                                <span class="ms-1" v-if="data.child > 0">{{ data.child }} {{
                                  'Child' || $t("searchPageContent.itinContent.ch")
                                  }}</span>
                                <span class="ms-1" v-if="data.infant > 0">{{ data.infant }} {{
                                  'Infant' || $t("searchPageContent.itinContent.inf")
                                  }}</span>
                              </p>
                            </div>
                            <div class="col-5 text-start" v-if="data.class">
                              <span class="f-size-14 fw-500">{{ 'Class' || $t("bookingviewContent.headings.class") }}: {{ data.class
                                }}</span>
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </div>
  
  
                  </div>
                </div>
              </div>
              <div v-if="travelHistory" style="max-height: 132px;overflow-y: scroll;overflow-x: hidden;">
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Banner end-->

  <!--Search start-->
  <div class="search-int mb-sm-4 mb-2" v-if="!handleform">
    <div class="container">
      {{ console.log(this.tripData, "asdfasfdasnewValue...2") }}
      <v-card @click="handleSearchform()" v-if="mobileResize" rounded="3" class="modifyform mb-3 p-2"
        data-toggle="collapse" data-target="#filtersection">
        <div class="row d-flex" v-if="!this.tripData.itinery">
          <div class="col-lg-5 col-md-6 col-sm-8 col-12 d-flex justify-content-between mb-sm-0 mb-1">
            <div>
              <h6 class="m-0">{{ this.tripData.from }}</h6>
              <p class="m-0" v-if="this.tripData.redate">
                {{ getdate(this.tripData.dedate) }}
              </p>
            </div>
            <div class="text-center">
              <v-icon size="20" v-if="this.tripData.redate">mdi-swap-horizontal</v-icon>
              <p class="m-0" v-if="!this.tripData.redate">
                <i class="fa fa-arrow-right"></i>
              </p>
              <p class="m-0" v-if="!this.tripData.redate" style="width: max-content">
                {{ getdate(this.tripData.dedate) }}
              </p>
            </div>
            <div>
              <h6 class="m-0 text-sm-center text-end">
                {{ this.tripData.to }}
              </h6>
              <p class="m-0 text-sm-center text-end" v-if="this.tripData.redate">
                {{ getdate(this.tripData.redate) }}
              </p>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-4 col-4 align-center">
            <h6 class="m-0 text-md-center text-start">
              {{ "Class Type" || $t("formsContents.options.classType") }}
            </h6>
            <p class="m-0 text-md-center text-start">
              {{ this.tripData.class }}
            </p>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-6 col-5 align-center">
            <h6 class="m-0 text-md-center text-start">
              {{ "Passengers" || $t("formsContents.options.passengers") }}
            </h6>
            <div class="d-flex justify-content-md-center justify-content-start">
              <span v-if="this.tripData.adult > 0">{{ this.tripData.adult }} Adt</span>
              <span v-if="this.tripData.adult > 0">, {{ this.tripData.child }} Ch</span>
              <span v-if="this.tripData.adult > 0">, {{ this.tripData.infant }} Inf</span>
            </div>
          </div>
          <div class="col-lg-2 col-md-1 col-sm-6 col-3 d-flex align-center justify-end">
            <button class="btn-search my-0 p-0" @click="modifySection = !modifySection"
              style="color: white; max-width: 100px">
              <span v-if="!mobileResize">{{
                "Modify" || $t("searchPageContent.itinContent.modify")
              }}</span>
              <v-icon size="14">
                {{
                  !formCollapse
                    ? "mdi-arrow-up-drop-circle-outline"
                    : "mdi-pencil"
                }}
              </v-icon>
            </button>
          </div>
        </div>
        <div class="row d-flex" v-else>
          <div class="col-lg-5 col-md-6 col-sm-12 col-12 d-flex flex-wrap justify-content-between mb-sm-0 mb-1">
            <div v-for="(item, index) of tripData.itinery" :key="index">
              <div class="d-flex align-center">
                <h6 class="m-0">{{ getcitycode(item.from) }}</h6>
                <p class="m-0 mx-1" v-if="!this.tripData.redate">
                  <i class="fa fa-arrow-right"></i>
                </p>
                <h6 class="m-0">{{ getcitycode(item.to) }}</h6>
              </div>
              <p class="m-0">{{ getdate(item.depdate) }}</p>
            </div>
            <!-- <div class="text-center">
              <v-icon size="20" v-if="this.tripData.redate">mdi-swap-horizontal</v-icon>
              <p class="m-0" v-if="!this.tripData.redate"><i class="fa fa-arrow-right"></i></p>
              <p class="m-0" v-if="!this.tripData.redate" style="width: max-content;">{{ getdate(this.tripData.dedate) }}</p>
            </div>
            <div>
              <h6 class="m-0 text-sm-center text-end">{{ this.tripData.to }}</h6>
              <p class="m-0 text-sm-center text-end" v-if="this.tripData.redate">{{ getdate(this.tripData.redate) }}</p>
            </div> -->
          </div>
          <div class="col-lg-2 col-md-2 col-sm-3 col-4 align-center">
            <h6 class="m-0 text-md-center text-start">
              {{ "Class Type" || $t("formsContents.options.classType") }}
            </h6>
            <p class="m-0 text-md-center text-start">
              {{ this.tripData.class }}
            </p>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-4 col-5 align-center">
            <h6 class="m-0 text-md-center text-start">
              {{ "Passengers" || $t("formsContents.options.passengers") }}
            </h6>
            <div class="d-flex justify-content-md-center justify-content-start">
              <span v-if="this.tripData.adult > 0">{{ this.tripData.adult }} Adt</span>
              <span v-if="this.tripData.adult > 0">, {{ this.tripData.child }} Ch</span>
              <span v-if="this.tripData.adult > 0">, {{ this.tripData.infant }} Inf</span>
            </div>
          </div>
          <div class="col-lg-2 col-md-1 col-sm-5 col-3 d-flex align-center justify-end">
            <button class="btn-search my-0 p-0" @click="modifySection = !modifySection"
              style="color: white; max-width: 100px">
              <span v-if="!mobileResize">{{
                "Modify" || $t("searchPageContent.itinContent.modify")
              }}</span>
              <v-icon size="14">
                {{
                  !formCollapse
                    ? "mdi-arrow-up-drop-circle-outline"
                    : "mdi-pencil"
                }}
              </v-icon>
            </button>
          </div>
        </div>
      </v-card>

      <div class="row" :class="{ show: !formCollapse, collapse: mobileResize }" id="collapseform">
        <div class="px-sm-2 px-3 mb-3">
          <div class="tab-pane active" id="flights" role="tabpanel">
            <!--Trip Type-->
            <div class="tab-bg d-flex justify-content-between align-items-center">
              <!--Tab Nav start-->
              <div class="list-group stab mb-0" :class="{
                'pe-none opacity-50 user-select-none': travelHistory,
              }" id="myList2" role="tablist">
                <a class="list-group-item list-group-item-action shadow-none"
                  :class="{ active: activetrip == 'Oneway' }" @click="activeType('Oneway')" data-toggle="list"
                  data-target="#oneway" role="tab">{{
                    $t("formsContents.options.oneWay") }}</a>
                <a class="list-group-item list-group-item-action shadow-none"
                  :class="{ active: activetrip == 'Roundtrip' }" @click="activeType('Roundtrip')" data-toggle="list"
                  data-target="#roundtrip" role="tab">{{ $t("formsContents.options.return") }}</a>
                <a class="list-group-item list-group-item-action shadow-none"
                  :class="{ active: activetrip == 'Multicity' }" @click="activeType('Multicity')" data-toggle="list"
                  data-target="#multicity" role="tab">{{ $t("formsContents.options.multiCity") }}</a>
              </div>
              <!--Tab Nav end-->

              <div @click="travelHistory = !travelHistory" v-if="this.RecentSearchData.length > 0" class="hisOptions">
                <p class="m-0">
                  <v-icon v-if="!travelHistory" color="white" size="16" class="me-1">mdi-history</v-icon>
                  <span>{{
                    !travelHistory ? "Recent Search" : "Search Form"
                  }}</span>
                </p>
                <!-- <p class="m-0"><i class="fa-solid fa-clock-rotate-left me-1"> </i>Recent Search</p> -->
                <!-- <v-icon color="white" size="28">{{ !travelHistory ? 'mdi-history' : 'mdi-close-thick' }}</v-icon> -->
              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-12" v-if="!travelHistory">
          <div class="row">
            <div class="col-xs-12 col-sm-9 col-md-9 col-lg-10">
              <!--One way and ROund trip-->

              <div class="row int" v-if="roundTrip || Oneway">
                <div class="col-12 col-sm-4 col-md-4 col-lg-3">
                  <div class="text-box">
                    <span>{{ $t("homePageContent.contentSection.from") }}</span>
                    <!-- <input type="text" value="Delhi, India"> -->
                    <v-text-field :placeholder="departureLabel()" v-model="oneway.from" variant="outlined"
                      @input="typeCity(oneway.from, 1)" class="inter-input" @click="handlefrom()"
                      v-bind="!fromloader ? { clearable: true } : {}">
                      <template v-slot:append-inner v-if="fromloader">
                        <v-fade-transition leave-absolute>
                          <v-progress-circular color="#002d5b" size="14" indeterminate></v-progress-circular>
                        </v-fade-transition>
                      </template>
                    </v-text-field>
                    <div v-if="typedrop" v-click-outside="clickoutDropdown">
                      <div v-if="this.city.length > 0 ? true : false" class="autocompete-menual-dropdown">
                        <div v-for="(data, index) in city" :key="index">
                          <div @click="datachange(data)" :style="index == city.length - 1
                            ? ''
                            : 'border-bottom:1px solid lightgray'
                            " class="d-flex p-2 va-back">
                            <div style="display: flex; align-items: center">
                              <v-icon color="gray" size="20">
                                {{
                                  data.location === "airport"
                                    ? "mdi-airplane"
                                    : "mdi-radiobox-marked"
                                }}
                              </v-icon>
                            </div>

                            <div class="m-1 va-content">
                              {{ data.iata }}, {{ data.city }},{{ data.name }}
                            </div>
                          </div>

                          <div v-if="data.group_airports.length > 0" class="ms-4">
                            <div v-for="group in data.group_airports" :key="group" class="va-back px-1">
                              <div v-if="data.group_airport_city != group.iata">
                                <div class="d-flex va-back" @click="datachange(group)">
                                  <div style="display: flex; align-items: center">
                                    <v-icon color="gray" size="15">mdi-airplane</v-icon>
                                  </div>
                                  <div class="m-1 va-content">
                                    {{ group.iata }}, {{ group.city }},{{
                                      group.name
                                    }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="formres && resultFrom.length > 0" v-click-outside="onClickOutside">
                      <div class="recent_style">
                        <v-card style="
                            max-height: 300px;
                            border-radius: 10px;
                            box-shadow: none;
                          ">
                          <h6 style="
                              text-align: left;
                              margin: 12px;
                              font-weight: 600;
                              font-size: 14px;
                            ">
                            {{ $t("homePageContent.label.recentSearches") }}
                          </h6>
                          <div class="resu-form d-flex mt-1" v-for="data in resultFrom" :key="data"
                            @click="getrecent(data)">
                            <div class="me-2">
                              <v-icon size="18"> mdi-clock-outline </v-icon>
                            </div>

                            <div>
                              <p class="m-0" style="margin-top: 1px !important">
                                {{ data.from }}
                              </p>
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-4 col-md-4 col-lg-3">
                  <div class="text-box">
                    <span>{{ $t("flightInfoContent.contents.to") }}</span>

                    <!-- <input type="text" value="Dubai, UAE"> -->
                    <v-text-field :placeholder="arrivalLabel()" v-model="oneway.to" variant="outlined"
                      @input="typeCity(oneway.to, 2)" class="inter-input" @click="handleto()" ref="secondAutoComplete"
                      :clearable="!toloader">
                      <template v-slot:append-inner>
                        <v-fade-transition leave-absolute>
                          <v-progress-circular v-if="toloader" color="#002d5b" size="14"
                            indeterminate></v-progress-circular>
                        </v-fade-transition>
                      </template>
                    </v-text-field>
                  </div>
                  <div v-if="typedrop1" v-click-outside="clickoutDropdown">
                    <div v-if="this.city1.length > 0 ? true : false" class="autocompete-menual-dropdown">
                      <div v-for="(data, index) in city1" :key="index">
                        <div @click="datachangeTo(data)" :style="index == city.length - 1
                          ? ''
                          : 'border-bottom:1px solid lightgray'
                          " class="d-flex p-2 va-back">
                          <div style="display: flex; align-items: center">
                            <v-icon color="gray" size="20">
                              {{
                                data.location === "airport"
                                  ? "mdi-airplane"
                                  : "mdi-radiobox-marked"
                              }}
                            </v-icon>
                          </div>

                          <div class="m-1 va-content">
                            {{ data.iata }}, {{ data.city }},{{ data.name }}
                          </div>
                        </div>

                        <div v-if="data.group_airports.length > 0" class="ms-4">
                          <div v-for="group in data.group_airports" :key="group" class="va-back px-1">
                            <div v-if="data.group_airport_city != group.iata">
                              <div class="d-flex va-back" @click="datachangeTo(group)">
                                <div style="display: flex; align-items: center">
                                  <v-icon style="transform: rotate(90deg)" color="gray" size="15">mdi-airplane</v-icon>
                                </div>

                                <div class="m-1 va-content">
                                  {{ group.iata }}, {{ group.city }},
                                  {{ group.name }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="recent_style" v-if="formres1 && resultTo.length > 0" v-click-outside="onClickOutside">
                    <v-card style="
                        max-height: 300px;
                        border-radius: 10px;
                        box-shadow: none;
                      ">
                      <h6 style="
                          text-align: left;
                          margin: 12px;
                          font-weight: 600;
                          font-size: 14px;
                        ">
                        {{ $t("homePageContent.label.recentSearches") }}
                      </h6>
                      <div class="resu-form d-flex mt-1" v-for="data in resultTo" :key="data" @click="getrecent1(data)">
                        <div class="me-2">
                          <v-icon size="18"> mdi-clock-outline </v-icon>
                        </div>
                        <div>
                          <p class="m-0" style="margin-top: 1px !important">
                            {{ data.to }}
                          </p>
                        </div>
                      </div>
                    </v-card>
                  </div>
                </div>

                <!-- <div class="col-12 col-sm-4 col-md-4 col-lg-2 DatecolumnSizing">
                  <div class="text-box">
                    <span>{{ $t("formsContents.options.depart") }}</span>
                    <VueDatePicker ref="dpRef1" :multi-calendars="multiCalender" v-model="oneway.dedate"
                      :min-date="new Date()" :format="dateFormat" no-today :six-weeks="sixWeekMode"
                      :placeholder="selectLabel()" :start-date="oneway.dedate" :hide-navigation="['time']" auto-apply
                      :max-date="new Date(
                        new Date().getFullYear() + 1,
                        new Date().getMonth(),
                        new Date().getDate()
                      )
                        " :on-click-outside="ClickOutDepat" @update:modelValue="fromDateChanged" class="datePickInput"
                      :month-change-on-scroll="false" style="--dp-cell-size: 28px">
                      <template #clear-icon="{ }">
                        <v-icon class="input-slot-image me-2"
                          @click="clearDate(oneway.dedate, 'dep')">mdi-close-circle</v-icon>
                      </template>
                    </VueDatePicker>
                  </div>
                </div>
                <div class="col-12 col-sm-4 col-md-4 col-lg-2 DatecolumnSizing">
                  <div class="text-box">
                    <span>{{ $t("formsContents.options.return") }}</span>
                    <VueDatePicker ref="dpRef2" :multi-calendars="multiCalender" v-model="oneway.redate"
                      :min-date="oneway.dedate" :format="dateFormat" no-today :six-weeks="sixWeekMode"
                      :placeholder="selectLabel()" :start-date="oneway.dedate" :hide-navigation="['time']" auto-apply
                      :max-date="new Date(
                        new Date().getFullYear() + 1,
                        new Date().getMonth(),
                        new Date().getDate()
                      )
                        " :on-click-outside="ClickOutReturn" @update:modelValue="toDateChanged" :disabled="Oneway"
                      class="datePickInput" style="--dp-cell-size: 28px">
                      <template #clear-icon="{ }">
                        <v-icon class="input-slot-image me-2"
                          @click="clearDate(oneway.redate, 'arr')">mdi-close-circle</v-icon>
                      </template>
                    </VueDatePicker>
                  </div>
                </div> -->
                <div class="col-12 col-sm-4 col-md-4 col-lg-3 DatecolumnSizing">
                  <div class="text-box">
                    <span v-if="activetrip==='Roundtrip'">{{ $t("formsContents.options.depart") }} - {{ $t("formsContents.options.return") }}</span>
                    <span v-else>{{ $t("formsContents.labels.departures") }}</span>                    <VueDatePicker ref="dpRef" :multi-calendars="activetrip === 'Roundtrip' ? 3 : 2" v-model="oneway.travelDate"
                      :min-date="new Date()" :format="dateFormat" no-today :six-weeks="sixWeekMode"
                      :placeholder="selectLabel()" :hide-navigation="['time']" auto-apply
                      :max-date="new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate())"
                      :on-click-outside="ClickOutDepat" @update:modelValue="fromDateChanged" class="datePickInput"
                      :month-change-on-scroll="false" style="--dp-cell-size: 28px" :range="roundTrip">
                      <template #clear-icon>
                        <v-icon class="input-slot-image me-2" @click="clearDate()">mdi-close-circle</v-icon>
                      </template>
                    </VueDatePicker>
                  </div>
                </div>


                <div class="col-12 col-sm-4 col-md-4 col-lg-3">
                  <div class="text-box">
                    <span>{{ $t("formsContents.options.passenger") }} &
                      {{ $t("formsContents.options.class") }}</span>
                    {{ console.log(this.classType, "sdasdclassType") }}
                    <input type="text" class="cursor" :value="travellerText" data-toggle="collapse"
                      data-target="#option1" readonly @click="togglePassenger()"
                      :class="{ collapsed: !isOpentoggler }" />
                    <!-- <select>
                      <option>sadasdasd</option>
                    </select> -->
                  </div>
                </div>
              </div>

              <div class="row int" v-if="multicity">
                <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-0">
                  <div class="d-flex flex-wrap" v-for="(data, index) in multiData.itinery" :key="index">
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-12 flightbehavebtn">
                      <div class="input d-flex justify-content-between mb-1">
                        <span class="text-white">{{ $t("homePageContent.label.flight") }}
                          {{ index + 1 }}</span>
                        <button class="px-1 rounded" @click="removemulti(index)" v-if="canRemove(index)"
                          style="background-color: #eeeeee">
                          {{ $t("homePageContent.label.remove") }}
                        </button>
                      </div>
                    </div>

                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                      <div class="text-box">
                        <span>{{
                          $t("homePageContent.contentSection.from")
                        }}</span>
                        <!-- <input type="text" value="Delhi, India"> -->
                        <v-text-field v-model="data.from" type="text" :placeholder="departureLabel()"
                          @input="typeMultiCity(data.from, index, 1)" @click="
                            (multiData.itinery[index].from = ''),
                            (data.selectedFrom = false)
                            " class="inter-input" ref="multiFromAutocomplete" variant="outlined"
                          :clearable="!data.fromloader">
                          <template v-slot:append-inner>
                            <v-fade-transition leave-absolute>
                              <v-progress-circular v-if="data.fromloader" color="#002d5b" size="14"
                                indeterminate></v-progress-circular>
                            </v-fade-transition>
                          </template>
                        </v-text-field>
                        <div v-if="
                          multiData.itinery[index].autocomfrom ? true : false
                        " v-click-outside="clickoutmultiDropdown">
                          <div class="autocompete-menual-dropdown" v-if="multidrop.length > 0 ? true : false">
                            <div v-for="(data, index1) in multidrop" :key="index1">
                              <div @click="datachangeMuti(data, index)" :style="index1 == multidrop1.length - 1
                                ? ''
                                : 'border-bottom:1px solid lightgray'
                                " class="d-flex p-2 va-back">
                                <div style="display: flex; align-items: center">
                                  <v-icon color="gray" size="20">
                                    {{
                                      data.location === "airport"
                                        ? "mdi-airplane"
                                        : "mdi-radiobox-marked"
                                    }}
                                  </v-icon>
                                </div>

                                <div class="ms-1 va-content">
                                  {{ data.iata }}, {{ data.city }},{{
                                    data.name
                                  }}
                                </div>
                              </div>

                              <div v-if="data.group_airports.length > 0" class="ms-4">
                                <div v-for="group in data.group_airports" :key="group">
                                  <div v-if="data.group_airport_city != group.iata">
                                    <div class="d-flex va-back px-1" @click="datachangeMuti(group, index)">
                                      <div style="
                                          display: flex;
                                          align-items: center;
                                        ">
                                        <v-icon color="gray" size="20">mdi-airplane</v-icon>
                                      </div>

                                      <div class="m-1 va-content">
                                        {{ group.iata }}, {{ group.city }},
                                        {{ group.name }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                      <div class="text-box">
                        <span>{{ $t("flightInfoContent.contents.to") }}</span>

                        <!-- <input type="text" value="Dubai, UAE"> -->
                        <v-text-field v-model="data.to" :placeholder="arrivalLabel()"
                          @input="typeMultiCity(data.to, index, 2)" @click="
                            (multiData.itinery[index].to = ''),
                            (data.selectedTo = false)
                            " class="inter-input" ref="multiToAutocomplete" variant="outlined"
                          :clearable="!data.toloader">
                          <template v-slot:append-inner>
                            <v-fade-transition leave-absolute>
                              <v-progress-circular v-if="data.toloader" color="#002d5b" size="14"
                                indeterminate></v-progress-circular>
                            </v-fade-transition>
                          </template>
                        </v-text-field>
                      </div>
                      <div v-if="data.autocomTo" v-click-outside="clickoutmultiDropdown">
                        <div class="autocompete-menual-dropdown" v-if="multidrop1.length > 0 ? true : false">
                          <div v-for="(data, index1) in multidrop1" :key="index1">
                            <div @click="datachangeMutiTo(data, index)" :style="index1 == multidrop1.length - 1
                              ? ''
                              : 'border-bottom:1px solid lightgray'
                              " class="d-flex p-2 va-back">
                              <div style="display: flex; align-items: center">
                                <v-icon color="gray" size="20">
                                  {{
                                    data.location === "airport"
                                      ? "mdi-airplane"
                                      : "mdi-radiobox-marked"
                                  }}
                                </v-icon>
                              </div>

                              <div class="ms-1 va-content">
                                {{ data.iata }}, {{ data.city }},{{ data.name }}
                              </div>
                            </div>

                            <div v-if="data.group_airports.length > 0" class="ms-4">
                              <div v-for="group in data.group_airports" :key="group">
                                <div v-if="data.group_airport_city != group.iata">
                                  <div class="d-flex va-back px-1" @click="datachangeMutiTo(group, index)">
                                    <div style="display: flex; align-items: center">
                                      <v-icon color="gray" size="20">mdi-airplane</v-icon>
                                    </div>

                                    <div class="m-1 va-content">
                                      {{ group.iata }}, {{ group.city }},
                                      {{ group.name }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-4 col-md-4 col-lg-4 d-flex">
                      <div class="text-box w-100 pe-sm-2 pe-0">
                        <span>{{ $t("formsContents.options.depart") }}</span>

                        <!-- <input type="text" value="05/07/2019" data-language="en" class="datepicker-here"> -->
                        <VueDatePicker ref="dpRef4" v-model="data.depdate"
                        :multi-calendars="multiCalender"
                          :min-date="getdate1(multiData.itinery, index)"
                          :start-date="getdate1(multiData.itinery, index)" :max-date="new Date(
                            new Date().getFullYear() + 1,
                            new Date().getMonth(),
                            new Date().getDate()
                          )
                            " auto-apply :six-weeks="sixWeekMode" :format="dateFormat" :hide-navigation="['time']"
                          @focus="
                            (focusEvent3 = true),
                            (focusEvent1 = false),
                            (focusEvent2 = false)
                            " :placeholder="selectLabel()" @blur="dateUnfocus" @closed="closeDateMenu"
                          @update:modelValue="
                            (newValue) => multiDateChanged(newValue, index)
                          " class="datePickInput px-0" :on-click-outside="ClickOutDepat"
                          :month-change-on-scroll="false" style="--dp-cell-size: 28px">
                          <template #clear-icon="{ }">
                            <v-icon class="input-slot-image me-2" v-if="focusEvent3"
                              @click="clearDate(), (data.depdate = null)">mdi-close-circle</v-icon>
                          </template>
                        </VueDatePicker>
                      </div>
                      <div v-if="canAdd(index)" class="flightbehaveIcon m-auto" style="cursor: pointer">
                        <v-icon color="success" size="25" @click="addTrip(index)">
                          mdi-plus-box
                        </v-icon>
                      </div>
                      <div v-if="canRemove(index)" class="flightbehaveIcon m-auto" style="cursor: pointer">
                        <v-icon @click="removemulti(index)" color="red" size="25">
                          mdi-close-box
                        </v-icon>
                      </div>
                    </div>

                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 flightbehavebtn">
                      <a href="#!" v-if="canAdd(index)" @click="addTrip(index)" class="addmore"><i
                          class="fa fa-plus"></i>{{ $t("formsContents.options.addflight") }}</a>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-3">
                  <div class="text-box">
                    <span>{{ $t("formsContents.options.passenger") }} &
                      {{ $t("formsContents.options.class") }}</span>
                    {{ console.log(this.classType, "sdasdclassType") }}
                    <input type="text" class="cursor" :value="travellerText" data-toggle="collapse"
                      data-target="#option1" readonly @click="togglePassenger()"
                      :class="{ collapsed: !isOpentoggler }" />
                    <!-- <select>
          <option>sadasdasd</option>
        </select> -->
                  </div>
                </div>
              </div>

              <!--PASSENGERS & CLASS-->

              <div class="option1 collapse flight-search" :class="{ show: isOpentoggler }" id="option1">
                <div class="dropdown d-none" id="myDDReturn">
                  <button class="dropdown-toggle form-control d-flex align-center" type="button"
                    id="travellerInfoReturn" data-bs-toggle="dropdown" aria-expanded="false">
                    <!-- <i class="bi bi-person-lines-fill h2 icon-pos mb-0 me-2"></i> -->
                    <span class="text-truncate">{{ adultValue + childValue + infantValue }}
                      {{
                        "Travellers" || $t("formsContents.options.travellers")
                      }},
                      {{ classType }}
                    </span>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="travellerInfoReturn">
                    <ul class="drop-rest">
                      <li>
                        <div class="d-flex">
                          {{ "Adult" || $t("formsContents.options.adult") }}
                        </div>
                        <div class="ms-auto input-group plus-minus-input">
                          <div class="addbtn" @click.stop="deg1()">-</div>
                          <div class="adddata">{{ adultValue }}</div>
                          <div class="addbtn" @click.stop="add1()">+</div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex">
                          {{ "Child" || $t("formsContents.options.child") }}
                        </div>
                        <div class="ms-auto input-group plus-minus-input">
                          <div class="addbtn" @click.stop="deg2()">-</div>
                          <div class="adddata">{{ childValue }}</div>
                          <div class="addbtn" @click.stop="add2()">+</div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex">
                          {{ "Infant" || $t("formsContents.options.infants") }}
                        </div>
                        <div class="ms-auto input-group plus-minus-input">
                          <div class="addbtn" @click.stop="deg3()">-</div>
                          <div class="adddata">{{ infantValue }}</div>
                          <div class="addbtn" @click.stop="add3()">+</div>
                        </div>
                      </li>
                    </ul>

                    <v-radio-group v-model="classType">
                      <v-radio :label="'Economy' || $t('formsContents.options.economy')
                        " value="Economy"></v-radio>
                      <v-radio :label="'Premium Economy' ||
                        $t('formsContents.options.premiumEconomy')
                        " value="Premium Economy"></v-radio>
                      <v-radio :label="'Business' || $t('formsContents.options.business')
                        " value="Business"></v-radio>
                      <v-radio :label="'First Class' ||
                        $t('formsContents.options.firstClass')
                        " value="First Class"></v-radio>
                    </v-radio-group>

                    <ul class="drop-rest">
                      <li class="justify-end">
                        <button type="button" class="btn btn" onclick="">
                          {{ "Done" || $t("formsContents.options.done") }}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="d-flex flex-wrap">
                  <div class="col-6 col-sm-6 col-md-4 col-lg-4 d-none">
                    <div class="input">
                      <span>Passenger(s)</span>
                    </div>
                    <ul class="drop-rest">
                      <li>
                        <div class="d-flex">
                          {{ "Adult" || $t("formsContents.options.adult") }}
                        </div>
                        <div class="ms-auto input-group plus-minus-input">
                          <div class="addbtn" @click.stop="deg1()">-</div>
                          <div class="adddata">{{ adultValue }}</div>
                          <div class="addbtn" @click.stop="add1()">+</div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex">
                          {{ "Child" || $t("formsContents.options.child") }}
                        </div>
                        <div class="ms-auto input-group plus-minus-input">
                          <div class="addbtn" @click.stop="deg2()">-</div>
                          <div class="adddata">{{ childValue }}</div>
                          <div class="addbtn" @click.stop="add2()">+</div>
                        </div>
                      </li>
                      <li>
                        <div class="d-flex">
                          {{ "Infant" || $t("formsContents.options.infants") }}
                        </div>
                        <div class="ms-auto input-group plus-minus-input">
                          <div class="addbtn" @click.stop="deg3()">-</div>
                          <div class="adddata">{{ infantValue }}</div>
                          <div class="addbtn" @click.stop="add3()">+</div>
                        </div>
                      </li>
                    </ul>
                    <!-- </div> -->
                  </div>

                  <div class="col-6 col-sm-6 col-md-4 col-lg-3">
                    <div class="input1">
                      <span>{{ $t("formsContents.options.adult") }} 12 + Yrs</span>

                      <input type="text" :value="adultValue" required readonly />

                      <div class="inc">
                        <span @click.stop="deg1()"><i class="fa fa-minus text-dark"></i></span>

                        <span @click.stop="add1()"><i class="fa fa-plus text-dark"></i></span>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 col-sm-6 col-md-4 col-lg-3">
                    <div class="input1">
                      <span>{{ $t("formsContents.options.child") }} 2 - 12
                        Yrs</span>

                      <input type="text" :value="childValue" required readonly />

                      <div class="inc">
                        <span @click.stop="deg2()"><i class="fa fa-minus text-dark"></i></span>

                        <span @click.stop="add2()"><i class="fa fa-plus text-dark"></i></span>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 col-sm-6 col-md-4 col-lg-3">
                    <div class="input1">
                      <span>{{ $t("formsContents.options.infants") }} 0 - 2
                        Yrs</span>

                      <input type="text" :value="infantValue" required readonly />

                      <div class="inc">
                        <span @click.stop="deg3()"><i class="fa fa-minus text-dark"></i></span>

                        <span @click.stop="add3()"><i class="fa fa-plus text-dark"></i></span>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 col-sm-6 col-md-4 col-lg-4 d-none">
                    <div class="input">
                      <span>{{ $t("formsContents.options.class") }}</span>
                    </div>
                    <v-radio-group v-model="classType">
                      <v-radio :label="$t('formsContents.options.economy')" value="Economy"></v-radio>
                      <v-radio :label="$t('formsContents.options.premiumEconomy')" value="Premium Economy"></v-radio>
                      <v-radio :label="$t('formsContents.options.business')" value="Business"></v-radio>
                      <v-radio :label="$t('formsContents.options.firstClass')" value="First Class"></v-radio>
                    </v-radio-group>
                  </div>

                  <div class="col-6 col-sm-6 col-md-6 col-lg-3">
                    <div class="input2">
                      <span>{{ $t("formsContents.options.class") }}</span>

                      <select v-model="classType">
                        <option>
                          {{ this.$t("formsContents.options.economy") }}
                        </option>

                        <option>
                          {{ this.$t("formsContents.options.premiumEconomy") }}
                        </option>

                        <option>
                          {{ this.$t("formsContents.options.business") }}
                        </option>

                        <option>
                          {{ this.$t("formsContents.options.firstClass") }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-6 col-sm-6 col-md-6 col-lg-3 d-none">
                    <div class="input2">
                      <span>Preferred Airline</span>

                      <input type="text" placeholder="Type here" />
                    </div>
                  </div>

                  <div class="col-6 col-sm-6 col-md-6 col-lg-3 d-none">
                    <div class="input2">
                      <span>Routing</span>

                      <select>
                        <option>All</option>

                        <option>Option 01</option>

                        <option>Option 02</option>

                        <option>Option 03</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12 col-md-6 col-lg-3 d-none">
                    <div class="input2">
                      <span>Meal</span>

                      <select>
                        <option>No</option>

                        <option>Yes</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2 mb-sm-2 mb-0">
                <div class="option mt-2 mb-2" @click="toggleShowmore()" :class="{ collapsed: !isShowmore }"
                  data-toggle="collapse" data-target="#option">
                  <b><i class="fa fa-chevron-down"></i>
                    {{ $t("formsContents.labels.moreoptions") }}</b>
                  <span v-if="selectedOptions.length">
                    <span> ( {{ selectedOptions.join(", ") }} )</span>
                  </span>
                </div>
                <div class="option-det collapse" id="option" :class="{ show: isShowmore }">
                  <div class="advanceOpt" style="
                      background-color: white;
                      padding: 10px 15px;
                      border-radius: 0.5rem;
                    ">
                    <div class="d-flex flex-wrap">
                      {{
                        console.log(
                          this.directFlight,
                          this.baggageInclusive,
                          this.nearbyAirport
                        )
                      }}
                      <div class="d-flex flex-wrap">
                        <v-checkbox v-model="directFlight" :label="this.$t('advancedOptions.directFlight')"
                          class="me-4"></v-checkbox>
                        <v-checkbox v-model="baggageInclusive" :label="this.$t('advancedOptions.BaggageInclusive')"
                          class="me-4"></v-checkbox>
                        <v-checkbox v-model="nearbyAirport" :label="this.$t('advancedOptions.nearbyAirport')"
                          class="me-4"></v-checkbox>
                        <!-- <v-switch v-model="directFlight" :label="'Direct Flight' || this.$t('advancedOptions.directFlight')" color="rgb(38 34 94)" class="me-4"></v-switch>
                          <v-switch v-model="baggageInclusive" :label="'Baggage Inclusive' || this.$t('advancedOptions.BaggageInclusive')" color="rgb(38 34 94)" class="me-4"></v-switch>
                          <v-switch v-model="nearbyAirport" :label="'Nearby Airports' || this.$t('advancedOptions.nearbyAirport')" color="rgb(38 34 94)" class="me-4"></v-switch> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2">
              <button class="btn-search my-3" @click.prevent="
                roundTrip || Oneway ? sended() : sendedMulticity()
                ">
                {{ $t("formsContents.options.search") }}
              </button>
            </div>
          </div>
        </div>

        <div class="tab-content rounded-3 px-2 py-2 bg-white border border-1" v-if="travelHistory">
          <div class="tab-pane active" id="flights" role="tabpanel">
            <p class="px-2 py-1 mb-1 bg-light fw-bold">
              {{ $t("formsContents.labels.recentSearch") }}
            </p>

            <div v-if="this.RecentSearchData.length > 0" class="searchrecent masonry-grid">
              <div v-for="data of this.RecentSearchData" :key="data" class="masonry-item"
                :class="{ 'multi-city': data.itinery }">
                <v-card @click="cardassigndata(data)" class="pa-3 card-style">
                  <!-- Multi-City Itinerary -->
                  <div v-if="data.itinery">
                    <div class="row" v-for="(item, index) of data.itinery" :key="index">
                      <div class="col-5 text-start">
                        <p class="f-size-14 fw-500 m-0">{{ item.from }}</p>
                        <p class="f-size-14 fw-500 m-0">
                          {{ getdated(item.depdate) }}
                        </p>
                      </div>
                      <div class="col-2 text-center">
                        <v-icon>mdi-arrow-right</v-icon>
                      </div>
                      <div class="col-5 text-start">
                        <p class="f-size-14 fw-500 m-0">{{ item.to }}</p>
                      </div>
                    </div>
                  </div>

                  <!-- One-Way & Roundtrip -->
                  <div v-else>
                    <div class="row">
                      <div class="col-5 text-start">
                        <p class="f-size-14 fw-500 m-0">{{ data.from }}</p>
                        <p class="f-size-14 fw-500 m-0">
                          {{ getdated(data.fromDate) }}
                        </p>
                      </div>
                      <div class="col-2 text-center">
                        <v-icon v-if="data.tripType == 'Roundtrip'">mdi-swap-horizontal</v-icon>
                        <v-icon v-if="data.tripType == 'Oneway'">mdi-arrow-right</v-icon>
                      </div>
                      <div class="col-5 text-start">
                        <p class="f-size-14 fw-500 m-0">{{ data.to }}</p>
                        <p class="f-size-14 fw-500 m-0" v-if="data.toDate">
                          {{ getdated(data.toDate) }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- Passenger & Class Info -->
                  <div class="row mt-2">
                    <div class="col-7 text-start">
                      <p class="f-size-14 fw-500 m-0">
                        {{ $t("formsContents.options.passengers") }}:
                        <span v-if="data.adult > 0">{{ data.adult }}
                          {{ $t("searchPageContent.itinContent.adt") }}</span>
                        <span class="ms-1" v-if="data.child > 0">{{ data.child }}
                          {{ $t("searchPageContent.itinContent.ch") }}</span>
                        <span class="ms-1" v-if="data.infant > 0">{{ data.infant }}
                          {{ $t("searchPageContent.itinContent.inf") }}</span>
                      </p>
                    </div>
                    <div class="col-5 text-start" v-if="data.class">
                      <span class="f-size-14 fw-500">{{ $t("bookingviewContent.headings.class") }}:
                        {{ data.class }}</span>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-xs-12 col-sm-9 col-md-9 col-lg-12" v-if="travelHistory">
          <div class="tab-content rounded-3 px-2 py-2 bg-white">
              <div class="tab-pane active" id="flights" role="tabpanel">
                <h6 class="m-0 pb-1">{{ 'Recent Search' || $t("formsContents.labels.recentSearch") }}</h6>
                <div v-if="this.RecentSearchData.length > 0 ? true : false" class="searchrecent">
                  <div class="row">
  
                    <div class="col-md-6 mt-2" v-for="data of this.RecentSearchData" :key="data">
                      <div v-if="!data.itinery">
                        <v-card @click="cardassigndata(data)" class="pa-2" style="border-radius:10px;">
                          <div class="row">
                            <div class="col-5 text-start">
                              <p class="f-size-14 fw-500 m-0">
                                {{ data.from }}
                              </p>
                              <p class="f-size-14 fw-500 m-0">
                                {{ getdated(data.fromDate) }}
                              </p>
                            </div>
                            <div class="col-2">
                              <div v-if="data.tripType == 'Roundtrip'">
                                <v-icon>mdi-swap-horizontal</v-icon>
                              </div>
                              <div v-if="data.tripType == 'Oneway'">
                                <v-icon>mdi-arrow-right</v-icon>
                              </div>
                              <div v-if="data.tripType == 'multithree'">
                                      <v-icon>mdi-swap-horizontal</v-icon>
                                      <v-icon>mdi-swap-horizontal</v-icon>
                                      <v-icon>mdi-swap-horizontal</v-icon>
                                    </div>
                            </div>
                            <div class="col-5 text-start">
                              <p class="f-size-14 fw-500 m-0">
                                {{ data.to }}
                              </p>
                              <p class="f-size-14 fw-500 m-0" v-if="data.toDate">
                                {{ getdated(data.toDate) }}
                              </p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-7 text-start">
                              <p class="f-size-14 fw-500 m-0">
                                {{ 'Passenger(s)' || $t("formsContents.options.passengers") }}:
                                <span v-if="data.adult > 0">{{ data.adult }} {{ 'Adult' || $t("searchPageContent.itinContent.adt")
                                  }}</span>
                                <span class="ms-1" v-if="data.child > 0">{{ data.child }} {{
                                  'Child' || $t("searchPageContent.itinContent.ch")
                                  }}</span>
                                <span class="ms-1" v-if="data.inf > 0">{{ data.inf }} {{
                                  'Infant' || $t("searchPageContent.itinContent.inf")
                                  }}</span>
                              </p>
                            </div>
                            <div class="col-5 text-start" v-if="data.class">
                              <span class="f-size-14 fw-500">{{ 'Class' || $t("bookingviewContent.headings.class") }}: {{ data.class
                                }}</span>
                            </div>
                          </div>
                        </v-card>
                      </div>
  
                      <div v-if="data.itinery">
                        <v-card @click="cardassigndata(data)" class="pa-2">
                          <div class="row" v-for="(item, index) of data.itinery" :key="index">
                            <div class="col-5 text-start">
                              <p class="f-size-14 fw-500 m-0">
                                {{ item.from }}
                              </p>
                              <p class="f-size-14 fw-500 m-0">
                                {{ getdated(item.depdate) }}
                              </p>
                            </div>
                            <div class="col-2">
                              <div>
                                <v-icon>mdi-arrow-right</v-icon>
                              </div>
  
                            </div>
                            <div class="col-5 text-start">
                              <p class="f-size-14 fw-500 m-0">
                                {{ item.to }}
                              </p>
                              <p class="f-size-14 fw-500 m-0" v-if="data.toDate">
                                      {{ getdated(data.toDate) }}
                                    </p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-7 text-start">
                              <p class="f-size-14 fw-500 m-0">
                                {{ 'Passenger(s)' || $t("formsContents.options.passengers") }}:
                                <span v-if="data.adult > 0">{{ data.adult }} {{ 'Adult' || $t("searchPageContent.itinContent.adt")
                                  }}</span>
                                <span class="ms-1" v-if="data.child > 0">{{ data.child }} {{
                                  'Child' || $t("searchPageContent.itinContent.ch")
                                  }}</span>
                                <span class="ms-1" v-if="data.infant > 0">{{ data.infant }} {{
                                  'Infant' || $t("searchPageContent.itinContent.inf")
                                  }}</span>
                              </p>
                            </div>
                            <div class="col-5 text-start" v-if="data.class">
                              <span class="f-size-14 fw-500">{{ 'Class' || $t("bookingviewContent.headings.class") }}: {{ data.class
                                }}</span>
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </div>
  
  
                  </div>
                </div>
              </div>
              <div v-if="travelHistory" style="max-height: 132px;overflow-y: scroll;overflow-x: hidden;">
            </div>
        </div> -->
      </div>
    </div>
  </div>
  <!--Search start-->
</template>

<script>
import axios from "axios";
import moment from "moment";

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
import { ref } from "vue";
export default {
  name: "HelloWorld",
  props: {
    formDatas: String,
  },
  data() {
    return {
      formCollapse: true,
      handleform: true,
      // tripType: 'One Way',
      tripTypelist: [
        { value: "Oneway", name: "One Way" },
        { value: "Roundtrip", name: "Round Trip" },
        // {value:"Multicity", name:'Multi City'}
      ],
      Oneway: false,
      roundTrip: true,
      multicity: false,
      activetrip: "Roundtrip",
      isShowmore: false,
      isOptionselect: false,
      isOpentoggler: false,
      dateFormat: "MMM dd",
      sixWeekMode: true,
      multiCalender: true,
      travelHistory: false,
      activeOption: "flight",
      RecentSearchData: [],
      recendFrom: [],
      recendTo: [],
      resultFrom: [],
      resultTo: [],
      selectedDateRange: null,

      // formDatas:{},
      // tripData:{},
      // tripData: {
      //   from: null,
      //   to: null,
      //   dedate: null,
      //   redate: null,
      //   class: "",
      //   adult: "",
      //   child: "",
      //   infant: "",
      //   tripType: "",
      // },

      oneway: {
        from: null,
        to: null,
        travelDate: ref([] || null),
        dedate: null,
        redate: null,
        class: "Economy",
        child: "",
        adult: "",
        infant: "",
        tripType: "",
      },
      multiData: {
        itinery: [
          {
            from: null,
            to: null,
            depdate: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocomfrom: false,
            autocomTo: false,
            selectedFrom: false,
            selectedTo: false,
          },
          {
            from: null,
            to: null,
            depdate: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocomfrom: false,
            autocomTo: false,
            selectedFrom: false,
            selectedTo: false,
          },
        ],
        class: "Economy",
        child: "",
        adult: "",
        infant: "",
        tripType: "",
      },

      adultValue: 1,
      childValue: 0,
      infantValue: 0,
      classType: "Economy",

      Adult: [],
      Child: [],
      Infrant: [],
      concatenatedArray: [],
      addition: 0,

      autocompleteApi: "",
      formres: false,
      formres1: false,
      fromloader: false,
      toloader: false,
      city: [],
      city1: [],
      multidrop: [],
      multidrop1: [],
      typedrop: false,
      typedrop1: false,
      maximumletter: false,
      maximumletter1: false,

      airlineApi: "",
      airportdataApi: "",
      subscribeApi: "",

      directFlight: false,
      baggageInclusive: false,
      nearbyAirport: false,

      pluginWhatspp: false,
      whatsappApi: "",

      mobileResize: false,
    };
  },
  methods: {
    getcitycode(event) {
      return event ? event.match(/\(([^)]+)\)/)?.[1] || event : "";
    },
    handleSearchform() {
      this.formCollapse = !this.formCollapse;
    },
    getdate($event) {
      // return moment($event, "YYYY-MM-DD").format("MMM DD");
      return moment($event).format("YYYY-MM-DD");
    },
    activeType(data) {
      console.log(data, "datadfsfsdfsdata");
      this.activetrip = data;
      this.oneway.tripType = this.activetrip;
      if (typeof this.oneway !== "object" || this.oneway === null) {
        this.oneway = {
          from: null,
          to: null,
          dedate: null,
          redate: null,
          travelDate: null,
          tripType: data,
        };
      }
      // Reset all trip type flags
      this.Oneway = data === "Oneway";
      this.roundTrip = data === "Roundtrip";
      this.multicity = data === "Multicity";

      // if (this.activetrip == "Oneway") {
      //   this.Oneway = true;
      //   (this.roundTrip = false), (this.multicity = false);

      //   this.oneway.redate = this.oneway.redate ? this.oneway.redate : null;

      //   if (this.multiData.itinery.length) {
      //     this.multiData.itinery.map((v, i) => {
      //       if (i == 0) {
      //         this.oneway.from = v.from ? v.from : (this.oneway.from ? this.oneway.from : null);
      //         this.oneway.to = v.to ? v.to : (this.oneway.to ? this.oneway.to : null);
      //         this.oneway.dedate = this.oneway.dedate ? this.oneway.dedate : (v.depdate ? v.depdate : null);
      //       }
      //     });
      //   }
      // }
      if (this.Oneway) {
        // Handle Oneway trip logic
        this.oneway.redate = null; // Clear return date
        this.oneway.travelDate = this.oneway.dedate ? this.oneway.dedate : null;
        this.oneway.travelDate = this.oneway.dedate;
        if (this.multiData.itinery.length) {
          const firstItinerary = this.multiData.itinery[0];
          this.oneway.from = firstItinerary.from || this.oneway.from;
          this.oneway.to = firstItinerary.to || this.oneway.to;
          this.oneway.dedate = this.oneway.dedate || firstItinerary.depdate;
          this.multiData.itinery.map((v, i) => {
            if (i == 0) {
              this.oneway.from = v.from
                ? v.from
                : this.oneway.from
                  ? this.oneway.from
                  : null;
              this.oneway.to = v.to
                ? v.to
                : this.oneway.to
                  ? this.oneway.to
                  : null;
              this.oneway.dedate = this.oneway.dedate
                ? this.oneway.dedate
                : v.depdate
                  ? v.depdate
                  : null;
            }
          });
        }
      }
      // if (data == "Roundtrip") {
      //   this.Oneway = false;
      //   this.roundTrip = true;
      //   this.multicity = false;

      //   if (this.oneway.redate) {
      //     this.oneway.travelDate = [this.oneway.dedate, this.oneway.redate];
      //   }

      //   if (this.multiData.itinery.length) {
      //     this.multiData.itinery.map((v, i) => {
      //       if (i == 0) {
      //         this.oneway.from = v.from ? v.from : (this.oneway.from ? this.oneway.from : null);
      //         this.oneway.to = v.to ? v.to : (this.oneway.to ? this.oneway.to : null);

      //         if (this.oneway.dedate && this.oneway.redate) {
      //           this.oneway.travelDate = [this.oneway.dedate, this.oneway.redate];
      //         }
      //         else if (v.depdate && this.oneway.redate) {
      //           this.oneway.travelDate = [v.depdate, this.oneway.redate];
      //         }
      //         else {
      //           this.oneway.travelDate = null;
      //         }
      //       }
      //     });
      //   }
      // }
      if (this.roundTrip) {
        // Handle Roundtrip logic
        if (this.oneway.dedate && this.oneway.redate) {
          this.oneway.travelDate = [this.oneway.dedate, this.oneway.redate];
        } else {
          this.oneway.travelDate = null;
        }

        if (this.multiData.itinery.length) {
          const firstItinerary = this.multiData.itinery[0];
          this.oneway.from = firstItinerary.from || this.oneway.from;
          this.oneway.to = firstItinerary.to || this.oneway.to;
          this.oneway.travelDate = this.oneway.dedate && this.oneway.redate
            ? [this.oneway.dedate, this.oneway.redate]
            : firstItinerary.depdate && this.oneway.redate
              ? [firstItinerary.depdate, this.oneway.redate]
              : null;
          this.multiData.itinery.map((v, i) => {
            if (i == 0) {
              this.oneway.from = v.from
                ? v.from
                : this.oneway.from
                  ? this.oneway.from
                  : null;
              this.oneway.to = v.to
                ? v.to
                : this.oneway.to
                  ? this.oneway.to
                  : null;

              if (this.oneway.dedate && this.oneway.redate) {
                this.oneway.travelDate = [
                  this.oneway.dedate,
                  this.oneway.redate,
                ];
              } else if (v.depdate && this.oneway.redate) {
                this.oneway.travelDate = [v.depdate, this.oneway.redate];
              } else {
                this.oneway.travelDate = null;
              }
            }
          });
        }
      }

      if (data == "Multicity") {
        this.Oneway = false;
        this.roundTrip = false;
        this.multicity = true;

        if (this.multiData.itinery.length) {
          this.multiData.itinery.map((v, i) => {
            if (i == 0) {
              v.from = v.from
                ? v.from
                : this.oneway.from
                  ? this.oneway.from
                  : null;
              v.to = v.to ? v.to : this.oneway.to ? this.oneway.to : null;
              v.depdate = this.oneway.dedate
                ? this.oneway.dedate
                : v.depdate
                  ? v.depdate
                  : null;
            }
          });
        }
      }
    },

    toggleShowmore() {
      this.isShowmore = !this.isShowmore;
    },
    togglePassenger() {
      this.isOpentoggler = !this.isOpentoggler;
    },
    toggleOption(option) {
      this.activeOption = option;
      if (this.activeOption == "recent") {
        this.travelHistory = true;
      } else {
        this.travelHistory = false;
      }
      // this.isOptionselect = !this.isOptionselect;
    },

    departureLabel() {
      return this.$t("formsContents.labels.departure");
    },
    arrivalLabel() {
      return this.$t("formsContents.labels.arrival");
    },
    selectLabel() {
      return this.$t("formsContents.labels.selectDate");
    },
    handlefrom() {
      this.oneway.from = null;
      this.formres = true;
      this.formres1 = false;
      this.city1 = [];
    },
    handleto() {
      this.oneway.to = null;
      this.formres1 = true;
      this.formres = false;
      this.city = [];
    },

    clickoutDropdown() {
      this.typedrop = false;
      this.typedrop1 = false;
      this.inputCity = null;
      this.inputCityto = null;
    },

    clickoutmultiDropdown() {
      this.multiData.itinery.map((v) => {
        if (v.autocomfrom) {
          v.from = null;
          v.autocomfrom = false;
        }

        if (v.autocomTo) {
          v.to = null;
          v.autocomTo = false;
        }
      });
    },

    // Recent Search Start
    cardassigndata($event) {
      console.log($event, "$event4444");
      this.resentSearch = false;
      this.airbooking = true;
      this.inputCity = $event.from;
      this.oneway.from = $event.from;
      this.inputCityto = $event.to;
      this.oneway.to = $event.to;
      this.adultValue = $event.adult;
      this.childValue = $event.child;
      this.infantValue = $event.inf;
      this.classType = $event.class;
      this.oneway.dedate = $event.fromDate;
      this.oneway.redate = $event.toDate;
      this.oneway.tripType = $event.tripType;
      if (this.oneway.redate) {
        this.oneway.travelDate = [this.oneway.dedate, this.oneway.redate];
      }

      this.activeType($event.tripType);
      // this.updateRecentSearch($event)

      if ($event.itinery) {
        console.log($event, "$event1111111");
        this.adultValue = $event.adult;
        this.childValue = $event.child;
        this.infantValue = $event.infant;

        this.multiData = {
          adult: $event.adult,
          child: $event.child,
          infant: $event.infant,
          class: $event.class,
          tripType: $event.tripType,
          itinery: $event.itinery.map((itin) => ({
            ...itin,
            depdate: itin.depdate,
            selectedFrom: true,
            selectedTo: true,
          })),
        };

        this.handleMultiSearch(this.multiData);
      } else {
        this.handleRecentSearch($event);
      }
      this.passengerData();
      this.travelHistory = false;
    },

    recentFromData() {
      let result1 = JSON.parse(localStorage.getItem("recentData")) || [];
      let today = new Date();

      // console.log(result1,'asdasdRecentSearchData...1');

      const filteredResults = result1.filter((item) => {
        if (item.itinery && item.itinery.length > 0) {
          for (let itineraryItem of item.itinery) {
            let depDate = new Date(itineraryItem.depdate);
            if (depDate < today) {
              return false;
            }
          }
        } else {
          let fromDate = new Date(item.fromDate);
          if (fromDate < today) {
            return false;
          }
        }
        return true;
      });

      localStorage.setItem("recentData", JSON.stringify(filteredResults));

      this.RecentSearchData = filteredResults.slice(0, 5);

      this.RecentSearchData.map((v) => {
        if (!v.toDate && v.tripType == "Roundtrip") {
          v.tripType = "Oneway";
          return v.tripType;
        }
      });
      // console.log(this.RecentSearchData,'asdasdRecentSearchData...2');
    },

    handleRecentSearch(data) {
      let recentSearchObj;

      if (data) {
        recentSearchObj = {
          from: data.from || "",
          to: data.to || "",
          fromDate: data.fromDate || "",
          toDate: data.toDate || null,
          adult: data.adult || 0,
          child: data.child || 0,
          inf: data.inf || 0,
          tripType: data.tripType || "",
          class: data.class || "",
        };
        console.log("Using provided data:", recentSearchObj);
      } else {
        recentSearchObj = {
          from: this.oneway.from || "",
          to: this.oneway.to || "",
          fromDate: this.oneway.dedate || "",
          toDate: this.oneway.redate || null,
          adult: this.adultValue || 0,
          child: this.childValue || 0,
          inf: this.infantValue || 0,
          tripType: this.activetrip || "",
          class: this.classType || "",
        };
        console.log("Using current state values:", recentSearchObj);
      }

      let storedRecentFrom =
        JSON.parse(localStorage.getItem("recentData")) || [];
      console.log("Stored recent searches:", storedRecentFrom);

      storedRecentFrom = storedRecentFrom.filter((search) => {
        const searchFromDate = new Date(search.fromDate).toDateString();
        const searchToDate = new Date(search.toDate).toDateString();
        const recentFromDate = new Date(
          recentSearchObj.fromDate
        ).toDateString();
        const recentToDate = new Date(recentSearchObj.toDate).toDateString();

        return !(
          search.from === recentSearchObj.from &&
          search.to === recentSearchObj.to &&
          searchFromDate === recentFromDate &&
          searchToDate === recentToDate &&
          search.adult === recentSearchObj.adult &&
          search.child === recentSearchObj.child &&
          search.inf === recentSearchObj.inf &&
          search.tripType === recentSearchObj.tripType &&
          search.class === recentSearchObj.class
        );
      });

      storedRecentFrom.unshift(recentSearchObj);
      console.log("Updated search list:", storedRecentFrom);

      // localStorage.setItem("recentcityData", JSON.stringify(storedRecentFrom));
      return storedRecentFrom;
    },

    handleMultiSearch(newMultiData) {
      let storedRecentFrom =
        JSON.parse(localStorage.getItem("recentData")) || [];

      storedRecentFrom = storedRecentFrom.filter((existingItem) => {
        const existingItinery = existingItem.itinery || [];

        const isDuplicate = newMultiData.itinery.every((newItin) => {
          const result = existingItinery.some((existingItin) => {
            const isMatch =
              newItin.from === existingItin.from &&
              newItin.to === existingItin.to &&
              newItin.depdate === existingItin.depdate;
            return isMatch;
          });
          return result;
        });

        const isAdditionalPropsMatch =
          existingItem.adult === newMultiData.adult &&
          existingItem.child === newMultiData.child &&
          existingItem.infant === newMultiData.infant &&
          existingItem.class === newMultiData.class &&
          existingItem.tripType === newMultiData.tripType;
        // console.log(existingItem,'========',newMultiData);
        // console.log(isDuplicate,'asdasdasdisDuplicate',isAdditionalPropsMatch);
        return !(isDuplicate && isAdditionalPropsMatch);
      });

      storedRecentFrom.unshift(newMultiData);

      // localStorage.setItem("recentcityData", JSON.stringify(storedRecentFrom));
      return storedRecentFrom;
    },

    // Recent search data for each input field
    updateRecentSearch(obj) {
      let recentFromData =
        JSON.parse(localStorage.getItem("recentcityData")) || [];
      let newRecentData = [...recentFromData];
      console.log(newRecentData, "sadasdasdrecentFromData...1");
      console.log(obj, "sadasdasdrecentFromData...2");

      if (obj) {
        const isFromDuplicate = newRecentData.some(
          (item) => item.from === obj.from
        );
        const isToDuplicate = newRecentData.some((item) => item.to === obj.to);

        if (!isFromDuplicate || !isToDuplicate) {
          newRecentData.unshift(obj);
        }
        newRecentData = newRecentData.slice(0, 4);
      } else {
        // Limit the array to 15 entries
        newRecentData = newRecentData.slice(0, 4);
      }

      // Save updated data to localStorage
      localStorage.setItem("recentcityData", JSON.stringify(newRecentData));

      // Update the component's data properties for display
      this.resultFrom = newRecentData.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.from === item.from)
      );
      this.resultTo = newRecentData.filter(
        (item, index, self) => index === self.findIndex((t) => t.to === item.to)
      );

      console.log(this.resultFrom, "saddresultFromasdasdresultFrom...2");
    },

    getdated($event) {
      console.log($event, "eventeventevent");
      return moment($event).format("MMM DD, YYYY");
    },

    getrecent(data) {
      console.log(data, "pppp");
      this.formres = false;
      this.oneway.from = data.from;
      this.oneway.from = data.from;
      this.fromRecent = data.from;
      // this.oneway.from= $data[$data.length - 1].replace(")", '')
    },

    getrecent1(data) {
      this.formres1 = false;
      this.oneway.to = data.to;
      this.oneway.to = data.to;
      this.toRecent = data.to;
      //
    },

    // Recent search data for each input field End

    onClickOutside() {
      this.formres = false;
      this.formres1 = false;
    },
    // Recent Search End

    async typeCity(event, $data) {
      console.log(event, $data, "searchsearchsearchsearch");
      if (!event || !event.length) return;

      if (event.length >= 1) {
        this.formres = false;
        if (event.length >= 1) {
          this.formres1 = false;
        }
      }
      this.city = [];
      this.city1 = [];
      // let $event = [];
      if ($data == 1 && event.length > 2) {
        this.fromloader = true;
      } else {
        this.fromloader = false;
      }

      if ($data == 2 && event.length > 2) {
        this.toloader = true;
      } else {
        this.toloader = false;
      }

      if (event.length >= 3) {
        await axios
          .get(`${this.autocompleteApi}?airportString=${event}`, {
            headers: {
              "Content-Type": this.airlineIcaoType,
            },
          })
          .then((response) => {
            // console.log(response, response.data, "responseyyyyyyy");

            if ($data == 1) {
              this.fromloader = false;
              this.city = response.data;
              this.typedrop = true;
              console.log(this.typedrop, "responseyyyyyyy1");
            } else if ($data == 2) {
              this.toloader = false;
              this.city1 = response.data;
              this.typedrop1 = true;
              console.log(this.typedrop1, "responseyyyyyyy2");
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
      }
    },
    async typeMultiCity(event, index, $num) {
      console.log(event, index, $num, "searchsearchsearchsearch");
      // let $event = [];
      // console.log(event, index, "searchsearchsearchsearch");

      if (event.length >= 3) {
        if ($num == 1 && event.length > 2) {
          this.multiData.itinery.map((v, i) => {
            v.autocomTo = false;
            v.fromloader = true;
            if (i == index && event.length > 2) {
              v.autocomfrom = true;
            } else {
              v.autocomfrom = false;
              v.fromloader = false;
              this.multidrop = [];
            }
          });
        } else if ($num == 2 && event.length > 0) {
          this.multiData.itinery.map((v, i) => {
            v.autocomfrom = false;
            v.toloader = true;
            if (i == index && event.length > 2) {
              v.autocomTo = true;
              v.toloader = true;
            } else {
              v.autocomTo = false;
              v.toloader = false;
              this.multidrop1 = [];
            }
          });
        }

        await axios
          .get(`${this.autocompleteApi}?airportString=${event}`, {
            headers: {
              "Content-Type": this.airlineIcaoType,
            },
          })
          .then((response) => {
            console.log(response.data, "responsedata");

            if ($num == 1) {
              this.multiData.itinery.map((v) => {
                v.fromloader = false;
              });

              this.multidrop = response.data;
              console.log(this.multidrop, "this.multidrop");
            } else if ($num == 2) {
              this.multiData.itinery.map((v) => {
                v.toloader = false;
              });

              this.multidrop1 = response.data;
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
      }
    },

    // handleReturnchange(){
    //   if(this.Oneway){
    //     this.Oneway = false;
    //     this.activetrip == 'Roundtrip';
    //     this.roundTrip = true;
    //     console.log(this.activetrip,this.Oneway,this.roundTrip,'sdadasdafsdfdf');
    //   }
    // },
    datachange(data) {
      this.oneway.from = `${data.city} (${data.iata})`;
      this.inputCity = this.oneway.from;
      this.typedrop = false;
      if (!this.oneway.from) {
        this.$refs.firstAutoComplete.focus();
      }
      if (!this.oneway.to) {
        setTimeout(() => {
          this.$refs.secondAutoComplete.focus();
          this.focusEvent2 = true;
        }, 100);
      } else {
        this.focusEvent2 = false;
      }
    },
    datachangeTo(data) {
      this.oneway.to = `${data.city} (${data.iata})`;
      this.inputCityto = this.oneway.to;
      this.typedrop1 = false;
      this.selectedFrom = true;

      if (!this.oneway.dedate) {
        setTimeout(() => {
          this.$refs.dpRef1.openMenu();
          this.focusEvent2 = true;
        }, 100);
      } else {
        this.focusEvent2 = false;
        setTimeout(() => {
          this.$refs.secondAutoComplete.blur();
        }, 100);
      }
    },

    datachangeMuti(data, index) {
      console.log(data, index, "data,index1");
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          v.from = `${data.city} (${data.iata})`;
          v.autocomfrom = false;
          v.selectedFrom = true;
          v.multiFromError = false;
          if (!v.to) {
            setTimeout(() => {
              const inputElement = this.$refs.multiToAutocomplete[index];
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = true;
              if (inputElement) {
                inputElement.focus();
              }
            }, 10);
          } else {
            setTimeout(() => {
              const inputElement = this.$refs.multiFromAutocomplete[index];
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = false;
              if (inputElement) {
                inputElement.blur();
              }
            }, 10);
          }
        }
      });
    },
    datachangeMutiTo(data, index) {
      console.log(data, index, "data,index1");

      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          v.to = `${data.city} (${data.iata})`;
          v.autocomTo = false;
          v.selectedTo = true;
          v.multiToError = false;
          if (this.multiData.itinery.length - 1 != index) {
            this.multiData.itinery[i + 1].from = this.multiData.itinery[i].to;
            this.multiData.itinery[i + 1].selectedFrom = true;
            this.multiData.itinery[i + 1].multiFromError = false;
          }

          if (!v.depdate) {
            setTimeout(() => {
              const inputElement = this.$refs.dpRef4[index];
              if (inputElement) {
                inputElement.openMenu();
              }
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = true;
            }, 10);
          } else {
            setTimeout(() => {
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = false;

              const inputElement = this.$refs.multiFromAutocomplete[index];
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = false;
              if (inputElement) {
                inputElement.blur();
              }
            }, 10);
          }
        }
      });
    },

    // fromDateChanged(newValue) {
    //   this.oneway.dedate = newValue;

    //   this.oneway.dedate = new Date(this.oneway.dedate);
    //   this.oneway.redate = new Date(this.oneway.redate);

    //   if (this.oneway.dedate) {
    //     if (this.oneway.redate && this.oneway.redate < this.oneway.dedate) {
    //       this.oneway.redate = null;
    //     }

    //     this.clicked1 = true;
    //     this.fromDateError = false;

    //     this.$refs.dpRef2.openMenu();
    //     // this.focusedElement = "calendar2";
    //   } else {
    //     this.clicked1 = false;
    //     this.fromDateError = true;

    //     this.$refs.dpRef1.openMenu();
    //   }
    //   if (this.rount && !this.oneway.redate) {
    //     this.$refs.dpRef2.openMenu();
    //   } else {
    //     this.$refs.dpRef1.openMenu();
    //   }
    // },

    // toDateChanged(newValue) {
    //   this.oneway.redate = newValue;
    //   // this.$refs.dpRef2.openMenu();
    //   // this.focusedElement = "calendar2";

    //   if (this.oneway.redate) {
    //     this.clicked1 = true;
    //     this.toDateError = false;
    //   } else {
    //     this.clicked1 = false;
    //     this.toDateError = true;
    //     this.$refs.dpRef2.openMenu();
    //   }
    // },

    // multiDateChanged(newValue, index) {
    //   console.log(newValue, "asdasdasdnewValue", index + 1);

    //   this.multiData.itinery.map((v, i) => {
    //     if (index == i) {
    //       if (v.depdate) {
    //         const inputElement = this.$refs.multiToAutocomplete[index + 1];
    //         console.log(inputElement, "sadasdasd");
    //         if (inputElement) {
    //           inputElement.focus();
    //         }
    //       } else {
    //         const inputElement = this.$refs.dpRef4[index];
    //         if (inputElement) {
    //           inputElement.openMenu();
    //         }
    //       }
    //     }
    //   });

    //   this.multiData.itinery[index].depdate = newValue;
    //   if (newValue) {
    //     this.multiData.itinery[index].multiDateError = false;
    //     this.multiData.itinery[index].focusMultiEvent3 = false;
    //   } else {
    //     this.multiData.itinery[index].multiDateError = true;
    //     this.multiData.itinery[index].focusMultiEvent3 = true;
    //   }

    //   console.log(
    //     this.multiData.itinery[index],
    //     `Updated itinerary at index ${index}`
    //   );

    //   this.multiData.itinery.forEach((v, i) => {
    //     if (index < i) {
    //       if (newValue > new Date(v.depdate)) {
    //         v.depdate = null;
    //       }
    //     }
    //   });
    // },

    fromDateChanged(newValue) {
      if (newValue) {
        const localDate = new Date(newValue);
        this.oneway.dedate = new Date(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()); // Reset time
      } else {
        this.oneway.dedate = null;
      }

      if (this.oneway.redate && this.oneway.redate < this.oneway.dedate) {
        this.oneway.redate = null;
      }

      this.clicked1 = !!this.oneway.dedate;
      this.fromDateError = !this.oneway.dedate;

      this.$refs.dpRef2?.openMenu();
    },

    toDateChanged(newValue) {
      if (newValue) {
        const localDate = new Date(newValue);
        this.oneway.redate = new Date(localDate.getFullYear(), localDate.getMonth(), localDate.getDate());
      } else {
        this.oneway.redate = null;
      }

      this.clicked1 = !!this.oneway.redate;
      this.toDateError = !this.oneway.redate;
    },

    multiDateChanged(newValue, index) {
      console.log(newValue, "Updated Multi Date", index + 1);

      if (!this.multiData.itinery[index]) return;

      this.multiData.itinery[index].depdate = newValue ? new Date(newValue) : null;
      this.multiData.itinery[index].multiDateError = !this.multiData.itinery[index].depdate;
      this.multiData.itinery[index].focusMultiEvent3 = !this.multiData.itinery[index].depdate;

      if (this.multiData.itinery[index].depdate) {
        const nextInput = this.$refs.multiToAutocomplete?.[index + 1];
        if (nextInput) nextInput.focus();
      } else {
        const nextDatePicker = this.$refs.dpRef4?.[index];
        if (nextDatePicker) nextDatePicker.openMenu();
      }

      // ✅ Ensure later dates are after the current selection
      this.multiData.itinery.forEach((v, i) => {
        if (index < i && this.multiData.itinery[index].depdate > new Date(v.depdate)) {
          v.depdate = null;
        }
      });

      console.log(this.multiData.itinery[index], `Updated itinerary at index ${index}`);
    },


    getdate1($data, index) {
      if (index > 0) {
        return $data[index - 1].depdate;
      } else {
        return `${new Date()}`;
      }
    },

    clearDate(tripdate, type) {
      console.log(tripdate, "tripdatetripdate");
      if (type === "dep") {
        this.oneway.dedate = null;
        this.oneway.travelDate = null;
        this.focusEvent3 = false;
      } else {
        this.oneway.redate = null;
      }
    },

    ClickOutDepat() {
      this.$refs.dpRef1.closeMenu();
      // console.log(v.clicked3,"checking....")

      if (this.oneway.dedate) {
        this.clicked1 = true;
        // this.fromlabel=true
      } else {
        this.clicked1 = false;
        // this.fromlabel=false;
      }
    },
    ClickOutDepatMulti() {
      // console.log(this.$refs.dpRef4,'depRef4depRef4')

      this.multiData.itinery.forEach((v, i) => {
        this.$refs.dpRef4[i].closeMenu();
        if (v.departDate) {
          v.clicked3 = true;
        } else {
          v.clicked3 = false;
        }
      });
    },

    // toDateChanged(newValue) {
    //   this.oneway.redate = newValue;

    //this.$refs.countrySelect.focus();

    // let inputField = this.$refs.countrySelect
    // if(inputField){
    //   inputField.menu=true
    // }
    // this.$refs.countrySelect.openMenu();
    // console.log(this.$refs.countrySelect,'this.$refs.countrySelectthis.$refs.countrySelect')

    // if (this.oneway.redate) {
    //   this.clicked2 = true;
    //   this.toDateError = false;
    //   this.focusedElement = null
    // }
    // else {
    //   this.clicked2 = false;
    //   this.toDateError = true;
    //   this.$refs.dpRef2.openMenu();
    // }
    // },

    ClickOutReturn() {
      this.$refs.dpRef2.closeMenu();

      // this.$refs.mySelect.focus();

      if (this.oneway.redate) {
        this.clicked2 = true;
      } else {
        this.clicked2 = false;
      }
    },
    // RoundTrip datePicker End

    // Passengers and Class

    addTrip() {
      if (this.multiData.itinery.length < 4) {
        this.multiData.itinery.push({
          from: null,
          to: null,
          depdate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          multiSearchBtn: true,
          focusMultiEvent1: false,
          focusMultiEvent2: false,
          focusMultiEvent3: false,
          autocomfrom: false,
          autocomTo: false,
          fromloader: false,
          toloader: false,
        });

        if (this.multiData.itinery.length == 4) {
          this.addmore = false;
        }
      }
      if (this.multiData.itinery.length > 2) {
        this.clearall = true;
      }

      for (let i = 1; i < this.multiData.itinery.length; i++) {
        this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
      }
    },
    clearall1() {
      this.multiData.itinery.splice(2, 5);
      (this.multiData.itinery = [
        {
          from: null,
          to: null,
          depdate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter: false,
          maximumletter1: false,
          clicked3: false,
          autocomfrom: false,
          autocomTo: false,
          fromloader: false,
          toloader: false,
        },
        {
          from: null,
          to: null,
          depdate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter1: false,
          clicked3: false,
          autocomfrom: false,
          autocomTo: false,
          fromloader: false,
          toloader: false,
        },
      ]),
        (this.clearall = false);
      this.addmore = true;
    },
    delet(index) {
      this.multiData.itinery.splice(index, 1);
    },

    removemulti(index) {
      this.multiData.itinery.splice(index, 1);
    },

    adulted() {
      this.Adult.push({
        PassengerID: "T",
        PTC: "ADT",
      });
      //console.log(this.Adult, 'add')
    },

    adulted1() {
      this.Adult.pop();
      //console.log(this.Adult, 'remove')
    },

    Childed() {
      this.Child.push({
        PassengerID: "T",
        PTC: "CHD",
      });
    },
    Childed1() {
      this.Child.pop();
    },
    Infranted() {
      this.Infrant.push({
        PassengerID: "T",
        PTC: "INF",
      });
      this.passengerData();
    },
    Infranted1() {
      this.Infrant.pop();
    },

    deg1() {
      this.adulted1();
      if (this.adultValue == this.infantValue && this.infantValue > 1) {
        this.deg3();
      }
      if (this.adultValue > 1) {
        this.adultValue = this.adultValue - 1;

        this.addedPassenger();
      }
      // //console.log( this.Adult,'concatenatedArray')
    },
    add1() {
      if (this.adultValue < 9 && this.addition < 9) {
        this.adultValue += 1;
        //console.log(this.adultValue, '1111')

        this.adulted();
        this.addedPassenger();
      }

      // //console.log( this.Adult,'concatenatedArray')
    },

    deg2() {
      if (this.childValue > 0) {
        this.childValue = this.childValue - 1;

        this.Childed1();
        this.addedPassenger();
      }
      // //console.log( this.Child,'concatenatedArray')
    },
    add2() {
      if (this.childValue < 8 && this.addition < 9) {
        this.childValue = this.childValue + 1;
        //console.log(this.childValue, '2222')

        this.Childed();
        this.addedPassenger();
      }
      // //console.log( this.Child,'concatenatedArray')
    },
    deg3() {
      if (this.infantValue > 0) {
        this.infantValue = this.infantValue - 1;

        this.Infranted1();
        this.addedPassenger();
      }
    },
    add3() {
      if (this.addition < 9 && this.adultValue > this.infantValue) {
        this.infantValue = this.infantValue + 1;
        //console.log(this.infantValue, '3333')

        this.Infranted();
        this.addedPassenger();
      }
    },

    addedPassenger() {
      this.addition = this.adultValue + this.childValue + this.infantValue;
      this.concatenatedArray = this.Adult.concat(this.Child).concat(
        this.Infrant
      );
      this.passengerData();
      //console.log(this.concatenatedArray, 'concatenatedArray')
    },

    passengerData() {
      this.totalPassenger =
        parseInt(this.adultValue) +
        parseInt(this.childValue) +
        parseInt(this.infantValue);
    },
    // Passengers and Class End

    resetOptions() {
      this.getallAdvanceOptions = [];
      this.directFlight = false;
      this.baggageInclusive = false;
      this.nearbyAirport = false;
      this.airlineSelect = "includeAirline";
      this.stopsSelect = "anyStop";
      this.adlayoverTime = "noPreference";
      this.adviaAirport = "includeAirport";
      this.selectedAirline = [];
      this.selectedAirport = [];
      this.avoidCountry = [];

      this.airlineVal = "";
      this.airportVal = "";
      this.airlineList = [];
      this.airportList = [];
    },

    dataAssign() {
      let onewayObj = {};
      // if(this.directFlight) onewayObj.directFlight = this.directFlight;
      if (this.baggageInclusive)
        onewayObj.baggageInclusive = this.baggageInclusive;
      if (this.nearbyAirport) onewayObj.nearbyAirport = this.nearbyAirport;
      if (this.avoidCountry && this.avoidCountry.length > 0)
        onewayObj.avoidCountry = this.avoidCountry;
      if (this.adlayoverTime && this.adlayoverTime !== "noPreference")
        onewayObj.adlayoverTime = this.adlayoverTime;
      this.airlineSelect == "includeAirline" && this.selectedAirline.length > 0
        ? (onewayObj.includeAirline = this.selectedAirline)
        : [];
      this.airlineSelect == "excludeAirline" && this.selectedAirline.length > 0
        ? (onewayObj.excludeAirline = this.selectedAirline)
        : [];
      this.adviaAirport == "includeAirport" && this.selectedAirport.length > 0
        ? (onewayObj.includeAirport = this.selectedAirport)
        : [];
      this.adviaAirport == "excludeAirport" && this.selectedAirport.length > 0
        ? (onewayObj.excludeAirport = this.selectedAirport)
        : [];

      console.log(this.stopsSelect, "sdstopsSsadsdelectasd...1");
      if (
        this.stopsSelect.length > 0 &&
        (this.stopsSelect !== "anyStop" ||
          !this.stopsSelect.includes("anyStop"))
      ) {
        console.log(this.stopsSelect, "sdstopsSsadsdelectasd...2");
        if (
          this.directFlight ||
          this.stopsSelect == 0 ||
          this.stopsSelect == "0"
        ) {
          this.stopsSelect = "0";
          onewayObj.stopsSelect = this.stopsSelect;
        } else {
          onewayObj.stopsSelect = this.stopsSelect;
        }
      }

      this.roundTrip || this.Oneway
        ? (this.oneway.advanceOptions = onewayObj)
        : (this.multiData.advanceOptions = onewayObj);
      console.log(this.oneway, "onewayonrounttripewayoneway...2");
      console.log(this.multiData, "multsiDataonrounttripewaymultisData...2");
    },
    getadvanceOption() {
      // console.log(this.searchCase,'sadsdasdasdsearchCasea...1');
      this.localSearchCase = JSON.parse(this.searchCase);

      if (this.localSearchCase && this.localSearchCase.advanceOptions) {
        let getSearchedData = this.localSearchCase.advanceOptions;

        this.baggageInclusive = getSearchedData.baggageInclusive ? true : false;
        this.nearbyAirport = getSearchedData.nearbyAirport ? true : false;
        this.stopsSelect = getSearchedData.stopsSelect
          ? getSearchedData.stopsSelect
          : "anyStop";
        this.directFlight = getSearchedData.stopsSelect
          ? getSearchedData.stopsSelect == "0"
          : false;
        this.adlayoverTime = getSearchedData.adlayoverTime
          ? getSearchedData.adlayoverTime
          : "noPreference";
        this.avoidCountry = getSearchedData.avoidCountry
          ? [getSearchedData.avoidCountry]
          : [];
        this.airlineSelect = getSearchedData.includeAirline
          ? "includeAirline"
          : getSearchedData.excludeAirline
            ? "excludeAirline"
            : "includeAirline";
        this.adviaAirport = getSearchedData.includeAirport
          ? "includeAirport"
          : getSearchedData.excludeAirport
            ? "excludeAirport"
            : "includeAirport";
        this.selectedAirline = getSearchedData.includeAirline
          ? getSearchedData.includeAirline
          : getSearchedData.excludeAirline
            ? getSearchedData.excludeAirline
            : [];
        this.selectedAirport = getSearchedData.includeAirport
          ? getSearchedData.includeAirport
          : getSearchedData.excludeAirport
            ? getSearchedData.excludeAirport
            : [];
      }
    },
    activeAdvanceSearch() {
      const hasAvoidCountry =
        Array.isArray(this.avoidCountry) && this.avoidCountry.length > 0;
      const hasSelectedAirline =
        Array.isArray(this.selectedAirline) && this.selectedAirline.length > 0;
      const hasSelectedAirport =
        Array.isArray(this.selectedAirport) && this.selectedAirport.length > 0;

      // Ensure stopsSelect is treated correctly based on its type
      const isStopValid =
        (Array.isArray(this.stopsSelect) &&
          this.stopsSelect.length > 0 &&
          this.stopsSelect[0] !== "anyStop") ||
        (this.stopsSelect !== "anyStop" && this.stopsSelect);

      const hasAdlayoverTime =
        this.adlayoverTime && this.adlayoverTime !== "noPreference";

      console.log(
        hasAvoidCountry,
        hasSelectedAirline,
        hasSelectedAirport,
        isStopValid,
        hasAdlayoverTime,
        "sadsdasdasdsearchCasea...12"
      );

      if (
        this.directFlight ||
        this.baggageInclusive ||
        this.nearbyAirport ||
        hasAvoidCountry ||
        isStopValid ||
        hasAdlayoverTime ||
        hasSelectedAirline ||
        hasSelectedAirport
      ) {
        console.log(
          this.directFlight,
          this.baggageInclusive,
          this.nearbyAirport,
          hasAvoidCountry,
          isStopValid,
          hasAdlayoverTime,
          hasSelectedAirline,
          hasSelectedAirport,
          "sadsdasdasdsearchCasea...2"
        );
        return true;
      } else {
        console.log(
          this.directFlight,
          this.baggageInclusive,
          this.nearbyAirport,
          hasAvoidCountry,
          isStopValid,
          hasAdlayoverTime,
          hasSelectedAirline,
          hasSelectedAirport,
          "sadsdasdasdsearchCasea...3"
        );
        delete this.oneway.advanceOptions;
        delete this.multiData.advanceOptions;
        return false;
      }
    },

    handleOptionChange(optionKey, newValue) {
      const removableValues = [
        "includeAirline",
        "excludeAirline",
        "includeAirport",
        "excludeAirport",
        "anyStop",
        "0",
        "noPreference",
      ];

      const isRemovable =
        !newValue || (Array.isArray(newValue) && newValue.length === 0);
      const isSpecialOption = removableValues.includes(newValue);

      if (isRemovable || isSpecialOption) {
        this.getallAdvanceOptions = this.getallAdvanceOptions.filter(
          (option) => !Object.prototype.hasOwnProperty.call(option, optionKey)
        );
        return;
      }

      const existingIndex = this.getallAdvanceOptions.findIndex((option) =>
        Object.prototype.hasOwnProperty.call(option, optionKey)
      );

      if (newValue && newValue.length !== 0) {
        if (existingIndex !== -1) {
          this.getallAdvanceOptions[existingIndex][optionKey] = newValue;
        } else {
          this.getallAdvanceOptions.push({ [optionKey]: newValue });
        }
      } else {
        if (existingIndex !== -1) {
          this.getallAdvanceOptions.splice(existingIndex, 1);
        }
      }
      console.log(
        this.getallAdvanceOptions,
        "getallAsadvancsadaeOptionssdasdasda"
      );
    },

    handleResize() {
      this.mobileResize = window.innerWidth <= 992;
      // if (window.innerWidth <= 480) {
      //   this.mobileResize = false;
      // } else {
      //   this.mobileResize = true;
      // }
    },

    sended() {
      // if(this.activeAdvanceSearch) this.dataAssign();

      // localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");
      console.log(this.classType, "classTypeclassTypelass");
      console.log(this.oneway, "oneway.classoneway.class");

      if (
        this.oneway.from &&
        this.oneway.to &&
        this.oneway.dedate &&
        this.oneway.from !== this.oneway.to
      ) {
        setTimeout(() => {
          // this.$refs.dpRef1.closeMenu();
          this.focusEvent3 = false;
          // console.log(this.$refs.dpRef1, 'fdfdfdfdfdf...1')
        }, 5);

        // this.recendSetData();
        this.oneway.adult = this.adultValue;
        this.oneway.infant = this.infantValue;
        this.oneway.child = this.childValue;
        this.oneway.class = this.classType;

        // console.log(this.oneway,'saddresultFromasdasdresultFrom...1');
        let obj = {
          from: this.oneway.from || this.fromRecent,
          to: this.oneway.to || this.toRecent,
        };
        this.updateRecentSearch(obj);

        const tabId = sessionStorage.getItem("tabId") || Date.now().toString();
        sessionStorage.setItem("tabId", tabId);

        const result = JSON.stringify(this.oneway);

        console.log(this.oneway.redate, "sdasdaresult1");

        Object.keys(localStorage).forEach((key) => {
          if (
            key.startsWith("searchedData-round-") &&
            key !== `searchedData-round-${tabId}`
          ) {
            localStorage.removeItem(key);
          }
        });

        if (this.oneway.redate && this.activetrip == "Roundtrip") {
          localStorage.setItem(`searchedData-round-${tabId}`, result);
        } else {
          this.oneway.redate = null;
          if (!this.oneway.redate) {
            Object.keys(localStorage).forEach((key) => {
              if (
                key.startsWith("searchedData-oneway-") &&
                key !== `searchedData-oneway-${tabId}`
              ) {
                localStorage.removeItem(key);
              }
            });
            localStorage.setItem(`searchedData-oneway-${tabId}`, result);
          }
        }

        this.searchCase = this.oneway;
        console.log(this.searchCase, "ssdasdseasaaSrchCase...5");

        let getfrom = "";
        if (this.oneway.from.name) {
          getfrom = this.oneway.from.name.split(" ");
          this.roundfrom = getfrom[getfrom.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          getfrom = this.oneway.from.split(" ");
          this.roundfrom = getfrom[getfrom.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let getto = "";
        if (this.oneway.to.name) {
          getto = this.oneway.to.name.split(" ");
          this.roundto = getto[getto.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          getto = this.oneway.to.split(" ");
          this.roundto = getto[getto.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        this.urldedate =
          (this.oneway.dedate
            ? moment(this.oneway.dedate).format("YYYY/MM/DD")
            : null) || null;
        this.urlredate =
          (this.oneway.redate
            ? moment(this.oneway.redate).format("YYYY/MM/DD")
            : null) || null;

        console.log(this.oneway, "sadasdadasdasdasd...1");

        // Router format
        let getUrlFormat = "";
        let getTriptype = "";
        if (this.activetrip == "Roundtrip" && this.urlredate) {
          console.log(this.urlredate, "urlredateurlredateurlredate");
          getUrlFormat = `${this.roundfrom}-${this.roundto}-${this.urldedate}_${this.roundto}-${this.roundfrom}-${this.urlredate}`;
          console.log(this.activetrip, "sadasdadasdasdasd...2", this.urlredate);
          getTriptype = "RT";
        } else if (
          (this.activetrip == "Roundtrip" || this.activetrip == "Oneway") &&
          !this.urlredate
        ) {
          console.log(this.activetrip, "sadasdadasdasdasd...3", this.urlredate);
          getUrlFormat = `${this.roundfrom}-${this.roundto}-${this.urldedate}`;
          getTriptype = "OW";
        }

        let [Adt, Chd, Inf] = [
          this.oneway.adult,
          this.oneway.child,
          this.oneway.infant,
        ];
        let getPaxtype = `A-${Adt}_C-${Chd}_I-${Inf}`;

        console.log(getPaxtype, "asdsfasdasdaeeqq");

        // let getLangual = this.$i18n.locale.split('-')[1];

        // console.log(getLangual,'dsdassagetLangualgetLangual')

        console.log(this.classType, "classTypeclassType");

        let getClassType;

        if (this.classType == "Economy") {
          getClassType = "Economy";
        }
        if (this.classType == "Business") {
          getClassType = "Business";
        }
        if (this.classType == "First Class") {
          getClassType = "FirstClass";
        }
        if (this.classType == "Premium Economy") {
          getClassType = "PremiumEconomy";
        }
        if (this.classType == "Premium Business") {
          getClassType = "PremiumBusiness";
        }
        if (this.classType == "Premium First") {
          getClassType = "PremiumFirstClass";
        }

        let rountdetail = {
          itinerary: getUrlFormat,
          tripType: getTriptype,
          paxType: getPaxtype,
          cabin: getClassType,
          // language: getLangual,
        };

        console.log(rountdetail, "rountdetailrountdetail");
        console.log(
          this.roundfrom,
          "roundfromroundfromroundfrom",
          this.roundto
        );

        const resultData = this.handleRecentSearch();

        localStorage.setItem("recentData", JSON.stringify(resultData));

        if (this.$route.path == "/flight/search") {
          setTimeout(() => {
            location.reload();
          }, 500);
        }

        this.$router.push({ path: "/flight/search", query: rountdetail });
        // Router format End
      } else {
        this.isNotActive = true;
        // this.alert = true;
        setTimeout(() => {
          this.isNotActive = false;
          // this.alert = true;
        }, 2000);
        // alert("Please enter your details");
        this.validateOnewayForm();
      }
    },

    sendedMulticity() {
      console.log(this.activeAdvanceSearch, "sdasdactiveAdvanceSearch...2");
      // if (this.activeAdvanceSearch) this.dataAssign();

      console.log(this.multiData, "multiDataonrounttripewaymultiData...1");
      if (
        this.multiData.itinery[0].from &&
        this.multiData.itinery[0].to &&
        this.multiData.itinery[0].depdate &&
        this.multiData.itinery[1].from &&
        this.multiData.itinery[1].to &&
        this.multiData.itinery[1].depdate &&
        (!this.multiData.itinery[2] ||
          (this.multiData.itinery[2].from &&
            this.multiData.itinery[2].to &&
            this.multiData.itinery[2].depdate)) &&
        (!this.multiData.itinery[3] ||
          (this.multiData.itinery[3].from &&
            this.multiData.itinery[3].to &&
            this.multiData.itinery[3].depdate))
      ) {
        this.multiData.adult = this.adultValue;
        this.multiData.child = this.childValue;
        this.multiData.infant = this.infantValue;
        this.multiData.class = this.classType;
        this.multiData.tripType = this.activetrip;

        const tabId = sessionStorage.getItem("tabId") || Date.now().toString();
        sessionStorage.setItem("tabId", tabId);

        const result1 = JSON.stringify(this.multiData);
        Object.keys(localStorage).forEach((key) => {
          if (
            key.startsWith("searchedData-multicity-") &&
            key !== `searchedData-multicity-${tabId}`
          ) {
            localStorage.removeItem(key);
          }
        });
        localStorage.setItem(`searchedData-multicity-${tabId}`, result1);

        console.log(result1, "ssdasdseasaaSrchCase...7");

        let getUrlFormat = [];
        this.multiData.itinery.forEach((v) => {
          let from = v.from.name
            ? v.from.name.split(" ").pop().replace("(", "").replace(")", "")
            : v.from.split(" ").pop().replace("(", "").replace(")", "");
          let to = v.to.name
            ? v.to.name.split(" ").pop().replace("(", "").replace(")", "")
            : v.to.split(" ").pop().replace("(", "").replace(")", "");
          let depDate = moment(v.depdate).format("YYYY/MM/DD");

          getUrlFormat.push(`${from}-${to}-${depDate}`);
        });

        let formattedItinerary = getUrlFormat.join("_");

        let [Adt, Chd, Inf] = [
          this.multiData.adult,
          this.multiData.child,
          this.multiData.infant,
        ];
        let getPaxtype = `A-${Adt}_C-${Chd}_I-${Inf}`;

        // let getLangual = this.$i18n.locale.split('-')[1];

        let getClassType;

        if (this.classType == "Economy") {
          getClassType = "Economy";
        }
        if (this.classType == "Business") {
          getClassType = "Business";
        }
        if (this.classType == "First Class") {
          getClassType = "FirstClass";
        }
        if (this.classType == "Premium Economy") {
          getClassType = "PremiumEconomy";
        }
        if (this.classType == "Premium Business") {
          getClassType = "PremiumBusiness";
        }
        if (this.classType == "Premium First") {
          getClassType = "PremiumFirstClass";
        }

        let multicityData = {
          itinerary: formattedItinerary,
          tripType: "MC",
          paxType: getPaxtype,
          cabin: getClassType,
          // language: getLangual,
        };

        console.log(multicityData, "dadsdasmulticityData");

        const resultData = this.handleMultiSearch(this.multiData);
        localStorage.setItem("recentData", JSON.stringify(resultData));

        if (this.$route.path == "/flight/search") {
          setTimeout(() => {
            location.reload();
          }, 500);
        }

        this.$router.push({ path: "/flight/search", query: multicityData });
      } else {
        this.isNotActive = true;
        setTimeout(() => {
          this.isNotActive = false;
        }, 2000);
        // this.validateMultiForm()
      }
    },

    validateOnewayForm() {
      console.log(this.oneway.from, "validateeeee");
      if (this.oneway.from) {
        this.fromErrorMessage = false;
      } else {
        this.fromErrorMessage = true;
      }

      if (this.oneway.to) {
        this.toErrorMessage = false;
      } else {
        this.toErrorMessage = true;
      }

      if (this.oneway.dedate) {
        this.fromDateError = false;
      } else {
        this.fromDateError = true;
      }

      if (
        this.oneway.redate &&
        !isNaN(new Date(this.oneway.redate).getTime())
      ) {
        this.toDateError = false;
      } else {
        this.toDateError = true;
      }
      // this.fromErrorMessage = !this.oneway.from;
      // this.toErrorMessage = !this.oneway.to;
      // this.fromDateError = !this.oneway.dedate;
      // this.toDateError = !this.oneway.redate;
    },

    loadSearchData() {
      const tabId = sessionStorage.getItem("tabId");
      const tripType = this.$route.query.tripType;
      // console.log(this.searchCase,'storedDatasdasdasdas...1')

      let key = "";

      if (tripType === "RT" || tripType === "OW") {
        key =
          tripType === "RT"
            ? `searchedData-round-${tabId}`
            : `searchedData-oneway-${tabId}`;
        this.Oneway = tripType === "OW";
        this.roundTrip = tripType === "RT";

        this.activetrip = tripType === "RT" ? "Roundtrip" : "Oneway";
        // console.log(this.roundTrip,this.Oneway,'storedDatasdasdasdas...dd');
        // console.log(key,'storedDatasdasdasdas...ddd');
      } else if (tripType === "MC") {
        key = `searchedData-multicity-${tabId}`;
        this.multicity = true;
        this.activetrip = "Multicity";
        console.log(key, "storedDatasdasdasdas...3");
      }

      console.log(this.activetrip, "sdasdactivetripasdad");
      this.activeType(this.activetrip);

      const storedData = localStorage.getItem(key);
      console.log(storedData, "storedDatasdasdasdas...4");

      // if (storedData) {
      //   if (this.multicity) {
      //     this.multiData = JSON.parse(storedData) || this.searchCase;
      //     console.log(storedData, "storedDatasdasdasdas...44");
      //     console.log(this.searchCase, "storedDatasdasdasdas...55");
      //     console.log(this.multiData, "storedDatasdasdasdas...5");
      //   } else {
      //     this.searchData = JSON.parse(storedData) || this.searchCase;
      //     console.log(this.searchData, "storedDatasdasdasdas...6");

      //     this.oneway = this.searchData;
      //     // this.formDatas = this.searchData;

      //     // this.tripData = this.formDatas;

      //     if (this.oneway.redate) {
      //       this.oneway.travelDate = [this.oneway.dedate, this.oneway.redate];
      //     }
      //     this.inputCity = this.searchData.from;
      //     this.inputCityto = this.searchData.to;
      //     console.log(this.searchData, "storedDatasdasdasdas...7");
      //   }

      //   this.classType = this.searchData
      //     ? this.searchData.class
      //     : this.multiData.class;
      //   console.log(this.classType, "asdasdclagetClasTypessTypeasdasda");

      //   // if(getClassType == "Economy"){
      //   //     this.classType = "Economy";
      //   // }
      //   // if(getClassType == "Business"){
      //   //     this.classType = "Business";
      //   // }
      //   // if(getClassType == "First Class"){
      //   //     this.classType = "FirstClass";
      //   // }
      //   // if(getClassType == "Premium Economy"){
      //   //     this.classType = "PremiumEconomy";
      //   // }
      //   // if(getClassType == "Premium Business"){
      //   //     this.classType = "Premium Business";
      //   // }
      //   // if(getClassType == "Premium First"){
      //   //     this.classType = "Premium First";
      //   // }

      //   // console.log(this.classType,'asdasdclassTypeasdasda');

      //   this.adultValue = this.searchData
      //     ? parseInt(this.searchData.adult)
      //     : parseInt(this.multiData.adult);
      //   this.childValue = this.searchData
      //     ? parseInt(this.searchData.child)
      //     : parseInt(this.multiData.child);
      //   this.infantValue = this.searchData
      //     ? parseInt(this.searchData.infant)
      //     : parseInt(this.multiData.infant);
      //   this.passengerData();
      //   this.addedPassenger();
      // } else {
      //   console.log("No search data found for this tab.");
      // }

      if (storedData) {
        if (this.multicity) {
          this.multiData = JSON.parse(storedData) || this.searchCase;
          console.log(this.multiData, "Loaded Multicity Data...");
        } else {
          this.searchData = JSON.parse(storedData) || this.searchCase;
          console.log(this.searchData, "Loaded Oneway/Roundtrip Data...");

          this.oneway = { ...this.searchData }; // Avoid direct reference issues

          // ✅ Ensure `travelDate` is set correctly based on `activetrip`
          if (this.activetrip === "Roundtrip" && this.oneway.redate) {
            this.oneway.travelDate = [this.oneway.dedate, this.oneway.redate];
          } else {
            this.oneway.travelDate = this.oneway.dedate ? new Date(this.oneway.dedate) : null;
            this.oneway.redate = null; // Reset return date for Oneway
          }

          this.inputCity = this.searchData.from;
          this.inputCityto = this.searchData.to;
        }

        this.classType = this.searchData?.class || this.multiData?.class;

        this.adultValue = parseInt(this.searchData?.adult || this.multiData?.adult || 1);
        this.childValue = parseInt(this.searchData?.child || this.multiData?.child || 0);
        this.infantValue = parseInt(this.searchData?.infant || this.multiData?.infant || 0);

        this.passengerData();
        this.addedPassenger();
      } else {
        console.log("No search data found.");
      }

    },

    getConfig() {
      const getConfigData = getConfigDataFromLocalStorage();

      if (getConfigData) {
        let pluginWhats = "";
        pluginWhats =
          getConfigData.payload.portal_configuration.menus.enabled.plugins;

        this.airlineApi =
          getConfigData.payload.portal_configuration.API_endpoints.airline_autocomplete;
        this.airportdataApi =
          getConfigData.payload.portal_configuration.API_endpoints.airport_data;
        this.susbcribeApi =
          getConfigData.payload.portal_configuration.API_endpoints.subscriber_form;
        this.autocompleteApi =
          getConfigData.payload.portal_configuration.API_endpoints.autocomplete;

        // console.log(pluginWhats, 'dasdasdasda..1..1..1.1')
        if (pluginWhats.includes("whatsapp")) {
          this.pluginWhatspp = true;
          this.whatsappApi =
            getConfigData.payload.portal_configuration.API_endpoints.whatsapp;
        } else {
          this.pluginWhatspp = false;
        }
      }
    },
  },
  computed: {
    tripData() {
      return JSON.parse(this.formDatas || {});
    },
    canAdd() {
      return (index) => {
        return (
          index === this.multiData.itinery.length - 1 &&
          this.multiData.itinery.length < 4
        );
      };
    },

    canRemove() {
      this.handleResize();
      return (index) => {
        const total = this.multiData.itinery.length;
        if (this.mobileResize) {
          // On mobile: Show remove button for all indexes if total > 2
          return total > 2 ? true : false;
        } else {
          // On desktop: Maintain existing logic
          return (
            (total > 2 && index === 0) ||
            (index !== 0 && (index < total - 1 || total === 4))
          );
        }
      };
    },
    selectedOptions() {
      const options = [];
      if (this.directFlight) options.push("Direct Flight");
      if (this.baggageInclusive) options.push("Baggage Inclusive");
      if (this.nearbyAirport) options.push("Nearby Airport");
      return options;
    },
    travellerText() {
      const totalTravellers =
        this.adultValue + this.childValue + this.infantValue;
      return `${totalTravellers} ${"Traveller(s)," || this.$t("formsContents.options.travellers")
        } ${this.classType}`;
    },
  },
  watch: {
    // "tripData": {
    //   immediate: true,
    //   handler(newValue) {
    //     console.log(newValue,'asdfasfdasnewValue...1');
    //     this.tripData = newValue;
    //   },
    // },
    "$route.path": {
      immediate: true,
      handler(newValue) {
        this.handleform = newValue == "/";
      },
    },
    directFlight(newValue) {
      if (newValue) {
        this.stopsSelect = "0";
      } else if (!newValue && this.stopsSelect == "0") {
        this.stopsSelect = "anyStop";
      } else if (
        !newValue &&
        (this.stopsSelect !== "anyStop" || this.stopsSelect !== "0")
      ) {
        return this.stopsSelect;
      } else {
        this.stopsSelect = "anyStop";
      }
    },

    "oneway.from "(val) {
      console.log(val, "ooooooo");
      // val && val !== this.selectAcc && this.querySelections(val)
    },
    "oneway.class"(newSelectValue) {
      // console.log('select property changed to:', newSelectValue);

      if (newSelectValue) {
        // setTimeout(() => {
        //this.$refs.countrySelect.focus();
        this.classSelect = false;
        // console.log(this.classSelect)
        //  }, 100);
      }
    },



    "oneway.travelDate"(value) {
      console.log(value);
      console.log("Watcher Triggered:", value, "Active Trip:", this.activetrip);
      // if (value && value.length <= 2) {
      //   this.oneway.dedate = value[0];
      //   this.oneway.redate = value[1];

      //   this.focusEvent3 = false;
      // } else {
      //   this.oneway.travelDate = null;
      // }
      if (this.activetrip === "Oneway") {
        // ✅ In Oneway, `travelDate` should be a single date
        if (value) {
          this.oneway.dedate = new Date(value);
          this.oneway.redate = null; // No return date for Oneway
        } else {
          this.oneway.dedate = null;
        }
      } else if (this.activetrip === "Roundtrip") {
        // ✅ In Roundtrip, `travelDate` should be an array with two dates
        if (Array.isArray(value) && value.length === 2) {
          this.oneway.dedate = value[0];
          this.oneway.redate = value[1];
        } else {
          this.oneway.dedate = null;
          this.oneway.redate = null;
        }
      }
    },

    "oneway.dedate"(value) {
      if (value) {
        this.focusEvent3 = false;
      } else {
        this.oneway.dedate = null;
      }
    },

    // "tripType":{
    //   immediate: true,
    //   handler(newVal,index) {
    //     console.log(newVal,'newValnewVal',index);

    //     // this.activetrip = newVal;
    //     // this.activeType(newVal);
    //     // Oneway Roundtrip Multicity
    //   },
    //   deep: true
    // }
  },
  created() {
    this.getConfig();
    console.log("getconfigcreate");
    window.addEventListener("resize", this.handleResize);
    // let getrecentdata = null;
    // let getrecentdata = JSON.parse(localStorage.getItem('recentData'||{}));
    // let getrecentCity = JSON.parse(localStorage.getItem('recentcityData'||{}));
    // console.log(getrecentdata,'sadasdasgetrecentCity...1');
    // console.log(getrecentCity,'sadasdasgetrecentCity...2');

    // localStorage.clear();

    // localStorage.setItem('recentData',JSON.stringify(getrecentdata));
    // localStorage.setItem('recentcityData',JSON.stringify(getrecentCity));
  },
  mounted() {
    this.recentFromData();
    this.updateRecentSearch();
    this.loadSearchData();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    // Remove event listener when component is destroyed
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../../assets/css/style-04.css";
/* @import '../../assets/css/style-responsive.css';
@import '../../assets/css/bootstrap.min.css'; */

/* .flight-search {
    position: relative;
    z-index: 1;
    padding: 1.5rem 0;
  }
  .flight-search .dropdown-menu{
    z-index:1
  }
  .flight-search .icon-20 {
    width: 20px;
    height: 20px;
  }
  .flight-search .search-pan {
    background: #ffffff;
    padding: 0.8rem;
    min-height: auto;
  }  */
.flight-search .dropdown-toggle::after {
  position: absolute;
  right: 12px;
  top: 45%;
}

.flight-search .dropdown .dropdown-menu {
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  /*-- Droup down Traveller --*/
}

.flight-search .dropdown .dropdown-menu .drop-rest {
  font-size: 13px;
  font-weight: 400;
}

.flight-search .dropdown .dropdown-menu .drop-rest li {
  border-bottom: 1px solid #f5faff;
  display: flex;
  padding: 5px 0 5px 0;
}

.flight-search .dropdown .dropdown-menu .drop-rest li span {
  font-size: 14px;
  font-weight: 500;
}

.flight-search .dropdown .dropdown-menu .drop-rest li .br {
  display: block;
  padding-top: 0px;
}

.flight-search .dropdown .dropdown-menu .drop-rest li .btn {
  font-size: 13px;
  font-weight: 400;
  background-color: #002d5b;
  color: #ffffff;
  border: 0;
}

.flight-search .dropdown .dropdown-menu .drop-rest li .btn:hover {
  background-color: #ffc104;
  color: #343534;
}

.flight-search .dropdown .dropdown-menu .plus-minus-input,
.search-int .drop-rest .plus-minus-input {
  align-items: center;
  justify-content: end;
  /* width: 60%; */
}

/* @media screen and (max-width: 992px) {

  .flight-search .dropdown .dropdown-menu .plus-minus-input,
  .search-int .drop-rest .plus-minus-input {
    width: 30%;
  }
} */

@media screen and (min-width: 576px) {
  .flightbehavebtn {
    display: none;
  }

  .flightbehaveIcon {
    display: block;
  }
}

@media screen and (max-width: 575px) {

  .flight-search .dropdown .dropdown-menu .plus-minus-input,
  .search-int .drop-rest .plus-minus-input {
    width: 60%;
  }

  .flightbehavebtn {
    display: block;
  }

  .flightbehaveIcon {
    display: none;
  }

  .search-int .int .col-12 {
    padding-left: 15px;

    padding-right: 15px;
  }
}

.flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input .input-group-button button {
  background-color: #002d5b;
  border: none;
  color: #ffffff;
}

.flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input .input-group-button button:hover {
  background-color: #ffc104;
  color: #343534;
}

.flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input .input-group-field {
  text-align: center;
  width: 50%;
  background: #f5faff;
  border: 0;
}

.flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input .input-group-field::-webkit-inner-spin-button,
.flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input .input-group-field::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.flight-search .form-group {
  position: relative;
}

.flight-search .icon-pos {
  display: none;
  top: 0.6rem;
  left: 0.5rem;
  color: #9f9f9f;
}

@media screen and (max-width: 992px) {
  .flight-search .icon-pos {
    top: 1rem;
  }
}

.addbtn {
  width: 30px;
  height: 25px;
  background: #002d5b;
  text-align: center;
  color: white;
  cursor: pointer;
}

.addbtn:hover {
  background: #ffc104;
  color: #002d5b;
}

.adddata {
  width: 70px;
  height: 25px;
  color: block;
  background: #f5faff;
  text-align: center;
}

/* .flightbehavebtn{
  display: none;
}
.flightbehaveIcon{
  display: block;
} */

:deep(.dropdown-menu .v-label) {
  font-size: 13px;
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 4px;
  height: auto;
}

:deep(.dropdown-menu .v-radio-group .v-selection-control__wrapper) {
  height: 20px;
}

.autocompete-menual-dropdown {
  border: 1px solid #d2d2d2;
  background-color: white;
  cursor: pointer;
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  top: 66px;
  width: -moz-max-content;
  width: max-content;
  max-width: 380px;
  padding: 4px;
  border-radius: 5px;
  z-index: 99999;
  font-size: 13px;
  font-weight: 400;
  box-shadow: 0px 0px 1px 1px #d2d2d2;
}

.autocompete-menual-dropdown .va-back:hover {
  background: #ffc104;
}

/* Advanced Options */

:deep(.advanceOpt .v-input__details) {
  display: none;
}

:deep(.advanceOpt .v-switch__thumb) {
  width: 16px;
  height: 16px;
}

:deep(.advanceOpt .v-switch__track) {
  height: 13px;
  min-width: 30px;
}

:deep(.v-switch .v-selection-control) {
  min-height: 20px;
}

:deep(.advanceOpt .v-label) {
  font-size: 13px;
  padding-left: 5px;
  color: #00000080 !important;
  opacity: 1;
  font-weight: 400;
  margin-bottom: 0px;
}

:deep(.v-input__details) {
  display: none;
}

:deep(.advanceOpt .dropdownSection .v-select .v-field__input) {
  padding: 0 !important;
  min-height: 30px;
}

:deep(.dropdownSection .v-field__append-inner) {
  padding: 0px !important;
}

:deep(.dropdownSection .v-radio-group .v-selection-control__wrapper) {
  height: 20px;
}

:deep(.dropdownSection .v-radio-group .v-label) {
  height: 20px;
}

:deep(.advanceOpt .dropdownSection .v-select input::placeholder) {
  font-size: 14px;
  font-weight: 500;
  color: #767676;
  opacity: 1;
  height: 20px;
}

:deep(.advanceOpt .v-checkbox .v-selection-control) {
  min-height: auto;
}

:deep(.advanceOpt .v-checkbox .v-selection-control__wrapper) {
  height: 20px;
  width: 20px;
}

:deep(.advanceOpt .v-selection-control__input > .v-icon) {
  font-size: 18px;
}

/* Advanced Options End */

/* Date Picker */

/* :deep(.datePickInput .dp__input::placeholder) {
  color: #9e9e9e !important;
  font-size: 16px !important;
  font-weight: 500 !important;
} */

:deep(.v-icon.mdi-close-circle) {
  font-size: 18px;
  color: #5f6367;
  opacity: 0.6;
}

:deep(.datePickInput .v-icon) {
  opacity: 0;
}

:deep(.datePickInput:hover .v-icon) {
  opacity: 0.6;
  transition: opacity 0.3s ease-in-out;
}

.dp__pointer {
  cursor: pointer;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 0px;
  width: 100% !important;
  border-radius: 50% !important;
  margin: 8px !important;
}

:deep(.input-styles .dp__menu) {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px !important;
  border-radius: 20px !important;
  border: 1px solid #d3d3d361 !important;
  top: 84px !important;
}

:deep(.banner-bg .DatecolumnSizing .dp__cell_inner) {
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 4px !important;
  width: 32px !important;
  /* height: 28px !important; */
  border-radius: inherit;
}

:deep(.multidatecolumnSizing .dp__cell_inner) {
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 4px !important;
  width: 32px !important;
  /* height: 28px !important; */
  border-radius: inherit;
}

:deep(.dp__range_between) {
  background: #70707066 !important;
}

:deep(.input-styles .dp__range_between) {
  border-radius: 0px !important;
}

:deep(.input-styles .dp__date_hover_end:hover) {
  background: #1976d2 !important;
  color: white !important;
  border-radius: 50% !important;
}

:deep(.input-styles .dp__calendar_item:hover) {
  background: #f3f3f3 !important;
  /* color:black !important; */
  border-radius: 50% !important;
}

:deep(.datePickInput .dp__month_year_row) {
  background-color: #ffc104;
  padding: 0px 10px;
}

:deep(.datePickInput .dp__inner_nav) {
  border: 1px solid black;
  background-color: black;
  color: white !important;
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  border-image: initial;
  font-size: 10px;
}

:deep(.datePickInput .dp__calendar_item) {
  border: 1px solid #c5c5c5;
  background: #f6f6f6;
  border-radius: 0px;
  margin: 0px 2px;
  width: 32px;
}

:deep(.datePickInput .dp__date_hover:hover) {
  border: 1px solid #ffc104;
  background: #ffc104;
}

:deep(.datePickInput .dp__input_icon) {
  display: none;
}

:deep(.datePickInput .dp__input input) {
  padding: 8px 14px !important;
}

:deep(.dp__today) {
  border-color: transparent !important;
}

/* Date Picker End */

/* Add Passenger  */
.addbtn {
  width: 28px;
  height: 25px;
  background: #002d5b;
  text-align: center;
  color: white;
  cursor: pointer;
}

.addbtn:hover {
  background: #ffc104;
  color: #002d5b;
}

.adddata {
  width: 60px;
  height: 25px;
  color: block;
  background: #f5faff;
  text-align: center;
}

/* Add Passenger End  */

/* ------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------- */

/* Search Form */

:deep(.inter-input.v-text-field .v-field__clearable) {
  background: transparent;
  position: absolute;
  right: 0;
  top: 25%;
  font-size: 10px;
}

:deep(.inter-input.v-text-field .v-field__append-inner) {
  background: transparent;
  position: absolute;
  right: 4px;
  top: 25%;
}

:deep(.inter-input.v-text-field .v-field) {
  padding: 0;
}

/* Date picker */

:deep(.search-int .DatecolumnSizing .dp__cell_inner) {
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 0px !important;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  margin: auto;
}

:deep(.search-int .multidatecolumnSizing .dp__cell_inner) {
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 0px !important;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  margin: auto;
}

:deep(.dp__range_between) {
  background: #70707066 !important;
}

:deep(.input-styles .dp__range_between) {
  border-radius: 0px !important;
}

:deep(.input-styles .dp__date_hover_end:hover) {
  background: #1976d2 !important;
  color: white !important;
  border-radius: 50% !important;
}

:deep(.input-styles .dp__calendar_item:hover) {
  background: #f3f3f3 !important;
  /* color:black !important; */
  border-radius: 50% !important;
}

:deep(.datePickInput .dp__month_year_row) {
  background-color: #ffc104;
  padding: 0px 10px;
}

:deep(.datePickInput .dp__inner_nav) {
  border: 1px solid black;
  background-color: black;
  color: white !important;
  width: 18px;
  height: 18px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(0, 0, 0);
  border-image: initial;
  font-size: 10px;
}

:deep(.datePickInput .dp__calendar_item) {
  border: 1px solid #c5c5c5;
  background: #f6f6f6;
  border-radius: 0px;
  margin: 0px 2px;
  width: 25px;
}

:deep(.datePickInput .dp__date_hover:hover) {
  border: 1px solid #ffc104;
  background: #ffc104;
}

:deep(.datePickInput .dp__input_icon) {
  display: none;
}

:deep(.datePickInput .dp__input input) {
  padding: 8px 14px !important;
}

:deep(.dp__today) {
  border-color: transparent !important;
}

:deep(button.dp__btn.dp__month_year_select) {
  height: inherit;
}

:deep(.datePickInput .dp__month_year_wrap) {
  height: 26px;
  font-size: 14px;
}

:deep(.datePickInput .dp__inner_nav svg) {
  width: 16px !important;
  height: 16px !important;
}

:deep(.text-box .datePickInput input) {
  background-color: revert !important;
}

/* Search page Passengers */
.search-int .flight-search .drop-rest {
  list-style: none;
}

.search-int .flight-search ul.drop-rest li {
  font-size: 13px;
  font-weight: 400;
  border-bottom: 1px solid #f5faff;
  display: flex;
  padding: 5px 0 5px 0;
}

/* Search page Classtype */
:deep(.flight-search .v-label) {
  font-size: 13px;
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 4px;
  height: auto;
}

:deep(.flight-search .v-radio-group .v-selection-control__wrapper) {
  height: 20px;
}

.search-int .input1 input {
  width: 100%;
  padding: 4px 45px;
  font-size: 13px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  text-align: center;
}

.search-int .inc span:first-child {
  left: 16px;
  border-right: 1px solid #e6e6e6;
}

.search-int .inc span:last-child {
  right: 20px;
  border-left: 1px solid #e6e6e6;
}

.search-int .inc span {
  position: absolute;
  margin-top: -29px;
  height: 29px;
  width: 35px;
  text-align: center;
  padding-top: 4px;
  color: #888;
  cursor: pointer;
}

.search-int .input2 select {
  width: 100%;
  padding: 5px;
  font-size: 13px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  text-align: center;
  appearance: auto;
}

.search-int .option b{
  color: white !important;
}

.search-int .modifyform h6 {
  font-size: 12px;
}

.search-int .modifyform p {
  font-size: 12px;
  font-weight: 400;
}

.search-int .modifyform span {
  font-size: 12px;
  font-weight: 400;
  color: #5f6367 !important;
}

.search-int .modifyform button span {
  font-size: 12px;
  font-weight: 500;
  color: white !important;
}

/* >>>>>>>>>>>>>>>Recent search styles<<<<<<<<<<<<<<<< */
.hisOptions p {
  font-size: 13px;
  font-weight: 400;
  color: white;
  letter-spacing: 1px;
  text-decoration: underline;
  cursor: pointer;
}

.searchrecent {
  font-size: 12px;
  max-height: 202px;
  min-height: 202px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 4px 8px;
}

.recent_style {
  position: absolute;
  top: 86%;
  background: white;
  width: 220px;
  /* margin: 4px; */
  padding: 4px;
  border-radius: 10px;
  z-index: 5;
  border: 2px solid gray;
  cursor: pointer;
}

.resu-form {
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  padding: 4px 10px;
}

.resu-form:hover {
  background-color: #ffc104 !important;
  border-radius: 4px !important;
}

/* masonry-style layout */
.masonry-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); Flexible grid */
  gap: 15px;
  grid-auto-flow: dense;
  /* Helps maintain structure */
  justify-content: center;
}

.masonry-item {
  break-inside: avoid;
  width: 100%;
}

.multi-city {
  grid-row: span 2;
  /* Multi-city cards take up more space */
}

.card-style {
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  background: white;
}

.searchClass {
  display: flex;
  align-items: center;
}

.recentSearch {
  cursor: pointer;
}

@media (max-width: 768px) {
  .masonry-grid {
    grid-template-columns: 1fr;
    /* grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)); */
  }
}

@media (max-width: 480px) {
  .masonry-grid {
    grid-template-columns: 1fr;
  }
}

/* masonry-style layout End */

/* >>>>>>>>>>>>>>>>Recent search styles End<<<<<<<<<<<<<<<<<<<<< */

:deep(.dp__year_select) {
  display: none !important;
}
</style>

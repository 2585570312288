<template>
  <!--Content start-->
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3">
        <v-form ref="form" v-model="valid" @submit.prevent="registerSubmitForm" style="width: 100%;">
        <div class="login-bg">
          <h1 class="text-capitalize text-start"><span>{{ $t('registerContents.buttonsLink.createAccount') }}</span></h1>

          <div class="login-inp row">
            <!-- <span>
              Email or Mobile Number <small>This will be your username</small>
            </span> -->

            <!-- <input type="text" placeholder="Email or Mobile Number" /> -->
            <div class="col-md-4 col-12">
              <v-text-field v-model="personal.firstName" :rules="fName" :label="this.$t('registerContents.labels.firstName')"
                variant="outlined" 
                ></v-text-field>
            </div>
            <div class="col-md-4 col-12">
              <v-text-field v-model="personal.middleName" :label="this.$t('registerContents.labels.middleName')"
                variant="outlined" 
                ></v-text-field>
            </div>
            <div class="col-md-4 col-12">
              <v-text-field v-model="personal.lastName" :rules="lName" :label="this.$t('registerContents.labels.lastName')"
                variant="outlined" 
                ></v-text-field>
            </div>
          </div>

          <div class="login-inp row">
            <!-- <span>Password</span>

            <input type="text" placeholder="minimum 6 characters." /> -->

            <div class="col-md-6 col-12">
              <v-text-field v-model="personal.email" :rules="eMail" :label="this.$t('registerContents.labels.emailId')"
              placeholder="johndoe@gmail.com" type="email" variant="outlined"
                ></v-text-field>
            </div>
            <div class="col-md-6 col-12">
              <v-text-field v-model="personal.phoneNumber" :label="this.$t('registerContents.labels.phoneNumber')"
              :placeholder="this.$t('registerContents.labels.phoneNumber')" type="text" variant="outlined"
                ></v-text-field>
            </div>
          </div>
          <div class="login-inp row">
            <!-- <span>Password</span>

            <input type="text" placeholder="minimum 6 characters." /> -->

            <div class="col-md-6 col-12">
              <v-text-field v-model="personal.password" :rules="passWord" :label="this.$t('registerContents.labels.password')"
              :placeholder="this.$t('registerContents.labels.password')" variant="outlined" required
                :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible"
                ></v-text-field>
            </div>
            <div class="col-md-6 col-12">
              <v-text-field v-model="personal.rePassword" :rules="repassWord" :label="this.$t('registerContents.labels.confirmPassword')"
              :placeholder="this.$t('registerContents.labels.confirmPassword')" variant="outlined" required
                :append-inner-icon="visibleConfirm ? 'mdi-eye-off' : 'mdi-eye'"
                :type="visibleConfirm ? 'text' : 'password'" @click:append-inner="visibleConfirm = !visibleConfirm"
                ></v-text-field>
            </div>
          </div>

          <!-- <button type="submit" class="btn-login">Create Account</button> -->
          <button type="submit" class="btn btn-book px-5" style="background-color:#003580;color:white;
          height:45px;font-size:18px;">
            <v-progress-circular v-if="loadRegister" :width="5" color="white" indeterminate></v-progress-circular>
          {{ !loadRegister ? 'Create Account' : '' }}</button>

          <!-- <div class="orlogin">
            <span>OR CREATE ACCOUNT WITH</span>
          </div>

          <div class="login-social">
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-6">
                <a href="#!" class="lfacebook" style="text-decoration: none"
                  ><i class="fab fa-facebook"></i> Signup with Facebook</a
                >
              </div>

              <div class="col-sm-6 col-md-6 col-lg-6">
                <a href="#!" class="lgoogle" style="text-decoration: none"
                  ><i class="fab fa-google"></i> Signup with Google</a
                >
              </div>
            </div>
          </div> -->

          <div class="orsignup">
            <span>{{ $t("registerContents.buttonsLink.alreadyamember") }}</span>
          </div>

          <div class="create-acc">
            <a href="/login" style="text-decoration: none"> {{ $t("registerContents.buttonsLink.login") }}</a>
          </div>
        </div>
        </v-form>
      </div>
    </div>
  </div>
  <!--Content end-->
  <div class="toast" :class="isActive ? 'active' : ''"  >
        <div class="toast-content">
          <i class="bi bi-check toast-check"></i>
          <div class="message">
            <span class="message-text text-1">{{ $t("loginContents.labels.success") }}</span>
            <span class="message-text text-2">{{ $t("loginContents.labels.your") }} {{ Successmsg }}</span>
          </div>
        </div>

        <i class="bi bi-x-lg toast-close" @click="closeToast"></i>
        <div class="progress" :class="isActive ? 'active' : ''" :style="{ '--progress-color': progressColor }"></div>
    </div>
    <div class="toast" :class="isNotActive ? 'active border-danger' : ''">
        <div class="toast-content">
          <i class="bi bi-x-lg toast-check" :class="isNotActive ? 'bg-danger' : 'bg-danger'"></i>
          <div class="message">
            <span class="message-text text-1">{{ ErrorMessage }}</span>
            <span class="message-text text-2">{{ $t("loginContents.labels.errMsg") }}</span>
          </div>
        </div>

        <i class="bi bi-x-lg toast-close" @click="closeToast"></i>
        <div class="progress" :class="isNotActive ? 'active' : ''" :style="{ '--progress-color': progressColor }"></div>
    </div>
</template>

<style scoped>
@import "../../assets/css/style-04.css";
@import "../../assets/css/style-responsive.css";
/* @import "../../assets/css/style.css"; */
@import "../../assets/css/bootstrap.min.css";
@import "../../assets/css/custom.css";
@import "../../assets/css/slick/slick.css";
@import "../../assets/css/slick/slick-theme.css";
</style>
<script>
// import loginCom from '@/components/loginCom.vue';
import axios from 'axios';

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
  components: {
    // loginCom
  },
  data() {
    return {
      isNotActive: false,
      valid: false,

      registerbox: true,
      loginSection: false,
      terms: false,
      visible: false,
      visibleConfirm: false,

      regForm: true,
      regMsg: false,
      registerMsg: "",
      loadRegister: false,
      btnDisabled: false,
      termscheck:false,

       personal: {
        firstName: "",
        middleName:"",
        lastName:"",
        email: "",
        password: "",
        rePassword: "",
        phoneNumber:""
      },
      data1: {
        "request_type": "user_register",
        "user_name": "admin",
        "email": "admin12553435@gmail.com",
        "password": "1234567890",
        "portal_id": "11111",
        "portal_group_id": "11111",
        "user_id": "0000",
      },


       fName: [
        v => !!v || 'Please enter your First Name',
        v => (v.length >= 3) || 'Name must be greater than 3 ',
      ],
       middleName: [
        v => !!v || 'Please enter your Middle Name',
        v => (v.length >= 3) || 'Name must be greater than 3 ',
      ],
       lName: [
        v => !!v || 'Please enter your Last Name',
      ],

      eMail: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],

      passWord: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "Password must be at least 8 characters",
        (v) => /[A-Z]/.test(v) || "Password must contain at least one uppercase letter",
        (v) => /[a-z]/.test(v) || "Password must contain at least one lowercase letter",
        (v) => /\d/.test(v) || "Password must contain at least one number",
        (v) => /[@$!%*?&]/.test(v) || "Password must contain at least one special character (@$!%*?&)",
      ],
      repassWord: [
        v => !!v || 'Please enter your Confirm Password',
        v => v === this.personal.password || 'Passwords do not match'
      ],

      // strResult: "0123456789",

      // user_reqId:"",


      registerReq: {
        "request_type": "user_register",
        "user_name": "",
        "email": "",
        "password": "",
        "portal_id": "11111",
        "portal_group_id": "11111",
        "user_id": "",
        "token": "Tc7RQaX79Hrx5CFK"
      },

      registerUrl:"",

      isActive:true,
      siteTitle:"",
      portalId:"",
      portalGroupId:"",
    };
  },
  methods: {
   showToast() {
      this.isActive = true;
      // setTimeout(() => {
      //   if(this.isActive){
      //       this.isActive = false;
      //   }
      // }, 5000);
    },
    closeToast() {
      this.isActive = false;
      this.isNotActive = false;
    },

    signInAgain(){
      this.loginSection = true 
      this.registerbox = false
      console.log(this.loginSection, this.registerbox, "Workinglogin....")
    },
    // closeRegBox() {
    //         this.loginbox = false;
    //         this.$emit('close'); // Emit the 'close' event to the parent component
    //         console.log(this.loginbox, "1111111")
    //     },
  onClickOutside() {
      this.registerbox = false
    },
   registerSubmitForm() {

      console.log(this.valid, 'oooooooooo',this.termscheck)
      if (this.valid) {

        this.loadRegister = true;
        this.btnDisabled = true;

        // this.generateBookingRefId()

        console.log(this.personal, 'eeeee......')

        this.registerReq.first_name = this.personal.firstName
        this.registerReq.middle_name = this.personal.middleName
        this.registerReq.last_name = this.personal.lastName
        this.registerReq.email = this.personal.email
        this.registerReq.password = this.personal.password
        this.registerReq.user_id = this.personal.email
        this.registerReq.portal_id = this.portalId
        this.registerReq.portal_group_id = this.portalGroupId
        this.registerReq.portal_domain = this.portal_domain


        console.log(this.registerReq, 'ffff.....')

        axios.post(this.registerUrl, this.registerReq, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => {

            if (response.data) {
              this.regForm = false
              this.regMsg = true

              this.isActive =  true;
              this.termscheck =  false;

              console.log('Response:', response.data);
              console.log('Response-Msg:', response.data.register.message);
              // this.registerMsg = response.data.register.message

              this.Successmsg = response.data.register.message

              setTimeout(()=>{
                  this.isActive =  false;
                  this.loadRegister = false;
                  this.$refs.form.reset();
              },5000)

              // this.registerbox = false
            }
          })


          .catch(error => {
            console.log(error.response.data.message, 'wejjfuoajk')
            alert(`${error.response.data.message}`)
            this.loadRegister = false
            this.btnDisabled = false
            this.isNotActive = true;
            setTimeout(()=>{
                            this.isNotActive =  false;
                        },5000)
          });
      }
    },




    // getRandomChars(length) {
    //         let result = "";
    //         for (let i = 0; i < length; i++) {
    //             const randomIndex = Math.floor(Math.random() * this.strResult.length);
    //             result += this.strResult[randomIndex];
    //         }
    //         console.log(result, "result");
    //         return result;
    //     },
    //     getRandomInt(min, max) {
    //         return Math.floor(Math.random() * (max - min + 1)) + min;
    //     },

    //     generateBookingRefId() {
    //         const randomChars1 = this.getRandomChars(1);
    //         const randomNum = this.getRandomInt(10, 99);
    //         const randomChars2 = this.getRandomChars(1);

    //         this.registerReq.user_id = randomChars1 + randomNum + randomChars2;
    //         console.log(this.registerReq.user_id, 'this.booking_refId ')
    //     },




    closeRegisterBox() {
      this.registerbox = false;
      this.$emit('close'); // Emit the 'close' event to the parent component
      console.log(this.registerbox, '00000')
    },



    getConfig() {
      // let portalLocal = localStorage.getItem("portalData")
      // let getConfigData1 = JSON.parse(portalLocal)
      const getConfigData1 = getConfigDataFromLocalStorage();
      if (getConfigData1) {
        this.registerUrl = getConfigData1.payload.portal_configuration.API_endpoints.b2cregister
        this.siteTitle = getConfigData1.payload.portal_configuration.site_title

        this.portalId = getConfigData1.payload.portal_configuration.portal_id;
        this.portal_domain = getConfigData1.payload.portal_configuration.portal_url.replace("https://","")
        this.portalGroupId = getConfigData1.payload.portal_configuration.portal_group_id
        console.log(this.registerUrl, 'head...reg..1..')
      }
    },
  },

  computed: {
        progressColor() {
         return this.isActive ? '#40f467' : '#ff0000';
        },
    },

  created() {  
    this.getConfig();
  }
}
</script>
<style scoped>
:deep(.v-field__input){
  min-height:45px;
}
.toast{
  position: absolute;
  top: 80px;
  right: 10px;
  border-radius: 6px;
  background: #fff;
  padding: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-left: 8px solid #40f467;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.25, 1.35);
}
.toast.active{
  transform: translateX(0);
}
.toast-content{
  display: flex;
  justify-content: center;
  align-items: center;
}
.toast-check{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  background-color: #40f467;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
}
.message{
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}
.message-text{
  font-size: 20px;
  font-weight: 600;
}
.text-1{
  color: #333;
}
.text-2{
  color: #666;
  font-weight: 400;
  font-size: 13px;
}
.toast-close{
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 5px;
  cursor: pointer;
  opacity: 0.7;
}
.toast-close:hover{
  opacity: 1;
}
:deep(.v-label){
  background: white;
  padding: 0px 5px;
}
</style>
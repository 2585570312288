<template>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 filterSection" v-if="isTripDataAvailable">
    <div @click="handleFilter()"
      class="filter-head d-flex justify-content-between align-items-center mb-2 px-sm-2 px-1 py-sm-2 py-1"
      data-toggle="collapse" data-target="#filtersection">
      <div class="filter-text d-flex justify-content-lg-between justify-content-normal align-baseline">
        <span>{{ $t("searchPageContent.filterContent.filter") }}</span>
        <i class="fa fa-filter ms-1 my-0"></i>
      </div>
      <!-- <span> -->
      <span class="filterarrow">
        <v-icon class="ms-1 my-0">{{
          !handlefilter
            ? "mdi-arrow-up-drop-circle"
            : "mdi-arrow-down-drop-circle"
        }}</v-icon>
      </span>
      <!-- </span> -->
    </div>

    <div class="filter-side" :class="handlefilter ? 'show' : 'collapse'" id="filtersection">
      <h3>
        <span>{{ $t("searchPageContent.filterContent.price") }}: </span>
        <span style="font-size: 12px; font-weight: 400">
          {{ currencyCode }} {{ changevalue[0] }} - {{ currencyCode }}
          {{ changevalue[1] }}</span>
      </h3>
      <div>
        <div>
          <v-range-slider v-model="changevalue" :min="minimum" :max="maximum" :step="steps" thumb-label
            class="price_slider" color="#0c4ca3"></v-range-slider>
        </div>
        <div class="d-flex justify-content-between f-size-14 fw-500">
          <p class="mb-0">{{ currencyCode }} {{ changevalue[0] }}</p>
          <p class="mb-0">{{ currencyCode }} {{ changevalue[1] }}</p>
        </div>
      </div>
      <h3>{{ $t("searchPageContent.filterContent.stops") }}</h3>

      <div class="stopcheckbox ps-3">
        <v-checkbox :label="this.$t('searchPageContent.filterContent.nonStop')" v-model="NonStopFil"
          @change="applyFilters()" :disabled="!hasNonStop" color="primary" v-if="showNonStop"></v-checkbox>
        <v-checkbox :label="`1 ${this.$t('searchPageContent.filterContent.stop')}`" v-model="OneStopFil"
          @change="applyFilters()" :disabled="!hasOneStop" color="primary" v-if="showOneStop" class="mt-2"></v-checkbox>
        <v-checkbox :label="`1 + ${this.$t('searchPageContent.filterContent.stops')}`" v-model="OnePlusFil"
          @change="applyFilters()" :disabled="!hasOnePlusStop" color="primary" v-if="showOneplusStop"
          class="mt-2"></v-checkbox>
      </div>

      <h3>{{ $t("searchPageContent.filterContent.fareType") }}</h3>

      <div class="stopcheckbox ps-3">
        <v-checkbox :label="this.$t('searchPageContent.itinContent.refund')" color="primary" v-model="RefundableData"
          :disabled="!hasRefundable"></v-checkbox>
        <v-checkbox :label="this.$t('searchPageContent.itinContent.nonRefund')" color="primary"
          v-model="nonRefundableData" :disabled="!hasNonRefundable" class="mt-2"></v-checkbox>
      </div>

      <div>
        <div v-if="this.onedatafil || this.rounddatafil">
          <h3>{{ FilterTimeDepname }} - {{ FilterTimearrname }}</h3>
          <p>{{ $t("searchPageContent.itinContent.depature") }}</p>
          <div class="scrollbar" id="scrollbarstyle">
            <div class="stopcheckbox ps-3">
              <v-checkbox :label="`${this.$t(
                'searchPageContent.filterContent.before'
              )} 6am`" @click="TimeFilter4()"></v-checkbox>
              <v-checkbox label="6am-12pm" @click="TimeFilter1()" class="mt-2"></v-checkbox>
              <v-checkbox label="12pm-6pm" @click="TimeFilter2()" class="mt-2"></v-checkbox>
              <v-checkbox :label="`${this.$t(
                'searchPageContent.filterContent.after'
              )} 6pm`" @click="TimeFilter3()" class="mt-2"></v-checkbox>
            </div>
          </div>

          <p>{{ $t("searchPageContent.itinContent.arrival") }}</p>
          <div class="scrollbar" id="scrollbarstyle">
            <div class="stopcheckbox ps-3">
              <v-checkbox :label="`${this.$t(
                'searchPageContent.filterContent.before'
              )} 6am`" @click="TimeFilterArr4()"></v-checkbox>
              <v-checkbox label="6am-12pm" @click="TimeFilterArr1()" class="mt-2"></v-checkbox>
              <v-checkbox label="12pm-6pm" @click="TimeFilterArr2()" class="mt-2"></v-checkbox>
              <v-checkbox :label="`${this.$t(
                'searchPageContent.filterContent.after'
              )} 6pm`" @click="TimeFilterArr3()" class="mt-2"></v-checkbox>
            </div>
          </div>
        </div>

        <div v-if="this.rounddatafil">
          <h3>{{ FilterTimearrname }} - {{ FilterTimeDepname }}</h3>
          <p>{{ $t("searchPageContent.itinContent.depature") }}</p>
          <div class="scrollbar" id="scrollbarstyle">
            <div class="stopcheckbox ps-3">
              <v-checkbox :label="`${this.$t(
                'searchPageContent.filterContent.before'
              )} 6am`" @click="TimeretFilter4()"></v-checkbox>
              <v-checkbox label="6am-12pm" @click="TimeretFilter1()" class="mt-2"></v-checkbox>
              <v-checkbox label="12pm-6pm" @click="TimeretFilter2()" class="mt-2"></v-checkbox>
              <v-checkbox :label="`${this.$t(
                'searchPageContent.filterContent.after'
              )} 6pm`" @click="TimeretFilter3()" class="mt-2"></v-checkbox>
            </div>
          </div>

          <p>{{ $t("searchPageContent.itinContent.arrival") }}</p>
          <div class="scrollbar" id="scrollbarstyle">
            <div class="stopcheckbox ps-3">
              <v-checkbox :label="`${this.$t(
                'searchPageContent.filterContent.before'
              )} 6am`" @click="TimeretFilterarr4()"></v-checkbox>
              <v-checkbox label="6am-12pm" @click="TimeretFilterarr1()" class="mt-2"></v-checkbox>
              <v-checkbox label="12pm-6pm" @click="TimeretFilterarr2()" class="mt-2"></v-checkbox>
              <v-checkbox :label="`${this.$t(
                'searchPageContent.filterContent.after'
              )} 6pm`" @click="TimeretFilterarr3()" class="mt-2"></v-checkbox>
            </div>
          </div>
        </div>
      </div>

      <h3>{{ $t("searchPageContent.filterContent.airlines") }}</h3>
      <div class="row w-100 g-0 d-flex justify-content-between mb-3" v-if="this.multicityData.length > 0 ? false : true"
        style="font-size: 14px; cursor: pointer">
        <div class="col-5">
          <span class="text-light border border-1" @click="airlineSelect()"
            style="padding: 2px 6px; border-radius: 4px; background: #002d5b">{{
              $t("searchPageContent.filterContent.selectAll") }}</span>
        </div>
        <div class="col-5 text-end pe-0">
          <span class="text-light border border-1" @click="airlineClear()"
            style="padding: 2px 6px; border-radius: 4px; background: #002d5b">{{
              $t("searchPageContent.filterContent.clearAll") }}</span>
        </div>
      </div>
      <div class="scrollbar" id="scrollbarstyle" style="max-height: 130px; height: auto">
        <div class="stopcheckbox ps-3">
          <div v-for="(data, index) of airlineList.slice(0, maxItemsToShow)" :key="index">
            <v-checkbox v-model="data.active" :label="data.airline" @click="selectAirline(data, index)"
              @change="applyFilters" class="mb-2"></v-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9" style="color:black !important" v-if="isTripDataAvailable">

    <div class="row mb-3 gx-0 mx-0" style="cursor: pointer">
      <div class="col-md-3 col-6 rec-bottom" :class="active ? 'recment' : 'normal-rec'">
        <div class="d-flex p-2" style="justify-content: space-around" @click="Recommend()">
          <div>
            <v-icon :style="active ? 'color:#ee2b34' : 'color:grey'" size="40">mdi-seal</v-icon>
          </div>
          <div>
            <div class="top_filterTitle">
              {{ $t('searchPageContent.filterContent.recommended') }}
            </div>
            <div class="top_filterprice">
              {{ recCurrecy }} {{ recperperson }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-6" :class="active1 ? 'recment' : 'normal-rec'">
        <div class="d-flex p-2" style="justify-content: space-around" @click="bestlayTime(1)">
          <div>
            <v-icon :style="active1 ? 'color:#ee2b34' : 'color:grey'" size="40">mdi-star</v-icon>
          </div>
          <div>
            <div class="top_filterTitle">{{ $t('searchPageContent.filterContent.best') }}
            </div>
            <div class="top_filterprice">
              {{ recCurrecy }} {{ layperperson }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-6" :class="active2 ? 'recment' : 'normal-rec'">
        <div class="d-flex p-2" style="justify-content: space-around" @click="Cheapest(1)">
          <div>
            <v-icon :style="active2 ? 'color:#ee2b34' : 'color:grey'" size="40">mdi-currency-usd</v-icon>
          </div>
          <div>
            <div class="top_filterTitle">{{ $t('searchPageContent.filterContent.cheapest') }}
            </div>
            <div class="top_filterprice">
              {{ recCurrecy }} {{ recperperson }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-6 rec-bottom1" :class="active3 ? 'recment' : 'normal-rec'">
        <div class="d-flex p-2" style="justify-content: space-around" @click="Shortest(1)">
          <div>
            <v-icon :style="active3 ? 'color:#ee2b34' : 'color:grey'" size="40">mdi-clock</v-icon>
          </div>
          <div>
            <div class="top_filterTitle">{{ $t('searchPageContent.filterContent.shortest') }}
            </div>
            <div class="top_filterprice">
              {{ shorCurrecy }} {{ shotperperson }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Flight 01-->
    <div v-for="(res, index) of rountresult" :key="index" class="flight-bg">
      <div class="row">
        <div class="flightlogo col-12 col-sm-2 col-md-2 col-lg-2 d-flex justify-content-sm-center align-items-center">
          <div class="d-flex align-center mb-sm-0 mb-2">
            <img :src="res.ownerlogo" class="flogo" alt="" />
            <h2 class="d-sm-none d-block ms-2 mb-0">{{ res.ownername }}</h2>
          </div>
        </div>

        <div class="col-12 col-sm-8 col-md-8 col-lg-8">
          <div class="row">
            <div class="col-5 col-sm-4 d-flex flex-column align-center">
              <span class="ft">{{ getTime(res.Depature) }}</span>

              <div class="d-flex w-md-auto justify-center aircity">
                <span class="text-truncate">{{ getCountryName1(res.fromTrip) }}
                </span>
                <span class="ms-1"> ({{ res.Depature[0].Departure.AirportCode }})</span>
              </div>
            </div>

            <div class="col-2 w-sm-auto ftime px-0 m-auto">
              <span>{{ getTotalTime(res) }} </span>
              <span>{{ res.Depaturejourney.Stops == 0 ? 'Direct' : res.Depaturejourney.Stops }}
                {{ $t("searchPageContent.itinContent.stop") }}</span>
            </div>

            <div class="col-5 col-sm-4 d-flex flex-column align-center">
              <span class="ft">{{ getArrivalTime(res.Depature) }}</span>

              <div class="d-flex w-md-auto justify-center aircity">
                <span class="text-truncate">{{ getCountryName2(res.toTrip) }}
                </span>
                <span class="ms-1">({{ getcitycode(res.Depature[res.Depature.length - 1].Arrival.AirportCode) }})</span>
              </div>
            </div>
          </div>
          <div v-if="res.Return" class="row mt-2">
            <div class="col-5 col-sm-4 d-flex flex-column align-center">
              <span class="ft">{{ getTime(res.Return) }}</span>

              <div class="d-flex w-md-auto justify-center aircity">
                <span class="text-truncate">
                  {{ getCountryName2(res.toTrip) }}
                </span>
                <span class="ms-1">({{ res.Return[0].Departure.AirportCode }})</span>
              </div>
            </div>

            <div class="col-2 w-sm-auto ftime px-0 m-auto">
              <span>{{ getReturnTotTime(res) }} </span>

              <span v-if="res.Returnjourney.Stops == 0">Direct</span>
              <span v-if="res.Returnjourney.Stops == 1">{{ res.Returnjourney.Stops == 0 ? 'Direct' : res.Returnjourney.Stops }}
                {{ $t("searchPageContent.itinContent.stop") }}</span>
              <span v-if="res.Returnjourney.Stops > 1">{{ res.Returnjourney.Stops == 0 ? 'Direct' : res.Returnjourney.Stops }}
                Stops</span>
            </div>

            <div class="col-5 col-sm-4 d-flex flex-column align-center">
              <span class="ft">{{ getArrivalTime(res.Return) }}</span>

              <div class="d-flex w-md-auto justify-center aircity">
                <span class="text-truncate">
                  {{ getCountryName1(res.fromTrip) }}
                </span>

                <span class="ms-1">({{ res.Return[res.Return.length - 1].Arrival.AirportCode }})</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-2 col-md-2 col-lg-2 m-auto price d-flex flex-column align-center">
        <span style="font-size: 13px;font-weight:500">Per Person</span>
          <span>{{ res.currency }} {{ res.find1 }}</span>
          <!-- <a href="/checkout" @click.prevent="selected(res, index)" class="book"
            >Book Now</a
          > -->
          <v-btn @click.prevent="selected(res, index)" :disabled="selectedIndex === index" class="book text-capitalize"
            width="100%"><span v-if="!selectedIndex !== index && selectedIndex !== index"
              style="font-size: 14px; font-weight: 400">{{ $t("searchPageContent.itinContent.bookNow") }}</span>
            <v-progress-circular class="mb-2" v-if="selectedIndex === index" :size="18" :width="4"
              indeterminate></v-progress-circular>
          </v-btn>
        </div>
      </div>

      <div class="flight-detail-bg">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-3 ">
            <h2>{{ res.ownername }}</h2>
          </div>

          <div class="col-5 col-sm-4 col-md-2 col-lg-1">
            <p class="details m-0">{{ res.cabinClass }}</p>
          </div>

          <div class="col-5 col-sm-4 col-md-2 col-lg-2">
            <p class="details m-0">{{ res.Refund }}</p>
          </div>

          <div class="col-6 col-sm-4 col-md-2 col-lg-1 d-none">
            <p class="details m-0" @click.prevent="openDeeplink(res.deeplinkurl)"
              style="text-decoration: none; color: #000080; ">DeeplinkURL</p>
          </div>

          <div class="col-5 col-sm-4 col-md-2 col-lg-2">
            <p v-if="rounddatafil" @click="faredetailrount(res, index)" class="details d-flex m-0" style="color: navy"
              data-toggle="collapse" data-target="#fareDetails">
              <span>{{
                $t("searchPageContent.itinContent.fareDetails")
              }}</span>
              <span class="ms-1">
                <i v-if="!res.FareDetails" class="bi bi-chevron-down"></i>
                <i v-if="res.FareDetails" class="bi bi-chevron-up"></i>
              </span>
            </p>
            <p v-if="onedatafil" @click="faredetailoneway(res, index)" class="details d-flex m-0" style="color: navy"
              data-toggle="collapse" data-target="#fareDetails">
              <span>{{
                $t("searchPageContent.itinContent.fareDetails")
              }}</span>
              <span class="ms-1">
                <i v-if="!res.FareDetails" class="bi bi-chevron-down"></i>
                <i v-if="res.FareDetails" class="bi bi-chevron-up"></i>
              </span>
            </p>
          </div>

          <div class="col-5 col-sm-4 col-md-2 col-lg-2">
            <p v-if="rounddatafil" @click="flighted1(res, index)" class="details d-flex m-0" style="color: navy"
              data-toggle="collapse" data-target="#flightDetails">
              <span>{{
                $t("searchPageContent.itinContent.flightDetails")
              }}</span>
              <span class="ms-1">
                <i v-if="!res.Flightdepature" class="bi bi-chevron-down"></i>
                <i v-if="res.Flightdepature" class="bi bi-chevron-up"></i>
              </span>
            </p>
            <p v-if="onedatafil" @click="flighted2(res, index)" class="details d-flex m-0" style="color: navy"
              data-toggle="collapse" data-target="#flightDetails">
              <span>{{
                $t("searchPageContent.itinContent.flightDetails")
              }}</span>
              <span class="ms-1">
                <i v-if="!res.Flightdepature" class="bi bi-chevron-down"></i>
                <i v-if="res.Flightdepature" class="bi bi-chevron-up"></i>
              </span>
            </p>
          </div>

          <div class="col-6 col-sm-6 col-md-2 col-lg-2">
            <p v-if="res.brantvalue" @click="fareSharing(res, index)" class="details d-flex m-0" style="color: navy">
              <span>{{
                !res.barantFare
                  ? $t("searchPageContent.itinContent.fareOptions")
                  : $t("searchPageContent.itinContent.hideOptions")
              }}</span>
              <span class="ms-1">
                <i v-if="!res.barantFare" class="bi bi-chevron-down"></i>
                <i v-if="res.barantFare" class="bi bi-chevron-up"></i>
              </span>
            </p>
          </div>
        </div>
      </div>

      <div v-if="res.barantFare" class="row gx-0" style="border-top: 1px solid lightgray">
        <div>
          <brantfareCom :childData="this.barentFareData" @childValue="handleChildValue"></brantfareCom>
        </div>
      </div>

      <div class="flight-detail" :class="{ collapse: !res.Flightdepature }" id="flightDetails">
        <div class="row">
          <div class="col-sm-12 col-md-8 col-lg-9">
            <div class="row">
              <p v-if="res.Depature" class="d-flex border border-1 rounded px-2 w-auto">
                <span class="me-2">{{ getAirportCode(res.Depature) }} -
                  {{ getArrivalCode(res.Depature) }}</span>
                <span><i data-v-2ed63808="" class="fa fa-plane"></i></span>
              </p>
              <div class="col-sm-12 col-md-8 col-lg-12" v-for="(data, index) of res.Depature" :key="index">
                <div class="row">
                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <span class="loc">{{ data.Departure.AirportCode }}</span>

                    <span class="time">{{ data.Departure.Time }}</span>

                    <span class="date">{{ getdated1(data.Arrival.Date) }}</span>

                    <span class="air">{{ data.Departure.AirportName }}</span>
                  </div>

                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="d-flex justify-center">
                      <span><img :src="data.MarketingCarrier.logo" class="flogo1" alt="" /></span>
                      <span class="ms-2 d-flex flex-column">
                        <div class="air" style="width:16ch;">{{ data.MarketingCarrier.Name }}</div>


                        <div style="    font-size: 11px;
    font-weight: 500;
    text-align: center;">
                          {{ data.MarketingCarrier.AirlineID }} -{{ data.MarketingCarrier.FlightNumber }}

                        </div>
                      </span>
                    </div>
                    <div class="line">
                      <i class="fa fa-plane" style="margin-top: 4px !important;"></i>
                    </div>
                    <div style="text-align: center;">
                      {{ getchangeTime(data.FlightDetail.FlightDuration.Value) }}
                    </div>

                  </div>

                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <span class="loc">{{ data.Arrival.AirportCode }}</span>

                    <span class="time">{{ data.Arrival.Time }}</span>

                    <span class="date">{{ getdated1(data.Arrival.Date) }}</span>

                    <span class="air">{{ data.Arrival.AirportName }}</span>
                  </div>
                </div>

                <div class="change-plan" v-if="index < res.Depature.length - 1">
                  {{ $t("searchPageContent.itinContent.changeplane") }}
                  {{ data.Arrival.AirportCode }},
                  {{ $t("searchPageContent.itinContent.time") }}
                  {{
                    res.layoverTimes1
                      ? res.layoverTimes1[index]
                      : res.layoverTimes[index]
                  }}
                </div>
              </div>

              <p v-if="res.Return" class="d-flex border border-1 rounded px-2 w-auto mt-3">
                <span class="me-2">{{ getreturnAirportCode(res.Return) }} -
                  {{ getreturnArrivalcode(res.Return) }}</span>
                <span><i data-v-2ed63808="" class="fa fa-plane" style="margin-top: 4px !important;"></i></span>
              </p>
              <div class="col-sm-12 col-md-8 col-lg-12" v-for="(data, index) of res.Return" :key="index">
                <div class="row">
                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <span class="loc">{{ data.Departure.AirportCode }}</span>

                    <span class="time">{{ data.Departure.Time }}</span>

                    <span class="date">{{
                      getdated(data.Departure.Date)
                    }}</span>

                    <span class="air">{{ data.Departure.AirportName }}</span>
                  </div>



                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="d-flex justify-center">
                      <span><img :src="data.MarketingCarrier.logo" class="flogo1" alt="" /></span>
                      <span class="ms-2 d-flex flex-column">
                        <div class="air" style="width:16ch;">{{ data.MarketingCarrier.Name }}</div>


                        <div style="    font-size: 11px;
    font-weight: 500;
    text-align: center;">
                          {{ data.MarketingCarrier.AirlineID }} -{{ data.MarketingCarrier.FlightNumber }}

                        </div>
                      </span>
                    </div>
                    <div class="line">
                      <i class="fa fa-plane" style="margin-top: 4px !important;"></i>
                    </div>
                    <div style="text-align: center;">
                      {{ getchangeTime(data.FlightDetail.FlightDuration.Value) }}
                    </div>

                  </div>


                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <span class="loc">{{ data.Arrival.AirportCode }}</span>

                    <span class="time">{{ data.Arrival.Time }}</span>

                    <span class="date">{{ getdated1(data.Arrival.Date) }}</span>

                    <span class="air">{{ data.Arrival.AirportName }}</span>
                  </div>
                </div>

                <div class="change-plan" v-if="index < res.Return.length - 1">
                  {{ $t("searchPageContent.itinContent.changeplane") }}
                  {{ data.Arrival.AirportCode }},
                  {{ $t("searchPageContent.itinContent.time") }}
                  {{ res.layoverTimes2[index] }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-4 col-lg-3">
            <div class="feat text-end">
              <div class="row">
                <div class="col-4 col-md-12 col-lg-12">
                  <b class="mb-2">{{
                    $t("searchPageContent.flightDetails.baggage")
                  }}</b>

                  <span>{{ res.Depature[0].Departure.AirportCode }} -
                    {{ getcitycode(res.toTrip) }}</span>

                  <br />
                </div>

                <div class="col-4 col-md-12 col-lg-12">
                  <b>{{ $t("baggageContent.checked") }}</b>

                  <span>{{ res?.depBaggage?.PieceAllowance?.TotalQuantity || 0 }}
                    {{ $t("baggageContent.pieces") }}</span>

                  <br />
                </div>
                <div class="col-4 col-md-12 col-lg-12">
                  <b>{{ $t("baggageContent.cabin") }}</b>

                  <span>{{
                    res?.depBaggage?.PieceAllowance?.TotalQuantity > 0
                      ? "1 Piece"
                      : "0 Piece"
                  }}</span>

                  <br />
                </div>
              </div>

              <div class="row">
                <div class="col-4 col-md-12 col-lg-12">
                  <!-- <b>BAGGAGE</b> -->

                  <span>{{ getcitycode(res.toTrip) }} -
                    {{ res.Depature[0].Departure.AirportCode }}</span>

                  <br />
                </div>

                <div class="col-4 col-md-12 col-lg-12">
                  <b>{{ $t("baggageContent.checked") }}</b>

                  <span>{{ res.arrBaggage.PieceAllowance?.TotalQuantity || 0 }}
                    {{ $t("baggageContent.pieces") }}</span>

                  <br />
                </div>
                <div class="col-4 col-md-12 col-lg-12">
                  <b>{{ $t("baggageContent.cabin") }}</b>

                  <span>{{
                    res.arrBaggage.PieceAllowance?.TotalQuantity > 0
                      ? "1 Piece"
                      : "0 Piece"
                  }}</span>

                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="amenities d-none">
          <div class="row">
            <div class="col-6 col-sm-6 col-md-6 col-lg-3">
              <i class="fa fa-wifi"></i> Email & messaging (paid)
            </div>

            <div class="col-6 col-sm-6 col-md-6 col-lg-3">
              <i class="fa fa-plug"></i> Power & USB outlets
            </div>

            <div class="col-6 col-sm-6 col-md-6 col-lg-3">
              <i class="fa fa-desktop"></i> On demand entertainment
            </div>

            <div class="col-6 col-sm-6 col-md-6 col-lg-3">
              <i class="fa fa-cutlery"></i> Meal provided
            </div>
          </div>
        </div>
      </div>

      <div class="fare" id="fareDetails" :class="{ collapse: !res.FareDetails }">
        <div v-for="(flightdatas, index) of flightdetailsData" :key="index">
          <div class="d-flex justify-content-between">
            <div>
              <p class="m-0" style="font-size: 16px; font-weight: 600">
                {{ $t("searchPageContent.itinContent.totalPrice") }}
              </p>
            </div>
            <div>
              <p class="fw-bold tot-price m-0" style="font-size: 16px">
                {{ flightdatas.currency }} {{ flightdatas.TotalPrice }}
              </p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <div class="row justify-content-between">
                <div class="col-4">
                  <span style="font-size: 13px; font-weight: 500">{{
                    $t("searchPageContent.itinContent.passenger")
                  }}</span>
                </div>
                <div class="col-4" style="font-size: 14px; text-align: end">
                  <span v-if="flightdatas.Adult > 0">{{ flightdatas.Adult }} x
                    {{ $t("searchPageContent.itinContent.adult") }}</span>
                  <span v-if="flightdatas.Child > 0">{{ flightdatas.Child }} x
                    {{ $t("searchPageContent.itinContent.child") }}</span>
                  <span v-if="flightdatas.Inf > 0">{{ flightdatas.Inf }} x
                    {{ $t("searchPageContent.itinContent.infant") }}</span>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-borderless text-center mb-0">
                  <thead>
                    <tr class="f-size-12 fw-500">
                      <td style="background: #dee2e6">
                        {{ $t("searchPageContent.itinContent.pax") }}
                      </td>
                      <td style="background: #dee2e6">
                        {{ $t("searchPageContent.itinContent.base") }}
                      </td>
                      <td style="background: #dee2e6">
                        {{ $t("searchPageContent.itinContent.tax") }}
                      </td>
                      <td style="background: #dee2e6">
                        {{ $t("searchPageContent.itinContent.perPerson") }}
                      </td>
                    </tr>
                  </thead>
                  <tbody class="f-size-10 fw-400">
                    <template v-for="(priceDetail, paxindex) in res.pricedetails" :key="paxindex">
                      <tr v-if="
                        priceDetail &&
                        priceDetail.pax_type &&
                        priceDetail.baseprice &&
                        priceDetail.taxprice
                      ">
                        <td>{{ priceDetail.pax_type }}</td>
                        <td>{{ priceDetail.baseprice }}</td>
                        <td>{{ priceDetail.taxprice }}</td>
                        <td>{{ getperpersonPrice(priceDetail) }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="bordeer-top-0 border border-2 border-top-0">
            <div></div>
          </div>
        </div>
      </div>
    </div>

    <div v-for="(res, index) of onewayresulted" :key="index" class="flight-bg">
      <div class="row">
        <div class="flightlogo col-12 col-sm-2 col-md-2 col-lg-2 d-flex justify-content-sm-center align-items-center">
          <div class="d-flex align-center mb-sm-0 mb-2">
            <img :src="res.ownerlogo" class="flogo" alt="" />
            <h2 class="d-sm-none d-block ms-2 mb-0">{{ res.ownername }}</h2>
          </div>
        </div>

        <div class="col-12 col-sm-8 col-md-8 col-lg-8">
          <div class="row">
            <div class="col-5 col-sm-4 d-flex flex-column align-center">
              <span class="ft">{{ getTime(res.Depature) }}</span>

              <div class="d-flex w-md-auto justify-center aircity">
                <span class="text-truncate">{{ getCountryName1(res.fromTrip) }}
                </span>
                <span class="ms-1">({{ res.Depature[0].Departure.AirportCode }})</span>
              </div>
            </div>

            <div class="col-2 w-sm-auto ftime px-0 m-auto">
              <span>{{ getTotalTime(res) }} </span>
              <span>{{ res.Depaturejourney.Stops == 0 ? 'Direct' : res.Depaturejourney.Stops }}
                {{ $t("searchPageContent.itinContent.stop") }}</span>
            </div>

            <div class="col-5 col-sm-4 d-flex flex-column align-center">
              <span class="ft">{{ getArrivalTime(res.Depature) }}</span>

              <div class="d-flex w-md-auto justify-center aircity">
                <span class="text-truncate">{{ getCountryName2(res.toTrip) }}
                </span>
                <span class="ms-1">({{ res.Depature[res.Depature.length - 1].Arrival.AirportCode }})</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-2 col-md-2 col-lg-2 m-auto price d-flex flex-column align-center">
          <span style="font-size: 13px;font-weight:500">Per Person</span>
          <span>{{ res.currency }} {{ res.find1 }}</span>
          <!-- <a href="/checkout" @click.prevent="onewayselect(res, index)" class="book">Book Now</a> -->
          <v-btn @click.prevent="selected(res, index)" :disabled="selectedIndex === index" class="book text-capitalize"
            width="100%"><span v-if="!selectedIndex !== index && selectedIndex !== index"
              style="font-size: 14px; font-weight: 400">{{ $t("searchPageContent.itinContent.bookNow") }}</span>
            <span><v-progress-circular v-if="selectedIndex === index" :size="18" :width="4"
                indeterminate></v-progress-circular></span>
          </v-btn>
        </div>
      </div>

      <div class="flight-detail-bg">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-3 ">
            <h2>{{ res.ownername }}</h2>
          </div>

          <div class="col-5 col-sm-4 col-md-2 col-lg-1">
            <p class="details m-0">{{ res.cabinClass }}</p>
          </div>

          <div class="col-5 col-sm-4 col-md-2 col-lg-2">
            <p class="details m-0">{{ res.Refund }}</p>
          </div>

          <div class="col-6 col-sm-4 col-md-2 col-lg-1 d-none">
            <p class="details m-0" @click.prevent="openDeeplink(res.deeplinkurl)"
              style="text-decoration: none; color: #000080; ">DeeplinkURL</p>
          </div>

          <div class="col-5 col-sm-4 col-md-2 col-lg-2">
            <p v-if="rounddatafil" @click="faredetailrount(res, index)" class="details d-flex m-0" style="color: navy"
              data-toggle="collapse" data-target="#fareDetails">
              <span>{{
                $t("searchPageContent.itinContent.fareDetails")
              }}</span>
              <span class="ms-1">
                <i v-if="!res.FareDetails" class="bi bi-chevron-down"></i>
                <i v-if="res.FareDetails" class="bi bi-chevron-up"></i>
              </span>
            </p>
            <p v-if="onedatafil" @click="faredetailoneway(res, index)" class="details d-flex m-0" style="color: navy"
              data-toggle="collapse" data-target="#fareDetails">
              <span>{{
                $t("searchPageContent.itinContent.fareDetails")
              }}</span>
              <span class="ms-1">
                <i v-if="!res.FareDetails" class="bi bi-chevron-down"></i>
                <i v-if="res.FareDetails" class="bi bi-chevron-up"></i>
              </span>
            </p>
          </div>

          <div class="col-5 col-sm-4 col-md-2 col-lg-2">
            <p v-if="rounddatafil" @click="flighted1(res, index)" class="details d-flex m-0" style="color: navy"
              data-toggle="collapse" data-target="#flightDetails">
              <span>{{
                $t("searchPageContent.itinContent.flightDetails")
              }}</span>
              <span class="ms-1">
                <i v-if="!res.Flightdepature" class="bi bi-chevron-down"></i>
                <i v-if="res.Flightdepature" class="bi bi-chevron-up"></i>
              </span>
            </p>
            <p v-if="onedatafil" @click="flighted2(res, index)" class="details d-flex m-0" style="color: navy"
              data-toggle="collapse" data-target="#flightDetails">
              <span>{{
                $t("searchPageContent.itinContent.flightDetails")
              }}</span>
              <span class="ms-1">
                <i v-if="!res.Flightdepature" class="bi bi-chevron-down"></i>
                <i v-if="res.Flightdepature" class="bi bi-chevron-up"></i>
              </span>
            </p>
          </div>

          <div class="col-6 col-sm-6 col-md-2 col-lg-2">
            <p v-if="res.brantvalue" @click="fareSharing(res, index)" class="details d-flex m-0" style="color: navy">
              <span>{{
                !res.barantFare
                  ? $t("searchPageContent.itinContent.fareOptions")
                  : $t("searchPageContent.itinContent.hideOptions")
              }}</span>
              <span class="ms-1">
                <i v-if="!res.barantFare" class="bi bi-chevron-down"></i>
                <i v-if="res.barantFare" class="bi bi-chevron-up"></i>
              </span>
            </p>
          </div>
        </div>
      </div>

      <div v-if="res.barantFare" class="row gx-0" style="border-top: 1px solid lightgray">
        <div>
          <brantfareCom :childData="this.barentFareData" @childValue="handleChildValue"></brantfareCom>
        </div>
      </div>

      <div class="flight-detail" :class="{ collapse: !res.Flightdepature }" id="flightDetails">
        <div class="row">
          <div class="col-sm-12 col-md-8 col-lg-9">
            <div class="row">
              <p v-if="res.Depature" class="d-flex border border-1 rounded px-2 w-auto">
                <span class="me-2">{{ getAirportCode(res.Depature) }} -
                  {{ getArrivalCode(res.Depature) }}</span>
                <span><i data-v-2ed63808="" class="fa fa-plane"></i></span>
              </p>
              <div class="col-sm-12 col-md-8 col-lg-12" v-for="(data, index) of res.Depature" :key="index">
                <div class="row">
                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <span class="loc">{{ data.Departure.AirportCode }}</span>

                    <span class="time">{{ data.Departure.Time }}</span>

                    <span class="date">{{ getdated1(data.Arrival.Date) }}</span>

                    <span class="air">{{ data.Departure.AirportName }}</span>
                  </div>

                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="d-flex justify-center">
                      <span><img :src="data.MarketingCarrier.logo" class="flogo1" alt="" /></span>
                      <span class="ms-2 d-flex flex-column">
                        <div class="air" style="width:16ch;">{{ data.MarketingCarrier.Name }}</div>


                        <div style="font-size: 11px;
    font-weight: 500;
    text-align: center;">
                          {{ data.MarketingCarrier.AirlineID }} -{{ data.MarketingCarrier.FlightNumber }}

                        </div>
                      </span>
                    </div>
                    <div class="line">
                      <i class="fa fa-plane" style="margin-top: 4px !important;"></i>
                    </div>
                    <div style="text-align: center;">
                      {{ getchangeTime(data.FlightDetail.FlightDuration.Value) }}
                    </div>

                  </div>

                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <span class="loc">{{ data.Arrival.AirportCode }}</span>

                    <span class="time">{{ data.Arrival.Time }}</span>

                    <span class="date">{{ getdated1(data.Arrival.Date) }}</span>

                    <span class="air">{{ data.Arrival.AirportName }}</span>
                  </div>
                </div>

                <div class="change-plan" v-if="index < res.Depature.length - 1">
                  {{ $t("searchPageContent.itinContent.changeplane") }}
                  {{ data.Arrival.AirportCode }},
                  {{ $t("searchPageContent.itinContent.time") }}
                  {{
                    res.layoverTimes1
                      ? res.layoverTimes1[index]
                      : res.layoverTimes[index]
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-4 col-lg-3">
            <div class="feat text-end">
              <div class="row">
                <div class="col-4 col-md-12 col-lg-12">
                  <b class="mb-2">{{
                    $t("searchPageContent.flightDetails.baggage")
                  }}</b>

                  <span>{{ res.Depature[0].Departure.AirportCode }} -
                    {{ getcitycode(res.toTrip) }}</span>

                  <br />
                </div>

                <div class="col-4 col-md-12 col-lg-12">
                  <b>{{ $t("baggageContent.checked") }}</b>

                  <span>{{ res?.depBaggage?.PieceAllowance?.TotalQuantity || 0 }}
                    {{ $t("baggageContent.pieces") }}</span>

                  <br />
                </div>
                <div class="col-4 col-md-12 col-lg-12">
                  <b>{{ $t("baggageContent.cabin") }}</b>

                  <span>{{
                    res?.depBaggage?.PieceAllowance?.TotalQuantity > 0
                      ? "1 Piece"
                      : "0 Piece"
                  }}</span>

                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="amenities d-none">
          <div class="row">
            <div class="col-6 col-sm-6 col-md-6 col-lg-3">
              <i class="fa fa-wifi"></i> Email & messaging (paid)
            </div>

            <div class="col-6 col-sm-6 col-md-6 col-lg-3">
              <i class="fa fa-plug"></i> Power & USB outlets
            </div>

            <div class="col-6 col-sm-6 col-md-6 col-lg-3">
              <i class="fa fa-desktop"></i> On demand entertainment
            </div>

            <div class="col-6 col-sm-6 col-md-6 col-lg-3">
              <i class="fa fa-cutlery"></i> Meal provided
            </div>
          </div>
        </div>
      </div>
      <div class="fare" id="fareDetails" :class="{ collapse: !res.FareDetails }">
        <div v-for="(flightdatas, index) of flightdetailsData1" :key="index">
          <div class="d-flex justify-content-between">
            <div>
              <p class="m-0" style="font-size: 16px; font-weight: 600">
                {{ $t("searchPageContent.itinContent.totalPrice") }}
              </p>
            </div>
            <div>
              <p class="fw-bold tot-price m-0" style="font-size: 16px">
                {{ flightdatas.currency }} {{ flightdatas.TotalPrice }}
              </p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <div class="row justify-content-between">
                <div class="col-4">
                  <span style="font-size: 13px; font-weight: 500">{{
                    $t("searchPageContent.itinContent.passenger")
                  }}</span>
                </div>
                <div class="col-4" style="font-size: 14px; text-align: end">
                  <span v-if="flightdatas.Adult > 0">{{ flightdatas.Adult }} x
                    {{ $t("searchPageContent.itinContent.adult") }}</span>
                  <span v-if="flightdatas.Child > 0">{{ flightdatas.Child }} x
                    {{ $t("searchPageContent.itinContent.child") }}</span>
                  <span v-if="flightdatas.Inf > 0">{{ flightdatas.Inf }} x
                    {{ $t("searchPageContent.itinContent.infant") }}</span>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-borderless text-center mb-0">
                  <thead>
                    <tr class="f-size-12 fw-500">
                      <td style="background: #dee2e6">
                        {{ $t("searchPageContent.itinContent.pax") }}
                      </td>
                      <td style="background: #dee2e6">
                        {{ $t("searchPageContent.itinContent.base") }}
                      </td>
                      <td style="background: #dee2e6">
                        {{ $t("searchPageContent.itinContent.tax") }}
                      </td>
                      <td style="background: #dee2e6">
                        {{ $t("searchPageContent.itinContent.perPerson") }}
                      </td>
                    </tr>
                  </thead>
                  <tbody class="f-size-10 fw-400">
                    <template v-for="(priceDetail, paxindex) in res.pricedetails" :key="paxindex">
                      <tr v-if="
                        priceDetail &&
                        priceDetail.pax_type &&
                        priceDetail.baseprice &&
                        priceDetail.taxprice
                      ">
                        <td>{{ priceDetail.pax_type }}</td>
                        <td>{{ priceDetail.baseprice }}</td>
                        <td>{{ priceDetail.taxprice }}</td>
                        <td>{{ getperpersonPrice(priceDetail) }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="bordeer-top-0 border border-2 border-top-0">
            <div></div>
          </div>
        </div>
      </div>
      <div class="fare collapse" id="fare">
        <div class="row">
          <div class="col-sm-12 col-md-5 col-lg-4">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th colspan="2">Fare breakup</th>
                  </tr>
                </thead>

                <tr>
                  <td>TOTAL</td>

                  <td>Rs 45,997</td>
                </tr>

                <tr>
                  <td><small>Base Fare</small></td>

                  <td>Rs 35,885</td>
                </tr>

                <tr>
                  <td><small>Surcharges</small></td>

                  <td>Rs 15,222</td>
                </tr>

                <tr>
                  <td><small>Instant discount applied</small></td>

                  <td>-Rs 5,110</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="col-sm-12 col-md-7 col-lg-8">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th colspan="3">
                      Fare rules DEL - DUB
                      <small>All charges are per passenger</small>
                    </th>
                  </tr>
                </thead>

                <tr>
                  <td><small>TIME</small></td>

                  <td>
                    <small> CANCELLATION FEE (Airline Fee + MMT Fee) </small>
                  </td>

                  <td>
                    <small>
                      DATE CHANGE FEES (Airline Fee + MMT Fee + Fare Difference
                      )
                    </small>
                  </td>
                </tr>

                <tr>
                  <td><small>24 hours to 365 days*</small></td>

                  <td><small>ADULT : ₹ 11,924 + ₹ 649</small></td>

                  <td>
                    <small>ADULT : ₹ 9,561 + ₹ 500 + Fare difference</small>
                  </td>
                </tr>
              </table>
            </div>

            <small>* From the Date of Departure</small>
          </div>
        </div>
      </div>
    </div>

    <div v-for="(res, index) of multicityData" :key="index" class="flight-bg">
      <div class="row">
        <div class="col-12 col-sm-10 col-md-10 col-lg-10">
          <!-- Flight 1 Start -->
          <div class="row">
            <div class="col-2">
              <img :src="res.Flight1[0].MarketingCarrier.logo" class="flogo" alt="" />
            </div>
            <div class="col-10">
              <div class="row">
                <div class="col-5 col-sm-4 d-flex flex-column align-center">
                  <span class="ft">{{ getTime(res.Flight1) }}</span>

                  <div class="d-flex w-md-auto justify-center aircity">
                    <span class="text-truncate">{{ getCountryName1(res.muticityData[0].from) }}
                    </span>
                    <span class="ms-1">({{ res.Flight1[0].Departure.AirportCode }})</span>
                  </div>
                </div>

                <div class="col-2 w-sm-auto ftime px-0 m-auto">
                  <span>{{ getMultiTotTime(res) }} </span>
                  <span>{{ res.Flight1JourneyTime.Stops }}
                    {{ $t("searchPageContent.itinContent.stop") }}</span>
                </div>

                <div class="col-5 col-sm-4 d-flex flex-column align-center">
                  <span class="ft">{{ getarrtime(res.Flight1) }}</span>

                  <div class="d-flex w-md-auto justify-center aircity">
                    <span class="text-truncate">{{ getCountryName2(res.muticityData[0].to) }}
                    </span>
                    <span class="ms-1">({{ res.Flight1[res.Flight1.length - 1].Arrival.AirportCode }})</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Flight 1 End -->

          <!-- Flight 2 Start -->
          <div class="row mt-3">
            <div class="col-2">
              <img :src="res.Flight2[0].MarketingCarrier.logo" class="flogo" alt="" />
            </div>
            <div class="col-10">
              <div class="row">
                <div class="col-5 col-sm-4 d-flex flex-column align-center">
                  <span class="ft">{{ getTime(res.Flight2) }}</span>

                  <div class="d-flex w-md-auto justify-center aircity">
                    <span class="text-truncate">{{ getCountryName1(res.muticityData[1].from) }}
                    </span>
                    <span class="ms-1">({{ res.Flight2[0].Departure.AirportCode }})</span>
                  </div>
                </div>

                <div class="col-2 w-sm-auto ftime px-0 m-auto">
                  <span>{{ getMultiTotTime1(res) }} </span>
                  <span>{{ res.Flight2JourneyTime.Stops }}
                    {{ $t("searchPageContent.itinContent.stop") }}</span>
                </div>

                <div class="col-5 col-sm-4 d-flex flex-column align-center">
                  <span class="ft">{{ getarrtime(res.Flight2) }}</span>

                  <div class="d-flex w-md-auto justify-center aircity">
                    <span class="text-truncate">{{ getCountryName2(res.muticityData[1].to) }}
                    </span>
                    <span class="ms-1">({{ res.Flight2[res.Flight2.length - 1].Arrival.AirportCode }})</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Flight 2 End -->

          <!-- Flight 3 Start -->
          <div class="row mt-3" v-if="res.Flight3 && res.Flight3.length > 0">
            <div class="col-2">
              <img :src="res.Flight3[0].MarketingCarrier.logo" class="flogo" alt="" />
            </div>
            <div class="col-10">
              <div class="row">
                <div class="col-5 col-sm-4 d-flex flex-column align-center">
                  <span class="ft">{{ getTime(res.Flight3) }}</span>

                  <div class="d-flex w-md-auto justify-center aircity">
                    <span class="text-truncate">{{ getCountryName1(res.muticityData[2].from) }}
                    </span>
                    <span class="ms-1">({{ res.Flight3[0].Departure.AirportCode }})</span>
                  </div>
                </div>

                <div class="col-2 w-sm-auto ftime px-0 m-auto">
                  <span>{{ getMultiTotTime2(res) }} </span>
                  <span>{{ res.Flight3JourneyTime.Stops }}
                    {{ $t("searchPageContent.itinContent.stop") }}</span>
                </div>

                <div class="col-5 col-sm-4 d-flex flex-column align-center">
                  <span class="ft">{{ getarrtime(res.Flight3) }}</span>

                  <div class="d-flex w-md-auto justify-center aircity">
                    <span class="text-truncate">{{ getCountryName2(res.muticityData[2].to) }}
                    </span>
                    <span class="ms-1">({{ res.Flight3[res.Flight3.length - 1].Arrival.AirportCode }})</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Flight 3 End -->

          <!-- Flight 4 Start -->
          <div class="row mt-3" v-if="res.Flight4 && res.Flight4.length > 0">
            <div class="col-2">
              <img :src="res.Flight4[0].MarketingCarrier.logo" class="flogo" alt="" />
            </div>
            <div class="col-10">
              <div class="row">
                <div class="col-5 col-sm-4 d-flex flex-column align-center">
                  <span class="ft">{{ getTime(res.Flight4) }}</span>

                  <div class="d-flex w-md-auto justify-center aircity">
                    <span class="text-truncate">{{ getCountryName1(res.muticityData[3].from) }}
                    </span>
                    <span class="ms-1">({{ res.Flight4[0].Departure.AirportCode }})</span>
                  </div>
                </div>

                <div class="col-2 w-sm-auto ftime px-0 m-auto">
                  <span>{{ getMultiTotTime3(res) }} </span>
                  <span>{{ res.Flight4JourneyTime.Stops }}
                    {{ $t("searchPageContent.itinContent.stop") }}</span>
                </div>

                <div class="col-5 col-sm-4 d-flex flex-column align-center">
                  <span class="ft">{{ getarrtime(res.Flight4) }}</span>

                  <div class="d-flex w-md-auto justify-center aircity">
                    <span class="text-truncate">{{ getCountryName2(res.muticityData[3].to) }}
                    </span>
                    <span class="ms-1">({{ res.Flight4[res.Flight4.length - 1].Arrival.AirportCode }})</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Flight 4 End -->
        </div>

        <div class="col-12 col-sm-2 col-md-2 col-lg-2 m-auto price d-flex flex-column align-center">
          <span style="font-size: 13px;font-weight:500">Per Person</span>
          <span>{{ res.currency }} {{ res.find1 }}</span>
          <!-- <a href="/checkout" @click.prevent="selected(res, index)" class="book"
            >Book Now</a
          > -->
          <v-btn @click.prevent="selected(res, index)" :disabled="selectedIndex === index" class="book text-capitalize"
            width="100%"><span v-if="!selectedIndex !== index && selectedIndex !== index"
              style="font-size: 14px; font-weight: 400">{{ $t("searchPageContent.itinContent.bookNow") }}</span>
            <span><v-progress-circular v-if="selectedIndex === index" :size="18" :width="4"
                indeterminate></v-progress-circular></span>
          </v-btn>
        </div>
      </div>

      <div class="flight-detail-bg">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-3">
            <h2>{{ res.ownername }}</h2>
          </div>
          <div class="col-5 col-sm-4 col-md-2 col-lg-1">
            <p class="details m-0">{{ res.cabinClass }}</p>
          </div>

          <div class="col-5 col-sm-4 col-md-2 col-lg-2">
            <p class="details m-0">{{ res.Refund }}</p>
          </div>

          <div class="col-6 col-sm-4 col-md-2 col-lg-1 d-none">
            <p class="details m-0" @click.prevent="openDeeplink(res.deeplinkurl)"
              style="text-decoration: none; color: #000080; ">DeeplinkURL</p>
          </div>

          <div class="col-5 col-sm-4 col-md-2 col-lg-2">
            <p v-if="multifil" @click="faredetailmulticity(res, index)" class="details d-flex m-0" style="color: navy"
              data-toggle="collapse" data-target="#fareDetails">
              <span>{{
                $t("searchPageContent.itinContent.fareDetails")
              }}</span>
              <span class="ms-1">
                <i v-if="!res.FareDetails" class="bi bi-chevron-down"></i>
                <i v-if="res.FareDetails" class="bi bi-chevron-up"></i>
              </span>
            </p>
          </div>

          <div class="col-5 col-sm-4 col-md-2 col-lg-2">
            <p v-if="multifil" @click="flighted3(res, index)" class="details d-flex m-0" style="color: navy"
              data-toggle="collapse" data-target="#flightDetails">
              <span>{{
                $t("searchPageContent.itinContent.flightDetails")
              }}</span>
              <span class="ms-1">
                <i v-if="!res.Flightdepature" class="bi bi-chevron-down"></i>
                <i v-if="res.Flightdepature" class="bi bi-chevron-up"></i>
              </span>
            </p>
          </div>

          <div class="col-6 col-sm-6 col-md-2 col-lg-2">
            <p v-if="res.brantvalue" @click="fareSharing(res, index)" class="details d-flex m-0" style="color: navy">
              <span>
                {{
                  !res.barantFare
                    ? $t("searchPageContent.itinContent.fareOptions")
                    : $t("searchPageContent.itinContent.hideOptions")
                }}</span>
              <span class="ms-1">
                <i v-if="!res.barantFare" class="bi bi-chevron-down"></i>
                <i v-if="res.barantFare" class="bi bi-chevron-up"></i>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div v-if="res.barantFare" class="row gx-0" style="border-top: 1px solid lightgray">
        <div>
          <brantfareCom :childData="this.barentFareData" @childValue="handleChildValue"></brantfareCom>
        </div>
      </div>

      <div class="flight-detail" :class="{ collapse: !res.Flightdepature }" id="flightDetails">
        <div class="row">
          <div class="col-sm-12 col-md-8 col-lg-9">
            <!-- Flight 1 Start -->
            <div class="row">
              <p v-if="res.Flight1" class="d-flex border border-1 rounded px-2 w-auto">
                <span class="me-2">{{ res.Flight1[0].Departure.AirportCode }} -
                  {{
                    res.Flight1[res.Flight1.length - 1].Arrival.AirportCode
                  }}</span>
                <span><i data-v-2ed63808="" class="fa fa-plane"></i></span>
              </p>
              <div class="col-sm-12 col-md-8 col-lg-12" v-for="(data, index) of res.Flight1" :key="index">
                <div class="row">
                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <span class="loc">{{ data.Departure.AirportCode }}</span>

                    <span class="time">{{ data.Departure.Time }}</span>

                    <span class="date">{{
                      getdated(data.Departure.Date)
                    }}</span>

                    <span class="air">{{ data.Departure.AirportName }}</span>
                  </div>

                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="d-flex justify-center">
                      <span><img :src="data.MarketingCarrier.logo" class="flogo1" alt="" /></span>
                      <span class="ms-2 d-flex flex-column">
                        <div class="air" style="width:16ch;">{{ data.MarketingCarrier.Name }}</div>


                        <div style="font-size: 11px;
    font-weight: 500;
    text-align: center;">
                          {{ data.MarketingCarrier.AirlineID }} -{{ data.MarketingCarrier.FlightNumber }}

                        </div>
                      </span>
                    </div>
                    <div class="line">
                      <i class="fa fa-plane" style="margin-top: 4px !important;"></i>
                    </div>
                    <div style="text-align: center;">
                      {{ getchangeTime(data.FlightDetail.FlightDuration.Value) }}
                    </div>

                  </div>

                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <span class="loc">{{ data.Arrival.AirportCode }}</span>

                    <span class="time">{{ data.Arrival.Time }}</span>

                    <span class="date">{{ getdated1(data.Arrival.Date) }}</span>

                    <span class="air">{{ data.Arrival.AirportName }}</span>
                  </div>
                </div>

                <div class="change-plan" v-if="index < res.Flight1.length - 1">
                  {{ $t("searchPageContent.itinContent.changeplane") }}
                  {{ data.Arrival.AirportCode }},
                  {{ $t("searchPageContent.itinContent.time") }}
                  {{ res.layoverTimes1 ? res.layoverTimes1[index] : null }}
                </div>
              </div>
            </div>
            <!-- Flight 1 End -->

            <!-- Flight 2 Start -->
            <div class="row mt-3">
              <p v-if="res.Flight2" class="d-flex border border-1 rounded px-2 w-auto">
                <span class="me-2">{{ res.Flight2[0].Departure.AirportCode }} -
                  {{
                    res.Flight2[res.Flight2.length - 1].Arrival.AirportCode
                  }}</span>
                <span><i data-v-2ed63808="" class="fa fa-plane"></i></span>
              </p>
              <div class="col-sm-12 col-md-8 col-lg-12" v-for="(data, index) of res.Flight2" :key="index">
                <div class="row">
                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <span class="loc">{{ data.Departure.AirportCode }}</span>

                    <span class="time">{{ data.Departure.Time }}</span>

                    <span class="date">{{
                      getdated2(data.Departure.Date)
                    }}</span>

                    <span class="air">{{ data.Departure.AirportName }}</span>
                  </div>

                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="d-flex justify-center">
                      <span><img :src="data.MarketingCarrier.logo" class="flogo1" alt="" /></span>
                      <span class="ms-2 d-flex flex-column">
                        <div class="air" style="width:16ch;">{{ data.MarketingCarrier.Name }}</div>


                        <div style="font-size: 11px;
    font-weight: 500;
    text-align: center;">
                          {{ data.MarketingCarrier.AirlineID }} -{{ data.MarketingCarrier.FlightNumber }}

                        </div>
                      </span>
                    </div>
                    <div class="line">
                      <i class="fa fa-plane" style="margin-top: 4px !important;"></i>
                    </div>
                    <div style="text-align: center;">
                      {{ getchangeTime(data.FlightDetail.FlightDuration.Value) }}
                    </div>

                  </div>

                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <span class="loc">{{ data.Arrival.AirportCode }}</span>

                    <span class="time">{{ data.Arrival.Time }}</span>

                    <span class="date">{{ getdated3(data.Arrival.Date) }}</span>

                    <span class="air">{{ data.Arrival.AirportName }}</span>
                  </div>
                </div>

                <div class="change-plan" v-if="index < res.Flight2.length - 1">
                  {{ $t("searchPageContent.itinContent.changeplane") }}
                  {{ data.Arrival.AirportCode }},
                  {{ $t("searchPageContent.itinContent.time") }}
                  {{ res.layoverTimes2 ? res.layoverTimes2[index] : null }}
                </div>
              </div>
            </div>
            <!-- Flight 2 End -->

            <!-- Flight 3 Start -->
            <div class="row mt-3" v-if="res.Flight3 && res.Flight3.length > 0">
              <p v-if="res.Flight3" class="d-flex border border-1 rounded px-2 w-auto">
                <span class="me-2">{{ res.Flight3[0].Departure.AirportCode }} -
                  {{
                    res.Flight3[res.Flight3.length - 1].Arrival.AirportCode
                  }}</span>
                <span><i data-v-2ed63808="" class="fa fa-plane"></i></span>
              </p>
              <div class="col-sm-12 col-md-8 col-lg-12" v-for="(data, index) of res.Flight3" :key="index">
                <div class="row">
                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <span class="loc">{{ data.Departure.AirportCode }}</span>

                    <span class="time">{{ data.Departure.Time }}</span>

                    <span class="date">{{
                      getdated2(data.Departure.Date)
                    }}</span>

                    <span class="air">{{ data.Departure.AirportName }}</span>
                  </div>

                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="d-flex justify-center">
                      <span><img :src="data.MarketingCarrier.logo" class="flogo1" alt="" /></span>
                      <span class="ms-2 d-flex flex-column">
                        <div class="air" style="width:16ch;">{{ data.MarketingCarrier.Name }}</div>


                        <div style="font-size: 11px;
    font-weight: 500;
    text-align: center;">
                          {{ data.MarketingCarrier.AirlineID }} -{{ data.MarketingCarrier.FlightNumber }}

                        </div>
                      </span>
                    </div>
                    <div class="line">
                      <i class="fa fa-plane" style="margin-top: 4px !important;"></i>
                    </div>
                    <div style="text-align: center;">
                      {{ getchangeTime(data.FlightDetail.FlightDuration.Value) }}
                    </div>

                  </div>

                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <span class="loc">{{ data.Arrival.AirportCode }}</span>

                    <span class="time">{{ data.Arrival.Time }}</span>

                    <span class="date">{{ getdated3(data.Arrival.Date) }}</span>

                    <span class="air">{{ data.Arrival.AirportName }}</span>
                  </div>
                </div>

                <div class="change-plan" v-if="index < res.Flight3.length - 1">
                  {{ $t("searchPageContent.itinContent.changeplane") }}
                  {{ data.Arrival.AirportCode }},
                  {{ $t("searchPageContent.itinContent.time") }}
                  {{ res.layoverTimes3 ? res.layoverTimes3[index] : null }}
                </div>
              </div>
            </div>
            <!-- Flight 3 End -->

            <!-- Flight 4 Start -->
            <div class="row mt-3" v-if="res.Flight4 && res.Flight4.length > 0">
              <p v-if="res.Flight4" class="d-flex border border-1 rounded px-2 w-auto">
                <span class="me-2">{{ res.Flight4[0].Departure.AirportCode }} -
                  {{
                    res.Flight4[res.Flight4.length - 1].Arrival.AirportCode
                  }}</span>
                <span><i data-v-2ed63808="" class="fa fa-plane"></i></span>
              </p>
              <div class="col-sm-12 col-md-8 col-lg-12" v-for="(data, index) of res.Flight4" :key="index">
                <div class="row">
                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <span class="loc">{{ data.Departure.AirportCode }}</span>

                    <span class="time">{{ data.Departure.Time }}</span>

                    <span class="date">{{
                      getdated2(data.Departure.Date)
                    }}</span>

                    <span class="air">{{ data.Departure.AirportName }}</span>
                  </div>

                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <div class="d-flex justify-center">
                      <span><img :src="data.MarketingCarrier.logo" class="flogo1" alt="" /></span>
                      <span class="ms-2 d-flex flex-column">
                        <div class="air" style="width:16ch;">{{ data.MarketingCarrier.Name }}</div>


                        <div style="font-size: 11px;
    font-weight: 500;
    text-align: center;">
                          {{ data.MarketingCarrier.AirlineID }} -{{ data.MarketingCarrier.FlightNumber }}

                        </div>
                      </span>
                    </div>
                    <div class="line">
                      <i class="fa fa-plane" style="margin-top: 4px !important;"></i>
                    </div>
                    <div style="text-align: center;">
                      {{ getchangeTime(data.FlightDetail.FlightDuration.Value) }}
                    </div>

                  </div>

                  <div class="col-sm-4 col-md-4 col-lg-4">
                    <span class="loc">{{ data.Arrival.AirportCode }}</span>

                    <span class="time">{{ data.Arrival.Time }}</span>

                    <span class="date">{{ getdated3(data.Arrival.Date) }}</span>

                    <span class="air">{{ data.Arrival.AirportName }}</span>
                  </div>
                </div>

                <div class="change-plan" v-if="index < res.Flight4.length - 1">
                  Change plane at {{ data.Arrival.AirportCode }}, Connecting
                  Time:
                  {{ res.layoverTimes4 ? res.layoverTimes4[index] : null }}
                </div>
              </div>
            </div>
            <!-- Flight 4 End -->
          </div>

          <div class="col-sm-12 col-md-4 col-lg-3">
            <div class="feat">
              <div class="mb-2">
                <b>{{ $t("searchPageContent.flightDetails.baggage") }}</b>
              </div>
              <!-- Flight 1 Start -->
              <div class="row">
                <div class="col-4 col-md-12 col-lg-12 mb-1">
                  <span>{{ getcitycode(res.muticityData[0].from) }} -
                    {{ getcitycode(res.muticityData[0].to) }}</span>
                </div>
                <div class="col-4 col-md-12 col-lg-6">
                  <b>{{ $t("baggageContent.checked") }}</b>

                  <span>{{ res.BaggageData1.PieceAllowance?.TotalQuantity || 0 }}
                    {{ $t("baggageContent.pieces") }}</span>

                  <br />
                </div>
                <div class="col-4 col-md-12 col-lg-6">
                  <b>{{ $t("baggageContent.cabin") }}</b>

                  <span>{{
                    res.BaggageData1.PieceAllowance?.TotalQuantity > 0
                      ? "1 Piece"
                      : "0 Piece"
                  }}</span>

                  <br />
                </div>
              </div>
              <!-- Flight 1 End -->

              <!-- Flight 2 Start -->
              <div class="row">
                <div class="col-4 col-md-12 col-lg-12 mb-1">
                  <span>{{ getcitycode(res.muticityData[1].from) }} -
                    {{ getcitycode(res.muticityData[1].to) }}</span>
                </div>
                <div class="col-4 col-md-12 col-lg-6">
                  <b>{{ $t("baggageContent.checked") }}</b>

                  <span>{{ res.BaggageData2.PieceAllowance?.TotalQuantity || 0 }}
                    {{ $t("baggageContent.pieces") }}</span>

                  <br />
                </div>
                <div class="col-4 col-md-12 col-lg-6">
                  <b>{{ $t("baggageContent.cabin") }}</b>

                  <span>{{
                    res.BaggageData2.PieceAllowance?.TotalQuantity > 0
                      ? "1 Piece"
                      : "0 Piece"
                  }}</span>

                  <br />
                </div>
              </div>
              <!-- Flight 2 End -->

              <!-- Flight 3 Start -->
              <div class="row" v-if="res.muticityData[2]">
                <div class="col-4 col-md-12 col-lg-12 mb-1">
                  <span>{{ getcitycode(res.muticityData[2].from) }} -
                    {{ getcitycode(res.muticityData[2].to) }}</span>
                </div>
                <div class="col-4 col-md-12 col-lg-6">
                  <b>{{ $t("baggageContent.checked") }}</b>

                  <span>{{ res.BaggageData3.PieceAllowance?.TotalQuantity || 0 }}
                    {{ $t("baggageContent.pieces") }}</span>

                  <br />
                </div>
                <div class="col-4 col-md-12 col-lg-6">
                  <b>{{ $t("baggageContent.cabin") }}</b>

                  <span>{{
                    res.BaggageData3.PieceAllowance?.TotalQuantity > 0
                      ? "1 Piece"
                      : "0 Piece"
                  }}</span>

                  <br />
                </div>
              </div>
              <!-- Flight 3 End -->

              <!-- Flight 4 Start -->
              <div class="row" v-if="res.muticityData[3]">
                <div class="col-4 col-md-12 col-lg-12 mb-1">
                  <span>{{ getcitycode(res.muticityData[3].from) }} -
                    {{ getcitycode(res.muticityData[3].to) }}</span>
                </div>
                <div class="col-4 col-md-12 col-lg-6">
                  <b>{{ $t("baggageContent.checked") }}</b>

                  <span>{{ res.BaggageData4.PieceAllowance?.TotalQuantity || 0 }}
                    {{ $t("baggageContent.pieces") }}</span>

                  <br />
                </div>
                <div class="col-4 col-md-12 col-lg-6">
                  <b>{{ $t("baggageContent.cabin") }}</b>

                  <span>{{
                    res.BaggageData4.PieceAllowance?.TotalQuantity > 0
                      ? "1 Piece"
                      : "0 Piece"
                  }}</span>

                  <br />
                </div>
              </div>
              <!-- Flight 4 End -->
            </div>
          </div>
        </div>
      </div>
      <div class="fare" id="fareDetails" :class="{ collapse: !res.FareDetails }">
        <div v-for="(flightdatas, index) of flightdetailsData2" :key="index">
          <div class="d-flex justify-content-between">
            <div>
              <p class="m-0" style="font-size: 16px; font-weight: 600">
                {{ $t("searchPageContent.itinContent.totalPrice") }}
              </p>
            </div>
            <div>
              <p class="fw-bold tot-price m-0" style="font-size: 16px">
                {{ flightdatas.currency }} {{ flightdatas.TotalPrice }}
              </p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <div class="row justify-content-between">
                <div class="col-4">
                  <span style="font-size: 13px; font-weight: 500">{{
                    $t("searchPageContent.itinContent.passenger")
                  }}</span>
                </div>
                <div class="col-4" style="font-size: 14px; text-align: end">
                  <span v-if="flightdatas.Adult > 0">{{ flightdatas.Adult }} x
                    {{ $t("searchPageContent.itinContent.adult") }}</span>
                  <span v-if="flightdatas.Child > 0">{{ flightdatas.Child }} x
                    {{ $t("searchPageContent.itinContent.child") }}</span>
                  <span v-if="flightdatas.Inf > 0">{{ flightdatas.Inf }} x
                    {{ $t("searchPageContent.itinContent.infant") }}</span>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-borderless text-center mb-0">
                  <thead>
                    <tr class="f-size-12 fw-500">
                      <td style="background: #dee2e6">
                        {{ $t("searchPageContent.itinContent.pax") }}
                      </td>
                      <td style="background: #dee2e6">
                        {{ $t("searchPageContent.itinContent.base") }}
                      </td>
                      <td style="background: #dee2e6">
                        {{ $t("searchPageContent.itinContent.tax") }}
                      </td>
                      <td style="background: #dee2e6">
                        {{ $t("searchPageContent.itinContent.perPerson") }}
                      </td>
                    </tr>
                  </thead>
                  <tbody class="f-size-10 fw-400">
                    <template v-for="(priceDetail, paxindex) in res.pricedetails" :key="paxindex">
                      <tr v-if="
                        priceDetail &&
                        priceDetail.pax_type &&
                        priceDetail.baseprice &&
                        priceDetail.taxprice
                      ">
                        <td>{{ priceDetail.pax_type }}</td>
                        <td>{{ priceDetail.baseprice }}</td>
                        <td>{{ priceDetail.taxprice }}</td>
                        <td>{{ getperpersonPrice(priceDetail) }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="bordeer-top-0 border border-2 border-top-0">
            <div></div>
          </div>
        </div>
      </div>

      <div class="fare collapse d-none" id="fare">
        <div class="row">
          <div class="col-sm-12 col-md-5 col-lg-4">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th colspan="2">Fare breakup</th>
                  </tr>
                </thead>

                <tr>
                  <td>TOTAL</td>

                  <td>Rs 45,997</td>
                </tr>

                <tr>
                  <td><small>Base Fare</small></td>

                  <td>Rs 35,885</td>
                </tr>

                <tr>
                  <td><small>Surcharges</small></td>

                  <td>Rs 15,222</td>
                </tr>

                <tr>
                  <td><small>Instant discount applied</small></td>

                  <td>-Rs 5,110</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="col-sm-12 col-md-7 col-lg-8">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th colspan="3">
                      Fare rules DEL - DUB
                      <small>All charges are per passenger</small>
                    </th>
                  </tr>
                </thead>

                <tr>
                  <td><small>TIME</small></td>

                  <td>
                    <small> CANCELLATION FEE (Airline Fee + MMT Fee) </small>
                  </td>

                  <td>
                    <small>
                      DATE CHANGE FEES (Airline Fee + MMT Fee + Fare Difference
                      )
                    </small>
                  </td>
                </tr>

                <tr>
                  <td><small>24 hours to 365 days*</small></td>

                  <td><small>ADULT : ₹ 11,924 + ₹ 649</small></td>

                  <td>
                    <small>ADULT : ₹ 9,561 + ₹ 500 + Fare difference</small>
                  </td>
                </tr>
              </table>
            </div>

            <small>* From the Date of Departure</small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Content end-->
</template>
<script>
import moment from "moment";
import axios from "axios";
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
import brantfareCom from "@/components/brantFare/brantFare.vue";
export default {
  components: {
    brantfareCom,
  },
  props: {
    getDataResult: {
      type: Array,
      default: () => [],
    },
    brandchildData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      handlefilter: false,
      mobileResize: false,

      getItindatas: [],
      rountresult: [],
      filterData: [],
      filteredData: [],
      onewayresulted: [],
      multicityData: [],
      onewayfilterData: [],
      multiFilterData: [],
      multishowData: [],
      isTripDataAvailable: false,
      selectedIndex: "",
      active: false,
      active1: false,
      active2: false,
      active3: false,
      airlineList: [],

      tripData: {
        from: null,
        to: null,
        dedate: null,
        redate: null,
        class: "",
        child: "",
        adult: "",
        infant: "",
        tripType: "",
      },

      // Filter data variables
      showNonStop: true,
      showOneStop: true,
      showOneplusStop: true,

      NonStopFil: false,
      OneStopFil: false,
      OnePlusFil: false,
      hasNonStop: false,
      hasOneStop: false,
      hasOnePlusStop: false,
      hasRefundable: false,
      hasNonRefundable: false,
      RefundableData: false,
      nonRefundableData: false,
      changevalue: [],
      minimum: null,
      maximum: null,
      steps: null,
      selectedFromAirports: false,
      selectedToAirports: false,
      onedatafil: false,
      rounddatafil: false,
      timeFilterData1: false,
      timeFilterData2: false,
      timeFilterData3: false,
      timeFilterData4: false,
      timeFilterArrData1: false,
      timeFilterArrData2: false,
      timeFilterArrData3: false,
      timeFilterArrData4: false,
      timearrFilterData1: false,
      timearrFilterData2: false,
      timearrFilterData3: false,
      timearrFilterData4: false,
      timearrFilterArrData1: false,
      timearrFilterArrData2: false,
      timearrFilterArrData3: false,
      timearrFilterArrData4: false,
      morningRange: [6, 12],
      afternoonRange: [12, 18],
      eveningRange: [18, 24],
      beforeMorningRange: [0, 6],
      hasMorningFlights: false,
      hasAfternoonFlights: false,
      hasEveningFlights: false,
      hasMorningFlights1: false,
      hasAfternoonFlights1: false,
      hasEveningFlights1: false,
      hasMorningReturn: false,
      hasAfternoonReturn: false,
      hasEveningReturn: false,
      hasMorningReturn1: false,
      hasAfternoonReturn1: false,
      hasEveningReturn1: false,
      nonStop: [],
      oneStop: [],
      onePlusStop: [],
      disableData: true,
      outBoundTime: [],
      inBoundTime: [],
      outBoundTimeMulti: [],
      defaultOutBoundMulti: null,
      defaultOutBoundTime: null,
      defaultInBoundTime: null,
      outBoundMulti: null,
      filNonstop: [],
      filOneStop: [],
      filOnePlusStop: [],
      concatStops: [],
      FilterTimeDepname: "",
      FilterTimearrname: "",
      maxItemsToShow: 5,
      // Filter data variables

      currencyCode: "",
      preferCurrency: "",

      flightdetailsData: [],
      flightdetailsData1: [],
      flightdetailsData2: [],

      priceChangeValue: [],
      priceapiData: "",
      priceRes: [],
      priceError: false,
      barentFareData: [],

      // getconfig variables Start
      makeSearchUrl: "",
      airLogoApi: "",
      metaCurrency: "",
      countryCodeData: "",
      autocompleteApi: "",
      portal_id: "",
      airlineApi: "",
      airportdataApi: "",
      pricingApi: "",
      pointofSale: "",
      shotperperson: "",
      shorCurrecy: "",
      // getconfig variables End
    };
  },
  methods: {
    openDeeplink(event) {
      // This should be a direct result of a user click, so most browsers allow it.
      const newWindow = window.open(event, "_blank");
      console.log(event, "eventevent")

      // Check if the popup was blocked.
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        console.log("deeplink not working");
        // alert("Popup was blocked. Please allow popups for this site.");
      }
    },
    Recommend() {
      this.active = true;
      this.active1 = false;
      this.active2 = false;
      this.active3 = false;
      console.log(this.active,this.active1,this.active2,this.active3,"active")

      if (this.onewayfilterData.length > 0) {
        // alert('pppppp99')
        // console.log(this.onewayfilterData,'this.onewayfilterDatathis.onewayfilterData')
        this.onewayresulted.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.onewayresulted[0].currency;
        this.recperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.rountresult[0].currency;
        this.recperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        this.multicityData.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.multicityData[0].currency;
        this.recperperson = this.multicityData[0].perperson;
      }
    },
    Cheapest(data) {
      if (data == 1) {
      console.log(data,"data")
        this.active = false;
        this.active1 = false;
        this.active2 = true;
        this.active3 = false;
      }

      if (this.onewayfilterData.length > 0) {
        // this.onewayfilterData.sort((a, b) => a.perperson - b.perperson)
        this.onewayresulted.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.onewayresulted[0].currency;
        this.recperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.rountresult[0].currency;
        this.recperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        // this.multicityData = this.multiFilterData;
        this.multicityData.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.multicityData[0].currency;
        this.recperperson = this.multicityData[0].perperson;
      }
    },
    Shortest(data) {
      // console.log('shortestDataaaa');

      if (data == "1") {
        this.active = false;
        this.active1 = false;
        this.active2 = false;
        this.active3 = true;
      } else {
        this.active3 = false;
      }

      let sortedData = [];

      if (this.onewayfilterData.length > 0) {
        sortedData = [...this.onewayresulted].sort((a, b) =>
          a.Depaturejourney.Time.localeCompare(
            b.Depaturejourney.Time,
            undefined,
            { numeric: true }
          )
        );

        if (!data) {  // Assign values only when no data is passed
          this.shorCurrecy = sortedData[0]?.currency;
          this.shotperperson = sortedData[0]?.perperson;
        } else {
          this.onewayresulted = sortedData;
        }
      } else if (this.filterData.length > 0) {
        sortedData = [...this.rountresult].sort((a, b) =>
          a.Depaturejourney.Time.localeCompare(
            b.Depaturejourney.Time,
            undefined,
            { numeric: true }
          ) &&
          a.Returnjourney.Time.localeCompare(
            b.Returnjourney.Time,
            undefined,
            { numeric: true }
          )
        );

        if (!data) {
          this.shorCurrecy = sortedData[0]?.currency;
          this.shotperperson = sortedData[0]?.perperson;
          // console.log(this.shotperperson,this.shorCurrecy,'this.shorCurrecythis.shorCurrecy')
        } else {
          this.rountresult = sortedData;
          // console.log('this.shorCurrecythis.shorCurrecy...2')
        }
      } else if (this.multiFilterData.length > 0) {
        sortedData = [...this.multicityData].sort((a, b) => {
          const compareFlight1 = a.Flight1JourneyTime.Time.localeCompare(
            b.Flight1JourneyTime.Time,
            undefined,
            { numeric: true }
          );
          const compareFlight2 = a.Flight2JourneyTime.Time.localeCompare(
            b.Flight2JourneyTime.Time,
            undefined,
            { numeric: true }
          );

          let compareFlight3 = 0;
          if (
            a.Flight3JourneyTime &&
            a.Flight3JourneyTime.Time &&
            b.Flight3JourneyTime &&
            b.Flight3JourneyTime.Time
          ) {
            compareFlight3 = a.Flight3JourneyTime.Time.localeCompare(
              b.Flight3JourneyTime.Time,
              undefined,
              { numeric: true }
            );
          }

          let compareFlight4 = 0;
          if (
            a.Flight4JourneyTime &&
            a.Flight4JourneyTime.Time &&
            b.Flight4JourneyTime &&
            b.Flight4JourneyTime.Time
          ) {
            compareFlight4 = a.Flight4JourneyTime.Time.localeCompare(
              b.Flight4JourneyTime.Time,
              undefined,
              { numeric: true }
            );
          }

          return compareFlight1 || compareFlight2 || compareFlight3 || compareFlight4;
        });

        if (!data) {  // Assign values only when no data is passed
          this.shorCurrecy = sortedData[0]?.currency;
          this.shotperperson = sortedData[0]?.perperson;
        } else {
          this.multicityData = sortedData;
        }
      }
    },
    bestlayTime(data) {
      if (data == 1) {
        this.active = false;
        this.active1 = true;
        this.active2 = false;
        this.active3 = false;
      }

      if (this.onewayfilterData.length > 0) {
        this.onewayresulted.sort((a, b) => a.layoverFilter - b.layoverFilter);
        this.layperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort((a, b) => a.layoverFilter - b.layoverFilter);
        this.layperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        // this.multicityData = this.multiFilterData;

        this.multicityData.sort((a, b) => a.layoverFilter - b.layoverFilter);
        this.layperperson = this.multicityData[0].perperson;
      }
    },

    handleResize() {
      this.mobileResize = window.innerWidth <= 992;
      console.log(this.mobileResize, "handlefilterhandlefilter...2");
      // if (window.innerWidth <= 480) {
      //   this.mobileResize = false;
      // } else {
      //   this.mobileResize = true;
      // }
    },
    handleFilter() {
      this.handlefilter = !this.handlefilter;
      console.log(this.handlefilter, "handlefilterhandlefilter...1");
    },
    getperpersonPrice(getdata) {
      if (getdata.adultcount) {
        let getAdtperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getAdtperfare, "getadultTotBasegetadultTotBase...2");
        return getAdtperfare.toFixed(2);
      }

      if (getdata.childcount) {
        let getChdperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getChdperfare, "getadultTotBasegetadultTotBase...3");
        return getChdperfare.toFixed(2);
      }

      if (getdata.infcount) {
        let getInfperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getInfperfare, "getadultTotBasegetadultTotBase...4");
        return getInfperfare.toFixed(2);
      }
    },
    handleChildValue(newval) {
      // this.returnvalue=value
      // console.log(this.returnvalue,'parseInt()000000000000000000000000000001111111')
      if (newval) {
        if (this.onewayresulted.length > 0) {
          this.onewayresulted[this.barentFareData.index].TotalPrice =
            newval.TotalPrice;
          this.onewayresulted[this.barentFareData.index].find1 = parseInt(newval.personPrice)
            ;
          this.onewayresulted[this.barentFareData.index].BrandId =
            newval.BrandId;
          this.onewayresulted[this.barentFareData.index].BrandName =
            newval.BrandName;
        } else if (this.rountresult.length > 0) {
          console.log(
            this.rountresult[this.barentFareData.index],
            this.barentFareData.index,
            newval,
            " // this.rountresult[this.barentFareData.index]"
          );
          this.rountresult[this.barentFareData.index].TotalPrice =
            newval.TotalPrice;
          this.rountresult[this.barentFareData.index].find1 =
            parseInt(newval.personPrice)
          this.rountresult[this.barentFareData.index].BrandId = newval.BrandId;
          this.rountresult[this.barentFareData.index].BrandName =
            newval.BrandName;
        } else if (this.multicityData.length > 0) {
          this.multicityData[this.barentFareData.index].TotalPrice =
            newval.TotalPrice;
          this.multicityData[this.barentFareData.index].find1 =
            parseInt(newval.personPrice)
          this.multicityData[this.barentFareData.index].BrandId =
            newval.BrandId;
          this.multicityData[this.barentFareData.index].BrandName =
            newval.BrandName;
        }
      }
    },

    // getcitycode(data) {
    //   let parts = data.split("(");
    //   return parts[1]?.split(")")[0];
    // },

    getcitycode(event) {
      return event ? event.match(/\(([^)]+)\)/)?.[1] || event : "";
    },

    getdepseat(data) {
      // console.log(data,'ppppppppppppppppppppppp')
      return data[0].Seat;
    },
    getdepName1(data) {
      // console.log(data, "getdepcode1");
      return data;
      // return data.split("(")[0];
    },
    getarrName1(data) {
      return data.split("(")[0];
    },
    getdepNameMulti(data) {
      console.log(data, "getdepcode1");
      return data.from;
    },
    getarrNameMulti(data) {
      return data.to;
    },

    getretseat(data) {
      return data[0].Seat;
    },
    getchangeTime(data) {
      // console.log(data,'sssssssssssssss')
      return data.replace(' H', 'h').replace(' M', 'm')
    },
    // getlogo($event) {
    //   return $event[0].MarketingCarrier.logo;
    // },
    // getlogo1($event) {
    //   return $event[0].MarketingCarrier.logo;
    // },
    getlogo($event) {
      console.log($event, "dataassss");
    },
    getlogo1(data) {
      return data.MarketingCarrier.logo;
    },
    getname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },
    getNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },

    // All Date Section
    getDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getArrivaldate($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    getreturnDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getreturnArrivaldate($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    // All Date Section End

    // Mobile Date Format Section

    getDateDeparture($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getArrivaldateReturn($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    getreturnDateDeparture($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getreturnArrivaldateReturn($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    // Mobile Date Show End

    getAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getCountryName1($event) {
      // console.log($event.length, 'vvvvasdsdasadasdas');
      this.FilterTimeDepname = $event.split("(")[0];
      // return $event.split("(")[0];
      return $event.split("(")[0];
    },
    airportCode($event) {
      return $event.split("(")[1];
    },
    getCountryName2($event) {
      this.FilterTimearrname = $event.split("(")[0];
      // return $event.split("(")[0];
      return $event.split("(")[0];
    },

    getAirportNameFrom($event) {
      return $event[0].Departure.AirportName;
    },

    getAirportNameTo($event) {
      return $event[0].Departure.AirportName;
    },

    getarrivaldata($event) {
      return $event[0].Arrival.AirportName;
    },
    getreturndata($event) {
      return $event[0].Arrival.AirportName;
    },
    getarrtime($event) {
      return $event[$event.length - 1].Arrival.Time;
    },
    getreturntime($event) {
      return $event[0].Arrival.Time;
    },
    get1arrtime($event) {
      // console.log($event, '$event')

      return $event[$event.length - 1].Departure.AirportName;
    },
    get1returntime($event) {
      return $event[$event.length - 1].Departure.AirportName;
    },

    get2arrtime($event) {
      return $event[$event.length - 1].Departure.Time;
    },

    get2returntime($event) {
      return $event[$event.length - 1].Departure.Time;
    },
    getTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getArrivalCode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getretairname($event) {
      return $event[$event.length - 1].Arrival.AirportName;
    },
    getreturnname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getreturnNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },
    getreturnid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },

    getdeparDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format(
        "ddd MMM DD"
      );
    },

    getdated($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdatedFlight($event) {
      return moment($event).format("MMM DD");
    },
    getdatedFlightMulti($event) {
      return moment($event[0].Date).format("MMM DD");
    },
    getdatedFlight1($event) {
      return moment($event).format("MMM DD");
    },
    getdated1($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated2($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated3($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getreturnAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },
    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getreturnTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getreturnArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getreturnArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    getDeptCode(event) {
      console.log(event, "asdasdaevent");
      return event.Depature[0].Departure.AirportCode;
    },
    getReturnFilter($data) {
      console.log($data, "asdasdadata...1");
      return $data.Depature[$data.Depature.length - 1].Arrival.AirportCode;
    },
    getReturnFilter1($data) {
      console.log($data, "asdasdadata...2");
      return $data.Return[$data.Return.length - 1].Arrival.AirportCode;
    },

    getTotalTime(data) {
      let totalMinutes = this.timeToMinutes(data.Depaturejourney.Time);
      //  console.log(totalMinutes,data,'totalMinutes..1')

      let layoverTimeData = data.layoverTimes1 || data.layoverTimes
      if (Array.isArray(layoverTimeData)) {
        for (let time of layoverTimeData) {
          totalMinutes += this.timeToMinutes(time);
        }
      }
      // console.log(totalMinutes,'totalMinutes')
      return this.minutesToTime(totalMinutes);
    },
    getReturnTotTime(data) {
      let totalMinutes = this.timeToMinutes(data.Returnjourney.Time);
      if (Array.isArray(data.layoverTimes2)) {
        for (let time of data.layoverTimes2) {
          totalMinutes += this.timeToMinutes(time);
        }
      }
      return this.minutesToTime(totalMinutes);
    },
    getMultiTotTime(data) {
      let totalMinutes = this.timeToMinutes(data.Flight1JourneyTime.Time);
      if (Array.isArray(data.layoverTimes1)) {
        for (let time of data.layoverTimes1) {
          totalMinutes += this.timeToMinutes(time);
        }
      }
      return this.minutesToTime(totalMinutes);
    },
    getMultiTotTime1(data) {
      let totalMinutes = this.timeToMinutes(data.Flight2JourneyTime.Time);
      if (Array.isArray(data.layoverTimes2)) {
        for (let time of data.layoverTimes2) {
          totalMinutes += this.timeToMinutes(time);
        }
      }
      return this.minutesToTime(totalMinutes);
    },
    getMultiTotTime2(data) {
      let totalMinutes = this.timeToMinutes(data.Flight3JourneyTime.Time);
      if (Array.isArray(data.layoverTimes3)) {
        for (let time of data.layoverTimes3) {
          totalMinutes += this.timeToMinutes(time);
        }
      }
      return this.minutesToTime(totalMinutes);
    },
    getMultiTotTime3(data) {
      let totalMinutes = this.timeToMinutes(data.Flight4JourneyTime.Time);
      if (Array.isArray(data.layoverTimes4)) {
        for (let time of data.layoverTimes4) {
          totalMinutes += this.timeToMinutes(time);
        }
      }
      return this.minutesToTime(totalMinutes);
    },

    timeToMinutes(timeString) {
      let hours = 0, minutes = 0;
      if (timeString.includes("h") || timeString.includes("H")) {
        hours = parseInt(timeString.split("h")[0] || timeString.split("H")[0]) || 0;
        minutes = parseInt(timeString.split("h")[1] || timeString.split("H")[1]) || 0;
      } else {
        minutes = parseInt(timeString) || 0;
      }
      // console.log(hours,minutes,'totalMinutes..2')
      return hours * 60 + minutes;
    },

    minutesToTime(minutes) {
      let h = Math.floor(minutes / 60);
      let m = minutes % 60;
      return `${h}h ${m}m`;
    },

    selected(result, index) {
      this.brantshareDate(result);
      this.searchloader = true;
      this.selectedIndex = index;
      this.priceChangeValue = result;
      this.priceConfirm(this.priceChangeValue);
    },

    priceConfirm(result, index) {

      this.routerChange()

      console.log(result, this.priceChangeValue, "Price confirmation");
      if (result == "1") {
        this.routerChange();
        console.log("pppppp");
      } else {
        let $pracingReq = {
          service_type: "PricingBeforeBooking",
          api_ver: "1",
          portal_id: this.portal_id,
          payload: {
            shopping_response_id: this.priceChangeValue.ShoppingResponseId,
            offer_id: this.priceChangeValue.selectId,
          },
        };
        console.log($pracingReq, this.pricingApi, "0p0p0p0p0p");
        axios
          .post(this.pricingApi, $pracingReq, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            let $priceres = response.data;
            this.priceRes = response.data;
            this.priceapiData = $priceres.payload.pricingid;

            console.log(
              $priceres.payload.itin_total_fare,
              this.priceChangeValue.TotalPrice,
              $priceres,
              "responsefareRulesApi"
            );
            this.routerChange()
            this.searchloader = false;
            this.selectedIndex = index;
            if ($priceres.response_status == "success") {
              console.log("ssssss");
              if (
                $priceres.payload.itin_total_fare ==
                this.priceChangeValue.TotalPrice
              ) {
                console.log("ssssss88");
                this.routerChange();
              } else if (
                $priceres.payload.itin_total_fare >
                this.priceChangeValue.TotalPrice
              ) {
                this.priceText =
                  "Oops! The fare has been updated by the Airlines";
                this.pricedialog = true;
              } else if (
                $priceres.payload.itin_total_fare <
                this.priceChangeValue.TotalPrice
              ) {
                this.priceText =
                  "Oops! The fare has been updated by the Airlines";
                this.pricedialog = true;
              } else {
                // this.routerChange();
              }
            }
          })
          .catch((error) => {
            console.log(error, "erroroor.....");
            this.searchloader = false;
            this.priceError = true;
          });
      }
    },

    routerChange() {
      let data = "";
      let event = "";
      this.priceChangeValue.pricingid = this.priceapiData;

      if (this.onewayresulted.length > 0) {
        data = "pass_uid1";
        event = this.priceChangeValue.currency;
      } else if (this.rountresult.length > 0) {
        data = "pass_id";
        event = this.priceChangeValue.currency;
      } else {
        data = "pass_uid";
        event = "multi";
      }

      Object.keys(sessionStorage).forEach((key) => {
        if (
          key.startsWith(`${event}-`) &&
          key !== `${event}-${this.priceChangeValue.selectId}`
        ) {
          sessionStorage.removeItem(key);
        }
      });
      sessionStorage.setItem(
        `${event}-${this.priceChangeValue.selectId}`,
        JSON.stringify(this.priceChangeValue)
      );

      this.$router
        .push({
          path: "/flightinfo",
          query: {
            [data]: `${event}-${this.priceChangeValue.selectId}`,
          },
        })
        .then(() => {
          this.selectedIndex = null;
        })
        .catch(() => {
          this.selectedIndex = null;
        });
    },

    // Filter Start Setup
    filterByTimeRange(flight, range) {
      let [start, end] = range;
      const depTimeParts = flight.Depature[0].Departure.Time.split(":");
      let hours = parseInt(depTimeParts[0]);
      let minutes = parseInt(depTimeParts[1]);
      let totalHours = Math.round(hours + minutes / 60);
      let value = totalHours >= start && totalHours < end;
      return value;
    },
    filterByTimeRange1(flight, range) {
      let [start, end] = range;
      const depTimeParts =
        flight.Depature[flight.Depature.length - 1].Arrival.Time.split(":");
      let hours = parseInt(depTimeParts[0]);
      let minutes = parseInt(depTimeParts[1]);
      let totalHours = Math.round(hours + minutes / 60);
      let value = totalHours >= start && totalHours < end;
      return value;
    },
    filterByTimeRangeRet(flight, range) {
      let [start, end] = range;
      const retTimeParts = flight.Return[0].Departure.Time.split(":");
      let hours = parseInt(retTimeParts[0]);
      let minutes = parseInt(retTimeParts[1]);
      let totalHours = Math.round(hours + minutes / 60);
      let value = totalHours >= start && totalHours < end;
      console.log(hours, value, "valuevalue");
      return value;
    },
    filterByTimeRangeRet1(flight, range) {
      let [start, end] = range;
      const retTimeParts =
        flight.Return[flight.Return.length - 1].Arrival.Time.split(":");
      let hours = parseInt(retTimeParts[0]);
      let minutes = parseInt(retTimeParts[1]);
      let totalHours = Math.round(hours + minutes / 60);
      let value = totalHours >= start && totalHours < end;
      console.log(hours, value, "valuevalue");
      return value;
    },

    // Methods to toggle time filters and apply them

    TimeFilter1() {
      this.timeFilterData1 = !this.timeFilterData1;
      this.applyFilters();
    },

    TimeFilter2() {
      this.timeFilterData2 = !this.timeFilterData2;
      this.applyFilters();
    },

    TimeFilter3() {
      this.timeFilterData3 = !this.timeFilterData3;
      this.applyFilters();
    },
    TimeFilter4() {
      this.timeFilterData4 = !this.timeFilterData4;
      this.applyFilters();
    },

    TimeFilterArr1() {
      this.timeFilterArrData1 = !this.timeFilterArrData1;
      this.applyFilters();
    },

    TimeFilterArr2() {
      this.timeFilterArrData2 = !this.timeFilterArrData2;
      this.applyFilters();
    },

    TimeFilterArr3() {
      this.timeFilterArrData3 = !this.timeFilterArrData3;
      this.applyFilters();
    },
    TimeFilterArr4() {
      this.timeFilterArrData4 = !this.timeFilterArrData4;
      this.applyFilters();
    },
    TimeretFilter1() {
      this.timearrFilterData1 = !this.timearrFilterData1;
      this.applyFilters();
    },
    TimeretFilter2() {
      this.timearrFilterData2 = !this.timearrFilterData2;
      this.applyFilters();
    },
    TimeretFilter3() {
      this.timearrFilterData3 = !this.timearrFilterData3;
      this.applyFilters();
    },
    TimeretFilter4() {
      this.timearrFilterData4 = !this.timearrFilterData4;
      this.applyFilters();
    },
    TimeretFilterarr1() {
      this.timearrFilterArrData1 = !this.timearrFilterArrData1;
      this.applyFilters();
    },
    TimeretFilterarr2() {
      this.timearrFilterArrData2 = !this.timearrFilterArrData2;
      this.applyFilters();
    },
    TimeretFilterarr3() {
      this.timearrFilterArrData3 = !this.timearrFilterArrData3;
      this.applyFilters();
    },
    TimeretFilterarr4() {
      this.timearrFilterArrData4 = !this.timearrFilterArrData4;
      this.applyFilters();
    },

    //multicity
    TimeMulti1() {
      this.timeMulti1 = !this.timeMulti1;
      this.applyFilters();
    },
    TimeMulti2() {
      this.timeMulti2 = !this.timeMulti2;
      this.applyFilters();
    },
    TimeMulti3() {
      this.timeMulti3 = !this.timeMulti3;
      this.applyFilters();
    },
    TimeMulti4() {
      this.timeMulti4 = !this.timeMulti4;
      this.applyFilters();
    },
    TimeMultiArr1() {
      this.timeMultiArr1 = !this.timeMultiArr1;
      this.applyFilters();
    },
    TimeMultiArr2() {
      this.timeMultiArr2 = !this.timeMultiArr2;
      this.applyFilters();
    },
    TimeMultiArr3() {
      this.timeMultiArr3 = !this.timeMultiArr3;
      this.applyFilters();
    },
    TimeMultiArr4() {
      this.timeMultiArr4 = !this.timeMultiArr4;
      this.applyFilters();
    },

    applyFilters() {
      console.log("resultttt....0");
      if (this.rountresult.length > 0) {
        this.filteredData = this.filterData;
        console.log(this.filteredData, "resulttttt....3");
      } else if (this.onewayresulted.length > 0) {
        this.filteredData = this.onewayfilterData;
        console.log(this.filteredData, "resulttttt....4");
      } else if (this.multicityData.length > 0) {
        this.filteredData = this.multiFilterData;
        this.multifil = true;
      }

      let result = this.filteredData;
      // console.log(this.filteredData, result, "resultttt...5");

      if (this.RefundableData && this.nonRefundableData) {
        result = result.filter(
          (v) => v.Refund === "Refundable" || v.Refund === "Non Refundable"
        );
      } else {
        // Refundable filter
        if (this.RefundableData) {
          result = result.filter((v) => v.Refund === "Refundable");
        }

        // Non-refundable filter
        if (this.nonRefundableData) {
          result = result.filter((v) => v.Refund === "Non Refundable");
        }
      }

      // Non-Stop filter OneStopFil
      if (this.rountresult.length > 0) {
        console.log(this.rountresult, "rountresultrountresult");
        if (this.NonStopFil && this.OneStopFil && this.OnePlusFil) {
          result = result.filter(
            (v) =>
              v.Depaturejourney.Stops === 0 ||
              v.Returnjourney.Stops === 0 ||
              v.Depaturejourney.Stops === 1 ||
              v.Returnjourney.Stops === 1 ||
              v.Depaturejourney.Stops > 1 ||
              v.Returnjourney.Stops > 1
          );
        } else {
          // Non Stop and One Stop filter
          if (this.NonStopFil && this.OneStopFil) {
            result = result.filter(
              (v) =>
                v.Depaturejourney.Stops === 0 ||
                v.Returnjourney.Stops === 0 ||
                v.Depaturejourney.Stops === 1 ||
                v.Returnjourney.Stops === 1
            );
          } else {
            // Non Stop and One Plus filter
            if (this.NonStopFil && this.OnePlusFil) {
              result = result.filter(
                (v) =>
                  v.Depaturejourney.Stops === 0 ||
                  v.Returnjourney.Stops === 0 ||
                  v.Depaturejourney.Stops > 1 ||
                  v.Returnjourney.Stops > 1
              );
            } else {
              // One Stop and One Plus filter
              if (this.OneStopFil && this.OnePlusFil) {
                result = result.filter(
                  (v) =>
                    v.Depaturejourney.Stops === 1 ||
                    v.Returnjourney.Stops === 1 ||
                    v.Depaturejourney.Stops > 1 ||
                    v.Returnjourney.Stops > 1
                );
              } else {
                if (this.NonStopFil) {
                  result = result.filter(
                    (v) =>
                      v.Depaturejourney.Stops === 0 ||
                      v.Returnjourney.Stops === 0
                  );
                }
                if (this.OneStopFil) {
                  result = result.filter(
                    (v) =>
                      v.Depaturejourney.Stops === 1 ||
                      v.Returnjourney.Stops === 1
                  );
                }
                if (this.OnePlusFil) {
                  result = result.filter(
                    (v) =>
                      v.Depaturejourney.Stops > 1 || v.Returnjourney.Stops > 1
                  );
                }
              }
            }
          }
        }
      } else if (this.onewayfilterData.length > 0) {
        if (this.NonStopFil && this.OneStopFil && this.OnePlusFil) {
          result = result.filter(
            (v) =>
              v.Depaturejourney.Stops === 0 ||
              v.Depaturejourney.Stops === 1 ||
              v.Depaturejourney.Stops > 1
          );
        } else {
          // Non Stop and One Stop filter
          if (this.NonStopFil && this.OneStopFil) {
            result = result.filter(
              (v) =>
                v.Depaturejourney.Stops === 0 || v.Depaturejourney.Stops === 1
            );
          } else {
            // Non Stop and One Plus filter
            if (this.NonStopFil && this.OnePlusFil) {
              result = result.filter(
                (v) =>
                  v.Depaturejourney.Stops === 0 || v.Depaturejourney.Stops > 1
              );
            } else {
              // One Stop and One Plus filter
              if (this.OneStopFil && this.OnePlusFil) {
                result = result.filter(
                  (v) =>
                    v.Depaturejourney.Stops === 1 || v.Depaturejourney.Stops > 1
                );
              } else {
                if (this.NonStopFil) {
                  result = result.filter((v) => v.Depaturejourney.Stops === 0);
                }
                if (this.OneStopFil) {
                  result = result.filter((v) => v.Depaturejourney.Stops === 1);
                }
                if (this.OnePlusFil) {
                  result = result.filter((v) => v.Depaturejourney.Stops > 1);
                }
              }
            }
          }
        }
      } else if (this.multiFilterData.length > 0) {
        if (this.NonStopFil && this.OneStopFil && this.OnePlusFil) {
          result = result.filter(
            (v) =>
              v.Flight1JourneyTime.Stops === 0 ||
              v.Flight2JourneyTime.Stops === 0 ||
              v.Flight3JourneyTime.Stops === 0 ||
              v.Flight4JourneyTime.Stops === 0 ||
              v.Flight1JourneyTime.Stops === 1 ||
              v.Flight2JourneyTime.Stops === 1 ||
              v.Flight3JourneyTime.Stops === 1 ||
              v.Flight4JourneyTime.Stops === 1 ||
              v.Flight1JourneyTime.Stops > 1 ||
              v.Flight2JourneyTime.Stops > 1 ||
              v.Flight3JourneyTime.Stops > 1 ||
              v.Flight4JourneyTime.Stops > 1
          );
        } else {
          // Non Stop and One Stop filter
          if (this.NonStopFil && this.OneStopFil) {
            result = result.filter(
              (v) =>
                v.Flight1JourneyTime.Stops === 0 ||
                v.Flight2JourneyTime.Stops === 0 ||
                v.Flight3JourneyTime.Stops === 0 ||
                v.Flight4JourneyTime.Stops === 0 ||
                v.Flight1JourneyTime.Stops === 1 ||
                v.Flight2JourneyTime.Stops === 1 ||
                v.Flight3JourneyTime.Stops === 1 ||
                v.Flight4JourneyTime.Stops === 1
            );
          } else {
            // Non Stop and One Plus filter
            if (this.NonStopFil && this.OnePlusFil) {
              result = result.filter(
                (v) =>
                  v.Flight1JourneyTime.Stops === 0 ||
                  v.Flight2JourneyTime.Stops === 0 ||
                  v.Flight3JourneyTime.Stops === 0 ||
                  v.Flight4JourneyTime.Stops === 0 ||
                  v.Flight1JourneyTime.Stops > 1 ||
                  v.Flight2JourneyTime.Stops > 1 ||
                  v.Flight3JourneyTime.Stops > 1 ||
                  v.Flight4JourneyTime.Stops > 1
              );
            } else {
              // One Stop and One Plus filter
              if (this.OneStopFil && this.OnePlusFil) {
                result = result.filter(
                  (v) =>
                    v.Flight1JourneyTime.Stops === 1 ||
                    v.Flight2JourneyTime.Stops === 1 ||
                    v.Flight3JourneyTime.Stops === 1 ||
                    v.Flight4JourneyTime.Stops === 1 ||
                    v.Flight1JourneyTime.Stops > 1 ||
                    v.Flight2JourneyTime.Stops > 1 ||
                    v.Flight3JourneyTime.Stops > 1 ||
                    v.Flight4JourneyTime.Stops > 1
                );
              } else {
                if (this.NonStopFil) {
                  result = result.filter((v) => {
                    if (v.Flight4JourneyTime) {
                      return (
                        v.Flight1JourneyTime.Stops === 0 ||
                        v.Flight2JourneyTime.Stops === 0 ||
                        v.Flight3JourneyTime.Stops === 0 ||
                        v.Flight4JourneyTime.Stops === 0
                      );
                    } else if (
                      v.Flight1JourneyTime &&
                      v.Flight2JourneyTime &&
                      v.Flight3JourneyTime
                    ) {
                      return (
                        v.Flight1JourneyTime.Stops === 0 ||
                        v.Flight2JourneyTime.Stops === 0 ||
                        v.Flight3JourneyTime.Stops === 0
                      );
                    } else if (v.Flight1JourneyTime && v.Flight2JourneyTime) {
                      return (
                        v.Flight1JourneyTime.Stops === 0 ||
                        v.Flight2JourneyTime.Stops === 0
                      );
                    }
                  });
                }
                if (this.OneStopFil) {
                  result = result.filter((v) => {
                    if (v.Flight4JourneyTime) {
                      return (
                        v.Flight1JourneyTime.Stops === 1 ||
                        v.Flight2JourneyTime.Stops === 1 ||
                        v.Flight3JourneyTime.Stops === 1 ||
                        v.Flight4JourneyTime.Stops === 1
                      );
                    } else if (
                      v.Flight1JourneyTime &&
                      v.Flight2JourneyTime &&
                      v.Flight3JourneyTime
                    ) {
                      return (
                        v.Flight1JourneyTime.Stops === 1 ||
                        v.Flight2JourneyTime.Stops === 1 ||
                        v.Flight3JourneyTime.Stops === 1
                      );
                    } else if (v.Flight1JourneyTime && v.Flight2JourneyTime) {
                      return (
                        v.Flight1JourneyTime.Stops === 1 ||
                        v.Flight2JourneyTime.Stops === 1
                      );
                    }
                  });
                }
                if (this.OnePlusFil) {
                  result = result.filter((v) => {
                    if (v.Flight4JourneyTime) {
                      return (
                        v.Flight1JourneyTime.Stops > 1 ||
                        v.Flight2JourneyTime.Stops > 1 ||
                        v.Flight3JourneyTime.Stops > 1 ||
                        v.Flight4JourneyTime.Stops > 1
                      );
                    } else if (
                      v.Flight1JourneyTime &&
                      v.Flight2JourneyTime &&
                      v.Flight3JourneyTime
                    ) {
                      return (
                        v.Flight1JourneyTime.Stops > 1 ||
                        v.Flight2JourneyTime.Stops > 1 ||
                        v.Flight3JourneyTime.Stops > 1
                      );
                    } else if (v.Flight1JourneyTime && v.Flight2JourneyTime) {
                      return (
                        v.Flight1JourneyTime.Stops > 1 ||
                        v.Flight2JourneyTime.Stops > 1
                      );
                    }
                  });
                }
              }
            }
          }
        }
      }

      if (this.changevalue && this.changevalue.length === 2) {
        const [minValue, maxValue] = this.changevalue;
        result = result.filter((item) => {
          const price = parseFloat(item.perperson);
          return price >= parseFloat(minValue) && price <= parseFloat(maxValue);
        });
      }
      // console.log(result, 'stopsResultttt')
      if (this.selectedFromAirports) {
        result = result.filter((flight) =>
          this.selectedFromAirports.includes(
            flight.Depature[0].Departure.AirportName
          )
        );
        // console.log(result, "resultttt...1");
      }
      if (this.selectedToAirports) {
        result = result.filter((flight) =>
          this.selectedToAirports.includes(
            flight.Depature[flight.Depature.length - 1].Arrival.AirportName
          )
        );
        // console.log(result, "resultttt...2");
      }

      if (this.multicityData && this.multicityData.length > 0) {
        if (this.multiFromAirports) {
          result = result.filter(
            (flight) =>
              this.multiFromAirports.includes(
                flight.Flight1[0].Departure.AirportName
              ) ||
              this.multiFromAirports.includes(
                flight.Flight2[0].Departure.AirportName
              ) ||
              this.multiFromAirports.includes(
                flight.Flight3[0].Departure.AirportName
              ) ||
              this.multiFromAirports.includes(
                flight.Flight4[0].Departure.AirportName
              )
          );
        }
        if (this.multiToAirports) {
          result = result.filter(
            (flight) =>
              this.multiToAirports.includes(
                flight.Flight1[flight.Flight1.length - 1].Arrival.AirportName
              ) ||
              this.multiToAirports.includes(
                flight.Flight2[flight.Flight2.length - 1].Arrival.AirportName
              ) ||
              this.multiToAirports.includes(
                flight.Flight3[flight.Flight3.length - 1].Arrival.AirportName
              ) ||
              this.multiToAirports.includes(
                flight.Flight4[flight.Flight4.length - 1].Arrival.AirportName
              )
          );
        }
      }

      if (this.rountresult || this.onewayfilterData) {
        if (this.defaultOutBoundTime) {
          let compare = parseInt(this.defaultOutBoundTime.split(" ")[0]);
          result = result.filter((v) => {
            if (v.Depaturejourney.Time) {
              let timeParts = v.Depaturejourney.Time.split(" ");
              let hours = parseInt(timeParts[0]);
              let minutes = parseInt(timeParts[2]);
              let roundedHours = Math.round(hours + minutes / 60);
              return roundedHours <= compare;
            }
            return false;
          });
          //  console.log(result,"resulttt.....Hours1")
        }
      }
      if (this.rountresult.length) {
        if (this.defaultInBoundTime) {
          // console.log(this.defaultInBoundTime,'defaultttt')
          let compare = parseInt(this.defaultInBoundTime.split(" ")[0]);
          result = result.filter((v) => {
            if (v.Returnjourney.Time) {
              let timeParts = v.Returnjourney.Time.split(" ");
              let hours = parseInt(timeParts[0]);
              let minutes = parseInt(timeParts[2]);

              let roundedHours = Math.round(hours + minutes / 60);
              return roundedHours <= compare;
            }
            return false;
          });
          // console.log(result,"resuttt.....Hours2")
        }
      }
      if (this.multiFilterData.length > 0) {
        if (this.defaultOutBoundMulti) {
          let compare = parseInt(this.defaultOutBoundMulti.split(" ")[0]);

          result = result.filter((v) => {
            const checkTime = (flight) => {
              if (flight && flight.Time) {
                let timeParts = flight.Time.split(" ");
                let hours = parseInt(timeParts[0]);
                let minutes = parseInt(timeParts[2]);
                let roundedHours = Math.round(hours + minutes / 60);
                return roundedHours <= compare;
              }
              return false;
            };

            return (
              checkTime(v.Flight1JourneyTime) ||
              checkTime(v.Flight2JourneyTime) ||
              checkTime(v.Flight3JourneyTime) ||
              checkTime(v.Flight4JourneyTime)
            );
          });
        }
      }

      if (this.rountresult || this.onewayresulted) {
        if (
          this.timeFilterData1 &&
          this.timeFilterData2 &&
          this.timeFilterData3 &&
          this.timeFilterData4
        ) {
          result = result.filter(
            (flight) =>
              this.filterByTimeRange(flight, this.morningRange) ||
              this.filterByTimeRange(flight, this.afternoonRange) ||
              this.filterByTimeRange(flight, this.eveningRange) ||
              this.filterByTimeRange(flight, this.beforeMorningRange)
          );
        } else if (
          (this.timeFilterData1 && this.timeFilterData2) ||
          (this.timeFilterData1 && this.timeFilterData4) ||
          (this.timeFilterData1 && this.timeFilterData3) ||
          (this.timeFilterData2 && this.timeFilterData4) ||
          (this.timeFilterData2 && this.timeFilterData3) ||
          (this.timeFilterData3 && this.timeFilterData4)
        ) {
          result = result.filter((flight) => {
            const timeFilters = [];
            if (this.timeFilterData1) timeFilters.push(this.morningRange);
            if (this.timeFilterData2) timeFilters.push(this.afternoonRange);
            if (this.timeFilterData3) timeFilters.push(this.eveningRange);
            if (this.timeFilterData4) timeFilters.push(this.beforeMorningRange);
            return timeFilters.some((range) =>
              this.filterByTimeRange(flight, range)
            );
          });
        } else {
          if (this.timeFilterData1) {
            result = result.filter((flight) =>
              this.filterByTimeRange(flight, this.morningRange)
            );
          } else if (this.timeFilterData2) {
            result = result.filter((flight) =>
              this.filterByTimeRange(flight, this.afternoonRange)
            );
          } else if (this.timeFilterData3) {
            result = result.filter((flight) =>
              this.filterByTimeRange(flight, this.eveningRange)
            );
          } else if (this.timeFilterData4) {
            result = result.filter((flight) =>
              this.filterByTimeRange(flight, this.beforeMorningRange)
            );
          }
        }

        // arrival time filters
        if (
          this.timeFilterArrData1 &&
          this.timeFilterArrData2 &&
          this.timeFilterArrData3 &&
          this.timeFilterArrData4
        ) {
          result = result.filter(
            (flight) =>
              this.filterByTimeRange1(flight, this.morningRange) ||
              this.filterByTimeRange1(flight, this.afternoonRange) ||
              this.filterByTimeRange1(flight, this.eveningRange) ||
              this.filterByTimeRange1(flight, this.beforeMorningRange)
          );
        } else if (
          (this.timeFilterArrData1 && this.timeFilterArrData2) ||
          (this.timeFilterArrData1 && this.timeFilterArrData4) ||
          (this.timeFilterArrData1 && this.timeFilterArrData3) ||
          (this.timeFilterArrData2 && this.timeFilterArrData4) ||
          (this.timeFilterArrData2 && this.timeFilterArrData3) ||
          (this.timeFilterArrData3 && this.timeFilterArrData4)
        ) {
          result = result.filter((flight) => {
            const timeFilters = [];
            if (this.timeFilterArrData1) timeFilters.push(this.morningRange);
            if (this.timeFilterArrData2) timeFilters.push(this.afternoonRange);
            if (this.timeFilterArrData3) timeFilters.push(this.eveningRange);
            if (this.timeFilterArrData4)
              timeFilters.push(this.beforeMorningRange);

            return timeFilters.some((range) =>
              this.filterByTimeRange1(flight, range)
            );
          });
        } else {
          if (this.timeFilterArrData1) {
            result = result.filter((flight) =>
              this.filterByTimeRange1(flight, this.morningRange)
            );
          } else if (this.timeFilterArrData2) {
            result = result.filter((flight) =>
              this.filterByTimeRange1(flight, this.afternoonRange)
            );
          } else if (this.timeFilterArrData3) {
            result = result.filter((flight) =>
              this.filterByTimeRange1(flight, this.eveningRange)
            );
          } else if (this.timeFilterArrData4) {
            result = result.filter((flight) =>
              this.filterByTimeRange1(flight, this.beforeMorningRange)
            );
          }
        }

        //Return
        if (
          this.timearrFilterData1 &&
          this.timearrFilterData2 &&
          this.timearrFilterData3 &&
          this.timearrFilterData4
        ) {
          result = result.filter(
            (flight) =>
              this.filterByTimeRangeRet(flight, this.morningRange) ||
              this.filterByTimeRangeRet(flight, this.afternoonRange) ||
              this.filterByTimeRangeRet(flight, this.eveningRange) ||
              this.filterByTimeRangeRet(flight, this.beforeMorningRange)
          );
        } else if (
          (this.timearrFilterData1 && this.timearrFilterData2) ||
          (this.timearrFilterData1 && this.timearrFilterData4) ||
          (this.timearrFilterData1 && this.timearrFilterData3) ||
          (this.timearrFilterData2 && this.timearrFilterData4) ||
          (this.timearrFilterData2 && this.timearrFilterData3) ||
          (this.timearrFilterData3 && this.timearrFilterData4)
        ) {
          result = result.filter((flight) => {
            const timeFilters = [];
            if (this.timearrFilterData1) timeFilters.push(this.morningRange);
            if (this.timearrFilterData2) timeFilters.push(this.afternoonRange);
            if (this.timearrFilterData3) timeFilters.push(this.eveningRange);
            if (this.timearrFilterData4)
              timeFilters.push(this.beforeMorningRange);

            return timeFilters.some((range) =>
              this.filterByTimeRangeRet(flight, range)
            );
          });
        } else {
          if (this.timearrFilterData1) {
            result = result.filter((flight) =>
              this.filterByTimeRangeRet(flight, this.morningRange)
            );
          }
          if (this.timearrFilterData2) {
            result = result.filter((flight) =>
              this.filterByTimeRangeRet(flight, this.afternoonRange)
            );
          }
          if (this.timearrFilterData3) {
            result = result.filter((flight) =>
              this.filterByTimeRangeRet(flight, this.eveningRange)
            );
          }
          if (this.timearrFilterData4) {
            result = result.filter((flight) =>
              this.filterByTimeRangeRet(flight, this.beforeMorningRange)
            );
          }
        }

        if (
          this.timearrFilterArrData1 &&
          this.timearrFilterArrData2 &&
          this.timearrFilterArrData3 &&
          this.timearrFilterArrData4
        ) {
          result = result.filter(
            (flight) =>
              this.filterByTimeRangeRet1(flight, this.morningRange) ||
              this.filterByTimeRangeRet1(flight, this.afternoonRange) ||
              this.filterByTimeRangeRet1(flight, this.eveningRange) ||
              this.filterByTimeRangeRet1(flight, this.beforeMorningRange)
          );
        } else if (
          (this.timearrFilterArrData1 && this.timearrFilterArrData2) ||
          (this.timearrFilterArrData1 && this.timearrFilterArrData4) ||
          (this.timearrFilterArrData1 && this.timearrFilterArrData3) ||
          (this.timearrFilterArrData2 && this.timearrFilterArrData4) ||
          (this.timearrFilterArrData2 && this.timearrFilterArrData3) ||
          (this.timearrFilterArrData3 && this.timearrFilterArrData4)
        ) {
          result = result.filter((flight) => {
            const timeFilters = [];
            if (this.timearrFilterArrData1) timeFilters.push(this.morningRange);
            if (this.timearrFilterArrData2)
              timeFilters.push(this.afternoonRange);
            if (this.timearrFilterArrData3) timeFilters.push(this.eveningRange);
            if (this.timearrFilterArrData4)
              timeFilters.push(this.beforeMorningRange);

            return timeFilters.some((range) =>
              this.filterByTimeRangeRet1(flight, range)
            );
          });
        } else {
          if (this.timearrFilterArrData1) {
            result = result.filter((flight) =>
              this.filterByTimeRangeRet1(flight, this.morningRange)
            );
          }
          if (this.timearrFilterArrData2) {
            result = result.filter((flight) =>
              this.filterByTimeRangeRet1(flight, this.afternoonRange)
            );
          }
          if (this.timearrFilterArrData3) {
            result = result.filter((flight) =>
              this.filterByTimeRangeRet1(flight, this.eveningRange)
            );
          }
          if (this.timearrFilterArrData4) {
            result = result.filter((flight) =>
              this.filterByTimeRangeRet1(flight, this.beforeMorningRange)
            );
          }
        }
      }
      if (this.multicityData) {
        console.log("dataaaa...1");
        if (
          this.timeMulti1 &&
          this.timeMulti2 &&
          this.timeMulti3 &&
          this.timeMulti4
        ) {
          result = result.filter(
            (flight) =>
              this.filterTimeRangeMulti(flight, this.morningRange) ||
              this.filterTimeRangeMulti(flight, this.afternoonRange) ||
              this.filterTimeRangeMulti(flight, this.eveningRange) ||
              this.filterTimeRangeMulti(flight, this.beforeMorningRange)
          );
        } else if (
          (this.timeMulti1 && this.timeMulti2) ||
          (this.timeMulti1 && this.timeMulti4) ||
          (this.timeMulti1 && this.timeMulti3) ||
          (this.timeMulti2 && this.timeMulti4) ||
          (this.timeMulti2 && this.timeMulti3) ||
          (this.timeMulti3 && this.timeMulti4)
        ) {
          console.log("dataaaa...2");
          result = result.filter((flight) => {
            const timeFilters = [];
            if (this.timeMulti1) timeFilters.push(this.morningRange);
            if (this.timeMulti2) timeFilters.push(this.afternoonRange);
            if (this.timeMulti3) timeFilters.push(this.eveningRange);
            if (this.timeMulti4) timeFilters.push(this.beforeMorningRange);
            return timeFilters.some((range) =>
              this.filterTimeRangeMulti(flight, range)
            );
          });
        } else {
          if (this.timeMulti4) {
            result = result.filter((flight) =>
              this.filterTimeRangeMulti(flight, this.beforeMorningRange)
            );
          }
          if (this.timeMulti1) {
            result = result.filter((flight) =>
              this.filterTimeRangeMulti(flight, this.morningRange)
            );
          }
          if (this.timeMulti2) {
            result = result.filter((flight) =>
              this.filterTimeRangeMulti(flight, this.afternoonRange)
            );
          }
          if (this.timeMulti3) {
            result = result.filter((flight) =>
              this.filterTimeRangeMulti(flight, this.eveningRange)
            );
          }
        }
        // console.log(result, "resulttMultii");
        if (
          this.timeMultiArr1 &&
          this.timeMultiArr2 &&
          this.timeMultiArr3 &&
          this.timeMultiArr4
        ) {
          result = result.filter(
            (flight) =>
              this.filterTimeMultiArr(flight, this.morningRange) ||
              this.filterTimeMultiArr(flight, this.afternoonRange) ||
              this.filterTimeMultiArr(flight, this.eveningRange) ||
              this.filterTimeMultiArr(flight, this.beforeMorningRange)
          );
        } else if (
          (this.timeMultiArr1 && this.timeMultiArr2) ||
          (this.timeMultiArr1 && this.timeMultiArr3) ||
          (this.timeMultiArr2 && this.timeMultiArr3) ||
          (this.timeMultiArr1 && this.timeMultiArr4) ||
          (this.timeMultiArr2 && this.timeMultiArr4) ||
          (this.timeMultiArr3 && this.timeMultiArr4)
        ) {
          result = result.filter((flight) => {
            const timeFilters = [];
            if (this.timeMultiArr1) timeFilters.push(this.morningRange);
            if (this.timeMultiArr2) timeFilters.push(this.afternoonRange);
            if (this.timeMultiArr3) timeFilters.push(this.eveningRange);
            if (this.timeMultiArr3) timeFilters.push(this.beforeMorningRange);

            return timeFilters.some((range) =>
              this.filterTimeMultiArr(flight, range)
            );
          });
        } else {
          if (this.timeMultiArr1) {
            result = result.filter((flight) =>
              this.filterTimeMultiArr(flight, this.morningRange)
            );
          }
          if (this.timeMultiArr2) {
            result = result.filter((flight) =>
              this.filterTimeMultiArr(flight, this.afternoonRange)
            );
          }
          if (this.timeMultiArr3) {
            result = result.filter((flight) =>
              this.filterTimeMultiArr(flight, this.eveningRange)
            );
          }
          if (this.timeMultiArr4) {
            result = result.filter((flight) =>
              this.filterTimeMultiArr(flight, this.beforeMorningRange)
            );
          }
        }
      }

      if (this.airlineList) {
        const activeAirlines = this.airlineList
          .filter((airline) => airline.active)
          .map((airline) => airline.airline);

        // result = result.filter(flight => activeAirlines.includes(flight.ownername));
        if (this.rountresult.length > 0) {
          result = result.filter((flight) => {
            const ownernameMatch = activeAirlines.includes(flight.ownername);

            const marketingCarrierMatch = flight.Depature.some((dep) =>
              activeAirlines.includes(dep.MarketingCarrier.Name)
            );
            const marketingCarrierMatch1 = flight.Return.some((dep) =>
              activeAirlines.includes(dep.MarketingCarrier.Name)
            );

            return (
              ownernameMatch || marketingCarrierMatch || marketingCarrierMatch1
            );
          });
        } else if (this.onewayresulted.length > 0) {
          result = result.filter((flight) => {
            const ownernameMatch = activeAirlines.includes(flight.ownername);

            const marketingCarrierMatch = flight.Depature.some((dep) =>
              activeAirlines.includes(dep.MarketingCarrier.Name)
            );

            return ownernameMatch || marketingCarrierMatch;
          });
        } else if (this.multicityData.length > 0) {
          result = result.filter((flight) => {
            const ownernameMatch = activeAirlines.includes(flight.ownername);
            if (
              flight.Flight1 &&
              flight.Flight2 &&
              flight.Flight3 &&
              flight.Flight4
            ) {
              const marketingFlight1 = flight.Flight1.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingFlight2 = flight.Flight2.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingFlight3 = flight.Flight3.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingFlight4 = flight.Flight4.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );

              return (
                ownernameMatch ||
                marketingFlight1 ||
                marketingFlight2 ||
                marketingFlight3 ||
                marketingFlight4
              );
            } else if (flight.Flight1 && flight.Flight2 && flight.Flight3) {
              const marketingFlight1 = flight.Flight1.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingFlight2 = flight.Flight2.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingFlight3 = flight.Flight3.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );

              return (
                ownernameMatch ||
                marketingFlight1 ||
                marketingFlight2 ||
                marketingFlight3
              );
            } else {
              const marketingCarrierMatch = flight.Flight1.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingCarrierMatch1 = flight.Flight2.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );

              return (
                ownernameMatch ||
                marketingCarrierMatch ||
                marketingCarrierMatch1
              );
            }
          });
        }
      }
      // console.log(result, "StopsResultttt...21");
      if (this.rountresult.length > 0) {
        this.hasNonStop = result.some(
          (flight) =>
            flight.Depaturejourney.Stops === 0 ||
            flight.Returnjourney.Stops === 0
        );
        this.hasOneStop = result.some(
          (flight) =>
            flight.Depaturejourney.Stops === 1 ||
            flight.Returnjourney.Stops === 1
        );
        this.hasOnePlusStop = result.some(
          (flight) =>
            flight.Depaturejourney.Stops > 1 || flight.Returnjourney.Stops > 1
        );
        // console.log(this.hasOnePlusStop, result, 'StopsResultttt...2')
      } else if (this.onewayresulted.length > 0) {
        this.hasNonStop = result.some(
          (flight) => flight.Depaturejourney.Stops === 0
        );
        this.hasOneStop = result.some(
          (flight) => flight.Depaturejourney.Stops === 1
        );
        this.hasOnePlusStop = result.some(
          (flight) => flight.Depaturejourney.Stops > 1
        );
      } else if (this.multiFilterData.length > 0) {
        this.multiFilterData.forEach((v) => {
          if (v.Flight4) {
            this.hasNonStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops === 0 ||
                flight.Flight2JourneyTime.Stops === 0 ||
                flight.Flight3JourneyTime.Stops === 0 ||
                flight.Flight4JourneyTime.Stops === 0
            );
            this.hasOneStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops === 1 ||
                flight.Flight2JourneyTime.Stops === 1 ||
                flight.Flight3JourneyTime.Stops === 1 ||
                flight.Flight4JourneyTime.Stops === 1
            );
            this.hasOnePlusStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops > 1 ||
                flight.Flight2JourneyTime.Stops > 1 ||
                flight.Flight3JourneyTime.Stops > 1 ||
                flight.Flight4JourneyTime.Stops > 1
            );
          } else if (v.Flight1 && v.Flight2 && v.Flight3) {
            this.hasNonStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops === 0 ||
                flight.Flight2JourneyTime.Stops === 0 ||
                flight.Flight3JourneyTime.Stops === 0
            );
            this.hasOneStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops === 1 ||
                flight.Flight2JourneyTime.Stops === 1 ||
                flight.Flight3JourneyTime.Stops === 1
            );
            this.hasOnePlusStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops > 1 ||
                flight.Flight2JourneyTime.Stops > 1 ||
                flight.Flight3JourneyTime.Stops > 1
            );
          } else {
            this.hasNonStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops === 0 ||
                flight.Flight2JourneyTime.Stops === 0
            );
            this.hasOneStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops === 1 ||
                flight.Flight2JourneyTime.Stops === 1
            );
            this.hasOnePlusStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops > 1 ||
                flight.Flight2JourneyTime.Stops > 1
            );
          }
        });
      }
      this.hasRefundable = result.some(
        (flight) => flight.Refund === "Refundable"
      );
      this.hasNonRefundable = result.some(
        (flight) => flight.Refund === "Non Refundable"
      );

      // console.log(result, "resulttttMultti..1");
      this.hasMorningFlights =
        !this.timeFilterData1 ||
        result.some((flight) =>
          this.filterByTimeRange(flight, this.morningRange)
        );
      this.hasAfternoonFlights =
        !this.timeFilterData2 ||
        result.some((flight) =>
          this.filterByTimeRange(flight, this.afternoonRange)
        );
      this.hasEveningFlights =
        !this.timeFilterData3 ||
        result.some((flight) =>
          this.filterByTimeRange(flight, this.eveningRange)
        );

      this.hasMorningFlights1 =
        !this.timeFilterArrData1 ||
        result.some((flight) =>
          this.filterByTimeRange1(flight, this.morningRange)
        );
      this.hasAfternoonFlights1 =
        !this.timeFilterArrData2 ||
        result.some((flight) =>
          this.filterByTimeRange1(flight, this.afternoonRange)
        );
      this.hasEveningFlights1 =
        !this.timeFilterArrData3 ||
        result.some((flight) =>
          this.filterByTimeRange1(flight, this.eveningRange)
        );

      this.hasMorningReturn =
        !this.timearrFilterData1 ||
        result.some((flight) =>
          this.filterByTimeRangeRet(flight, this.morningRange)
        );
      this.hasAfternoonReturn =
        !this.timearrFilterData2 ||
        result.some((flight) =>
          this.filterByTimeRangeRet(flight, this.afternoonRange)
        );
      this.hasEveningReturn =
        !this.timearrFilterData3 ||
        result.some((flight) =>
          this.filterByTimeRangeRet(flight, this.eveningRange)
        );

      this.hasMorningReturn1 =
        !this.timearrFilterArrData1 ||
        result.some((flight) =>
          this.filterByTimeRangeRet1(flight, this.morningRange)
        );
      this.hasAfternoonReturn1 =
        !this.timearrFilterArrData2 ||
        result.some((flight) =>
          this.filterByTimeRangeRet1(flight, this.afternoonRange)
        );
      this.hasEveningReturn1 =
        !this.timearrFilterArrData3 ||
        result.some((flight) =>
          this.filterByTimeRangeRet1(flight, this.eveningRange)
        );

      // this.disableData = !this.hasMorningFlights || !this.hasAfternoonFlights || !this.hasEveningFlights ||!this.hasMorningFlights1 || !this.hasAfternoonFlights1 || !this.hasEveningFlights1 || !this.hasMorningReturn || !this.hasAfternoonReturn || !this.hasEveningReturn || !this.hasMorningReturn1 || !this.hasAfternoonReturn1 || !this.hasEveningReturn1 || result.length == 0

      // console.log(this.hasMorningFlights, "filterFlights1");
      // console.log(this.hasAfternoonFlights, "filterFlights2");
      // console.log(this.hasEveningFlights, "filterFlights3");

      if (this.filterData.length > 0) {
        this.rountresult = result;

        this.disableData = this.rountresult.length == 0;
        if (this.disableData) {
          this.hasRefundable = this.filterData.some(
            (flight) => flight.Refund === "Refundable"
          );
          this.hasNonRefundable = this.filterData.some(
            (flight) => flight.Refund === "Non Refundable"
          );
          this.hasNonStop = this.filterData.some(
            (flight) =>
              flight.Depaturejourney.Stops === 0 ||
              flight.Returnjourney.Stops === 0
          );
          this.hasOneStop = this.filterData.some(
            (flight) =>
              flight.Depaturejourney.Stops === 1 ||
              flight.Returnjourney.Stops === 1
          );
          this.hasOnePlusStop = this.filterData.some(
            (flight) =>
              flight.Depaturejourney.Stops > 1 || flight.Returnjourney.Stops > 1
          );
        }
      }
      console.log(this.hasOnePlusStop, this.hasOneStop, "StopsResulttt...1");
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = result;
        this.disableData = this.onewayresulted.length == 0;
        if (
          (this.hasMorningFlights ||
            this.hasAfternoonFlights ||
            this.hasEveningFlights ||
            this.hasMorningFlights1 ||
            this.hasAfternoonFlights1 ||
            this.hasEveningFlights1) &&
          this.disableData
        ) {
          this.hasRefundable = this.onewayfilterData.some(
            (flight) => flight.Refund === "Refundable"
          );
          this.hasNonRefundable = this.onewayfilterData.some(
            (flight) => flight.Refund === "Non Refundable"
          );
          this.hasNonStop = this.onewayfilterData.some(
            (flight) => flight.Depaturejourney.Stops === 0
          );
          this.hasOneStop = this.onewayfilterData.some(
            (flight) => flight.Depaturejourney.Stops === 1
          );
          this.hasOnePlusStop = this.onewayfilterData.some(
            (flight) => flight.Depaturejourney.Stops > 1
          );
        }
      }
      if (this.multiFilterData.length > 0) {
        this.multicityData = result;
        // console.log(result, "resulttttMult");
        this.disableData = this.multicityData.length == 0;
        if (this.disableData) {
          this.hasRefundable = this.multiFilterData.some(
            (flight) => flight.Refund === "Refundable"
          );
          this.hasNonRefundable = this.multiFilterData.some(
            (flight) => flight.Refund === "Non Refundable"
          );
          this.multiFilterData.forEach((v) => {
            if (v.Flight4) {
              this.hasNonStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops === 0 ||
                  flight.Flight2JourneyTime.Stops === 0 ||
                  flight.Flight3JourneyTime.Stops === 0 ||
                  flight.Flight4JourneyTime.Stops === 0
              );
              this.hasOneStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops === 1 ||
                  flight.Flight2JourneyTime.Stops === 1 ||
                  flight.Flight3JourneyTime.Stops === 1 ||
                  flight.Flight4JourneyTime.Stops === 1
              );
              this.hasOnePlusStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops > 1 ||
                  flight.Flight2JourneyTime.Stops > 1 ||
                  flight.Flight3JourneyTime.Stops > 1 ||
                  flight.Flight4JourneyTime.Stops > 1
              );
            } else if (v.Flight1 && v.Flight2 && v.Flight3) {
              this.hasNonStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops === 0 ||
                  flight.Flight2JourneyTime.Stops === 0 ||
                  flight.Flight3JourneyTime.Stops === 0
              );
              this.hasOneStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops === 1 ||
                  flight.Flight2JourneyTime.Stops === 1 ||
                  flight.Flight3JourneyTime.Stops === 1
              );
              this.hasOnePlusStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops > 1 ||
                  flight.Flight2JourneyTime.Stops > 1 ||
                  flight.Flight3JourneyTime.Stops > 1
              );
            } else {
              this.hasNonStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops === 0 ||
                  flight.Flight2JourneyTime.Stops === 0
              );
              this.hasOneStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops === 1 ||
                  flight.Flight2JourneyTime.Stops === 1
              );
              this.hasOnePlusStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops > 1 ||
                  flight.Flight2JourneyTime.Stops > 1
              );
            }
          });
        }
        // console.log(this.multicityData, "multiiiiii");
      }
      // this.rountresult = result;
    },

    priceRange() {
      let min = Infinity;
      let max = -Infinity;

      console.log(this.filterData, "filterDatafilterData");
      for (let i = 0; i < this.filterData.length; i++) {
        const totalAmount = parseFloat(this.filterData[i].perperson);
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      for (let i = 0; i < this.onewayfilterData.length; i++) {
        const totalAmount = parseFloat(this.onewayfilterData[i].perperson);
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      for (let i = 0; i < this.multiFilterData.length; i++) {
        const totalAmount = parseFloat(this.multiFilterData[i].perperson);

        console.log(totalAmount, "tatatatat,,,.....");
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      this.minimum = min.toFixed(2);
      this.maximum = max.toFixed(2);
      this.steps = ((max - min) / 50).toFixed(2);
      this.changevalue[0] = min.toFixed(2);
      this.changevalue[1] = max.toFixed(2);
      console.log(this.changevalue, "totalAmount");

      this.initialChangeValue = this.changevalue;

      this.currencyCode = this.preferCurrency;
    },
    filterOutBound() {
      // console.log(data,'outBoundTime')
      let outBoundTime1 = [];
      let inBoundTime1 = [];
      if (this.rountresult) {
        this.rountresult.forEach((v) => {
          if (v.Depature) {
            let result = v.Depaturejourney.Time.split(" ");
            let hours = parseInt(result[0]);
            let minutes = parseInt(result[2]);

            let roundedHours = Math.round(hours + minutes / 60);
            outBoundTime1.push(roundedHours + "Hour");
          }
          if (v.Return) {
            this.isReturn = true;
            let result1 = v.Returnjourney.Time.split(" ");
            let hours = parseInt(result1[0]);
            let minutes = parseInt(result1[2]);

            let roundedHours = Math.round(hours + minutes / 60);
            inBoundTime1.push(roundedHours + "Hour");
          }
        });
      }
      if (this.onewayresulted) {
        this.onewayresulted.forEach((v) => {
          // console.log(this.onewayresulted, "onewayyyy");
          if (v.Depature) {
            let result = v.Depaturejourney.Time.split("H")[0];
            outBoundTime1.push(result + "Hour");
          }
        });
      }

      let hours = [...new Set(outBoundTime1)];
      // console.log(hours,'hours')
      let numbers = hours.map((str) => parseInt(str));

      let minHour = Math.min(...numbers);
      let maxHour = Math.max(...numbers);
      this.defaultOutBoundTime = `${maxHour} hour`;
      this.outBound = `${maxHour} hour`;
      for (let i = minHour; i <= maxHour; i++) {
        this.outBoundTime.push(`${i} hour`);
      }
      // console.log(this.defaultOutBoundTime,'this.outBoundTimethis.outBoundTime..1')

      let hours1 = [...new Set(inBoundTime1)];
      let numbers1 = hours1.map((str) => parseInt(str));

      let minHour1 = Math.min(...numbers1);
      let maxHour1 = Math.max(...numbers1);
      this.defaultInBoundTime = `${maxHour1} hour`;
      this.inBound = `${maxHour1} hour`;
      for (let i = minHour1; i <= maxHour1; i++) {
        this.inBoundTime.push(`${i} hour`);
      }
      // console.log(this.defaultInBoundTime,'this.outBoundTimethis.outBoundTime..2')
    },
    filterOutBoundMulti() {
      let outBoundTime1 = [];
      this.multicityData.forEach((v) => {
        for (let i = 1; i <= 4; i++) {
          if (v[`Flight${i}`]) {
            let result = v[`Flight${i}JourneyTime`].Time.split("H");
            let hours = parseInt(result[0]);
            let minutes = parseInt(result[1]);
            let roundedHours = Math.round(hours + minutes / 60);
            outBoundTime1.push(roundedHours + "Hour");
          }
        }
      });
      let hours = [...new Set(outBoundTime1)];
      // console.log(hours,'hours')
      let numbers = hours.map((str) => parseInt(str));

      let minHour = Math.min(...numbers);
      let maxHour = Math.max(...numbers);
      this.defaultOutBoundMulti = `${maxHour} hour`;
      this.outBoundMulti = `${maxHour} hour`;
      for (let i = minHour; i <= maxHour; i++) {
        this.outBoundTimeMulti.push(`${i} hour`);
      }
      console.log(this.outBoundTimeMulti, "outBoundoutBound");
    },

    getAiportFilter() {
      if (this.rountresult.length > 0) {
        console.log("yessss1");
        let fromAirportsSet = new Set();
        let toAirportsSet = new Set();

        this.filterData.forEach((data) => {
          fromAirportsSet.add(data.Depature[0].Departure.AirportName);
          toAirportsSet.add(
            data.Depature[data.Depature.length - 1].Arrival.AirportName
          );
        });

        this.fromAirportName = Array.from(fromAirportsSet);
        this.toAirportName = Array.from(toAirportsSet);
      }
      console.log(this.onewayresulted, "onewayyyyyyyy");
      if (this.onewayresulted.length > 0) {
        console.log("yessss");
        let fromAirportsSet = new Set();

        this.onewayfilterData.forEach((data) => {
          fromAirportsSet.add(data.Depature[0].Departure.AirportName);
        });

        this.fromAirportName = Array.from(fromAirportsSet);
      }
    },

    getAiportFilterMulti() {
      let flight1From = new Set();
      let flight1To = new Set();
      let flight2From = new Set();
      let flight2To = new Set();
      let flight3From = new Set();
      let flight3To = new Set();
      let flight4From = new Set();
      let flight4To = new Set();

      this.multiFilterData.forEach((data) => {
        flight1From.add(data.Flight1[0].Departure.AirportName);
        flight1To.add(
          data.Flight1[data.Flight1.length - 1].Arrival.AirportName
        );
        flight2From.add(data.Flight2[0].Departure.AirportName);
        flight2To.add(
          data.Flight2[data.Flight2.length - 1].Arrival.AirportName
        );

        if (data.Flight3) {
          flight3From.add(data.Flight3[0].Departure.AirportName);
          flight3To.add(
            data.Flight3[data.Flight3.length - 1].Arrival.AirportName
          );
        }

        if (data.Flight4) {
          flight4From.add(data.Flight4[0].Departure.AirportName);
          flight4To.add(
            data.Flight4[data.Flight4.length - 1].Arrival.AirportName
          );
        }
      });

      this.multiAirport = [
        {
          from: Array.from(flight1From) || [],
          to: Array.from(flight1To) || [],
        },
        {
          from: Array.from(flight2From) || [],
          to: Array.from(flight2To) || [],
        },
        {
          from: Array.from(flight3From) || [],
          to: Array.from(flight3To) || [],
        },
        {
          from: Array.from(flight4From) || [],
          to: Array.from(flight4To) || [],
        },
      ];
      console.log(this.multiAirport, "multiAirportmultiAirport");
    },

    filterShowing() {
      let filterStore = [];
      // filterStore = this.multiFilterData
      filterStore = this.filterData;

      console.log(filterStore, "fsfsfsfsfs....1...1...1");

      filterStore.forEach((v) => {
        if (v.Depaturejourney.Stops !== 0 && v.Returnjourney.Stops !== 0) {
          this.showNonStop = false;
        } else {
          this.showNonStop = true;
        }
      });

      filterStore.forEach((v) => {
        if (v.Depaturejourney.Stops == 1 && v.Returnjourney.Stops == 1) {
          this.showOneStop = true;
        } else {
          this.showOneStop = false;
        }
      });

      filterStore.forEach((v) => {
        if (v.Depaturejourney.Stops > 1 && v.Returnjourney.Stops > 1) {
          this.showOneplusStop = true;
        } else {
          this.showOneplusStop = false;
        }
      });
    },

    airlineFilter() {
      let storeAirline = [];
      let $event = [];
      if (this.filterData.length > 0) {
        $event = this.filterData;
      }

      if (this.onewayfilterData.length > 0) {
        $event = this.onewayfilterData;
      }
      if (this.multiFilterData.length > 0) {
        $event = this.multiFilterData;
      }
      let airlogo = [];
      $event.forEach((v) => {
        if (!storeAirline.includes(v.ownername)) {
          storeAirline.push(v.ownername);
          airlogo.push(v.ownerlogo);
        }
      });
      storeAirline.forEach((s, index) => {
        let obj = {
          airline: s,
          active: true,
          airlinelog: airlogo[index],
        };
        this.airlineList.push(obj);
      });
      console.log(this.airlineList, "airline logos");
      this.filterAirline = this.airlineList;
    },

    selectAirline(data, index) {
      let $data = []
      let $result = [];
      if (this.rountresult.length > 0) {
        $data = this.rountresult;
      }

      if (this.onewayresulted.length > 0) {
        $data = this.onewayresulted;
      }
      if (this.multicityData.length > 0) {
        $data = this.multicityData;
      }
      this.airlineList.map((a, i) => {
        if (i == index) {
          a.active = !a.active;
        }
        if (a.active) {
          $data.forEach((c) => {
            if (c.ownername == a.airline) {
              $result.push(c);
            }
          });
        }

        $result.sort((a, b) => a.perperson - b.perperson);
        if (this.filterData.length > 0) {
          this.rountresult = $result;
        }

        else if (this.onewayfilterData.length > 0) {
          this.onewayresulted = $result;
        }
        else if (this.multiFilterData.length > 0) {
          this.multicityData = $result;
        }
      });
      this.applyFilters()

      //  this.Cheapest()
      //   this.Shortest()
      // this.bestlayTime()
      //  this.Recommend()
      // $result.sort((a, b) => a.perperson - b.perperson);
      // this.rountresult = $result;
    },

    airlineClear() {
      let $result = [];
      let $data = [];

      if (this.filterData.length > 0) {
        $data = this.filterData;
      }

      if (this.onewayfilterData.length > 0) {
        $data = this.onewayfilterData;
      }

      this.airlineList.forEach((v, i) => {
        if (i !== 0) {
          v.active = false;
        } else {
          v.active = true;
          $data.forEach((c) => {
            if (c.ownername == v.airline) {
              $result.push(c);
            }
          });
        }
      });

      $result.sort((a, b) => a.perperson - b.perperson);
      this.rountresult = $result;
    },

    airlineSelect() {
      this.airlineList.forEach((v) => {
        v.active = true;
      });
      this.rountresult = this.filterData;
    },

    multicitystopFilter() {
      this.multiFilterData.forEach((v) => {
        if (
          v.Flight1JourneyTime.Stops == 0 ||
          v.Flight2JourneyTime.Stops == 0 ||
          (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
            ? v.Flight3JourneyTime.Stops
            : "" == 0) ||
          (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
            ? v.Flight4JourneyTime.Stops
            : "" == 0)
        ) {
          this.showNonStop = true;
        } else if (
          v.Flight1JourneyTime.Stops == 1 ||
          v.Flight1JourneyTime.Stops == 1 ||
          (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
            ? v.Flight3JourneyTime.Stops
            : "" == 1) ||
          (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
            ? v.Flight4JourneyTime.Stops
            : "" == 1)
        ) {
          this.showOneStop = true;
        } else if (
          v.Flight1JourneyTime.Stops >= 1 ||
          v.Flight1JourneyTime.Stops >= 1 ||
          (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
            ? v.Flight3JourneyTime.Stops
            : "" >= 1) ||
          (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
            ? v.Flight4JourneyTime.Stops
            : "" >= 1)
        ) {
          this.showOneplusStop = true;
        }
      });
    },
    // Filter Start Setup End

    closeRet(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.Flightreturn = false;
        }
      });
    },

    // Fare details
    faredetailrount(res, index) {
      // this.showSpinner = true
      this.flightdetailsData = [];
      this.flightdetailsData.push(res);

      this.rountresult.find((v, i) => {
        if (i == index) {
          v.FareDetails = !v.FareDetails;
          v.Flightdepature = false;
          v.barantFare = false;
          console.log(v.FareDetails, "pppppppiiithis....1");
        } else {
          v.FareDetails = false;
        }
      });
      console.log(this.flightdetailsData, "pppppppiiithis....2");
    },

    faredetailoneway(res, index) {
      // this.openOffCanvas = true;
      this.flightdetailsData1 = [];
      this.flightdetailsData1.push(res);
      console.log(
        this.flightdetailsData1,
        "this.flightdetailsDatathis.flightdetailsData"
      );
      this.onewayresulted.find((v, i) => {
        if (i == index) {
          v.FareDetails = !v.FareDetails;
          v.Flightdepature = false;
          v.barantFare = false;
        } else {
          v.FareDetails = false;
        }
      });
    },

    faredetailmulticity(res, index) {
      console.log(index, res);
      this.flightdetailsData2 = [];
      this.flightdetailsData2.push(res);
      this.multicityData.find((v, i) => {
        if (i == index) {
          v.FareDetails = !v.FareDetails;
          v.Flightdepature = false;
          v.barantFare = false;
        } else {
          v.FareDetails = false;
        }
      });
      // document.documentElement.style.overflow = 'hidden';
      // document.body.scroll = "no";
    },
    // Fare details End

    // Flight details
    flighted1(res, index) {
      // this.showSpinner = true
      this.flightdetailsData = [];
      this.flightdetailsData.push(res);

      this.rountresult.find((v, i) => {
        if (i == index) {
          v.Flightdepature = !v.Flightdepature;
          v.FareDetails = false;
          v.barantFare = false;
          console.log(v.Flightdepature, "pppppppiiithis....1");
        } else {
          v.Flightdepature = false;
        }
      });
      console.log(this.flightdetailsData, "pppppppiiithis....2");
    },

    flighted2(res, index) {
      // this.openOffCanvas = true;
      this.flightdetailsData1 = [];
      this.flightdetailsData1.push(res);
      this.onewayresulted.find((v, i) => {
        if (i == index) {
          v.Flightdepature = !v.Flightdepature;
          v.FareDetails = false;
          v.barantFare = false;
        } else {
          v.Flightdepature = false;
        }
      });
    },

    flighted3(res, index) {
      console.log(index, res);
      this.flightdetailsData2 = [];
      this.flightdetailsData2.push(res);
      this.multicityData.find((v, i) => {
        if (i == index) {
          v.Flightdepature = !v.Flightdepature;
          v.FareDetails = false;
          v.barantFare = false;
        } else {
          v.Flightdepature = false;
        }
      });
      // document.documentElement.style.overflow = 'hidden';
      // document.body.scroll = "no";
    },
    // Flight details End

    // Branded Fare
    fareSharing(res, index) {
      // this.barantFare = !this.barantFare
      this.barentFareData.index = index;
      this.barentFareData.brantDeatil = res;

      if (this.onewayresulted.length > 0) {
        this.onewayresulted.forEach((v, i) => {
          // console.log(i,index,'9999999')
          if (i === index) {
            // console.log(i,index,'99999994444')
            v.barantFare = !v.barantFare;
            v.Flightdepature = false;
            v.FareDetails = false;
            this.barentFareData.index = index;
            this.barentFareData.brantDeatil = res;
          } else {
            v.barantFare = false;
          }
        });
      }
      if (this.multicityData.length > 0) {
        this.multicityData.forEach((v, i) => {
          // console.log(i,index,'9999999')
          if (i === index) {
            // console.log(i,index,'99999994444')
            v.barantFare = !v.barantFare;
            v.Flightdepature = false;
            v.FareDetails = false;
            this.barentFareData.index = index;
            this.barentFareData.brantDeatil = res;
          } else {
            v.barantFare = false;
          }
        });
      }
      if (this.rountresult.length > 0) {
        this.rountresult.forEach((v, i) => {
          // console.log(i,index,'9999999')
          if (i === index) {
            // console.log(i,index,'99999994444')
            v.barantFare = !v.barantFare;
            v.Flightdepature = false;
            v.FareDetails = false;
            this.barentFareData.index = index;
            this.barentFareData.brantDeatil = res;
          } else {
            v.barantFare = false;
          }
        });
      }
      this.brantshareDate(res);
    },

    brantshareDate(res) {
      Object.keys(sessionStorage).forEach((key) => {
        if (
          key.startsWith("brantFare-") &&
          key !== `brantFare-${this.barentFareData}`
        ) {
          sessionStorage.removeItem(key);
        }
      });
      sessionStorage.setItem(
        `${"brantFare"}-${res.selectId}`,
        JSON.stringify(this.barentFareData)
      );
    },
    // Branded Fare End

    getConfig() {
      const getConfigData1 = getConfigDataFromLocalStorage();

      if (getConfigData1) {
        this.makeSearchUrl =
          getConfigData1.payload.portal_configuration.API_endpoints.search;
        this.airLogoApi =
          getConfigData1.payload.portal_configuration.content_data.airlines_logo;
        this.preferCurrency =
          getConfigData1.payload.portal_configuration.currency.iso_code;
        this.metaCurrency =
          getConfigData1.payload.portal_configuration.currency.iso_code;
        this.countryCodeData =
          getConfigData1.payload.portal_configuration.country.iso_code;
        this.autocompleteApi =
          getConfigData1.payload.portal_configuration.API_endpoints.autocomplete;
        this.portal_id = getConfigData1.payload.portal_configuration.portal_id;
        console.log(this.countryCodeData, "vvvvvvvvvvvvvvvvvvvvvvv");
        this.airlineApi =
          getConfigData1.payload.portal_configuration.API_endpoints.airline_autocomplete;
        this.airportdataApi =
          getConfigData1.payload.portal_configuration.API_endpoints.airport_data;
        this.pricingApi =
          getConfigData1.payload.portal_configuration.API_endpoints
            .pricingconfirmation ||
          getConfigData1.payload.portal_configuration.API_endpoints.pricing;

        this.pointofSale =
          getConfigData1.payload.portal_configuration.pointofsale;
        // this.airlineLogo_func();
      }
    },
  },
  computed: {
    getTripdatas() {
      return this.rountresult || this.onewayresulted || this.multicityData;
    },
  },

  watch: {
    brandchildData: {
      handler(val) {
        this.barentFareData = val;
        console.log(val, this.barentFareData, "this.barentFareData22");
      },
      deep: true,
      immediate: true,
    },

    getDataResult: {
      handler(newVal) {
        if (!newVal || newVal.length === 0) return;
        Promise.resolve(newVal)
          .then((values) => {
            this.getItindatas = values;

            if (this.getItindatas[0]?.triptype === "RoundTrip") {
              this.isTripDataAvailable = true;
              // console.log(this.getItindatas, "getItindatsasgetItinsdatas");
              this.rountresult = this.getItindatas;
              this.filterData = this.getItindatas;
              this.filteredData = this.filterData;
              this.rounddatafil = true;
              console.log(this.filteredData, "resultttt....1");
              // this.gettimedep();
              // this.gettimeret();
              // this.filterOutBound();
              this.priceRange();
              this.Recommend();
              this.Cheapest();
              this.bestlayTime();
              this.Shortest();
              this.airlineFilter();
              this.applyFilters();

            }
            if (this.getItindatas[0]?.triptype === "OneWay") {
              this.isTripDataAvailable = true;
              console.log(this.getItindatas, "getItindatsasgetItinsdatas");
              this.onewayresulted = this.getItindatas;
              this.onewayfilterData = this.getItindatas;
              this.filteredData = this.onewayfilterData;
              this.onedatafil = true;
              this.rounddatafil = false;
              console.log(this.onewayresulted, "resultttt....2");
              // this.gettimedep();
              // this.gettimeret();
              // this.filterOutBound();
              this.priceRange();
              this.Recommend();
              this.Cheapest();
              this.bestlayTime();
              this.Shortest();
              this.airlineFilter();
              this.applyFilters();
            }
            if (this.getItindatas[0]?.triptype === "MultiCity") {
              this.isTripDataAvailable = true;
              this.multicityData = this.getItindatas;
              this.multiFilterData = this.getItindatas;
              this.filteredData = this.multiFilterData;
              this.onedatafil = false;
              this.rounddatafil = false;
              this.multifil = true;

              console.log(this.multicityData, "multicitsyDatamulticitsyData");

              this.priceRange();
              this.airlineFilter();
              this.Recommend();
              this.Cheapest();
              this.bestlayTime();
              this.Shortest();
              // this.multicitystopFilter();
              // this.filterOutBound();
              this.applyFilters();
            }
          })
          .catch((error) => {
            console.error("Error processing getDataResult:", error);
          });
      },
      immediate: true,
      deep: true,
    },
    // Filter Start Setup
    RefundableData() {
      this.applyFilters();
    },
    nonRefundableData() {
      this.applyFilters();
    },
    NonStopFil() {
      this.applyFilters();
    },
    OneStopFil() {
      this.applyFilters();
    },
    OnePlusFil() {
      this.applyFilters();
    },
    defaultOutBoundTime() {
      this.applyFilters();
    },
    defaultInBoundTime() {
      this.applyFilters();
    },
    selectedFromAirports() {
      this.applyFilters();
    },
    selectedToAirports() {
      this.applyFilters();
    },
    changevalue() {
      this.applyFilters();
    },
    timeFilterData1() {
      this.applyFilters();
    },
    timeFilterData2() {
      this.applyFilters();
    },
    // Filter Start Setup End
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.getConfig();
    window.addEventListener("resize", this.handleResize);
    (function (u, p, l, i, f, t, o, b, j) {
      u['UpLiftPlatformObject'] = f;
      u[f] = u[f] || function () { (u[f].q = u[f].q || []).push(arguments) };
      u[f].l = 1 * new Date();
      b = p.createElement(l), j = p.getElementsByTagName(l)[0];
      b.async = 1;
      b.src = i + '?upcode=' + t;
      j.parentNode.insertBefore(b, j);
      o = u.location.host.match(/[\w-]+\.\w{2,3}(:\d+)?$/);
      if (o) o = o[0];
      u[f]('create', t, o);
    })(window, document, 'script', 'https://tag.uplift.com/external/up.js', 'up', 'UP-48608936-99');

  },
};
</script>
<style scoped>
/* @import '../../assets/css/style.css';
	@import '../../assets/css/style-responsive.css';
	@import '../../assets/css/bootstrap.min.css'; */

/* Advanced Options */
.rec-bottom {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.rec-bottom1 {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media screen and (max-width: 760px) {
  .rec-bottom {
    border-radius: 0 !important;
  }

  .rec-bottom1 {
    border-radius: 0 !important;
  }

  .refundData {
    text-align: center !important;
    display: flex;
  }
}

@media screen and (min-width: 998px) and (max-width: 1024px) {
  .price-head .price-currency {
    font-size: 14px;
  }

  .price-head .price-Amount {
    font-size: 20px;
    margin-top: 3px;
  }
}

.recment {
  border: 1px solid rgb(31 206 229);
  background-color: #bae9e45e;
}

.normal-rec {
  border: 1px solid rgb(163, 161, 161) !important;
}

.top_filterprice {
  font-size: 18px;
  font-weight: 500;
}

.top_filterTitle {
  font-size: 14px;
}

.top_filterprice {
  font-size: 14px !important;
}

.headtext {
  font-size: 13px;
}

:deep(.stopcheckbox .v-input__details) {
  display: none;
}

:deep(.stopcheckbox .v-switch__thumb) {
  width: 16px;
  height: 16px;
}

:deep(.stopcheckbox .v-switch__track) {
  height: 13px;
  min-width: 30px;
}

:deep(.v-switch .v-selection-control) {
  min-height: 20px;
}

:deep(.stopcheckbox .v-label) {
  font-size: 13px;
  padding-left: 5px;
  color: black !important;
  opacity: 1;
  font-weight: 400;
  margin-bottom: 0px;
  margin-left: 0px;
}

:deep(.v-input__details) {
  display: none;
}

:deep(.stopcheckbox .v-checkbox .v-selection-control) {
  min-height: auto;
}

:deep(.stopcheckbox .v-checkbox .v-selection-control__wrapper) {
  height: 20px;
  width: 20px;
}

:deep(.stopcheckbox .v-selection-control__input > .v-icon) {
  font-size: 18px;
}

:deep(.price_slider .v-slider-track__fill) {
  background-color: #ed1d24 !important;
}

/* Advanced Options End */

.flightlogo h2 {
  display: none;
}

.aircity {
  width: 100px;
}

@media only screen and (max-width: 992px) {
  .filter-side.collapse {
    display: none !important;
  }

  .filter-side.show {
    display: block !important;
  }

  .aircity {
    width: 130px;
  }
}

@media only screen and (max-width: 767px) {
  .aircity {
    width: 100px;
  }
}

@media only screen and (max-width: 576px) {
  .filter-text span {
    font-size: 14px;
  }

  .filter-text i {
    font-size: 14px;
  }

  .filter-head .filterarrow {
    font-size: 14px !important;
  }

  .flightlogo img {
    width: 30px !important;
    height: 30px !important;
  }

  .flightlogo h2 {
    font-size: 18px !important;
  }
}

.flogo1 {
  width: 36px;
  height: 34px;
  border-radius: 50%;
  box-shadow: 0px 3px 3px 0px #d2cece;
}
</style>

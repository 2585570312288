<template>
  <div v-if="!dialog4">
    <!-- Loader -->
    <!-- <div v-if="isLoading" class="loader-container">
      <div class="loader"></div>
    </div> -->
    <div class="container">
      <div class="hheading">
        <h1 class="text-start text-capitalize">{{ $t("checkoutcontent.contents.checkout")}}</h1>
      </div>
      <div class="slide-bg">
        <v-form ref="form" v-model="valid1" @submit.prevent="submitform">
          <div class="row">
            <div class="col-sm-12 col-md-8 col-lg-9">
              <div>
                <div v-for="(data, index) of roundresult" :key="index">
                  <div class="flight-detail">
                    <div class="d-flex justify-content-between mb-2 pb-1 border-bottom border-1">
                      <h6 class="m-0">
                        {{ getdepcode(data.Depature) }} -
                        {{ getarrcode(data.Depature) }}
                      </h6>
                      <h6 class="m-0">{{ depdate(data.Depature) }}</h6>
                    </div>
                    <div class="col-12">
                      <div class="row" v-for="(data1, index) of data.Depature" :key="index">
                        <div class="col-sm-3 col-md-3 col-lg-2">
                          <div class="fcart">
                            <img :src="data1.MarketingCarrier.logo" alt="" />

                            <span>{{ data1.MarketingCarrier.Name }}</span>

                            <small>{{ data1.MarketingCarrier.AirlineID }} -
                              {{ data1.MarketingCarrier.FlightNumber }}</small>
                          </div>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-3">
                          <span class="loc">{{
                            data1.Departure.AirportCode
                          }}</span>

                          <span class="time">{{ getflightdep(data1) }}</span>

                          <span class="date">{{ data1.Departure.Date }}</span>

                          <span class="air">{{
                            data1.Departure.AirportName
                          }}</span>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-4">
                          <div class="line">
                            {{ getDurationTime(data1) }}

                            <i class="fa fa-plane"></i>
                          </div>

                          <span class="loc">{{ data.cabinClass }} | {{ data.Refund }}</span>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-3">
                          <span class="loc">{{
                            data1.Arrival.AirportCode
                          }}</span>

                          <span class="time">{{ getflightarr(data1) }}</span>

                          <span class="date">{{ data1.Arrival.Date }}</span>

                          <span class="air">{{
                            data1.Arrival.AirportName
                          }}</span>
                        </div>
                        <div class="change-plan" v-if="data.Depature.length - 1 !== index">
                          Change plane at {{ data1.Arrival.AirportCode }},
                          Connecting Time: 
                          {{Array.isArray(data.layoverTimes1)?data.layoverTimes1[index]: data1.layoverTimes }}


                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flight-detail" v-if="data.Return">
                    <div class="d-flex justify-content-between mb-2 pb-1 border-bottom border-1">
                      <h6 class="m-0">
                        {{ getstart(data.Return) }} - {{ getend(data.Return) }}
                      </h6>
                      <h6 class="m-0">{{ getstartdate(data.Return) }}</h6>
                    </div>
                    <div class="col-12">
                      <div class="row" v-for="(data1, index) of data.Return" :key="index">
                        <div class="col-sm-3 col-md-3 col-lg-2">
                          <div class="fcart">
                            <img :src="data1.MarketingCarrier.logo" alt="" />

                            <span>{{ data1.MarketingCarrier.Name }}</span>

                            <small>{{ data1.MarketingCarrier.AirlineID }} -
                              {{ data1.MarketingCarrier.FlightNumber }}</small>
                          </div>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-3">
                          <span class="loc">{{
                            data1.Departure.AirportCode
                          }}</span>

                          <span class="time">{{ getflightdep(data1) }}</span>

                          <span class="date">{{ data1.Departure.Date }}</span>

                          <span class="air">{{
                            data1.Departure.AirportName
                          }}</span>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-4">
                          <div class="line">
                            {{ getDurationTime(data1) }}

                            <i class="fa fa-plane"></i>
                          </div>

                          <span class="loc">{{ data.cabinClass }} | {{ data.Refund }}</span>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-3">
                          <span class="loc">{{
                            data1.Arrival.AirportCode
                          }}</span>

                          <span class="time">{{ getflightarr(data1) }}</span>

                          <span class="date">{{ data1.Arrival.Date }}</span>

                          <span class="air">{{
                            data1.Arrival.AirportName
                          }}</span>
                        </div>
                        <div class="change-plan" v-if="data.Return.length - 1 !== index">
                          Change plane at {{ data1.Arrival.AirportCode }},
                          Connecting Time: {{Array.isArray(data.layoverTimes1)?data.layoverTimes1[index]: data1.layoverTimes }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-for="(data, index) of onewayresult" :key="index">
                  <div class="flight-detail">
                    <div class="d-flex justify-content-between mb-2 pb-1 border-bottom border-1">
                      <h6 class="m-0">
                        {{ getdepcode(data.Depature) }} -
                        {{ getarrcode(data.Depature) }}
                      </h6>
                      <h6 class="m-0">{{ depdate(data.Depature) }}</h6>
                    </div>
                    <div class="col-12">
                      <div class="row" v-for="(data1, index) of data.Depature" :key="index">
                        <div class="col-sm-3 col-md-3 col-lg-2">
                          <div class="fcart">
                            <img :src="data1.MarketingCarrier.logo" alt="" />

                            <span>{{ data1.MarketingCarrier.Name }}</span>

                            <small>{{ data1.MarketingCarrier.AirlineID }} -
                              {{ data1.MarketingCarrier.FlightNumber }}</small>
                          </div>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-3">
                          <span class="loc">{{
                            data1.Departure.AirportCode
                          }}</span>

                          <span class="time">{{ getflightdep(data1) }}</span>

                          <span class="date">{{ data1.Departure.Date }}</span>

                          <span class="air">{{
                            data1.Departure.AirportName
                          }}</span>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-4">
                          <div class="line">
                            {{ getDurationTime(data1) }}

                            <i class="fa fa-plane"></i>
                          </div>

                          <span class="loc">{{ data.cabinClass }} | {{ data.Refund }}</span>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-3">
                          <span class="loc">{{
                            data1.Arrival.AirportCode
                          }}</span>

                          <span class="time">{{ getflightarr(data1) }}</span>

                          <span class="date">{{ data1.Arrival.Date }}</span>

                          <span class="air">{{
                            data1.Arrival.AirportName
                          }}</span>
                        </div>
                        <div class="change-plan" v-if="data.Depature.length - 1 !== index">
                          Change plane at {{ data1.Arrival.AirportCode }},
                          Connecting Time: {{Array.isArray(data.layoverTimes1)?data.layoverTimes1[index]: data1.layoverTimes }}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-for="(data, index) of multiresult" :key="index">
                  <!-- -----flight1---- -->
                  <div class="flight-detail">
                    <div class="d-flex justify-content-between mb-2 pb-1 border-bottom border-1">
                      <h6 class="m-0">
                        {{ getdepcode(data.Flight1) }} -
                        {{ getarrcode(data.Flight1) }}
                      </h6>
                      <h6 class="m-0">{{ depdate(data.Flight1) }}</h6>
                    </div>
                    <div class="col-12">
                      <div class="row" v-for="(data1, index) of data.Flight1" :key="index">
                        <div class="col-sm-3 col-md-3 col-lg-2">
                          <div class="fcart">
                            <img :src="data1.MarketingCarrier.logo" alt="" />

                            <span>{{ data1.MarketingCarrier.Name }}</span>

                            <small>{{ data1.MarketingCarrier.AirlineID }} -
                              {{ data1.MarketingCarrier.FlightNumber }}</small>
                          </div>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-3">
                          <span class="loc">{{
                            data1.Departure.AirportCode
                          }}</span>

                          <span class="time">{{ getflightdep(data1) }}</span>

                          <span class="date">{{ data1.Departure.Date }}</span>

                          <span class="air">{{
                            data1.Departure.AirportName
                          }}</span>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-4">
                          <div class="line">
                            {{ getDurationTime(data1) }}

                            <i class="fa fa-plane"></i>
                          </div>

                          <span class="loc">{{ data.cabinClass }} | {{ data.Refund }}</span>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-3">
                          <span class="loc">{{
                            data1.Arrival.AirportCode
                          }}</span>

                          <span class="time">{{ getflightarr(data1) }}</span>

                          <span class="date">{{ data1.Arrival.Date }}</span>

                          <span class="air">{{
                            data1.Arrival.AirportName
                          }}</span>
                        </div>
                        <div class="change-plan" v-if="data.Flight1.length - 1 !== index">
                          Change plane at {{ data1.Arrival.AirportCode }},
                          Connecting Time: {{Array.isArray(data.layoverTimes1)?data.layoverTimes1[index]: data1.layoverTimes }}

                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- -----flight2---- -->
                  <div class="flight-detail">
                    <div class="d-flex justify-content-between mb-2 pb-1 border-bottom border-1">
                      <h6 class="m-0">
                        {{ getdepcode(data.Flight2) }} -
                        {{ getarrcode(data.Flight2) }}
                      </h6>
                      <h6 class="m-0">{{ depdate(data.Flight2) }}</h6>
                    </div>
                    <div class="col-12">
                      <div class="row" v-for="(data1, index) of data.Flight2" :key="index">
                        <div class="col-sm-3 col-md-3 col-lg-2">
                          <div class="fcart">
                            <img :src="data1.MarketingCarrier.logo" alt="" />

                            <span>{{ data1.MarketingCarrier.Name }}</span>

                            <small>{{ data1.MarketingCarrier.AirlineID }} -
                              {{ data1.MarketingCarrier.FlightNumber }}</small>
                          </div>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-3">
                          <span class="loc">{{
                            data1.Departure.AirportCode
                          }}</span>

                          <span class="time">{{ getflightdep(data1) }}</span>

                          <span class="date">{{ data1.Departure.Date }}</span>

                          <span class="air">{{
                            data1.Departure.AirportName
                          }}</span>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-4">
                          <div class="line">
                            {{ getDurationTime(data1) }}

                            <i class="fa fa-plane"></i>
                          </div>

                          <span class="loc">{{ data.cabinClass }} | {{ data.Refund }}</span>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-3">
                          <span class="loc">{{
                            data1.Arrival.AirportCode
                          }}</span>

                          <span class="time">{{ getflightarr(data1) }}</span>

                          <span class="date">{{ data1.Arrival.Date }}</span>

                          <span class="air">{{
                            data1.Arrival.AirportName
                          }}</span>
                        </div>
                        <div class="change-plan" v-if="data.Flight2.length - 1 !== index">
                          Change plane at {{ data1.Arrival.AirportCode }},
                          Connecting Time: {{Array.isArray(data.layoverTimes1)?data.layoverTimes1[index]: data1.layoverTimes }}

                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- ----flight3------ -->

                  <div class="flight-detail" v-if="data.Flight3 && data.Flight3.length > 0">
                    <div class="d-flex justify-content-between mb-2 pb-1 border-bottom border-1">
                      <h6 class="m-0">
                        {{ getdepcode(data.Flight3) }} -
                        {{ getarrcode(data.Flight3) }}
                      </h6>
                      <h6 class="m-0">{{ depdate(data.Flight3) }}</h6>
                    </div>
                    <div class="col-12">
                      <div class="row" v-for="(data1, index) of data.Flight3" :key="index">
                        <div class="col-sm-3 col-md-3 col-lg-2">
                          <div class="fcart">
                            <img :src="data1.MarketingCarrier.logo" alt="" />

                            <span>{{ data1.MarketingCarrier.Name }}</span>

                            <small>{{ data1.MarketingCarrier.AirlineID }} -
                              {{ data1.MarketingCarrier.FlightNumber }}</small>
                          </div>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-3">
                          <span class="loc">{{
                            data1.Departure.AirportCode
                          }}</span>

                          <span class="time">{{ getflightdep(data1) }}</span>

                          <span class="date">{{ data1.Departure.Date }}</span>

                          <span class="air">{{
                            data1.Departure.AirportName
                          }}</span>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-4">
                          <div class="line">
                            {{ getDurationTime(data1) }}

                            <i class="fa fa-plane"></i>
                          </div>

                          <span class="loc">{{ data.cabinClass }} | {{ data.Refund }}</span>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-3">
                          <span class="loc">{{
                            data1.Arrival.AirportCode
                          }}</span>

                          <span class="time">{{ getflightarr(data1) }}</span>

                          <span class="date">{{ data1.Arrival.Date }}</span>

                          <span class="air">{{
                            data1.Arrival.AirportName
                          }}</span>
                        </div>
                        <div class="change-plan" v-if="data.Flight3.length - 1 !== index">
                          Change plane at {{ data1.Arrival.AirportCode }},
                          Connecting Time: {{Array.isArray(data.layoverTimes1)?data.layoverTimes1[index]: data1.layoverTimes }}

                        </div>
                      </div>
                    </div>
                  </div>




                  <!-- ------flight4----- -->


                  <div class="flight-detail" v-if="data.Flight4 && data.Flight4.length > 0">
                    <div class="d-flex justify-content-between mb-2 pb-1 border-bottom border-1">
                      <h6 class="m-0">
                        {{ getdepcode(data.Flight4) }} -
                        {{ getarrcode(data.Flight4) }}
                      </h6>
                      <h6 class="m-0">{{ depdate(data.Flight4) }}</h6>
                    </div>
                    <div class="col-12">
                      <div class="row" v-for="(data1, index) of data.Flight4" :key="index">
                        <div class="col-sm-3 col-md-3 col-lg-2">
                          <div class="fcart">
                            <img :src="data1.MarketingCarrier.logo" alt="" />

                            <span>{{ data1.MarketingCarrier.Name }}</span>

                            <small>{{ data1.MarketingCarrier.AirlineID }} -
                              {{ data1.MarketingCarrier.FlightNumber }}</small>
                          </div>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-3">
                          <span class="loc">{{
                            data1.Departure.AirportCode
                          }}</span>

                          <span class="time">{{ getflightdep(data1) }}</span>

                          <span class="date">{{ data1.Departure.Date }}</span>

                          <span class="air">{{
                            data1.Departure.AirportName
                          }}</span>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-4">
                          <div class="line">
                            {{ getDurationTime(data1) }}

                            <i class="fa fa-plane"></i>
                          </div>

                          <span class="loc">{{ data.cabinClass }} | {{ data.Refund }}</span>
                        </div>

                        <div class="col-sm-3 col-md-3 col-lg-3">
                          <span class="loc">{{
                            data1.Arrival.AirportCode
                          }}</span>

                          <span class="time">{{ getflightarr(data1) }}</span>

                          <span class="date">{{ data1.Arrival.Date }}</span>

                          <span class="air">{{
                            data1.Arrival.AirportName
                          }}</span>
                        </div>
                        <div class="change-plan" v-if="data.Flight4.length - 1 !== index">
                          Change plane at {{ data1.Arrival.AirportCode }},
                          Connecting Time: {{Array.isArray(data.layoverTimes1)?data.layoverTimes1[index]: data1.layoverTimes }}

                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- -------flight4 end----- -->

                </div>
              </div>

              <!--Traveller 01 start-->
              <br />

              <div class="mb-3" v-for="(data, index) of adultData" :key="index">
                <h2 class="htitle">
                  <i class="fa fa-user"></i> Enter Traveller Details |
                  <small>Adult {{ index + 1 }} </small>
                </h2>

                <div class="form-bg">
                  <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label>Salutation</label>
                        <!-- <select v-model="data.salutation" class="form-control">
                        <option v-for="(item, index) of salutationItems" :key="index">{{ item.text }}</option>
                      </select> -->
                        <v-select :items="salutationItems" v-model="data.salutation" variant="outlined"
                          item-title="text"></v-select>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label>First Name <span v-if="!data.fname" class="text-danger">*</span></label>
                        <!-- <input v-model="data.fname" type="text" class="form-control" /> -->
                        <v-text-field v-model="data.fname" :rules="[v => !!v || 'First Name is required']" variant="outlined"
                          class="form-control"></v-text-field>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label>Middle Name(optional) </label>

                        <!-- <input type="text" class="form-control" /> -->
                        <v-text-field variant="outlined" v-model="data.middle"></v-text-field>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label>Last Name <span v-if="!data.last" class="text-danger">*</span></label>

                        <!-- <input type="text" class="form-control" /> -->
                        <v-text-field variant="outlined" v-model="data.last" :rules="lastName"></v-text-field>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4 d-none">
                      <div class="form-group">
                        <label>Organisation Name (optional)</label>

                        <!-- <input type="text" class="form-control" /> -->
                        <v-text-field
                          variant="outlined"
                          v-model="data.organisationName"
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label>Gender <span v-if="!data.gender" class="text-danger">*</span></label>
                        <!-- <select class="form-control" v-model="data.gender">
                        <option v-for="(item, index) of genderItems" :key="index"> {{ item.text }}</option>
                      </select> -->
                        <v-select v-model="data.gender" :items="genderItems" variant="outlined" item-title="text"
                          :rules="[(v) => !!v || 'This field is required']"></v-select>
                      </div>
                    </div>

                    <!-- <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label>Date of Birth <span v-if="!data.dateofBirth" class="text-danger">*</span></label>
                        <VueDatePicker v-model="data.dateOfBirth" ref="dpRef1" no-today :six-weeks="true" auto-apply
                          :start-date="new Date(
                                    new Date(getdepDate).getFullYear() - 12,
                                    new Date(getdepDate).getMonth(),
                                    new Date(getdepDate).getDate(), 0, 0, 0)"
                                :max-date="
                                  new Date(
                                    new Date(getdepDate).getFullYear() - 12,
                                    new Date(getdepDate).getMonth(),
                                    new Date(getdepDate).getDate(), 23, 59, 59)"
                                :min-date="
                                  new Date(
                                    new Date(getdepDate).getFullYear() - 100,
                                    new Date(getdepDate).getMonth(),
                                    new Date(getdepDate).getDate()
                                  )
                                " :format="dateFormat" :hide-navigation="['time']" @update:modelValue="adultDob()"
                          class="px-0 datepicker form-control" placeholder="Select Date" required :class="!data.dateOfBirth && formSubmitted
                              ? 'error-border'
                              : ''
                            " />
                        <p v-if="!data.dateOfBirth && formSubmitted" class="p-error ps-3 m-0">
                          Date of birth is required
                        </p>
                      </div>
                    </div> -->

                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label> {{this.$t('flightInfoContent.contents.labels.year' ) }}<span  class="text-danger">*</span></label>
                       
                        <v-text-field

                                          v-model="data.dateDob.year"
                                          class="yearPick"
                                          id="inlineFormSelectTitle3"
                                          :rules="yearRules(data.Type, index)"
                                          maxlength="4"
                                           variant="outlined"
                                          @keypress="filter(event)"
                                          @input="inputHandle(data, index)"
                                          ref="yearInput"
                                        ></v-text-field>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label> {{this.$t('flightInfoContent.contents.labels.month' ) }}<span  class="text-danger">*</span></label>
                       
                        <v-select
                                          class="monthPick border-0"
                                          id="inlineFormSelectTitle4"
                                          v-model="data.dateDob.month"
                                          :items="
                                            data.isYearValid
                                              ? monthListOriginal
                                              : []
                                          "
                                          variant="outlined"
                                          :rules="monthRules(data.Type, index)"
                                          @click="inputChange(data, index)"
                                        ></v-select>
                      </div>
                    </div>


                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label> {{this.$t('flightInfoContent.contents.labels.date') }}<span  class="text-danger">*</span></label>
                       
                        <v-text-field
                                          v-model="data.dateDob.date"
                                          id="inlineFormSelectTitle15"
                                          class="datePick border-0"
                                          :rules="dateRules(data, index)"
                                          maxlength="2"
                                          variant="outlined"
                                          @keypress="filter(event)"
                                        ></v-text-field>
                      </div>
                    </div>



                    
                  </div>

                  <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label>Passport Number</label>
                        <!-- <input v-model="data.fname" type="text" class="form-control" /> -->
                        <v-text-field
                          v-model="data.passPortNumber"
                          variant="outlined"
                          class="form-control"
                          
                        ></v-text-field>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label>Country </label>

                        <v-autocomplete
                          v-model="data.countryCode"
                          :items="countrycode"
                          item-title="name"        
                          item-value="code"        
                          variant="outlined"
                          width="auto"
                        >
                        </v-autocomplete>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label>Expiry Date </label>
                        <v-text-field v-model="data.ExpireDate" 
                          @keydown="handleInput" @paste.prevent label="Expiry (MM/YY)"
                          @input="(event) => formatExpireDate(event, 'adultData',index)"
                          @keypress="(event) => filterExpdate(event,'adultData',index)" 
                          variant="outlined"
                          @keydown.tab="focusNext" tabindex="3" class="expiryDate"></v-text-field>
                      </div>
                    </div>
                  </div>


   
    <div class="d-flex">
      <span style="color:black;font-size:14px;font-weight: 500;">
        Frequent Flier Details
      </span>
      <span style="    position: relative;
        left: 10px;
        top: 3px;cursor:pointer">
        <input type="checkbox" @click="freueChange(data,index,this.adultData)"
        style="transform: scale(1.4);cursor: pointer;">
      </span>
    </div>
 

     <div class="row mt-2" v-if="data.freuent">
      <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label>Airlines Name </label>
                       
                        <v-select
                          :items="flightname"
                          item-title="Name"
                          item-value="AirlineID"
                          v-model="data.flyerName"
                          variant="outlined"
                          class="form-control"
                          
                        ></v-select>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label>Frequent flyer Number</label>
                        <v-text-field
                          v-model="data.flyerId"
                          variant="outlined"
                          class="form-control"
                           
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div class="mb-3" v-for="(data, index) of childData" :key="index">
                
                <h2 class="htitle">
                  <i class="fa fa-user"></i> Enter Traveller Details |
                  <small>Child {{(index + 1) }} </small>
                </h2>

                <div class="form-bg">
                  <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label>Salutation</label>
                        <!-- <select v-model="data.salutation" class="form-control">
                        <option v-for="(item, index) of salutationItems" :key="index">{{ item.text }}</option>
                      </select> -->
                        <v-select :items="salutationItemsChild" v-model="data.salutation" variant="outlined"
                          item-title="text"></v-select>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label>First Name<span v-if="!data.fname" class="text-danger">*</span></label>
                        <!-- <input v-model="data.fname" type="text" class="form-control" /> -->
                        <v-text-field v-model="data.fname" :rules="firstName" variant="outlined"></v-text-field>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label>Middle Name(optional)</label>

                        <!-- <input type="text" class="form-control" /> -->
                        <v-text-field variant="outlined" v-model="data.middle"></v-text-field>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label>Last Name<span v-if="!data.last" class="text-danger">*</span></label>

                        <!-- <input type="text" class="form-control" /> -->
                        <v-text-field variant="outlined" v-model="data.last" :rules="lastName"></v-text-field>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4 d-none">
                      <div class="form-group">
                        <label>Organisation Name (optional)</label>

                        <!-- <input type="text" class="form-control" /> -->
                        <v-text-field
                          variant="outlined"
                          v-model="data.organisationName"
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label>Gender</label>
                        <!-- <select class="form-control" v-model="data.gender">
                        <option v-for="(item, index) of genderItems" :key="index"> {{ item.text }}</option>
                      </select> -->
                        <v-select v-model="data.gender" :items="genderItems" variant="outlined" item-title="text"
                          :rules="[(v) => !!v || 'This field is required']"></v-select>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label> {{this.$t('flightInfoContent.contents.labels.year' ) }}<span  class="text-danger">*</span></label>
                       
                        <v-text-field

                                          v-model="data.dateDob.year"
                                          class="yearPick"
                                          id="inlineFormSelectTitle3"
                                          :rules="yearRules(data.Type, index)"
                                          maxlength="4"
                                           variant="outlined"
                                          @keypress="filter(event)"
                                          @input="inputHandle(data, index)"
                                          ref="yearInput"
                                        ></v-text-field>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label> {{this.$t('flightInfoContent.contents.labels.month' ) }}<span  class="text-danger">*</span></label>
                       
                        <v-select
                                          class="monthPick border-0"
                                          id="inlineFormSelectTitle4"
                                          v-model="data.dateDob.month"
                                          :items="
                                            data.isYearValid
                                              ? monthListOriginal
                                              : []
                                          "
                                          variant="outlined"
                                          :rules="monthRules(data.Type, index)"
                                          @click="inputChange(data, index)"
                                        ></v-select>
                      </div>
                    </div>


                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label> {{this.$t('flightInfoContent.contents.labels.date') }}<span  class="text-danger">*</span></label>
                       
                        <v-text-field
                                          v-model="data.dateDob.date"
                                          id="inlineFormSelectTitle15"
                                          class="datePick border-0"
                                          :rules="dateRules(data, index)"
                                          maxlength="2"
                                          variant="outlined"
                                          @keypress="filter(event)"
                                        ></v-text-field>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label>Passport Number</label>
                        <!-- <input v-model="data.fname" type="text" class="form-control" /> -->
                        <v-text-field
                          v-model="data.passPortNumber"
                          variant="outlined"
                          class="form-control"
                          
                        ></v-text-field>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label>Country</label>

                        <v-autocomplete
                          v-model="data.countryCode"
                          :items="countrycode"
                          item-title="name"        
                          item-value="code"        
                          variant="outlined"
                          width="auto"
                        >
                        </v-autocomplete>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label>Expiry Date</label>
                        <v-text-field v-model="data.ExpireDate" 
                          @keydown="handleInput" @paste.prevent label="Expiry (MM/YY)"
                          @input="(event) => formatExpireDate(event, 'childData',index)"
                          @keypress="(event) => filterExpdate(event,'childData',index)" 
                          variant="outlined"
                          @keydown.tab="focusNext" tabindex="3" class="expiryDate"></v-text-field>
                      </div>
                    </div>
               
                    
     </div>

     
     <div class="d-flex">
      <span style="color:black;font-size:14px;font-weight: 500;">
        Frequent Flier Details
      </span>
      <span style="    position: relative;
        left: 10px;
        top: 3px;cursor:pointer">
        <input type="checkbox" @click="freueChange(data,index,this.childData)"
        style="transform: scale(1.4);cursor: pointer;">
      </span>
    </div>


     <div class="row mt-2"  v-if="data.freuent">
      <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label>Airlines  Name</label>
                       
                        <v-select
                          :items="flightname"
                          item-title="Name"
                          item-value="AirlineID"
                          v-model="data.flyerName"
                          variant="outlined"
                          class="form-control"
                          
                        ></v-select>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label>Frequent flyer Number</label>
                        <v-text-field
                          v-model="data.flyerId"
                          variant="outlined"
                          class="form-control"
                           
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div class="mb-3" v-for="(data, index) of infrantData" :key="index">
                <h2 class="htitle">
                  <i class="fa fa-user"></i> Enter Traveller Details |
                  <small>Infants
                    {{(index + 1) }}
                  </small>
                </h2>

                <div class="form-bg">
                  <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label>Salutation</label>
                        <!-- <select v-model="data.salutation" class="form-control">
                        <option v-for="(item, index) of salutationItems" :key="index">{{ item.text }}</option>
                      </select> -->
                        <v-select :items="salutationItemsChild" v-model="data.salutation" variant="outlined"
                          item-title="text"></v-select>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label>First Name</label>
                        <!-- <input v-model="data.fname" type="text" class="form-control" /> -->
                        <v-text-field v-model="data.fname" :rules="firstName" variant="outlined"></v-text-field>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label>Middle Name(optional)</label>

                        <!-- <input type="text" class="form-control" /> -->
                        <v-text-field variant="outlined" v-model="data.middle"></v-text-field>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label>Last Name</label>

                        <!-- <input type="text" class="form-control" /> -->
                        <v-text-field variant="outlined" v-model="data.last" :rules="lastName"></v-text-field>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4 d-none">
                      <div class="form-group">
                        <label>Organisation Name (optional)</label>

                        <!-- <input type="text" class="form-control" /> -->
                        <v-text-field
                          variant="outlined"
                          v-model="data.organisationName"
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label>Gender</label>
                        <!-- <select class="form-control" v-model="data.gender">
                        <option v-for="(item, index) of genderItems" :key="index"> {{ item.text }}</option>
                      </select> -->
                        <v-select v-model="data.gender" :items="genderItems" variant="outlined" item-title="text"
                          :rules="[(v) => !!v || 'This field is required']"></v-select>
                      </div>
                    </div>

                 <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label> {{this.$t('flightInfoContent.contents.labels.year' ) }}<span  class="text-danger">*</span></label>
                       
                        <v-text-field

                                          v-model="data.dateDob.year"
                                          class="yearPick"
                                          id="inlineFormSelectTitle3"
                                          :rules="yearRules(data.Type, index)"
                                          maxlength="4"
                                           variant="outlined"
                                          @keypress="filter(event)"
                                          @input="inputHandle(data, index)"
                                          ref="yearInput"
                                        ></v-text-field>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label> {{this.$t('flightInfoContent.contents.labels.month' ) }}<span  class="text-danger">*</span></label>
                       
                        <v-select
                                          class="monthPick border-0"
                                          id="inlineFormSelectTitle4"
                                          v-model="data.dateDob.month"
                                          :items="
                                            data.isYearValid
                                              ? monthListOriginal
                                              : []
                                          "
                                          variant="outlined"
                                          :rules="monthRules(data.Type, index)"
                                          @click="inputChange(data, index)"
                                        ></v-select>
                      </div>
                    </div>


                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group">
                        <label> {{this.$t('flightInfoContent.contents.labels.date') }}<span  class="text-danger">*</span></label>
                       
                        <v-text-field
                                          v-model="data.dateDob.date"
                                          id="inlineFormSelectTitle15"
                                          class="datePick border-0"
                                          :rules="dateRules(data, index)"
                                          maxlength="2"
                                          variant="outlined"
                                          @keypress="filter(event)"
                                        ></v-text-field>
                      </div>
                    </div> 


                  </div>

                  <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label>Passport Number</label>
                        <!-- <input v-model="data.fname" type="text" class="form-control" /> -->
                        <v-text-field
                          v-model="data.passPortNumber"
                          variant="outlined"
                          class="form-control"
                          
                        ></v-text-field>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label>Country</label>

                        <v-autocomplete
                          v-model="data.countryCode"
                          :items="countrycode"
                          item-title="name"        
                          item-value="code"        
                          variant="outlined"
                          width="auto"
                        >
                        </v-autocomplete>

                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label>Expiry Date</label>
                        <v-text-field v-model="data.ExpireDate" 
                          @keydown="handleInput" @paste.prevent label="Expiry (MM/YY)"
                          @input="(event) => formatExpireDate(event, 'infrantData',index)"
                          @keypress="(event) => filterExpdate(event,'infrantData',index)" 
                          variant="outlined"
                          @keydown.tab="focusNext" tabindex="3" class="expiryDate"></v-text-field>
                      </div>
                    </div>
                  </div>

     <div class="d-flex">
      <span style="color:black;font-size:14px;font-weight: 500;">
        Frequent Flier Details
      </span>
      <span style="    position: relative;
        left: 10px;
        top: 3px;cursor:pointer">
        <input type="checkbox" @click="freueChange(data,index,this.infrantData)"
        style="transform: scale(1.4);cursor: pointer;">
      </span>
    </div>

 


     <div class="row mt-2" v-if="data.freuent">
      <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label>Airlines Name</label>
                       
                        <v-select
                          :items="flightname"
                          item-title="Name"
                          item-value="AirlineID"
                          v-model="data.flyerName"
                          variant="outlined"
                          class="form-control"
                          
                        ></v-select>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-4">
                      <div class="form-group">
                        <label>Frequent flyer Number</label>
                        <v-text-field
                          v-model="data.flyerId"
                          variant="outlined"
                          class="form-control"
                           
                        ></v-text-field>
                      </div>
                    </div>
                   
                  </div>

                </div>
              </div>

              <div >
              <h2 class="htitle">
                <i class="fa fa-user"></i> Enter Contact Details |
                <small>
                  <a href="/login" style="text-decoration: none">Sign in</a>
                  to book faster</small>
              </h2>

              <div class="form-bg">
                <div class="row" v-for="data of paxContacts" :key="data">
                  <div class="col-sm-12 col-md-5 col-lg-5">
                    <div class="form-group">
                      <label>
                        Email ID

                        <small>(Booking confirmation will be sent to this email
                          ID)</small><span v-if="!data.email" class="text-danger">*</span>
                      </label>

                      <!-- <input type="text" class="form-control" /> -->
                      <v-text-field :rules="emailRequire" variant="outlined" v-model="data.email"></v-text-field>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-3 col-lg-3">
                    <div class="form-group">
                      <label> Country Code <span v-if="!data.code" class="text-danger">*</span></label>

                      <v-autocomplete v-model="data.code" :items="countrycode" item-title="name" variant="outlined"
                        width="auto" :rules="[(v) => !!v || 'Country Code is required']" placeholder="Country Code" class="countryInput">
                      </v-autocomplete>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="form-group">
                      <label>Mobile Number <span v-if="!data.number" class="text-danger">*</span></label>

                      <!-- <input type="number" class="form-control" /> -->
                      <v-text-field v-model="data.number" minlength="5" maxlength="15" :rules="phoneNumberValid"
                        variant="outlined"></v-text-field>
                    </div>
                  </div>
                </div>

                <div class="form-group form-check ps-0">
                  <div class="d-flex">
                    <!-- <input type="checkbox" class="form-check-input" id="GST" data-toggle="collapse" data-target="#gstd" /> -->
                    <v-checkbox v-model="formCheckBox" class="px-3" style="flex: none"></v-checkbox>
                    <label class="form-check-label" for="GST" data-toggle="collapse" data-target="#gstd">
                      By selecting to continue I acknowledge that I have read
                      and accept the Rules & Restrictions, Terms of use, Fare
                      rules and Privacy Policy. In addition, I also confirm that
                      I have verified that all information entered during the
                      reservation process is accurate. I also acknowledge that
                      this will serve as my electronic signature.
                    </label>
                  </div>
                  <p v-if="!formCheckBox && checkForm" class="w-50 ps-4 m-0 fw-400"
                    style="color: #b00020; font-size: 13px">
                    You must agree to continue!
                  </p>
                </div>

                <div class="row collapse" id="gstd">
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label>GST Number</label>

                      <input type="text" class="form-control" />
                    </div>
                  </div>

                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label> Company Name </label>

                      <input type="text" class="form-control" />
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="form-group">
                      <label> Company Address </label>

                      <input type="text" class="form-control" />
                    </div>
                  </div>
                </div>
              </div>

              <br /><br />

              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-4">
                  <button type="submit" class="btn-book1">Pay Now</button>
                </div>
           </div>
           </div>
            </div>
            <!--Fare Detail-->
            <div class="col-sm-12 col-md-4 col-lg-3">
              <div class="fw-bold mb-3">Fare Details</div>
              <div class="row">
                <div class="col-12 mb-3">
                  <div class="theme-box-shadow theme-border-radius theme-bg-white">
                    <ul class="p-0">
                      <li class="border-bottom p-3 font-medium">
                        <div class="d-flex justify-content-between">
                          <span class="font-medium" style="font-weight: 500">
                            Passenger</span>
                          <!-- <span>
                                                          <span class="" style="font-weight:500;">Total Price</span>
                                                      </span> -->
                        </div>
                      </li>
                      <!-- gst charge fare -->
                      <li class="border-bottom p-3 font-medium">
                        <div class="d-flex flex-column">
                          <div v-if="adult > 0">{{ adult }} x Adult</div>
                          <div v-if="child > 0">{{ child }} x Child</div>
                          <div v-if="inf > 0">{{ inf }} x Infrant</div>
                          <!-- </span> -->
                        </div>
                      </li>
                      <!-- grand total charge fare -->
                      <li class="border-bottom p-3 font-medium">
                        <div class="d-flex justify-content-between">
                          <span class="fs-6" style="font-weight: 600">
                            Grand Total</span>
                          <span class="fs-6">
                            <span class="fw-bold">
                              {{ currency }}
                              {{ totalAmountpassenger }}</span>
                          </span>
                        </div>
                      </li>
                      <li class="border-bottom p-3 font-medium">
                        <a class="d-flex justify-content-between align-center theme-text-accent-one"
                          data-bs-toggle="collapse" href="#faresummary" role="button" aria-expanded="false"
                          aria-controls="faresummary">
                          <span class="font-medium fw-bold">
                            Fare Summary</span>
                          <span>
                            <i class="bi bi-plus-circle-fill theme-text-accent-two"></i>
                          </span>
                        </a>
                        <div class="collapse" id="faresummary">
                          <div v-for="(res, index) of getDataResult" :key="index"
                            class="bordeer-top-0 border border-2 border-top-0">
                            <div class="comDetailSection">
                              <div class="paxpricesection">
                                <table class="table table-borderless mb-0" style="font-size: 10px;">
                                  <thead>
                                    <tr class="f-size-10 fw-500">
                                      <td style="background: #dee2e6">Pax</td>
                                      <td style="background: #dee2e6">Base</td>
                                      <td style="background: #dee2e6">Tax</td>
                                      <td style="background: #dee2e6">Per Person</td>
                                    </tr>
                                  </thead>
                                  <tbody class="f-size-10 fw-400">
                                    {{
                                      console.log(res, "priceDetailllll")
                                    }}
                                    <template v-for="(priceDetail, paxindex) in res.pricedetails" :key="paxindex">
                                      <tr
                                        v-if="priceDetail && priceDetail.pax_type && priceDetail.baseprice && priceDetail.taxprice">
                                        <td>{{ priceDetail.pax_type }}</td>
                                        <td>{{ priceDetail.baseprice }}</td>
                                        <td>{{ priceDetail.taxprice }}</td>
                                        <td>{{ getperpersonPrice(priceDetail) }}</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="border-bottom p-3 font-medium">
                        <div class="d-flex justify-content-between">
                          <span class="font-medium">Base Price<br />
                            Taxes & Fees</span>
                          <div class="d-flex flex-column">
                            <div class="d-flex justify-end">
                              <div class="pe-0">{{ currency }}</div>
                              <div class="text-end ps-1">
                                {{ totalbasePassanger }}
                              </div>
                            </div>
                            <div class="d-flex justify-end">
                              <div class="pe-0">{{ currency }} </div>
                              <div class="text-end ps-1">
                                {{ totalTaxPassanger }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="border-bottom font-medium">
                        <div class="d-flex text-start">
                          <a class="baggage border-none p-3" style="
                                    font-size: 13px;
                                    font-weight: 600;
                                    color: rgb(13, 110, 253);
                                    text-decoration: underline;
                                  ">
                            <span data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop"
                              aria-controls="staticBackdrop" @click="getFareRules()">Baggage and Cancellation
                              Rules</span>
                          </a>
                        </div>
                        <div class="drawer-section">
                          <div class="offcanvas offcanvas-end" data-bs-backdrop="static" tabindex="-1"
                            id="staticBackdrop" aria-labelledby="staticBackdropLabel">
                            <div class="offcanvas-header d-flex justify-content-between"
                              style="background: #002d5b !important;">
                              <h6 class="offcanvas-title" id="staticBackdrop" style="color: #fff !important;">
                                Baggage and Cancellation Rules
                              </h6>
                              <button @click="closeBaggageRules" type="button" class="text-reset"
                                data-bs-dismiss="offcanvas" aria-label="Close" color="white">
                                <v-icon color="white">mdi-close-circle-outline</v-icon>
                              </button>
                            </div>
                            <div class="offcanvas-body pt-0" style="overflow-x: hidden">
                              <v-card class="mt-4">
                                <v-tabs v-model="tab" align-tabs="center" stacked>
                                  <v-tab value="tab-1" class="m-0">
                                    <v-icon>mdi-bag-checked</v-icon>
                                    Baggage
                                  </v-tab>
                                  <v-tab value="tab-2">
                                    <v-icon>mdi-book-cancel-outline</v-icon>
                                    Cancellation Rules
                                  </v-tab>
                                </v-tabs>

                                <v-card-text class="pt-0">
                                  <keep-alive>
                                    <v-window v-model="tab">
                                      <div v-if="tab === 'tab-1'">
                                        <h6 class="mt-1">Baggage Rules:</h6>
                                        {{ console.log(roundresult,onewayresult,'onewayresultonewayresult') }}
                                        <div class="p-2 rounded" v-for="(data, index) of roundresult" :key="index">
                                          <div v-if="data.depBaggage">
                                            <div style="
                                                      font-size: 15px;
                                                      font-weight: 500;
                                                    ">
                                              {{ data.fromTrip }} -
                                              {{ data.toTrip }}
                                            </div>
                                            <div class="d-flex ms-2 mt-2">
                                              <!-- <div>
                                    
                                  </div> -->
                                              <div style="
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        margin-left: 5px;
                                                      ">
                                                <span> Checked:</span>
                                                <span v-if="data.depBaggage" class="ms-1">
                                                  {{
                                                    data.depBaggage
                                                      .PieceAllowance
                                                      .TotalQuantity || 0
                                                  }}

                                                  {{
                                                    data.depBaggage
                                                      .PieceAllowance
                                                      .TotalQuantity > 1
                                                      ? "Pieces" : "Piece"
                                                  }}
                                                </span>
                                                <span v-if="data.BaggageData" class="ms-1">
                                                  {{
                                                    data.BaggageData
                                                      .PieceAllowance
                                                      .TotalQuantity || 0
                                                  }}

                                                  {{
                                                    data.BaggageData
                                                      .PieceAllowance
                                                      .TotalQuantity > 1
                                                      ? "Pieces" : "Piece"
                                                  }}
                                                </span>
                                                <v-icon color="gray" style="
                                                          color: gray !important;
                                                        ">
                                                  mdi-bag-suitcase
                                                </v-icon>
                                              </div>
                                            </div>

                                            <div class="d-flex ms-2 mt-2">
                                              <!-- <div >
                                    
                                  </div> -->
                                              <div style="
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        margin-left: 5px;
                                                      ">
                                                <span>Carry-On:</span>
                                                <span v-if="data.depBaggage" class="ms-1">
                                                  {{
                                                    data.depBaggage
                                                      .PieceAllowance
                                                      .TotalQuantity > 0
                                                      ? 1 + " " + "Piece" : 1 + " " + "Piece"
                                                  }}
                                                </span>
                                                <span v-if="data.BaggageData" class="ms-1">
                                                  {{
                                                    data.BaggageData
                                                      .PieceAllowance
                                                      .TotalQuantity > 0
                                                      ? 1 + " " + "Piece" : 1 + " " + "Piece"
                                                  }}
                                                </span>
                                                <v-icon color="gray" style="
                                                          color: gray !important;
                                                        ">
                                                  mdi-bag-checked
                                                </v-icon>
                                              </div>
                                            </div>
                                          </div>
                                          <hr v-if="data.Return" />
                                          <div v-if="data.Return" class="mt-2">
                                            <div class="mt-1" style="
                                                      font-size: 15px;
                                                      font-weight: 500;
                                                    ">
                                              {{ data.toTrip }} -
                                              {{ data.fromTrip }}
                                            </div>
                                            <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                            <div class="d-flex ms-2 mt-1">
                                              <!-- <div style="color: gray !important">
                                    
                                  </div> -->
                                              <div style="
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        margin-left: 5px;
                                                      ">
                                                <span> Checked:</span>
                                                <!-- <span class="ms-1"> {{res.arrBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                                <span class="ms-1">
                                                  {{
                                                    data?.arrBaggage
                                                      ?.PieceAllowance
                                                      ?.TotalQuantity || 0
                                                  }}
                                                  {{
                                                    data?.arrBaggage
                                                      ?.PieceAllowance
                                                      ?.TotalQuantity > 1
                                                      ? "Pieces" : "Piece"
                                                  }}
                                                </span>
                                                <v-icon color="gray" style="
                                                          color: gray !important;
                                                        ">
                                                  mdi-bag-suitcase
                                                </v-icon>
                                              </div>
                                            </div>

                                            <div class="d-flex ms-2 mt-2">
                                              <!-- <div >
                                    
                                  </div> -->
                                              <div style="
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        margin-left: 5px;
                                                      ">
                                                <span>Carry-On:</span>
                                                <span class="ms-1">
                                                  {{
                                                    data?.arrBaggage
                                                      ?.PieceAllowance
                                                      ?.TotalQuantity > 0
                                                      ? 1 + " " + "Piece" : 1 + " " + "Piece"
                                                  }}
                                                </span>
                                                <v-icon color="gray" style="
                                                          color: gray !important;
                                                        ">
                                                  mdi-bag-checked
                                                </v-icon>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="p-2 rounded" v-for="(data, index) of onewayresult" :key="index">
                                          <div v-if="data.depBaggage">
                                            <div style="
                                                      font-size: 15px;
                                                      font-weight: 500;
                                                    ">
                                              {{ data.fromTrip }} -
                                              {{ data.toTrip }}
                                            </div>
                                            <div class="d-flex ms-2 mt-2">
                                              <!-- <div>
                                    
                                  </div> -->
                                              <div style="
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        margin-left: 5px;
                                                      ">
                                                <span> Checked:</span>
                                                <span v-if="data.depBaggage" class="ms-1">
                                                  {{
                                                    data.depBaggage
                                                      .PieceAllowance
                                                      .TotalQuantity || 0
                                                  }}

                                                  {{
                                                    data.depBaggage
                                                      .PieceAllowance
                                                      .TotalQuantity > 1
                                                      ? "Pieces" : "Piece"
                                                  }}
                                                </span>
                                                <span v-if="data.BaggageData" class="ms-1">
                                                  {{
                                                    data.BaggageData
                                                      .PieceAllowance
                                                      .TotalQuantity || 0
                                                  }}

                                                  {{
                                                    data.BaggageData
                                                      .PieceAllowance
                                                      .TotalQuantity > 1
                                                      ? "Pieces" : "Piece"
                                                  }}
                                                </span>
                                                <v-icon color="gray" style="
                                                          color: gray !important;
                                                        ">
                                                  mdi-bag-suitcase
                                                </v-icon>
                                              </div>
                                            </div>

                                            <div class="d-flex ms-2 mt-2">
                                              <!-- <div >
                                    
                                  </div> -->
                                              <div style="
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        margin-left: 5px;
                                                      ">
                                                <span>Carry-On:</span>
                                                <span v-if="data.depBaggage" class="ms-1">
                                                  {{
                                                    data.depBaggage
                                                      .PieceAllowance
                                                      .TotalQuantity > 0
                                                      ? 1 + " " + "Piece" : 1 + " " + "Piece"
                                                  }}
                                                </span>
                                                <span v-if="data.BaggageData" class="ms-1">
                                                  {{
                                                    data.BaggageData
                                                      .PieceAllowance
                                                      .TotalQuantity > 0
                                                      ? 1 + " " + "Piece" : 1 + " " + "Piece"
                                                  }}
                                                </span>
                                                <v-icon color="gray" style="
                                                          color: gray !important;
                                                        ">
                                                  mdi-bag-checked
                                                </v-icon>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div v-if="multiresult">
                                          <div class="border p-2 rounded" v-for="(
data, index
                                                  ) of multiresult" :key="index">
                                            <div>
                                              <div style="
                                                        font-size: 15px;
                                                        font-weight: 500;
                                                      ">
                                                {{
                                                  getdepNameMulti(
                                                    data.muticityData[0]
                                                  )
                                                }}
                                                -
                                                {{
                                                  getarrNameMulti(
                                                    data.muticityData[0]
                                                  )
                                                }}
                                              </div>
                                              <div class="d-flex ms-2 mt-2">
                                                <!-- <div>
                                    
                                  </div> -->
                                                <div style="
                                                          font-size: 14px;
                                                          font-weight: 400;
                                                          margin-left: 5px;
                                                        ">
                                                  <span>
                                                    Checked:
                                                  </span>
                                                  <span class="mt-1">
                                                    {{
                                                      data.BaggageData1
                                                        .PieceAllowance
                                                        .TotalQuantity || 0
                                                    }}{{
                                                      data.BaggageData1
                                                        .PieceAllowance
                                                        .TotalQuantity > 1
                                                        ? "Pieces" : "Piece"
                                                    }}</span>
                                                  <v-icon color="gray" style="
                                                            color: gray !important;
                                                          ">
                                                    mdi-bag-suitcase
                                                  </v-icon>
                                                </div>
                                              </div>

                                              <div class="d-flex ms-2 mt-2">
                                                <!-- <div >
                                    
                                  </div> -->
                                                <div style="
                                                          font-size: 14px;
                                                          font-weight: 400;
                                                          margin-left: 5px;
                                                        ">
                                                  <span>Carry-On: </span>
                                                  <span class="">{{
                                                    data.BaggageData1
                                                      .PieceAllowance
                                                      .TotalQuantity > 0
                                                      ? 1 + " " + "Piece" : 1 + " " + "Piece"
                                                  }}</span>
                                                  <v-icon color="gray" style="
                                                            color: gray !important;
                                                          ">
                                                    mdi-bag-checked
                                                  </v-icon>
                                                </div>
                                              </div>
                                            </div>
                                            <hr v-if="data.Flight2" />
                                            <div v-if="data.Flight2" class="mt-2">
                                              <div class="mt-1" style="
                                                        font-size: 15px;
                                                        font-weight: 500;
                                                      ">
                                                {{
                                                  getdepNameMulti(
                                                    data.muticityData[1]
                                                  )
                                                }}
                                                -
                                                {{
                                                  getarrNameMulti(
                                                    data.muticityData[1]
                                                  )
                                                }}
                                              </div>
                                              <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                              <div class="d-flex ms-2 mt-2">
                                                <!-- <div>
                                    
                                  </div> -->
                                                <div style="
                                                          font-size: 14px;
                                                          font-weight: 400;
                                                          margin-left: 5px;
                                                        ">
                                                  <span>
                                                    Checked:
                                                  </span>
                                                  <span class="mt-1">
                                                    {{
                                                      data.BaggageData2
                                                        .PieceAllowance
                                                        .TotalQuantity || 0
                                                    }}{{
                                                      data.BaggageData2
                                                        .PieceAllowance
                                                        .TotalQuantity > 1
                                                        ? "Pieces" : "Piece"
                                                    }}</span>
                                                  <v-icon color="gray" style="
                                                            color: gray !important;
                                                          ">
                                                    mdi-bag-suitcase
                                                  </v-icon>
                                                </div>
                                              </div>

                                              <div class="d-flex ms-2 mt-2">
                                                <!-- <div >
                                    
                                  </div> -->
                                                <div style="
                                                          font-size: 14px;
                                                          font-weight: 400;
                                                          margin-left: 5px;
                                                        ">
                                                  <span>Carry-On: </span>
                                                  <span class="">{{
                                                    data.BaggageData2
                                                      .PieceAllowance
                                                      .TotalQuantity > 0
                                                      ? 1 + " " + "Piece" : 1 + " " + "Piece"
                                                  }}</span>
                                                  <v-icon color="gray" style="
                                                            color: gray !important;
                                                          ">
                                                    mdi-bag-checked
                                                  </v-icon>
                                                </div>
                                              </div>
                                            </div>
                                            <hr v-if="data.Flight3" />
                                            <div v-if="data.Flight3" class="mt-2">
                                              <div class="mt-1" style="
                                                        font-size: 15px;
                                                        font-weight: 500;
                                                      ">
                                                {{
                                                  getdepNameMulti(
                                                    data.muticityData[2]
                                                  )
                                                }}
                                                -
                                                {{
                                                  getarrNameMulti(
                                                    data.muticityData[2]
                                                  )
                                                }}
                                              </div>
                                              <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                              <div class="d-flex ms-2 mt-2">
                                                <!-- <div>
                                    
                                  </div> -->
                                                <div style="
                                                          font-size: 14px;
                                                          font-weight: 400;
                                                          margin-left: 5px;
                                                        ">
                                                  <span>
                                                    Checked:
                                                  </span>
                                                  <span class="mt-1">
                                                    {{
                                                      data.BaggageData3
                                                        .PieceAllowance
                                                        .TotalQuantity || 0
                                                    }}{{
                                                      data.BaggageData3
                                                        .PieceAllowance
                                                        .TotalQuantity > 1
                                                        ? "Pieces" : "Piece"
                                                    }}</span>
                                                  <v-icon color="gray" style="
                                                            color: gray !important;
                                                          ">
                                                    mdi-bag-suitcase
                                                  </v-icon>
                                                </div>
                                              </div>

                                              <div class="d-flex ms-2 mt-2">
                                                <!-- <div >
                                    
                                  </div> -->
                                                <div style="
                                                          font-size: 14px;
                                                          font-weight: 400;
                                                          margin-left: 5px;
                                                        ">
                                                  <span>Carry-On: </span>
                                                  <span class="">{{
                                                    data.BaggageData3
                                                      .PieceAllowance
                                                      .TotalQuantity > 0
                                                      ? 1 + " " + "Piece" : 1 + " " + "Piece"
                                                  }}</span>
                                                  <v-icon color="gray" style="
                                                            color: gray !important;
                                                          ">
                                                    mdi-bag-checked
                                                  </v-icon>
                                                </div>
                                              </div>
                                            </div>
                                            <hr v-if="data.Flight4" />
                                            <div v-if="data.Flight4" class="mt-2">
                                              <div class="mt-1" style="
                                                        font-size: 15px;
                                                        font-weight: 500;
                                                      ">
                                                {{
                                                  getdepNameMulti(
                                                    data.muticityData[3]
                                                  )
                                                }}
                                                -
                                                {{
                                                  getarrNameMulti(
                                                    data.muticityData[3]
                                                  )
                                                }}
                                              </div>
                                              <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                                              <div class="d-flex ms-2 mt-2">
                                                <!-- <div>
                                    
                                  </div> -->
                                                <div style="
                                                          font-size: 14px;
                                                          font-weight: 400;
                                                          margin-left: 5px;
                                                        ">
                                                  <span>
                                                    Checked:
                                                  </span>
                                                  <span class="mt-1">
                                                    {{
                                                      data.BaggageData4
                                                        .PieceAllowance
                                                        .TotalQuantity || 0
                                                    }}{{
                                                      data.BaggageData4
                                                        .PieceAllowance
                                                        .TotalQuantity > 1
                                                        ? "Pieces" : "Piece"
                                                    }}</span>
                                                  <v-icon color="gray" style="
                                                            color: gray !important;
                                                          ">
                                                    mdi-bag-suitcase
                                                  </v-icon>
                                                </div>
                                              </div>

                                              <div class="d-flex ms-2 mt-2">
                                                <!-- <div >
                                    
                                  </div> -->
                                                <div style="
                                                          font-size: 14px;
                                                          font-weight: 400;
                                                          margin-left: 5px;
                                                        ">
                                                  <span>Carry-On: </span>
                                                  <span class="">{{
                                                    data.BaggageData4
                                                      .PieceAllowance
                                                      .TotalQuantity > 0
                                                      ? 1 + " " + "Piece" : 1 + " " + "Piece"
                                                  }}</span>
                                                  <v-icon color="gray" style="
                                                            color: gray !important;
                                                          ">
                                                    mdi-bag-checked
                                                  </v-icon>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div v-if="tab === 'tab-2'">
                                        <h6 class="mt-2">
                                          Cancellation Rules:
                                        </h6>
                                        <div class="p-2 cancellationRules">
                                          <div v-html="fareRulesContent"></div>
                                          <p class="my-1">
                                            Please check the Airline websites
                                          </p>
                                        </div>
                                      </div>
                                    </v-window>
                                  </keep-alive>
                                </v-card-text>
                              </v-card>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row" style="display: none">
                <div class="col-12">
                  <div class="theme-box-shadow theme-border-radius bg-light p-3 font-small">
                    Cancellation &amp; Date change charges
                    <a href="#" class="">More</a>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </v-form>

<!-- <div>
  <paymentUplift />
</div> -->

      </div>
    </div>
  </div>
  <!-- <div v-if="pageLoader">
    <pageLoader></pageLoader>
  </div> -->
  <v-dialog v-model="dialog4" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">OOPS!</h3>
        <h5>Something went wrong.</h5>
        <h6>Please contact customer support at</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <!-- <v-btn @click="dialog3 = false" color="#002d5b">Try Again</v-btn> -->

          <v-btn to="/" color="#002d5b">Return To Home</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import moment from "moment";
// import VueDatePicker from "@vuepic/vue-datepicker";
// import "@vuepic/vue-datepicker/dist/main.css";
// import pageLoader from "@/components/pageLoader.vue"
import axios from "axios";
import country1 from "@/coundtrycode.json";
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
// import paymentUplift from "../paymentPage/paymentUplift.vue";


export default {
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  components: {
    // headerCom,
    // footerCom,
    // travellerDetail,
    // VueDatePicker,
    // pageLoader
    // paymentUplift
  },
  data() {
    const monthListAll = Array.from({ length: 12 }, (_, i) => {
      const date = new Date(2000, i, 1); // Use any year, here I used 2000
      const monthName = date
        .toLocaleString("default", { month: "short" })
        .substring(0, 3);
      return monthName;
    });

    return {
      // isLoading: true,
      shoppingid: "",
      metaCurrency: "",
      pageLoader: true,
      dialog4: false,
      selectId: "",
      tab: null,
      portalId: "",
      bookingviewApi: "",
      bookingApi: "",
      fareRulesContent: "",
      fareLoader: false,
      airlogodata: [],
      portal: false,
      countrycode: [],
      countrycode1: [],
      portal_shopId: "",
      portal_offId: "",
      limitPosition: 500,
      scrolled: false,
      lastPosition: 0,
      topSticky: "0%",
      booking_Id: "",
      formCheckBox: false,
      checkForm: false,
      datenumber: false,
      contactCheck: true,
      couponsCheck: true,
      totalAmountpassenger: [],
      currency: "",
      totalTaxPassanger: [],
      totalbasePassanger: [],
      valid1: false,
      ffb: "",
      air: "Aeroplan-Air canada",
      Redress: "",
      Traveler: "",
      Redressnumber: true,
      result: "name",
      value: "1",
      dateOfBirth: "",
      dateFormat: "dd MMM yyyy",
      apivalue: true,
      dateData: [],
      yearData: [],
      portalData: [],
      mode: true,
      dialog3: false,
      formSubmitted: false,
      fareRulesApi: "",
      preferCurrency:"",
      autocompleteApi:"",
      passengersDatas: {
        adultDatas: [],
        childDatas: [],
        infrantDatas: [],
      },

      adultData: [],
      childData: [],
      infrantData: [],
      adult: "",
      child: "",
      inf: "",

      paxContacts: [
        {
          number: "",
          email: "",
        },
      ],

      emailRequire: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address",
      ],

      phoneNumberValid: [
        (v) => !!v || "Phone Number is required",
        (v) => /^\+?\d{5,15}$/.test(v) || "Invalid Phone Number",
      ],

      //     emailRequire: {
      //   required: (v) => !!v || 'Email is required',
      //   validEmail: (v) => /.+@.+\..+/.test(v) || 'Please enter a valid email address'
      // }

      itineraryInfo: false,
      itineraryInfo1: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      flightInfo: false,
      flightDetail: false,
      bagInfo: false,
      baggageDetails: false,
      overlaySelector: false,
      valid: true,
      titleBox: true,
      roundresult: [],
      onewayresult: [],
      multiresult: [],
      isIcon: false,
      fromData: "",
      toData: "",
      adultLength: "",
      childLength: "",
      infLength: "",
      classed: "",
      fareItems: [],
      adultFare: [],
      childFare: [],
      infrantFare: [],
      adultprice: [],
      childprice: [],
      infprice: [],
      adulttax: [],
      childtax: [],
      inftax: [],
      totaltax: [],
      totalprice: [],
      totalamount: [],
      layoverTimes: [],
      portalResData: [],
      fareshow: false,
      // dobError:false,
      // clicked1: false,
      offerData1: [],
      offerData2: [],
      offerData3: [],
      offerData4: [],
      Flight2: [],
      Flight3: [],
      Flight4: [],
      Flight1: [],
      muticityData: [],
      dobAdultError: false,
      dobChildError: false,
      dobInfrantError: false,

      adultpax: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },
      chd: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },

      infpax: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },
      segmentList:[],
      local_id: "",
      Localoneway_id: null,

      titles: ["Flight", "Passenger", "Request", "Free Text"],
      specialBox: [
        {
          fBox: null,
          pBox: "Adult 1",
          rBox: null,
          freeBox: "",
        },
      ],
      selectFlight: [
        "California",
        "Colorado",
        "Florida",
        "Georgia",
        "Texas",
        "Wyoming",
      ],
      selectPassanger: ["Adult 1"],
      selectRequest: [
        "California",
        "Colorado",
        "Florida",
        "Georgia",
        "Texas",
        "Wyoming",
      ],

      flightValid: [(v) => !!v || "Field is required"],
      passengerValid: [(v) => !!v || "Field is required"],
      requestValid: [(v) => !!v || "Field is required"],
      // textValid:[v => !!v || 'Field is required'],
      expDate: [
        (v) => !!v || "Expiry Date is required",
        (v) => /^[0-9/]+$/.test(v) || 'Only numbers and "/" are allowed',
        (v) => {
          const [month, year] = v.split("/");

          const isInvalidFormat = !v.match(/^(0[1-9]|1[0-2])\/\d{2}$/);
          if (isInvalidFormat) return "Invalid format (MM / YY)";

          const inputMonth = parseInt(month);
          const inputYear = parseInt(year);

          const currentDate = new Date();
          const currentMonth = currentDate.getMonth() + 1;
          const currentYear = currentDate.getFullYear() % 100;
          const maxYear = currentYear + 10;

          if (inputMonth < 1 || inputMonth > 12) return "Invalid month";
          if (inputYear < currentYear) return "Invalid year";
          if (inputYear === currentYear && inputMonth < currentMonth) return "Invalid month";
          if (inputYear > maxYear) return "Year exceeded max year";

          return true;
        },
      ],
      firstName: [
        (v) => !!v || "First Name is required",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
        (v) => v.length > 1 || "Max 2 letters are allowed",
      ],
      priceapiData:"",
      pricingApi:"",
      lastName: [
        (v) => !!v || "Last Name is required",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
        (v) => v.length > 1 || "Max 2 letters are allowed",
      ],

      middleName: [
        (v) => !!v || "Please enter your Middle Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
        (v) => v.length > 1 || "Max 2 letters are allowed",
      ],

      getdepDate: "",
      portal_domain: "",
      pointScale:"",
      airLogoApi: "",
      airline_LogoData: [],
      salutationItems: this.getSalutation(),
      salutationItemsChild: this.getSalutationChild(),
      genderItems: this.getGender(),
      flightname:[],
      indexData: 0,
      seatmapApi: "",
      totalSeat: [],
      totalData: [],
      totalpassanger: [],
      seatSelect: [],
      passSelect: 0,
      flightSelect: 0,
      monthListOriginal: [...monthListAll],
      monthList: [...monthListAll],
    };
  },
  methods: {
    getsegment() {

      if (this.roundresult) {
        console.log(this.roundresult,'this.roundresult')
        this.roundresult.forEach((v) => {

          v.Depature.forEach((s) => {
            this.segmentList.push(s);
          });
          if (v.Return) {
            v.Return.forEach((e) => {
              this.segmentList.push(e);
            });
          }
        });
        console.log(this.segmentList, "this.segmentListthis.segmentList...1.");
      }

      if (this.onewayresult) {
        console.log(this.onewayresult,'this.onewayresult222')
        this.onewayresult.forEach((v) => {
          v.Depature.forEach((s) => {
            this.segmentList.push(s);
          });
        });
        console.log(this.onewayresult, "this.segmentListthis.segmentList...2.");
      }

      if (this.multiresult) {
        this.multiresult.forEach((v) => {
          if (v.Flight1) {
            v.Flight1.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight2) {
            v.Flight2.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight3) {
            v.Flight3.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight4) {
            v.Flight4.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight5) {
            v.Flight5.forEach((s) => {
              this.segmentList.push(s);
            });
          }
        });
        console.log(this.segmentList, "this.segmentListthis.segmentList...3.");
      }
    },

    inputHandle(data, index) {
      if (data.Type == "Adult") {
        if (data.dateDob.year.length < 4) {
          this.adultData.map((v, i) => {
            if (index == i) {
              v.dateDob.month = null;
              v.dateDob.date = null;
              v.isYearValid = false;
            }
          });
        }
      } else if (data.Type == "Child") {
        if (data.dateDob.year.length < 4) {
          this.childData.map((v, i) => {
            if (index == i) {
              v.dateDob.month = null;
              v.dateDob.date = null;
              v.isYearValid = false;
            }
          });
        }
      } else if (data.Type == "Infrant") {
        if (data.dateDob.year.length < 4) {
          this.infrantData.map((v, i) => {
            if (index == i) {
              v.dateDob.month = null;
              v.dateDob.date = null;
              v.isYearValid = false;
            }
          });
        }
      }
    },

    inputChange(data, index) {
      // console.log(data,index,'data,indexdata,indexdata,index')

      const enteredYear = data.dateDob.year;

      if (data.Type == "Adult") {
        this.adultData.map((v, i) => {
          if (index == i) {
            v.dateDob.date = null;
          }
        });

        this.monthList = [...this.monthListOriginal];
        console.log(this.monthList, "this.monthListthis.monthList....1");
      } else if (data.Type == "Child") {
        this.childData.map((v, i) => {
          if (index == i) {
            v.dateDob.date = null;
          }
        });

        this.monthList = [...this.monthListOriginal];
        console.log(this.monthList, "this.monthListthis.monthList....2");
      } else if (data.Type == "Infrant") {
        this.infrantData.map((v, i) => {
          if (index == i) {
            v.dateDob.date = null;
          }
        });

        let currentDate = moment(
          this.segmentList[this.segmentList.length - 1].Departure.Date
        ).format("YYYY-MMM-DD");
        let maximumDate = new Date(
          new Date(currentDate).getFullYear(),
          new Date(currentDate).getMonth(),
          new Date(currentDate).getDate() - 15
        );
        let maxMonth = moment(maximumDate, "YYYY-MMM-DD").format("MMM");

        console.log(currentDate, "currentDate...", maxMonth, "maxMonth...");

        if (enteredYear == new Date(maximumDate).getFullYear()) {
          const maxMonthIndex = this.monthListOriginal.indexOf(maxMonth);
          this.monthList = [...this.monthListOriginal];
          this.monthList.splice(maxMonthIndex + 1);
          console.log(this.monthList, "this.monthListthis.monthList....3");
        } else {
          this.monthList = [...this.monthListOriginal];
          console.log(this.monthList, "this.monthListthis.monthList.....4");
        }
      }
    },

    yearRules(Type, index) {
      return [
        (v) => !!v || "Please enter the Year",
        (v) => /^\d+$/.test(v) || "Please enter a valid Year",
        (v) => {
          const currentYear = new Date(
            this.segmentList[0].Departure.Date
          ).getFullYear();
          const enteredYear = parseInt(v, 10);

          let minYear, maxYear;

          if (Type === "Adult") {
            minYear = currentYear - 100;
            maxYear = currentYear - 12;

            if (v.length === 4) {
              console.log(enteredYear, "enteredYearenteredYear");
              const isValid = enteredYear >= minYear && enteredYear <= maxYear;
              if (isValid) {
                this.adultData.map((v, i) => {
                  if (index == i) {
                    v.isYearValid = true;
                  }
                });
              }
              return (
                isValid || `Enter a year between ${minYear} and ${maxYear}`
              );
            }
          } else if (Type === "Child") {
            this.segmentList.forEach((v) => {
              if (this.segmentList.length - 1) {
                maxYear = new Date(v.Departure.Date).getFullYear();
              }
            });

            maxYear = currentYear - 2;
            minYear = maxYear - 10;

            console.log(minYear, "minYearminYearminYear");

            if (v.length === 4) {
              const isValid = enteredYear >= minYear && enteredYear <= maxYear;
              if (isValid) {
                this.childData.map((v, i) => {
                  if (index == i) {
                    v.isYearValid = true;
                  }
                });
              }
              return (
                isValid || `Enter a year between ${minYear} and ${maxYear}`
              );
            }
          } else if (Type === "Infrant") {
            let currentDate = moment(
              this.segmentList[this.segmentList.length - 1].Departure.Date
            ).format("YYYY-MMM-DD");
            let maximumDate = new Date(
              new Date(currentDate).getFullYear(),
              new Date(currentDate).getMonth() - 1,
              new Date(currentDate).getDate()
            );
            let maxDate = moment(maximumDate, "YYYY-MMM-DD").format(
              "YYYY-MMM-DD"
            );

            maxYear = new Date(maxDate).getFullYear();
            minYear = maxYear - 2;

            if (v.length === 4) {
              const isValid = enteredYear >= minYear && enteredYear <= maxYear;
              if (isValid) {
                this.infrantData.map((v, i) => {
                  if (index == i) {
                    v.isYearValid = true;
                  }
                });
              }
              return (
                isValid || `Enter a year between ${minYear} and ${maxYear}`
              );
            }
          }
        },
      ];
    },

    monthRules(Type, index) {
      return [
        // () => (!!this.adultData[index].dateDob.year || !!this.childData[index].dateDob.year || !!this.infrantData[index].dateDob.year) || `Please enter the Year first`,
        () => {
          if (Type === "Adult") {
            if (!this.adultData[index].dateDob.year) {
              return (
                !!this.adultData[index].dateDob.year ||
                `Please enter the Year first`
              );
            }
          } else if (Type === "Child") {
            if (!this.childData[index].dateDob.year) {
              return (
                !!this.childData[index].dateDob.year ||
                `Please enter the Year first`
              );
            }
          } else if (Type === "Infrant") {
            if (!this.infrantData[index].dateDob.year) {
              return (
                !!this.infrantData[index].dateDob.year ||
                `Please enter the Year first`
              );
            }
          }
        },

        (v) => !!v || `Please enter the Month`,
      ];
    },

    dateRules(data, index) {
      return [
        () => {
          if (data.Type === "Adult") {
            if (
              !this.adultData[index].dateDob.year &&
              !this.adultData[index].dateDob.month
            ) {
              return (
                (!!this.adultData[index].dateDob.year &&
                  !!this.adultData[index].dateDob.month) ||
                `Please enter the Year and Month first`
              );
            }
          } else if (data.Type === "Child") {
            if (
              !this.childData[index].dateDob.year &&
              !this.childData[index].dateDob.month
            ) {
              return (
                (!!this.childData[index].dateDob.year &&
                  !!this.childData[index].dateDob.month) ||
                `Please enter the Year and Month first`
              );
            }
          } else if (data.Type === "Infrant") {
            if (
              !this.infrantData[index].dateDob.year &&
              !this.infrantData[index].dateDob.month
            ) {
              return (
                (!!this.infrantData[index].dateDob.year &&
                  !!this.infrantData[index].dateDob.month) ||
                `Please enter the Year and Month first`
              );
            }
          }
        },

        (v) => /^\d+$/.test(v) || "Please enter the date",
        (v) => (v >= 1 && v <= 31) || "Please enter a valid date",
        (v) => /^\d{2}$/.test(v) || 'format like "05"',

        (v) => {
          const dataDob =
            data.Type === "Adult"
              ? this.adultData[index].dateDob
              : data.Type === "Child"
              ? this.childData[index].dateDob
              : this.infrantData[index].dateDob;

          const year = dataDob.year;
          const month = dataDob.month;

          if (year && month) {
            console.log(year, month, "yearyearyearyear");
            const monthIndex = this.monthList.indexOf(month);
            console.log(monthIndex, "monthIndexmonthIndexmonthIndex");
            console.log(this.monthList, "monthListmonthListmonthList");
            const lastDayOfMonth = new Date(year, monthIndex + 1, 0).getDate();
            console.log(lastDayOfMonth, "lastDayOfMonthlastDayOfMonth");
            return (
              (v >= 1 && v <= lastDayOfMonth) || `Invalid date for ${month}`
            );
          }

          return true;
        },

        (v) => {
          let currentDate = moment(
            this.segmentList[this.segmentList.length - 1].Departure.Date
          ).format("YYYY-MMM-DD");
          let maximumDate = new Date(
            new Date(currentDate).getFullYear(),
            new Date(currentDate).getMonth(),
            new Date(currentDate).getDate() - 15
          );
          let formatDate = moment(maximumDate, "YYYY-MMM-DD").format(
            "YYYY-MMM-DD"
          );
          let maxYear = new Date(formatDate).getFullYear();
          let maxMonth = moment(maximumDate, "YYYY-MMM-DD").format("MMM");
          let maxDate = new Date(formatDate).getDate();
          maxDate = maxDate.toString().padStart(2, "0");

          console.log(maxDate, "maxDatemaxDatemaxDate..111.");
          console.log(
            currentDate,
            "currentDate....",
            maximumDate,
            "maximumDatemaximumDatemaximumDate..."
          );

          if (data.Type === "Infrant") {
            let normalDate = v >= 1 && v <= 31;
            let validDate = v >= 1 && v <= maxDate;

            if (
              maxYear == data.dateDob.year &&
              maxMonth == data.dateDob.month
            ) {
              return (
                validDate ||
                `Allow travel before ${maxMonth},${maxDate} for infrants`
              );
            } else {
              return normalDate;
            }
          }
        },
      ];
    },
    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },


    freueChange(data,index,value){
    
     value.forEach((a,i)=>{
      if(i==index){
        a.freuent=!a.freuent
      }
     })
     console.log(data,index,value,'data,index,value')
    }, 

    handleInput(e) {
      console.log(e,'eeeee')
      if (e.key.length === 5 && e.keyCode !== 8) {
        e.preventDefault();
      }
    },
    formatExpireDate(event,type,index) {
    console.log(event, type,this[type][index].ExpireDate, "event type");
      if (
        this[type][index].ExpireDate.length <= 2 &&
        event.inputType === "deleteContentBackward"
      ) {
        this[type][index].ExpireDate = this[type][index].ExpireDate.slice(
          0,
          3
        );
      } else if (this[type][index].ExpireDate.length === 2) {
        this[type][index].ExpireDate += "/";
      }
    },

  //   formatExpireDate(event, type) {
  //   console.log(event, type, "event type");

  //   let value = event.target.value.replace(/\D/g, ""); 

  //   if (value.length > 2 && !value.includes("/")) {
  //     value = value.slice(0, 2) + "/" + value.slice(2, 4);
  //   }
  //   if (type === "adult") {
  //     this.adultData.ExpireDate = value;
  //   } else if (type === "child") {
  //     this.childData.ExpireDate = value;
  //   } else if (type === "infant") {
  //     this.infrantData.ExpireDate = value;
  //   }
  // },
    filterExpdate: function (evt) {
      evt = evt || window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      // Allow only numbers and the '/' character
      if (!/^[0-9/]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    focusNext(e) {
      const inputs = Array.from(
        e.target.form.querySelectorAll(
          "v-text-field, input, select, textarea, button"
        )
      );
      const index = inputs.indexOf(e.target);

      if (index < inputs.length - 1) {
        inputs[index + 1].focus();
        e.preventDefault();
      }
    },


    async getseatMapping() {
      this.dataLoder = true;
      let $data = [];
      let triptype = "";
      let seat = {
        request_type: "seatmap",
        offer_id: "",
        shopping_response_id: "",
        hit_id: "",
        search_cabin_type: "",
        trip_type: "",
        pointofsale: this.pointScale,
        adult: "",
        child: "",
        paxInfant: "",
      };

      if (this.roundresult.length > 0) {
        $data = this.roundresult;
        triptype = "RoundTrip";
        console.log(this.roundresult, "seatmapping");
      } else if (this.onewayresult.length > 0) {
        $data = this.onewayresult;
        triptype = "OneWay";
      } else if (this.multiresult.length > 0) {
        $data = this.multiresult;
        triptype = "MultiCity";
      }

      if ($data) {
        seat.shopping_response_id = $data[0].ShoppingResponseId;
        seat.offer_id = $data[0].selectId;
        seat.hit_id = $data[0].hitId;
        seat.search_cabin_type = $data[0].cabinClass;
        seat.pointofsale = this.pointOfSale;
        seat.adult = $data[0].Adult;
        seat.child = $data[0].Child || 0;
        seat.paxInfant = $data[0].Inf || 0;
        seat.trip_type = triptype;
      }
      console.log($data, seat, this.seatmapApi, "4567");

      let obj = {
        adult: $data[0].Adult,
        chd: $data[0].Child || 0,
        inf: $data[0].Inf || 0,
      };
      await axios
        .post(this.seatmapApi, seat, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((responce) => {
          // console.log(responce,this.seatSelect, "seatmapdata223");
          this.seatSelect = responce.data.data;
          console.log(this.seatSelect, "this.seatSelect");
          this.getseatselect();
          this.seatPassanger(obj);
        })
        .catch((err) => {
          console.log(err, "ppppp52222");
        });

      // console.log(seat, "kkkk3333333333");
    },
    totalpassData(data, index) {
      // console.log(data, index, "data, index");
      this.flightSelect = 0;
      this.passSelect = index;
    },

    selectdata(data, index) {
      console.log(data, index, "000000");
      // this.passSelect = 0;
      this.flightSelect = index;
      this.indexData = index;
      this.totalData = [];
      let falData = false;
      if (this.totalSeat.length > 0) {
        this.totalSeat[index].cabins.forEach((c, k) => {
          c.seat_rows[k].color = falData[k];

          let arrayData = [];
          arrayData = c.columns;
          let data = 0;
          arrayData.forEach((a, l) => {
            if (a.type == "AisleA") {
              data = data + 1;
              if (data == 2) {
                arrayData[l - 1].space = true;
                arrayData[l].space = false;
              }
            } else {
              data = 0;
              arrayData[l].space = false;
            }

            c.seat_rows.forEach((d) => {
              // console.log(d, "rowsrows");
              if (d.seats[d.seats.length - 1].seat_number == a.column) {
                if (!this.totalData[a.column]) {
                  this.totalData[a.column] = [];
                }
                this.totalData[a.column].push(d);
              }
            });

            // this.totalData[a.column].forEach((w) => {
            //   console.log(w, "dddwwwwdddd");
            // });
          });
        });
      }
    },
    seatSelectdata(datas, index1, index) {
      console.log(this.passSelect, this.flightSelect, datas, "this.passSelect");

      if (datas.seats[0].occupied === "false") {
        for (let key in this.totalData) {
          this.totalData[key].forEach((item) => (item.color = false));
        }
        if (this.totalData[index] && this.totalData[index][index1]) {
          this.totalData[index][index1].color = true;
        }

        this.totalpassanger[this.passSelect].seat_number[this.flightSelect] =
          `${this.totalSeat[this.flightSelect].origin}-${this.totalSeat[this.flightSelect].destination
          }:${datas.row_number}${index}` || "";

        if (this.totalSeat.length - 1 != this.flightSelect) {
          this.flightSelect = this.flightSelect + 1;
          this.selectdata(this.totalSeat[this.flightSelect], this.flightSelect);
        }
      }
    },

    seatPassanger(obj) {
      const totalPassengers =
        Number(obj.adult || 0) + Number(obj.chd || 0) + Number(obj.inf || 0);
      console.log(totalPassengers, "totalPassengers");
      // Create an array for passengers based on total passengers
      // let index = 0;
      this.totalpassanger = [];

      // Add adult passengers
      for (let i = 0; i < Number(obj.adult || 0); i++) {
        this.totalpassanger.push({ seat_number: [], type: "Adult", index: i });
        // index++;
      }

      // Add child passengers
      for (let i = 0; i < Number(obj.chd || 0); i++) {
        this.totalpassanger.push({ seat_number: [], type: "Child", index: i });
        // index++;
      }

      // Add infant passengers
      for (let i = 0; i < Number(obj.inf || 0); i++) {
        this.totalpassanger.push({
          seat_number: [],
          type: "Infrant",
          index: i,
        });
        // index++;
      }

      console.log(
        this.totalpassanger,
        this.totalSeat.length,
        "this.totalpassanger"
      );
    },

    getseatselect() {
      let $event = [];
      this.seatSelect.forEach((v, i) => {
        // console.log(v, i, "keerthikannan123");
        this.seatSelect[i].forEach((c) => {
          // console.log(c,c.cabins.length, "vasanthaganesh");
          $event.push(c);
          //           if (c && Array.isArray(c.cabins) && c.cabins.length > 0) {
          //   $event.push(c);
          // }
        });
      });
      this.totalSeat = $event;
      // console.log(this.totalSeat, "this.totalSeat");
      //  let arrayData = []; // You can remove this line as it's not being used

      if (this.totalSeat.length > 0) {
        this.dataLoder = false;
        this.totalSeat[0].cabins.forEach((c) => {
          // console.log(c, "ppppppppppppppppp");
          let arrayData = [];
          arrayData = c.columns;

          let data = 0;
          arrayData.forEach((a, l) => {
            console.log(a, "rowsrows");
            if (a.type == "AisleA") {
              data = data + 1;
              if (data == 2) {
                arrayData[l - 1].space = true;
                arrayData[l].space = false;
              }
            } else {
              data = 0;
              arrayData[l].space = false;
            }
            c.seat_rows.forEach((d) => {
              console.log(d, a, "000000");
              if (d.seats[0].seat_number == a.column) {
                console.log(this.totalData, d, a.column, "lololol");

                if (!this.totalData[a.column]) {
                  this.totalData[a.column] = [];
                }

                this.totalData[a.column].push(d);
              }
            });
            this.totalData[a.column].forEach((w) => {
              w.color = false;
              console.log(w, "dddwwwwdddd");
            });
          });
          console.log(arrayData, "arrayData");
        });
        console.log(this.totalData, "$data$data$data");
      }
    },
    getperpersonPrice(getdata) {
      if (getdata.adultcount) {
        let getAdtperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getAdtperfare, "getadultTotBasegetadultTotBase...2");
        return getAdtperfare.toFixed(2);
      }

      if (getdata.childcount) {
        let getChdperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getChdperfare, "getadultTotBasegetadultTotBase...3");
        return getChdperfare.toFixed(2);
      }

      if (getdata.infcount) {
        let getInfperfare =
          parseFloat(getdata.baseprice) + parseFloat(getdata.taxprice);
        console.log(getInfperfare, "getadultTotBasegetadultTotBase...4");
        return getInfperfare.toFixed(2);
      }
    },

    getSalutation() {
      this.salutationItems = [
        {
          text: "Mr" || this.$t("flightInfoContent.labels.mr"),
          value: "Mr",
        },
        {
          text: "Miss" || this.$t("flightInfoContent.labels.miss"),
          value: "Miss",
        },
        {
          text: "Mrs" || this.$t("flightInfoContent.labels.mrs"),
          value: "Mrs",
        },
        {
          text: "Mstr" || this.$t("flightInfoContent.labels.mstr"),
          value: "Ms",
        },
      ];
    },
    getSalutationChild() {
      this.salutationItemsChild = [
        {
          text: "Mstr" || this.$t("flightInfoContent.labels.mstr"),
          value: "Mstr",
        },
        {
          text: "Miss" || this.$t("flightInfoContent.labels.miss"),
          value: "Miss",
        },
      ];
    },

    getGender() {
      this.genderItems = [
        {
          text: "Male" || this.$t("flightInfoContent.labels.male"),
          value: "Male",
        },
        {
          text: "Female" || this.$t("flightInfoContent.labels.female"),
          value: "Female",
        },
      ];
    },
    getDurationTime(data) {
      // console.log(data, "durationnnnnn");
      return data.FlightDetail.FlightDuration.Value;
    },

    flight1(data) {
      console.log(data, "pppppppppppppppppp");
      data.Flightdepature = true;
    },
    flight2(data) {
      console.log(data, "pppppppppppppppppp");
      data.Flightreturn = true;
    },
    closeDep(data) {
      data.Flightdepature = false;
    },
    closearr(data) {
      data.Flightreturn = false;
    },

    flight() {
      this.overlaySelector = true;
      this.baggageDetails = false;
      this.flightInfo = true;
      this.flightDetail = true;
      this.bagInfo = false;
    },
    overlay() {
      this.overlaySelector = false;
    },
    itinerary() {
      this.dialog = true;
      this.overlaySelector = true;
      this.flightInfo = true;
      this.flightDetail = true;
      this.baggageDetails = false;
      this.bagInfo = true;
    },
    baggage() {
      this.dialog = true;
      this.overlaySelector = true;
      this.flightInfo = true;
      this.flightDetail = false;
      this.baggageDetails = true;
      this.bagInfo = true;
    },
    closedialog() {
      this.dialog = false;
      this.overlaySelector = false;
    },
    closedialog1() {
      this.dialog = false;
      this.overlaySelector = false;
    },
    addBox() {
      if (this.valid && this.specialBox.length < 5) {
        this.specialBox.push({
          // fBox: null,
          pBox: "Adult 1",
          // rBox: null,
          // freeBox: null,
          ...this.specialBox,
        });
      }
      console.log(this.valid, "rest");
    },
    resetValues() {
      this.valid = false;
      this.specialBox = [
        {
          fBox: null,
          pBox: "Adult 1",
          rBox: null,
          freeBox: "",
        },
      ];
    },

    getdepName(data) {
      console.log(data, "getdepcode1");
      return data.split("(")[0];
    },
    // getdepNameCode(){
    //   return data.split("(")[1].split(")")[0]
    // },
    getdepName1(data) {
      console.log(data, "getdepcode1");
      return data.from;
    },

    getarrName(data) {
      return data.split("(")[0];
    },
    getarrName1(data) {
      return data.to;
    },

    getdepcode(data) {
      console.log(data, "getdepcode");
      return data[0].Departure.AirportCode;
    },
    getarrcode(data) {
      return data[data.length - 1].Arrival.AirportCode;
    },
    getFromCityName($event) {
      console.log($event, "oioioi");
      return $event.split("(")[0];
    },
    depdate(data) {
      this.getdepDate = moment(data[0].Departure.Date).format(
        "ddd, MMM DD, YYYY"
      );
      return moment(data[0].Departure.Date).format("ddd, MMM DD, YYYY");
    },
    getStop(data) {
      return data.length - 1;
    },
    getflightname(data) {
      return data[0].MarketingCarrier.Name;
    },

    getlogo($event) {
      return $event[0].MarketingCarrier.logo;
    },
    getlogo1($event) {
      return $event[0].MarketingCarrier.logo;
    },

    getflightnumber(data) {
      return data[0].MarketingCarrier.FlightNumber;
    },

    getflightid(data) {
      return data[0].MarketingCarrier.AirlineID;
    },
    depairname(data) {
      return data[0].Departure.AirportName;
    },
    depairtime(data) {
      return moment(data[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    arrAirname(data) {
      return data[data.length - 1].Arrival.AirportName;
    },
    arrAirtime(data) {
      return moment(data[data.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    // ----return --------------

    getstart(data) {
      return data[0].Departure.AirportCode;
      // console.log(data[0].Departure.AirportCode, 'kkkkkkk...q.e.we.e.e.')
    },
    getend(data) {
      return data[data.length - 1].Arrival.AirportCode;
    },
    getToCityName($event) {
      console.log($event, "oioioi");
      return $event.split("(")[0];
    },
    getstartdate(data) {
      return moment(data[0].Departure.Date).format("ddd, MMM DD, YYYY");
    },
    getdepNameMulti(data) {
      console.log(data, "getdepcode1");
      return data.from;
    },
    getarrNameMulti(data) {
      return data.to;
    },
    getstop1(data) {
      return data.length - 1;
    },

    getstartname(data) {
      return data[0].MarketingCarrier.Name;
    },

    getstartnumber(data) {
      return data[0].MarketingCarrier.FlightNumber;
    },

    getstartid(data) {
      return data[0].MarketingCarrier.AirlineID;
    },
    getdepname(data) {
      return data[0].Departure.AirportName;
    },
    getdeptime(data) {
      return moment(data[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getarrname(data) {
      return data[data.length - 1].Arrival.AirportName;
    },

    getarrtime(data) {
      return moment(data[data.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getdeparDate($event) {
      console.log($event, "$event$event$event$event");
      return moment($event[0].Departure.Date).format("ddd MMM DD");
    },

    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    getdated($event) {
      return moment($event).format("MMM DD");
    },

    getdated1($event) {
      return moment($event).format("MMM DD");
    },
    getflightdep(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarr(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },

    reset() {
      (this.ffb = ""), (this.air = "");
    },
    reset1() {
      (this.Redress = ""), (this.Traveler = "");
    },
    added() {
      let result =
        this.adultData.length + this.childData.length + this.infrantData.length;
      if (this.paxContacts.length < result) {
        this.paxContacts.push({
          number: "",
          email: "",
        });
      }
    },
    delet(index) {
      this.paxContacts.splice(index, 1);
    },
    // add() {
    //     for (let i = 0; i < this.adultData.length; i++) {
    //         if (this.adultData.length < 1) {
    //             this.adultData.push({
    //                 fname: "",
    //                 last: "",
    //                 middle: "",
    //                 year: "",
    //                 date: "",
    //                 month: "",
    //                 gender: "",
    //                 salutation: "",
    //                 ffbnumber: false,
    //             })
    //         }
    //     }
    // },
    ffbdata(data, index) {
      console.log(index, "index");
      console.log(data, "12345");
      this.adultData.map((item, i) => {
        if (i == index) {
          item.ffbnumber = !item.ffbnumber;
        }

        return item;
      });
    },
    tsadata(index) {
      this.adultData.map((item, i) => {
        if (i == index) {
          item.TSAnumber = !item.TSAnumber;
        }

        return item;
      });
    },
    apidata(index) {
      this.apivalue = true;
      this.adultData.map((item, i) => {
        if (i == index) {
          item.Apinumber = !item.Apinumber;
        }

        return item;
      });
    },

    day() {
      for (let i = 1; i <= 31; i++) {
        this.dateData.push(i);
        // console.log(this.date, '9999999')
      }
    },
    year() {
      for (let i = 2023; i <= 2035; i++) {
        this.yearData.push(i);
        // console.log(this.yearData, '9999999')
      }
    },

    adultDob() {
      this.adultData.map((v) => {
        if (v.dateOfBirth) {
          console.log(v.dateOfBirth, "dobdob");

          v.clicked1 = true;
          v.dobAdultError = false;
          console.log(v.dobAdultError, "dobdob");
        } else {
          v.clicked1 = false;
          v.dobAdultError = true;
        }
      });
    },
    childDob() {
      this.childData.map((v) => {
        if (v.dateOfBirth) {
          v.clicked1 = true;
          v.dobChildError = false;
        } else {
          v.clicked1 = false;
          v.dobChildError = true;
        }
      });
    },
    infrantDob() {
      this.infrantData.map((v) => {
        if (v.dateOfBirth) {
          v.clicked1 = true;
          v.dobInfrantError = false;
        } else {
          v.clicked1 = false;
          v.dobInfrantError = true;
        }
      });
    },

    // ClickOutDepat() {
    //     this.$refs.dpRef1.closeMenu();

    //     if (this.oneway.dedate) {
    //         this.clicked1 = true;
    //     }
    //     else {
    //         this.clicked1 = false;
    //     }
    // },

    getData() {

      console.log(this.adultData, "this.adultthis.adult");
      for (let i = 0; i <= this.adultData.length; i++) {
        if (this.adultData.length < this.adult) {
          this.adultData.push({
            freuent:false,
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mr",
            organisationName:"",
            countryCode:"",
            passPortNumber:"",
            ExpireDate:"",
            flyerName:"",
            flyerId:"",
            dateDob: {
              year: null,
              month: null,
              date: null,
            },
            dateOfBirth: null,
            ffbnumber: true,
            TSAnumber: false,
            Apinumber: false,
            Type: "Adult",
            clicked1: false,
            dobAdultError: false,
          });
        } else {
          return this.adultData;
        }
      }
      console.log(this.adultData, "ssss");
    },

    getChild() {
      for (let i = 0; i <= this.childData.length; i++) {
        if (this.childData.length < this.child) {
          this.childData.push({
            fname: "",
            last: "",
            middle: "",
            organisationName:"",
            gender: "Male",
            freuent:false,
            salutation: "Mstr",
            dateDob: {
              year: null,
              month: null,
              date: null,
            },
            dateOfBirth: null,
            countryCode:"",
            passPortNumber:"",
            ExpireDate:"",
            flyerName:"",
            flyerId:"",
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Child",
            clicked1: false,
            dobChildError: false,
          });
        } else {
          return this.childData;
        }
      }
    },

    getinfrant() {
      for (let i = 0; i <= this.infrantData.length; i++) {
        if (this.infrantData.length < this.inf) {
          this.infrantData.push({
            freuent:false,
            fname: "",
            last: "",
            middle: "",
            organisationName:"",
            countryCode:"",
            passPortNumber:"",
            ExpireDate:"",
            flyerName:"",
            flyerId:"",
            gender: "Male",
            salutation: "Mstr",
            dateDob: {
              year: null,
              month: null,
              date: null,
            },
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Infrant",
            clicked1: false,
            dobInfrantError: false,
          });
        } else {
          return this.infrantData;
        }
      }
    },

    getadult() {
      this.roundresult.forEach((v) => {
        console.log(v, "tttooooo....");
        this.currency = v.currency;
        this.totalTaxPassanger = parseFloat( v.Taxprice).toFixed(2);
        this.totalbasePassanger =  parseFloat(v.Baseprice).toFixed(2);
        this.totalAmountpassenger = v.TotalPrice;
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infLength = v.Inf;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    onewayData() {
      this.onewayresult.forEach((v) => {
        console.log(v, "tttooooo....");
        this.currency = v.currency;
        this.totalTaxPassanger = parseFloat( v.Taxprice).toFixed(2);
        this.totalbasePassanger =  parseFloat(v.Baseprice).toFixed(2);
        this.totalAmountpassenger = v.TotalPrice;
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infLength = v.Inf;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    multiData() {
      let res = this.local_id.split("-");
      let res1 = res[res.length - 1];
      let data_pass = [];
      data_pass = JSON.parse(sessionStorage.getItem(`${"multi"}-${res1}`));
console.log(data_pass,res,res1,'data_pass')
      this.adultLength = data_pass.Adult;
      this.childLength = data_pass.Child;
      this.infLength = data_pass.Inf;
      this.adult = data_pass.Adult;
      this.child = data_pass.Child;
      this.inf = data_pass.Inf;
      console.log(data_pass, "data_pass");

      let totalpass = [];
      totalpass = this.adultLength + this.childLength + this.infLength;
      console.log(totalpass, "totalpass");

      this.multiresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = parseFloat( v.Taxprice).toFixed(2);
        this.totalbasePassanger =  parseFloat(v.Baseprice).toFixed(2);
        this.totalAmountpassenger = v.TotalPrice;
      });
    },

    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true;
        this.topSticky = "5%";
      } else {
        this.scrolled = false;
        this.topSticky = "17%";
      }

      this.lastPosition = window.scrollY;
    },

    getConfig() {
      const getConfigData1 = getConfigDataFromLocalStorage();
      if (getConfigData1) {

  console.log(getConfigData1,'getConfigData1')

        this.portalId = getConfigData1.payload.portal_configuration.portal_id;
        this.pointScale=getConfigData1.payload.portal_configuration.pointofsale
        console.log(this.pointScale,'this.pointScale')
        this.metaCurrency =
          getConfigData1.payload.portal_configuration.currency.iso_code;
        this.preferCurrency =
          getConfigData1.payload.portal_configuration.currency.display;
          this.autocompleteApi =
          getConfigData1.payload.portal_configuration.API_endpoints.autocomplete;
        this.bookingviewApi =
          getConfigData1.payload.portal_configuration.API_endpoints.meta_get_itinerary;
          this.pricingApi =
          getConfigData1.payload.portal_configuration.API_endpoints
            .pricingconfirmation ||
          getConfigData1.payload.portal_configuration.API_endpoints.pricing;
        this.bookingApi =
          getConfigData1.payload.portal_configuration.API_endpoints.bookingview;
        this.fareRulesApi =
          getConfigData1.payload.portal_configuration.API_endpoints.farerules;
        this.airLogoApi =
          getConfigData1.payload.portal_configuration.content_data.airlines_logo;
        this.portal_domain =
          getConfigData1.payload.portal_configuration.portal_url.replace(
            "https://",
            ""
          );
        this.seatmapApi =
          getConfigData1.payload.portal_configuration.API_endpoints.seatmap;
        this.pointOfSale =
          getConfigData1.payload.portal_configuration.pointofsale;
      }
    },
    // fareinsert() {
    //   this.insertFare.hit_id = this.booking_Id

    // },
    closeBaggageRules() {
      this.tab = "tab-1";
      // document.documentElement.style.overflow = 'scroll';
      document.body.style.overflow = "auto";
      document.body.style.position = "static";
    },
    getFareRules() {
      this.fareLoader = true;
      // document.documentElement.style.overflow = 'hidden';
      // document.body.scroll = "no";

      window.scrollTo(0, 0);

      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
      document.body.style.width = "100%";

      let fareRequest = {
        request_type: "farerules",
        shopping_response_id: this.shoppingid,
        offer_id: this.selectId,
        portal_id: this.portalId,
        portal_domain: this.portal_domain,
      };
      console.log(fareRequest, this.fareRulesApi, "fareRequestssssss");
      axios.get(this.fareRulesApi, {
        headers: {
          "Content-Type": "text/html",
        },
        params: fareRequest,
      })
        .then((response) => {
          this.fareRulesContent = response.data;
          // console.log(response.data, "fareRulesContentfareRulesContent.....")
          this.fareLoader = false;
        })

        .catch((error) => {
          console.log(error, "erroroor.....");
          this.fareLoader = false;
        });
    },
    totalSeatData() {
      this.totalpassanger.forEach((c, i) => {
        console.log(c, i, this.adultData, "datasss");
        if (c.type == "Adult") {
          this.adultData[i].seat_requested = c.seat_number;
          // c.name=
        }
        if (c.type == "Child") {
          this.childData[i].seat_requested = c.seat_number;
        }
        if (c.type == "Infrant") {
          this.infrantData[i].seat_requested = c.seat_number;
        }
      });
    },
    validatePassenger(data){
      const requiredFields = {
        Adult: ["fname", "last", "gender", "salutation", "dateOfBirth"],
        Child: ["fname", "last", "gender", "salutation", "dateOfBirth"],
        Infrant: ["fname", "last", "gender", "salutation", "dateOfBirth"]
    };

    function hasValidFields(obj, type) {
        return requiredFields[type].every(field => {
            return (field in obj) && obj[field] !== null && obj[field] !== "";
        });
    }
    const allAdultsValid = data.adultDatas.every(obj => hasValidFields(obj, "Adult"));
    const allChildrenValid = data.childDatas.every(obj => hasValidFields(obj, "Child"));
    const allInfantsValid = data.infrantDatas.every(obj => hasValidFields(obj, "Infrant"));

    return allAdultsValid && allChildrenValid && allInfantsValid;
  },
    submitform() {
      // this.fareinsert();
      this.totalSeatData();
      this.formSubmitted = true;
      let contact = {
        contactCheck: this.contactCheck,
        couponsCheck: this.couponsCheck,
      };
      localStorage.setItem("Travelcontact", JSON.stringify(contact));
      this.passengersDatas.adultDatas = this.adultData;
      this.passengersDatas.childDatas = this.childData;
      this.passengersDatas.infrantDatas = this.infrantData;
      
      // let validate = this.validatePassenger(this.passengersDatas)
      console.log(this.valid1, "ggghghghghg.....");
      if (this.valid1 && this.paxContacts[0].number && this.formCheckBox ) {
        this.datenumber = true;


        let uniDataId = `${"env"}-${this.local_id}`;
          let data = location.href;
      if (data && data.includes("env")) {
         if (data.includes("env")) {
          this.portalResData[0].pricingid = this.priceapiData

        localStorage.setItem(
            `${"env"}-${this.local_id}`,
            JSON.stringify(this.portalResData)
          );
          this.$router.push({
              path: "/payment",
              query: { pass_uid: uniDataId },
            });

            }}

       else if (this.portal) {
       
       if (this.local_id) {
            // localStorage.setItem(`${"env"}-${this.local_id}`, JSON.stringify(this.portalResData));

            this.$router.push({
              path: "/payment",
              query: { pass_id: this.local_id },
            });
          } 
          else if (this.Localoneway_id) {
            // localStorage.setItem(`${"env"}-${this.Localoneway_id}`, JSON.stringify(this.portalResData));
            this.$router.push({
              path: "/payment",
              query: { pass_uid1: this.Localoneway_id },
            });
          }
           else if (this.multiresult.length > 0) {
            localStorage.setItem(
              `${"env"}-${this.local_id}`,
              JSON.stringify(this.portalResData)
            );
            localStorage.setItem(
              "env-local_id",
              JSON.stringify(this.portalResData)
            );
            this.$router.push({
              path: "/payment",
              query: { pass_uid: uniDataId },
            });
          }
        } 
        else {
          if (this.local_id) {
            this.$router.push({
              path: "/payment",
              query: { pass_id: this.local_id },
            });
          } else if (this.Localoneway_id) {
            this.$router.push({
              path: "/payment",
              query: { pass_uid1: this.Localoneway_id },
            });
          } else {
            console("Not....working....");
          }
          console.log("Not....working....");
        }



      } 
      else {
        this.dobAdultError = true;
        this.dobChildError = true;
        this.dobInfrantError = true;
        this.datenumber = true;

        if (!this.formCheckBox) {
          this.checkForm = true;
        }

        return this.valid1;
      }

      sessionStorage.setItem(
        "TravelerDetails",
        JSON.stringify(this.passengersDatas)
      );
      localStorage.setItem(
        "passengerContact",
        JSON.stringify(this.paxContacts)
      );
    },
    getPriceDetails(e, type, quantityKey) {
      // console.log(e, "univer");
      if (e) {
        let basePrice =
          e.FareDetail[e.FareDetail.length - 1].Price.BaseAmount
            .BookingCurrencyPrice;
        let taxPrice =
          e.FareDetail[e.FareDetail.length - 1].Price.TaxAmount
            .BookingCurrencyPrice;
        let count = e.PassengerQuantity;

        return {
          baseprice: parseFloat(basePrice).toFixed(2),
          taxprice: parseFloat(taxPrice).toFixed(2),
          [quantityKey]: count,
          pax_type: type,
        };
      }
    },
    async portalDataConvert() {
      let userBooking = {
        offer_id: this.portal_offId,
        shopping_response_id: this.portal_shopId,
        pos: this.pointOfSale,
        api_env: "CERT",
        request_type: "user_booking_view",
      };

      console.log(this.bookingApi, userBooking, "userBookinguserBooking");
      try {
        const responce = await axios.post(this.bookingApi, userBooking, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(responce, "responcedataresponcedata");
        if (responce.data.view_response == "fail") {
          setTimeout(() => {
            this.dialog1 = true;
          }, 3000);
          console.log("dialog1111");
        } else {
          let result = responce.data;
          this.portalData.push(result);
          console.log(this.portalData, "this.portalDatathis.portalData");
          this.checkOutPage = true;
          // this.dataloader = false;
          // this.pageloader = false;
          this.showConfirmPage = true;
          console.log("dialog11114444444444444");
        }
      } catch (err) {
        console.log(err, "ppppp5555555");
        setTimeout(() => {
          this.dialog3 = true;
        }, 3000);
      }

      // catch((err) => {
      //   console.log(err, "ppppp5555555");
      //   setTimeout(() => {
      //     this.dialog3 = true;
      //   }, 3000);
      // });

      if (this.portalData.length > 0 && (!this.portalData[0] == "" || null)) {
   await this.airlineLogo_func();

        this.dialog3 = false;
        console.log("sdfadfzxczxsdvasdfasdf");
      
        for (let i = 0; i < this.portalData.length; i++) {
          console.log(this.portalData[i], "power");

          let Journey = [];
          let flightsegment = [];
          let baggageList = [];
          baggageList =
            this.portalData[i].DataLists.BaggageAllowanceList.BaggageAllowance;
          console.log(baggageList, "baggageListbaggageList");
          Journey = this.portalData[i].DataLists.FlightList.Flight;
          console.log(Journey, "Journey");
          flightsegment =
            this.portalData[i].DataLists.FlightSegmentList.FlightSegment;
          let shoppingId = this.portalData[i].ShoppingResponseId;
          let $data = [];
          $data.push(this.portalData[i].OffersGroup.AirlineOffers.Offer);
          let getSegment =
            this.portalData[i].OffersGroup.AirlineOffers.Offer.OfferItem[0][0]
              .FareComponent;
          console.log(getSegment, "getSesgmentgetSesgment...1");
          let restripType = this.portalData[i].TripType;
          console.log(
            restripType,
            "getSesgmentgetSesgment...2",
            typeof restripType
          );
          let cabinClass = this.portalData[i].OffersGroup.AirlineOffers.Offer.CabinType;

          let source1 = [];
          let source2 = [];
          let source3 = [];
          let source4 = [];
          let Baggage1 = [];
          let Baggage2 = [];
          let Baggage3 = [];
          let Baggage4 = [];
          let adult = 0;
          let child = 0;
          let inf = 0;
          $data.forEach((v) => {
            v.OfferItem[0].forEach((t) => {
              if (t.PassengerType == "ADT" || t.PassengerType == "JCB") {
                adult = t.PassengerQuantity;
              }
              if (t.PassengerType == "CNN" || t.PassengerType == "JNN") {
                child = t.PassengerQuantity;
              }
              if (t.PassengerType == "INF" || t.PassengerType == "JNF" ) {
                inf = t.PassengerQuantity;
              }
             });

            v.OfferItem[0][0].FareComponent.forEach((s, index) => {
              console.log(s, "sasdasdasdsdasa", index);
              if (index == 0) {
                if (v.OfferItem[0][0].BaggageAllowance.length > 0) {
                  Baggage1.push(
                    v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                  );
                }
                this.offerData1.push(s.SegmentRefs.split(" "));

                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source1.push(Journey[i].Journey);
                  }
                }
              }
              if (index == 1) {
                if (v.OfferItem[0][0].BaggageAllowance.length > 0) {
                  Baggage2.push(
                    v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                  );
                }
                this.offerData2.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source2.push(Journey[i].Journey);
                  }
                }
              }

              if (index == 2) {
                if (v.OfferItem[0][0].BaggageAllowance.length > 0) {
                  Baggage3.push(
                    v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                  );
                }
                this.offerData3.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source3.push(Journey[i].Journey);
                  }
                }
              }
              if (index == 3) {
                if (v.OfferItem[0][0].BaggageAllowance.length > 0) {
                  Baggage4.push(
                    v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
                  );
                }
                this.offerData4.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source4.push(Journey[i].Journey);
                  }
                }
              }
            });
          });

          let BaggageData1 = [];

          if (this.offerData1.length > 0) {
            Baggage1.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData1.push(c);
                }
              });
            });
            for (let i = 0; i < this.offerData1.length; i++) {
              let seg = [];
              this.offerData1[i].forEach((f, index) => {
                let items = [];
                items = flightsegment.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == this.offerData1[i].length - 1) {
                  this.portalResData.push({ Depature: seg });
                  this.Flight1.push(seg);
                  // getSegment.length > 2 && this.portalData[i].TripType === "MULTICITY" ? this.Flight1.push(seg) : this.portalResData.push({ Depature: seg });
                  // console.log(this.Flight1, "thisflightsegment...1");
                  // console.log(
                  //   this.portalResData,
                  //   "portalResDataportalResData...1"
                  // );
                }
              });
            }
          }

          let BaggageData2 = [];
          if (this.offerData2.length > 0) {
            Baggage2.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData2.push(c);
                }
              });
            });
            for (let i = 0; i < this.offerData2.length; i++) {
              let seg = [];
              this.offerData2[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData2[i].length - 1) {
                  this.Flight2.push(seg);
                  console.log(
                    this.Flight2,
                    "thisflightsegment...2",
                    restripType
                  );
                }
              });
            }
          }

          let BaggageData3 = [];
          if (this.offerData3.length > 0) {
            Baggage3.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData3.push(c);
                }
              });
            });
            for (let i = 0; i < this.offerData3.length; i++) {
              let seg = [];
              this.offerData3[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData3[i].length - 1) {
                  this.Flight3.push(seg);
                }
              });
            }
          }

          let BaggageData4 = [];
          if (this.offerData4.length > 0) {
            Baggage4.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData4.push(c);
                }
              });
            });
            for (let i = 0; i < this.offerData4.length; i++) {
              let seg = [];
              this.offerData4[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData4[i].length - 1) {
                  this.Flight4.push(seg);
                }
              });
            }
          }

          console.log(flightsegment, "flightsegmentsdasdas...1");
          console.log(this.airlogodata, "flightsegmentsdasdas...2");
          for (let i = 0; i < this.airlogodata.length; i++) {
            for (let j = 0; j < flightsegment.length; j++) {
              if (
                flightsegment[j].MarketingCarrier.AirlineID.trim() ==
                this.airlogodata[i].id.trim()
              ) {
                console.log(
                  flightsegment[j].MarketingCarrier.AirlineID,
                  "flightsegmentsdasdas...3",
                  this.airlogodata[i].id
                );
                flightsegment[j].MarketingCarrier.logo =
                  this.airlogodata[i].logo;
              }
            }
          }
          console.log(flightsegment, "flightsegmentsdasdas...4");

          let currency = [];
          let Baseprice = [];
          let TotalPrice = [];
          let Tax = [];
          let perperson = [];
          let ownername = [];
          let ownerlogo = [];
          let offerId = [];
          let refund = [];

          let combinedPricedetail = [];

          console.log($data, "dataofferdataoffer");

          $data.forEach((v) => {
            // console.log(v, i, "offeriddatataa");
            offerId.push(v.OfferID);
          });

          $data.forEach((s) => {
            currency=s.PosCurrency
  
            ownername.push(s.OwnerName);
            console.log(s, "airlineDataassairlineDataass");
            console.log(this.airlogodata, "airlogodataairlogodata");

            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              if (s.Owner == this.airlogodata[i].id) {
                ownerlogo.push(this.airlogodata[i].logo);
              }
            }
            Baseprice.push(s.BasePrice.BookingCurrencyPrice);
            Tax.push(s.TaxPrice.BookingCurrencyPrice);
            perperson.push(s.PerPerson.BookingCurrencyPrice);
            TotalPrice.push(s.TotalPrice.BookingCurrencyPrice);

            let adultPriceDetails = [];
            let childPriceDetails = [];
            let infPriceDetails = [];
            s.OfferItem[0].forEach((e) => {
              if (e.PassengerType === "ADT") {
                adultPriceDetails = this.getPriceDetails(
                  e,
                  "Adult",
                  "adultcount"
                );
              }

              if (e.PassengerType === "CNN") {
                childPriceDetails = this.getPriceDetails(
                  e,
                  "Child",
                  "childcount"
                );
              }

              if (eval.PassengerType === "INF") {
                infPriceDetails = this.getPriceDetails(
                  e,
                  "Inf(lap)",
                  "infcount"
                );
              }
            });

            combinedPricedetail.push([
              adultPriceDetails,
              childPriceDetails,
              infPriceDetails,
            ]);
            s.OfferItem.forEach((v) => {
              console.log(
                v[0].FareDetail[0].Price.NonRefundable,
                "Price.RefundablePrice.Refundable"
              );

              if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
                refund.push("Refundable");
              } else {
                refund.push("Non Refundable");
              }
            });
          });

          if(cabinClass == "Y"){
            cabinClass = "Economy"
          }
          else if(cabinClass == "C"){
            cabinClass = "Business"
          }
          else if(cabinClass == "F"){
            cabinClass = "First Class"
          }

          this.muticityData = [];

          if (restripType == "MULTICITY") {
            console.log(restripType, "restripTypeeee");
            if (this.Flight1 && this.Flight1.length > 0) {
              this.muticityData.push({
                from: this.Flight1[0][0].Departure.AirportCode,
                to: this.Flight1[0][this.Flight1[0].length - 1].Arrival
                  .AirportCode,
              });
            }

            if (this.Flight2 && this.Flight2.length > 0) {
              this.muticityData.push({
                from: this.Flight2[0][0].Departure.AirportCode,
                to: this.Flight2[0][this.Flight2[0].length - 1].Arrival
                  .AirportCode,
              });
            }

            if (this.Flight3 && this.Flight3.length > 0) {
              this.muticityData.push({
                from: this.Flight3[0][0].Departure.AirportCode,
                to: this.Flight3[0][this.Flight3[0].length - 1].Arrival
                  .AirportCode,
              });
            }

            if (this.Flight4 && this.Flight4.length > 0) {
              this.muticityData.push({
                from: this.Flight4[0][0].Departure.AirportCode,
                to: this.Flight4[0][this.Flight4[0].length - 1].Arrival
                  .AirportCode,
              });
            }
          }

          console.log(this.muticityData, "muticityData...1");

          for (let e = 0; e < this.muticityData.length; e++) {
            console.log(e, "frsomfsromsdasdaasfrsom...1");
            const [fromcity, tocity] = await Promise.all([
              this.fetchCity(this.muticityData[e].from),
              this.fetchCity(this.muticityData[e].to),
            ]);
            console.log(fromcity, "frsomfsromsdasdaasfrsom...2", tocity);
            console.log(
              fromcity[0] ? fromcity[0].city : null,
              "frsomfsromsdasdaasfrsom...2",
              tocity[0] ? tocity[0].city : null
            );
            let objcityname = {
              from: fromcity[0].city,
              to: tocity[0].city,
            };
            this.muticityData[e] = objcityname;
          }

          console.log(this.muticityData, "muticityData...2");

          console.log(this.portalResData, "portalResDataportalResData...1");

          let getfromcode;
          let gettocode;
          let fromcitycode;
          let tocitydode;

          if (this.portalResData.length > 0) {
            getfromcode =
              this.portalResData[0].Depature[0].Departure.AirportCode;
            gettocode =
              this.portalResData[0].Depature[
                this.portalResData[0].Depature.length - 1
              ].Arrival.AirportCode;

            if (getfromcode && gettocode) {
              var [fromCityList, toCityList] = await Promise.all([
                this.fetchCity(getfromcode),
                this.fetchCity(gettocode),
              ]);

              fromcitycode = `${fromCityList[0].city} (${getfromcode})`;
              tocitydode = `${toCityList[0].city} (${gettocode})`;
            }
          }


          // if (this.Flight3.length > 0) {
          //   console.log(
          //     this.Flight2[0][0].Departure.AirportCode,
          //     "this.Flight2[0].Departure.AirportCode"
          //   );
          //   this.muticityData = [
          //     {
          //       from: "",
          //       to: "",
          //     },
          //     {
          //       from: this.Flight2[0][0].Departure.AirportCode,
          //       to: this.Flight2[0][this.Flight2[0].length - 1].Arrival
          //         .AirportCode,
          //     },
          //     {
          //       from: this.Flight3[0][0].Departure.AirportCode,
          //       to: this.Flight3[0][this.Flight3[0].length - 1].Arrival
          //         .AirportCode,
          //     },
          //     {
          //       from: this.Flight4[0][0].Departure.AirportCode,
          //       to: this.Flight4[0][this.Flight4[0].length - 1].Arrival
          //         .AirportCode,
          //     },
          //   ];
          // }


          console.log(this.portalResData, "portalResDataportalResData...2");
          console.log(this.$route.query,this.$route.query.rsource,this.$route.query.rsource_type,'this.$route.query')
       
          for (let i = 0; i < this.portalResData.length; i++) {
            this.portalResData[i].selectId = offerId[i];
            this.portalResData[i].sourceName = this.$route.query.rsource;
            this.portalResData[i].source = this.$route.query.rsource_type;
            this.portalResData[i].currency = currency;
            this.portalResData[i].Baseprice = Baseprice[i];
            this.portalResData[i].TotalPrice = TotalPrice[i];
            this.portalResData[i].Taxprice = Tax[i];
            this.portalResData[i].perperson = perperson[i];
            this.portalResData[i].pricedetails = combinedPricedetail[i];
            this.portalResData[i].Refund = refund[i];
            this.portalResData[i].cabinClass = cabinClass;

            if (
              this.Flight2.length > 0 &&
              restripType == "MULTICITY" &&
              !this.Flight3.length > 0
            ) {
              this.portalResData[i].Flight1 = this.portalResData[0].Depature;
              this.portalResData[i].Flight2 = this.Flight2[i];
              this.portalResData[i].BaggageData1 = BaggageData1[i];
              this.portalResData[i].BaggageData2 = BaggageData2[i];
              this.portalResData[i].Flight1JourneyTime = source1[i];
              this.portalResData[i].Flight2JourneyTime = source2[i];
              this.portalResData[i].muticityData = this.muticityData;
            } else {
              if (!this.Flight2.length > 0) {
                this.portalResData[i].Depature = this.portalResData[0].Depature;
                this.portalResData[i].depBaggage = BaggageData1[i];
                this.portalResData[i].Depaturejourney = source1[i];
                // this.portalResData[i].BaggageData = BaggageData1[i];
              } else {
                this.portalResData[i].Depature = this.portalResData[0].Depature;
                this.portalResData[i].depBaggage = BaggageData1[i];
                this.portalResData[i].Depaturejourney = source1[i];
                // this.portalResData[i].BaggageData = BaggageData1[i];
                this.portalResData[i].Return = this.Flight2[i];
                this.portalResData[i].arrBaggage = BaggageData2[i];
                this.portalResData[i].Returnjourney = source2[i];
              }
            }

            if (this.Flight3.length > 0) {
              this.portalResData[i].Flight1 = this.portalResData[0].Depature;
              this.portalResData[i].Flight2 = this.Flight2[i];
              this.portalResData[i].Flight3 = this.Flight3[i];
              // this.muticityData[0].from = this.portalResData[0].Depature[0].Departure.AirportCode;
              // this.muticityData[0].to = this.portalResData[0].Depature[this.portalResData[0].Depature.length - 1].Arrival.AirportCode;
              this.portalResData[i].BaggageData1 = BaggageData1[i];
              this.portalResData[i].BaggageData2 = BaggageData2[i];
              this.portalResData[i].BaggageData3 = BaggageData3[i];
              this.portalResData[i].Flight1JourneyTime = source1[i];
              this.portalResData[i].Flight2JourneyTime = source2[i];
              this.portalResData[i].Flight3JourneyTime = source3[i];

              this.portalResData[i].muticityData = this.muticityData;
            }

            if (this.Flight4.length > 0) {
              this.portalResData[i].BaggageData4 = BaggageData4[i];
              this.portalResData[i].Flight4 = this.Flight4[i];

              this.portalResData[i].Flight4JourneyTime = source4[i];
              // this.muticityData[3].from = this.Flight4[0][0].Departure.AirportCode;
              // this.muticityData[3].to = this.Flight4[0][this.Flight4[0].length - 1].Arrival.AirportCode;
              this.portalResData[i].muticityData = this.muticityData;
            }

            this.portalResData[i].ownerlogo = ownerlogo[i];
            this.portalResData[i].ownername = ownername[i];
            this.portalResData[i].adult = adult;
            this.portalResData[i].child = child;
            this.portalResData[i].inf = inf;
            this.portalResData[i].ShoppingResponseId = shoppingId;
            this.portalResData[i].fromTrip = fromcitycode;
            this.portalResData[i].toTrip = tocitydode;
          }
          console.log(this.portalResData, "thisportalResDatathisportalResData");

          if (
            ((this.offerData2.length > 0 && !this.offerData3.length > 0) ||
              this.offerData3.length > 0 ||
              this.offerData4.length > 0) &&
            restripType === "MULTICITY"
          ) {
            console.log(this.portalResData, "this.portalResadassDaata...1");
            this.multiresult = this.portalResData;
            this.getmultitime1();
            this.dataloader = false;

            // this.itineraryInfo = true;
            // this.gettimeret();
            // this.gettimedep();
            // console.log(this.roundresult, "this.roundresult88");
          } else if (
            (this.offerData1.length > 0 && !this.offerData2.length > 0) ||
            this.offerData2.length > 0
          ) {
            this.roundresult = this.portalResData;
            this.itineraryInfo = true;
            if (this.offerData2.length > 0) {
              this.gettimeret();
            }
            this.gettimedep();
            this.dataloader = false;
            console.log(this.roundresult, "this.roundresult88");
          }
          // else if (this.offerData1.length > 0) {
          //   this.onewayresult = this.portalResData;
          //   console.log(this.onewayresult, "this.onewayresult5555");
          //   this.itineraryInfo1 = true;
          //   this.gettimedep1();
          //   this.dataloader = false;
          // }

          this.portalResData.forEach((v) => {
            console.log(v, "ppppp");
            this.local_id = v.selectId;
            this.currency =v.currency;
            this.adult = parseFloat(v.adult);
            console.log(this.adult, "pppp");
            this.adultLength = parseFloat(v.adult);
            this.childLength = parseFloat(v.child);
            this.child = parseFloat(v.child);
            this.inf = parseFloat(v.inf);
            this.infLength = parseFloat(v.inf);
            this.totalTaxPassanger = parseFloat(v.Taxprice).toFixed(2)
            this.totalbasePassanger = parseFloat(v.Baseprice).toFixed(2);
            // this.totalbasePassanger = v.Baseprice;
            this.totalAmountpassenger = v.TotalPrice;
          });
          this.getData();
          this.getChild();
          this.getinfrant();
          this.priceConfirm(this.portalResData)
          console.log(
            this.portalResData,
            "this.portalResDatathis.portalResData"
          );

          this.pageloader = false;
          // this.getseatMapping();
          this.getsegment();
        }
      } else {
        this.dialog3 = true;
        console.log("flightdata");
      }
    },

    priceConfirm(result) {
      console.log(result, this.priceChangeValue, "Price confirmation");
   
        let $pracingReq = {
          service_type: "PricingBeforeBooking",
          api_ver: "1",
          portal_id: this.portalId,
          payload: {
            shopping_response_id: result[0].ShoppingResponseId,
            offer_id: result[0].selectId,
          },
        };
        console.log($pracingReq, this.pricingApi, "0p0p0p0p0p");
        axios
          .post(this.pricingApi, $pracingReq, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            let $priceres = response.data;
            // this.priceRes = response.data;
            this.priceapiData = $priceres.payload.pricingid;
            console.log(this.priceapiData,'this.priceapiData')

            console.log(
              $priceres.payload.itin_total_fare,
              $priceres,
              "responsefareRulesApi"
            );
           
          })
          .catch((error) => {
            console.log(error, "erroroor.....");
          });
      
    },


    gettimedep1() {
      for (let i = 0; i < this.onewayresult.length; i++) {
        for (let j = 0; j < this.onewayresult[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.onewayresult[i].Depature.length;

          const time1 = new Date(
            `${this.onewayresult[i].Depature[currentIndex].Arrival.Date}T${this.onewayresult[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.onewayresult[i].Depature[nextIndex].Departure.Date}T${this.onewayresult[i].Depature[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.onewayresult[i].Depature[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }
      }
    },

    gettimeret() {
      for (let i = 0; i < this.roundresult.length; i++) {
        // console.log(this.roundresult, "result222222222222222222222222");
        for (let j = 0; j < this.roundresult[i].Return.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.roundresult[i].Return.length;

          const time1 = new Date(
            `${this.roundresult[i].Return[currentIndex].Arrival.Date}T${this.roundresult[i].Return[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.roundresult[i].Return[nextIndex].Departure.Date}T${this.roundresult[i].Return[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.roundresult[i].Return[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }
      }
    },
    async fetchCity(event) {
      if (event.length >= 3) {
        try {
          const response = await axios.get(
            `${this.autocompleteApi}?airportString=${event}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          );

          let resData = response.data;

          console.log(event, "respresponseonse...1");
          return resData;
        } catch (error) {
          console.log(error, "erroroor.....");
        }
      } else {
        return [];
      }
    },
    gettimedep() {
      for (let i = 0; i < this.roundresult.length; i++) {
        // console.log(this.roundresult, "result222222222222222222222222");
        for (let j = 0; j < this.roundresult[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.roundresult[i].Depature.length;

          const time1 = new Date(
            `${this.roundresult[i].Depature[currentIndex].Arrival.Date}T${this.roundresult[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.roundresult[i].Depature[nextIndex].Departure.Date}T${this.roundresult[i].Depature[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.roundresult[i].Depature[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }
      }
    },
    getmultitime1() {
      for (let i = 0; i < this.multiresult.length; i++) {
        for (let j = 0; j < this.multiresult[i].Flight1.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.multiresult[i].Flight1.length;

          const time1 = new Date(
            `${this.multiresult[i].Flight1[currentIndex].Arrival.Date}T${this.multiresult[i].Flight1[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.multiresult[i].Flight1[nextIndex].Departure.Date}T${this.multiresult[i].Flight1[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.multiresult[i].Flight1[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }

        for (let j = 0; j < this.multiresult[i].Flight2.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.multiresult[i].Flight2.length;

          const time1 = new Date(
            `${this.multiresult[i].Flight2[currentIndex].Arrival.Date}T${this.multiresult[i].Flight2[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.multiresult[i].Flight2[nextIndex].Departure.Date}T${this.multiresult[i].Flight2[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.multiresult[i].Flight2[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }

        if (this.multiresult[i].Flight3) {
          for (let j = 0; j < this.multiresult[i].Flight3.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multiresult[i].Flight3.length;

            const time1 = new Date(
              `${this.multiresult[i].Flight3[currentIndex].Arrival.Date}T${this.multiresult[i].Flight3[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multiresult[i].Flight3[nextIndex].Departure.Date}T${this.multiresult[i].Flight3[nextIndex].Departure.Time}`
            );

            const diffInMilliseconds = Math.abs(time2 - time1);
            const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

            const hours = Math.floor(diffInMinutes / 60);
            const minutes = diffInMinutes % 60;

            this.multiresult[i].Flight3[currentIndex].layoverTimes = `${hours
              .toString()
              .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
          }
        }

        if (this.multiresult[i].Flight4) {
          for (let j = 0; j < this.multiresult[i].Flight4.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multiresult[i].Flight4.length;

            const time1 = new Date(
              `${this.multiresult[i].Flight4[currentIndex].Arrival.Date}T${this.multiresult[i].Flight4[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multiresult[i].Flight4[nextIndex].Departure.Date}T${this.multiresult[i].Flight4[nextIndex].Departure.Time}`
            );

            const diffInMilliseconds = Math.abs(time2 - time1);
            const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

            const hours = Math.floor(diffInMinutes / 60);
            const minutes = diffInMinutes % 60;

            this.multiresult[i].Flight4[currentIndex].layoverTimes = `${hours
              .toString()
              .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
          }
        }
      }
    },
    translatePaxType(data) {
      console.log(data, "data56");
      if (data == "Adult") {
        return this.$t("formsContents.options.adult");
      } else if (data == "Child") {
        return this.$t("formsContents.options.child");
      } else if (data == "Inf(lap)") {
        return this.$t("formsContents.options.infant");
      }
    },

    async airlineLogo_func() {
      let airlogoUrl = this.airLogoApi.url;
      let airlogoType = this.airLogoApi.type;
      console.log(this.airLogoApi.url,'this.airLogoApi.url')

      await axios
        .get(airlogoUrl, {
          headers: {
            "Content-Type": airlogoType,
          },
        })
        .then((response) => {
          if (response.data) {
            this.airlogodata = response.data;
            console.log(this.airlogodata, "ressloglooglooglooglool")
          }
        })
        .catch((error) => {
          console.log(error, "ressloerrrororooool");
        });
    },
  },

  watch: {


    "$i18n.locale": {
      handler: function () {
        this.getSalutation();
        this.getSalutationChild();
        this.getGender();
        this.translatePaxType();
      },
      deep: true,
    },


    adultData: {
      deep: true,
      handler(newData) {
        if (newData) {
          newData.forEach((data) => {
            if (data.salutation !== "Mr") {
              data.gender = "Female";
            } else {
              data.gender = "Male";
            }

            if (data.dateDob.date) {
              // console.log(data.dateOfBirth.date,'newDatanewData')
              if (
                data.dateDob.year &&
                data.dateDob.month &&
                data.dateDob.date
              ) {
                const monthIndex = this.monthList.indexOf(data.dateDob.month);
                const convertDob = new Date(
                  parseInt(data.dateDob.year),
                  monthIndex,
                  parseInt(data.dateDob.date)
                );

                const formatDate = moment(convertDob).format("YYYY-MMM-DD");

                data.dateOfBirth = formatDate;

                // console.log(data.dateOfBirth, 'data.dateOfBirthdata.dateOfBirth')
              }
            }
          });
        }
        // if (newData) {
        //   console.log(newData, "newDatanewDatanewDatanewDatanewDatanewData");
        //   newData.forEach((data) => {
        //     console.log(data,'pppppp')
        //     if (data.salutation !== "Mr") {
        //       data.gender = "Female";
        //     } else {
        //       data.gender = "Male";
        //     }
        //   });
        // } else {
        //   console.log(
        //     newData,
        //     "newDatanewDatanewDatanewDatanewDatanewDataelse"
        //   );
        // }
      },
    },

    childData: {
      deep: true,
      handler(newData) {
        if (newData) {
          newData.forEach((data) => {
            // this.passeSeatNameData(data, index);
            if (data.salutation !== "Mr") {
              data.gender = "Female";
            } else {
              data.gender = "Male";
            }

            if (data.dateDob.date) {
              // console.log(data.dateOfBirth.date,'newDatanewData')
              if (
                data.dateDob.year &&
                data.dateDob.month &&
                data.dateDob.date
              ) {
                const monthIndex = this.monthList.indexOf(data.dateDob.month);
                const convertDob = new Date(
                  parseInt(data.dateDob.year),
                  monthIndex,
                  parseInt(data.dateDob.date)
                );

                const formatDate = moment(convertDob).format("YYYY-MMM-DD");

                data.dateOfBirth = formatDate;

                // console.log(data.dateOfBirth, 'data.dateOfBirthdata.dateOfBirth')
              }
            }
          });
        }
        // if (newData) {
        //   newData.forEach((data) => {
        //     if (data.salutation !== "Mstr") {
        //       data.gender = "Female";
        //     } else {
        //       data.gender = "Male";
        //     }
        //   });
        // }
      },
    },

    infrantData: {
      deep: true,
      handler(newData) {
       
        newData.forEach((data) => {
          // this.passeSeatNameData(data, index);
          if (data.salutation !== "Mr") {
            data.gender = "Female";
          } else {
            data.gender = "Male";
          }

          if (data.dateDob.date) {
            // console.log(data.dateOfBirth.date,'newDatanewData')
            if (data.dateDob.year && data.dateDob.month && data.dateDob.date) {
              const monthIndex = this.monthList.indexOf(data.dateDob.month);
              const convertDob = new Date(
                parseInt(data.dateDob.year),
                monthIndex,
                parseInt(data.dateDob.date)
              );

              const formatDate = moment(convertDob).format("YYYY-MMM-DD");

              data.dateOfBirth = formatDate;

              // console.log(data.dateOfBirth, 'data.dateOfBirthdata.dateOfBirth')
            }
          }
        });
        // newData.forEach((data) => {
        //   if (data.salutation !== "Mstr") {
        //     data.gender = "Female";
        //   } else {
        //     data.gender = "Male";
        //   }
        // });
      },
    },

    



    "data.number"(newValue) {
      console.log(newValue, "ndnjdnjdnjnd");

      if (newValue.length <= 12 && newValue.length > 5) {
        this.data.number = newValue.slice(0, 12);
      }
    },

    // "data.number"(newValue) {

    //   if (newValue.length <= 12 && newValue.length >5) {
    //     this.data.number = newValue.slice(0, 12);
    //   }
    // },
  },
  computed: {
    getDataResult() {
      if (this.roundresult && this.roundresult.length > 0) {
        return this.roundresult;
      } else if (this.onewayresult && this.onewayresult.length > 0) {
        return this.onewayresult;
      } else if (this.multiresult && this.multiresult.length > 0) {
        return this.multiresult;
      }

      return [];
    },
  },

  mounted() {
    // setTimeout(() => {
    //   this.isLoading = false;
    // }, 2000);
    (function (u, p, l, i, f, t, o, b, j) { 
      u['UpLiftPlatformObject'] = f; 
      u[f] = u[f] || function () { (u[f].q = u[f].q || []).push(arguments) }; 
      u[f].l = 1 * new Date(); 
      b = p.createElement(l), j = p.getElementsByTagName(l)[0]; 
      b.async = 1; 
      b.src = i + '?upcode=' + t; 
      j.parentNode.insertBefore(b, j); 
      o = u.location.host.match(/[\w-]+\.\w{2,3}(:\d+)?$/); 
      if (o) o = o[0]; 
      u[f]('create', t, o);
    }) (window, document, 'script', 'https://tag.uplift.com/external/up.js', 'up', 'UP-48608936-99');
  
    this.getSalutation();
    this.getSalutationChild();
    this.getGender();

    country1.forEach((v) => {
      let obj = {
        name: `${v.name}(${v.dial_code})`,
        code: v.code,
      };
      let obj1 = {
        name: `${v.name}`,
        code: v.code,
      };
      this.countrycode.push(obj);
      this.countrycode1.push(obj1);
    });

    let result1 = localStorage.getItem("value1");
    console.log(result1, "123245");

    // console.log(this.dateOfBirth, "dobdob.....");

    this.getData();
    this.getChild();
    this.getinfrant();
  },

  created() {
    this.getConfig();
    
    let $data = localStorage.getItem("loader");
    if ($data == "true") {
      this.$router.push("/");
    }

    this.local_id = this.$route.query.pass_id || this.$route.query.pass_uid;
    let data = location.href;
    this.Localoneway_id = this.$route.query.pass_uid1;
    console.log(this.local_id,data, "this.local_idthis.local_id");

    if (data) {
      if (data.includes("env")) {
        console.log(this.$route.query,this.$route.query.rsource,this.$route.query.rsource_type,'this.$route.query')
        this.portal = true;
        this.portal_offId = this.$route.query.pass_uid;
        this.portal_shopId = this.$route.query.response_id;
        this.portalDataConvert();
       
      }
    }

  if (this.Localoneway_id) {
      let onewaydata_uid = [];
      onewaydata_uid = JSON.parse(
        sessionStorage.getItem(`${this.Localoneway_id}`)
      );
      if (onewaydata_uid) {
        this.onewayresult.push(onewaydata_uid);
        console.log(this.onewayresult, "onewayresultttttt");
        this.onewayData();
        this.itineraryInfo1 = true;
        setTimeout(() => {
          this.pageLoader = false;
          this.getsegment();
        }, 2000)
      } else {
        console.log("SomethingwentWrong...1");
        this.dialog4 = true;
      }
    }
    else if (this.local_id) {
      if (this.local_id.includes("multi")) {
        let data_uid = [];
        data_uid = JSON.parse(sessionStorage.getItem(`${this.local_id}`));
        if (data_uid) {
          this.multiresult.push(data_uid);
          this.multiData();
          setTimeout(() => {
            this.pageLoader = false;
            this.getsegment();
          }, 2000)
          console.log(data_uid, "multicitymulticitymulticity");
        } else {
          console.log("SomethingwentWrong...2");
          this.dialog4 = true;
        }
      } else {
        let data_uid = [];
        data_uid = JSON.parse(sessionStorage.getItem(`${this.local_id}`));

        if (data_uid) {
          this.roundresult.push(data_uid);
          console.log(this.roundresult, "thisroundresultthisroundresult");
          this.getadult();
          this.itineraryInfo = true;
          setTimeout(() => {
            this.pageLoader = false;
            this.getsegment();
          }, 2000)
        }
        else {
          console.log("SomethingwentWrong...3");
          // this.dialog4 = true;
        }
      }
    }
    else {
      console.log("SomethingwentWrong...4");
      this.dialog4 = true;
    }

    if (this.roundresult.length > 0) {
      this.shoppingid = this.roundresult[0].ShoppingResponseId;
      this.selectId = this.roundresult[0].selectId;
      console.log(this.roundresult,this.flightname,'flightname')
      this.roundresult[0].Depature.forEach((c)=>{
        // console.log(c.MarketingCarrier,'flightname')
        if(!this.flightname.some(d=>d.AirlineID == c.MarketingCarrier.AirlineID))
        this.flightname.push(c.MarketingCarrier)
      })
      this.roundresult[0].Return.forEach((c)=>{
        if(!this.flightname.some(d=>d.AirlineID == c.MarketingCarrier.AirlineID))
        this.flightname.push(c.MarketingCarrier)
      })
      
      // console.log(this.roundresult,this.flightname,'flightname')
      //this.getseatMapping();
    } else if (this.onewayresult.length > 0) {
      this.shoppingid = this.onewayresult[0].ShoppingResponseId;
      this.selectId = this.onewayresult[0].selectId;
      this.onewayresult[0].Depature.forEach((c)=>{
        // console.log(c.MarketingCarrier,'flightname')
        if(!this.flightname.some(d=>d.AirlineID == c.MarketingCarrier.AirlineID))
        this.flightname.push(c.MarketingCarrier)
      })
      //this.getseatMapping();
    } else if (this.multiresult.length > 0) {
      this.shoppingid = this.multiresult[0].ShoppingResponseId;
      this.selectId = this.multiresult[0].selectId;
      console.log(this.multiresult,this.flightname,'flightname')
      this.multiresult[0].Flight1.forEach((c)=>{
        if(!this.flightname.some(d=>d.AirlineID == c.MarketingCarrier.AirlineID))
        this.flightname.push(c.MarketingCarrier)
      })
      this.multiresult[0].Flight2.forEach((c)=>{
        if(!this.flightname.some(d=>d.AirlineID == c.MarketingCarrier.AirlineID))
        this.flightname.push(c.MarketingCarrier)
      })
  if(this.multiresult[0]?.Flight3?.length > 0){
    this.multiresult[0].Flight3.forEach((c)=>{
        if(!this.flightname.some(d=>d.AirlineID == c.MarketingCarrier.AirlineID))
        this.flightname.push(c.MarketingCarrier)
      })
  }
  if(this.multiresult[0]?.Flight4?.length > 0){
    this.multiresult[0].Flight4.forEach((c)=>{
        if(!this.flightname.some(d=>d.AirlineID == c.MarketingCarrier.AirlineID))
        this.flightname.push(c.MarketingCarrier)
      })
  }


      //this.getseatMapping();
    }

    // else {
    //         this.$router.push("/")
    //         console.log("workiiiii....1")
    //       }
  },
};
</script>

<style scoped>
.change-plan {
    background-color: #4c8ae240;
    border-radius: 50px;
    text-align: center;
    font-weight: 400;
    color: black;
    padding: 5px;
    margin: 20px 0px;
    border: 2px solid #003580;
}

.form-control {
  padding: 0 !important;
}

.form-group label {
  margin-bottom: 0 !important;
}

:deep(.v-field__input) {
  min-height: 38px !important;
  padding: 0 8px !important;
}

/* :deep(.v-text-field input) {
  min-height: 38px !important;
  padding: 0 8px !important;
} */

.table-bordered> :not(caption)>* {
  padding: 10px !important;
}

.p-error {
  font-size: 12px;
  color: #b00020;
  letter-spacing: 0.0333333333em;
  transition-duration: 150ms;
  position: relative;
}

table[data-v-4aed7f06] {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid lightgray;
  table-layout: fixed;
  font-size: 10px;
}

.drawer-section .offcanvas {
  width: 35%;
}


:deep(.cancellationRules img){
    width: 50px !important;
}
:deep(.cancellationRules h2){
    font-size:18px !important;
    font-family: "Poppins", sans-serif !important;
    color: #343a40 !important;
}
:deep(.cancellationRules p){
  font-family: 'roboto', sans-serif !important;
  color: #5f6367 !important;
}
:deep(.error-border .dp__input){
  border:1px solid #b00020 !important;
}
:deep(.error-border input::placeholder){
  color:#b00020 !important;
}
:deep(.error-border .dp__input_icon){
  color:#b00020 !important;
}
:deep(.countryInput input::placeholder){
  color:black;
}

@media screen and (max-width: 430px) {
  .drawer-section .offcanvas {
    width: 100% !important;
  }
}
</style>
